// Page with Google Form to fill
import React from 'react';
import { TooltipProvider } from '../contexts/TooltipContext'; // Needed for header to work
import { useTestContext } from '../contexts/TestContext';
import Header from '../components/common/Header';
import FeedbackForm from '../components/inputs/FeedbackForm';

const CandidateFeedbackPage = () => {
  const { feedbackLink } = useTestContext();

  return (
    <TooltipProvider>
        <Header />
        <FeedbackForm feedbackLink={feedbackLink} />
    </TooltipProvider>
  );
};

export default CandidateFeedbackPage;
