// Form the form to edit a user
import React, { useEffect } from 'react';
import styles from '../../sharedStyles/AdminFormsStyles.module.css';

const EditUserForm = ({ editedUserFirstName, editedUserLastName, editedUserEmail, setFirstName, setLastName, setEmail }) => {

    return (
        <div>
            <div className={styles.inputGroup}>
                <label>Nome(s)</label>
                <input
                    type="text"
                    value={editedUserFirstName}  // Bind to the name state
                    onChange={(e) => setFirstName(e.target.value)}
                />
            </div>
            <div className={styles.inputGroup}>
                <label>Sobrenome(s)</label>
                <input
                    type="text"
                    value={editedUserLastName}  // Bind to the name state
                    onChange={(e) => setLastName(e.target.value)}
                />
            </div>
            <div className={styles.inputGroup}>
                <label>Email</label>
                <input
                    type="email"
                    value={editedUserEmail}  // Bind to the email state
                    onChange={(e) => setEmail(e.target.value)}
                />
            </div>
        </div>
    );
};

export default EditUserForm;

