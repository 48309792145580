// Simple input field 
import React from 'react';
import PropTypes from 'prop-types';
import styles from './InputField.module.css'; 

const InputField = ({ type, value, onChange, placeholder }) => {
  return (
    <div className={styles.inputFieldContainer}>
      <input
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className={styles.inputField}
      />
    </div>
  );
};

InputField.propTypes = {
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default InputField;
