import React, { useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import KanbanColumn from './KanbanColumn';
import initialData from './KanbanData.json';  // Assuming initial data import
import styles from './KanbanBoard.module.css';

const KanbanBoard = () => {
    const [kanbanData, setKanbanData] = useState(initialData);

    const onDragEnd = (result) => {
        const { source, destination } = result;
        if (!destination || (source.droppableId === destination.droppableId && source.index === destination.index)) {
            return;
        }
        const sourceColumn = kanbanData[source.droppableId];
        const destColumn = kanbanData[destination.droppableId];
        const sourceCards = [...sourceColumn];
        const destCards = destination.droppableId === source.droppableId ? sourceCards : [...destColumn];
        const [removed] = sourceCards.splice(source.index, 1);
        destCards.splice(destination.index, 0, removed);

        const newKanbanData = {
            ...kanbanData,
            [source.droppableId]: sourceCards,
            [destination.droppableId]: destCards
        };
        setKanbanData(newKanbanData);
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <div className={styles.board}>
                {Object.entries(kanbanData).map(([columnName, cards]) => (
                    <KanbanColumn key={columnName} title={columnName} cards={cards} droppableId={columnName} />
                ))}
            </div>
        </DragDropContext>
    );
};

export default KanbanBoard;
