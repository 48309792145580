import React, { useEffect } from 'react';
import ActionButton from '../common/ActionButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faTrash, faSync } from '@fortawesome/free-solid-svg-icons';
import styles from './ReportSummarySidebarFilters.module.css';

const ReportSummarySidebarFilters = ({ 
    roleList, 
    roleFilters, 
    handleRoleFilterChange,
    totalCount,
    roleCounts,
    tagFilters, 
    handleTagFilterChange, 
    tagCounts,
    handleAddCompanyTag, 
    companyCustomTags, 
    handleEditCompanyTag, 
    handleRemoveTag, 
    handleSelectAll, 
    selectAll, 
    candidateNameFilter, 
    handleCandidateNameChange,
    companies,
    companyFilters,
    handleCompanyFilterChange,
    companyCounts,
    approvedFilters,
    handleApprovedFilterChange,
    approvedCounts,
    isGlobalDashboard = false,
    refreshData,
}) => {

    // Sort roles list by count
    const sortedRoles = (roleList && roleCounts) ? roleList
        .filter(role => roleCounts[role.roleCode])
        .sort((a, b) => roleCounts[b.roleCode] - roleCounts[a.roleCode])
        : [];

   // Sort companies list by count
   const sortedCompanies = (companies && companies.length > 0 && companyCounts) ? companies
        .filter(company => companyCounts[company.companyCode])
        .sort((a, b) => companyCounts[b.companyCode] - companyCounts[a.companyCode])
        : [];


    // Sort approved list by count
    const sortedApproved = approvedCounts ? Object.entries(approvedCounts)
        .sort((a, b) => b[1] - a[1])
        : [];

    // Sort tags list by count
    const sortedTags = (companyCustomTags && tagCounts) ? companyCustomTags
        .sort((a, b) => (tagCounts[b.tag_id] || 0) - (tagCounts[a.tag_id] || 0))
        : [];


    
    return (
        <div className={styles.sidebarContainer}>
            <input
                type="text"
                placeholder="Buscar por nome"
                value={candidateNameFilter}
                onChange={handleCandidateNameChange}
                className={styles.filterInput}
            />
            <div className={`${styles.totalFilterRow} ${styles.filterRow}`}>
                <input
                    type="radio"
                    id="selectAll"
                    name="roleFilter"
                    checked={selectAll}
                    onChange={handleSelectAll}
                />
                <label htmlFor="selectAll">
                    Todos ({totalCount})
                </label>
            </div>
            {/* Role filters */}
            <div className={styles.filterTitle}>Cargos</div>
            {sortedRoles.map(
                (role) => (
                    <div key={role.roleCode} className={styles.filterRow}>
                        <input
                            type="checkbox"
                            id={role.roleCode}
                            name="roleFilter"
                            checked={
                                roleFilters.includes(role.roleCode) && !selectAll
                            }
                            onChange={() => handleRoleFilterChange(role.roleCode)}
                        />
                        <label htmlFor={role.roleCode}>
                            {role.roleName} ({roleCounts[role.roleCode]})
                        </label>
                    </div>
                )
            )}
            {/* Company filters */}
            {isGlobalDashboard && sortedCompanies && sortedCompanies.length > 0 && (
                <>
                    <div className={styles.filterTitle}>Empresas</div>
                    {sortedCompanies.map(
                        (company) => (
                            <div key={company.companyCode} className={styles.filterRow}>
                                <input
                                    type="checkbox"
                                    id={company.companyCode}
                                    name="companyFilter"
                                    checked={
                                        companyFilters.includes(company.companyCode) && !selectAll
                                    }
                                    onChange={() => handleCompanyFilterChange(company.companyCode)}
                                />
                                <label htmlFor={company.companyCode}>
                                    {company.companyName} ({companyCounts[company.companyCode]})
                                </label>
                            </div>
                        )
                    )}
                </>
            )}
           {/* Approved filter */}
           {isGlobalDashboard && sortedApproved.length > 0 && (
                <>
                    <div className={styles.filterTitle}>Approved</div>
                    {sortedApproved.map(([approved, count]) => (
                        <div key={approved} className={styles.filterRow}>
                            <input
                                type="checkbox"
                                id={`approved-${approved}`}
                                name="approvedFilter"
                                checked={approvedFilters.includes(approved) && !selectAll}
                                onChange={() => handleApprovedFilterChange(approved)}
                            />
                            <label htmlFor={`approved-${approved}`}>
                                {approved} ({count})
                            </label>
                        </div>
                    ))}
                </>
            )}
            {/* Tag filters */}
            <div className={styles.filterTitle}>
                Tags
                <ActionButton 
                    icon={faPlus} 
                    label="Adicionar Tag" 
                    onClick={handleAddCompanyTag}
                    showHoverPopout={true}
                    popoutText="Adicionar tag"
                    popoutPosition="left"
                />
            </div>
            {sortedTags.map((tag) => (
                <div key={tag.tag_id} className={styles.tagFilterRow}>
                    <div className={styles.tagAndCheckbox}>
                        <input
                            type="checkbox"
                            id={`tag-${tag.tag_id}`}
                            name="tagFilter"
                            checked={tagFilters.includes(tag.tag_name)}
                            onChange={() => handleTagFilterChange(tag.tag_name)}
                        />
                        <label
                            className={styles.tagLabel}
                            htmlFor={`tag-${tag.tag_id}`}
                            style={{
                                backgroundColor: tag.colour,
                                color: tag.text_colour,
                            }}
                        >
                            {tag.tag_name}{tagCounts[tag.tag_id] > 0 ? ` (${tagCounts[tag.tag_id]})` : ''}
                        </label>
                    </div>
                    <div className={styles.tagActions}>
                        <ActionButton 
                            icon={faEdit} 
                            label="Editar Tag" 
                            onClick={() => handleEditCompanyTag(tag.tag_id)}
                            showHoverPopout={true}
                            popoutText="Editar tag"
                            popoutPosition="left"
                        />
                        <ActionButton 
                            icon={faTrash} 
                            label="Excluir Tag" 
                            onClick={() => handleRemoveTag(tag.tag_id)}
                            showHoverPopout={true}
                            popoutText="Excluir tag"
                            popoutPosition="left"
                        />
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ReportSummarySidebarFilters;
