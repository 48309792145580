import React from "react";
import { useTestContext } from "../contexts/TestContext";
import Chatbox from "./chat/Chatbox";
import KanbanBoard from "./crm/KanbanBoard";
import BackendInterface from "./interfaces/BackendInterface";
import Popup from "./popup/Popup";
import ScreenCover from "./popup/ScreenCover";

const TestStageComponents = ({}) => {
  const {
    testStage,
    updateTermsStatus,
    acceptTerms,
    roleDetails,
    activeTest,
    isTestInterrupted,
    currentSection,
  } = useTestContext();

  // General error popup renderer
  const renderErrorPopup = (variant, zIndex) => (
    <Popup variant={variant} isVisible={true} zIndex={zIndex} />
  );

  // Function to render the correct interface based on the current section
  const renderInterface = () => {
    if (!currentSection || !currentSection.UI) return null; // If there is no current section or UI defined, return null

    const { interface: interfaceType } = currentSection.UI; // Get the interface type from the current section

    switch (interfaceType) {
      case "BackendInterface":
        return <BackendInterface />;
      case "SalesInterface":
        return <KanbanBoard />;
      case "Chatbox":
        return (
          <Chatbox
            style={{
              height: `calc(100vh - 60px)`,
              width: "100%",
              margin: "45px 0 0 0",
            }}
          />
        );
      // Add other interfaces as needed
      default:
        return (
          <Chatbox
            style={{
              height: `100vh`,
              width: "100%",
              margin: "0",
            }}
          />
        );
    }
  };

  // Render different components based on the testStage
  switch (testStage) {
    case "initialLoading": // Cover when loading the initial components
      return <ScreenCover isVisible={true} variant="complete" zIndex={11} />;

    case "acceptTerms": // T&Cs popup and checkbox that user needs to click before proceeding
      return (
        <>
          <ScreenCover isVisible={true} variant="blur" zIndex={11} />
          <Popup
            variant="TermsContent"
            isVisible={true}
            onConfirm={acceptTerms}
            onTermsChange={updateTermsStatus}
            zIndex={12}
            showCloseButton={true}
          />
        </>
      );

    case "introChat": // Chatbox to introduce the user to the test
      return (
        <>
          <Chatbox
            style={{
              height: `100vh`,
              width: "100%",
              margin: "0",
            }}
          />
          {activeTest && (
            <>
              <ScreenCover isVisible={true} variant="complete" zIndex={15} />
              <Popup variant="ActiveTest" isVisible={true} zIndex={16} />
            </>
          )}
        </>
      );

    case "test":
      return (
        <>
          {renderInterface()}
          {isTestInterrupted.interrupted && (
            // If there is an issue with the test, show the error popup, keeping the rendered test behind as it may solve within the retry time
            <>
              <ScreenCover isVisible={true} variant="blur" zIndex={15} />
              {renderErrorPopup("TestInterrupted", 16)}
            </>
          )}
        </>
      );

    case "endOfTest": // Popup to show the user that the test is complete and button to click for feedback
      return (
        <>
          <ScreenCover isVisible={true} variant="complete" zIndex={11} />
          <Popup
            variant="EndTest"
            isVisible={true}
            zIndex={12}
            showCloseButton={true}
            buttonLink="/feedback"
          />
        </>
      );

    // Error handling states
    case "loadFailed":
    case "TestDown":
    case "retrievalFailed":
    case "uniqueUrlFail":
    case "saveFailed":
    case "retrievedTestComplete":
      return (
        <>
          <ScreenCover isVisible={true} variant="complete" zIndex={15} />
          {renderErrorPopup(testStage, 16)}
        </>
      );

    default:
      // Handle unknown stages
      return <ScreenCover isVisible={true} variant="blur" zIndex={10} />;
  }
};

export default TestStageComponents;
