import React, { useState, useEffect, useRef } from 'react';
import { useCodeContext } from '../../contexts/CodeContext';
import CodeDropdown from './CodeDropdown';
import CodeFontSize from './CodeFontSize';
import SqlEditor from './SqlEditor';
import SqlOutput from './SqlOutput';
import SqlLogOutput from './SqlLogOutput';
import SqlTreeView from './SqlTreeView';
import Button from '../common/Button';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faBolt } from '@fortawesome/free-solid-svg-icons';
import { PulseLoader } from 'react-spinners'; // Show loading icon when sql query is running
import styles from './SqlInterface.module.css';

const SqlInterface = () => {
    const { sqlLogs, sqlQueryData, runQuery, isFirstSqlRun, themeDropdownOptions, handleThemeChange, themeDropdownValue, theme, currentlyCompilingSql } = useCodeContext();
    const themeClass = theme === 'dark' ? styles.dark : styles.light;
    const [autocompleteEnabled, setAutocompleteEnabled] = useState(true);
    const editorRef = useRef(null);
    const outputRef = useRef(null);
    const logOutputRef = useRef(null);
    const [showLeftTab, setShowLeftTab] = useState(true);

    // Adjust the heights dynamically
    useEffect(() => {
        const adjustHeights = () => {
            if (logOutputRef.current && editorRef.current) { // Ensure refs are available
                const outerBuffer = 102; // Buffer for header (42) and tabs (32) and topRibbon (28)
                const logBuffer = 5; // Buffer for border and padding of logOutput
                const outputBuffer = 4; // Buffer for border, any padding 

                // Reset heights to calculate based on content
                logOutputRef.current.style.height = 'auto'; // Reset height to auto to calculate based on content
                if (outputRef.current) {
                    outputRef.current.style.height = 'auto';
                }
                editorRef.current.style.height = 'auto';

                // Get the height needed for the log and output
                const logOutputHeight = Math.min(Math.max(logOutputRef.current.scrollHeight, 40), 158) + logBuffer; // Ensure logOutput has at least 40px height and at most 155px (enough to show 5 logs)
                const outputHeight = outputRef.current ? Math.min(Math.max(outputRef.current.scrollHeight, 30), window.innerHeight * 0.30) + outputBuffer : 0; // Ensure output has at least 30px height and at most 30% of the window height
                
                // Calculate the remaining height for the editor
                const remainingHeight = window.innerHeight - logOutputHeight - outputHeight - outerBuffer; 

                // Set the heights
                logOutputRef.current.style.height = `${logOutputHeight}px`; // Set the height of logOutput
                if (outputRef.current) {
                    outputRef.current.style.height = `${outputHeight}px`; // Set the height of output
                }
                editorRef.current.style.height = `${Math.max(remainingHeight, 100)}px`; // Ensure editor has at least 100px height
            }
        };

        adjustHeights(); 
    }, [currentlyCompilingSql, sqlQueryData, sqlLogs]);

    // Toggle the left tab
    const toggleLeftTab = () => {
        setShowLeftTab(!showLeftTab);
    };

    const tabIcon = showLeftTab ? faChevronLeft : faChevronRight;

    return (
        <div className={`${styles.sqlInterfaceContainer} ${themeClass}`}>
            <div className={`${styles.leftTab} ${!showLeftTab ? styles.hidden : ''}`}>
                <button className={styles.toggleBtn} onClick={toggleLeftTab}>
                    <FontAwesomeIcon icon={tabIcon} />
                </button>
                {showLeftTab && (
                    <SqlTreeView />
                )}
            </div>
            <div className={`${styles.mainTab} ${!showLeftTab ? styles.wide : ''}`}>
                <div className={`${styles.topRibbon} ${themeClass}`}>
                    <div className={styles.topRibbonLeft}>
                        <p>Linguagem: SQLite</p>
                    </div>
                    <div className={styles.topRibbonRight}>
                        <CodeDropdown
                            options={themeDropdownOptions}
                            onChange={handleThemeChange}
                            currentValue={themeDropdownValue}
                        />
                        <CodeFontSize />
                    </div>
                </div>

                <div className={styles.editor} ref={editorRef}>
                    <SqlEditor
                        autocompleteEnabled={autocompleteEnabled}
                    />
                </div>

                <div className={`${styles.middleRibbon} ${themeClass}`}>

                    {currentlyCompilingSql ? (
                        <div className={`${styles.sqlExecuteLoader} ${themeClass}`}>
                            <PulseLoader size={7} color={"#246E24"} />
                            <p>{isFirstSqlRun ? '' : 'Executando query'}</p>
                        </div>
                    ) : (
                        <Button type="executeCode" onClick={() => runQuery()} />
                    )}
                </div>

                {sqlQueryData.length > 0 && (
                    <div className={styles.output} ref={outputRef}>
                        <SqlOutput
                            data={sqlQueryData}
                            theme={theme}
                        />
                    </div>
                )}

                <div className={styles.logOutput} ref={logOutputRef}>
                    <SqlLogOutput />
                </div>
            </div>
        </div>
    );
};

export default SqlInterface;
