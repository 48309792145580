import React from 'react';
import Logo from '../common/DegrauLogo';
import styles from './Popup.module.css'; 

const ReportLoadIssueContent = ({ onLoad, logoLoaded, ...otherProps }) => {
    return (
        <>
            <Logo className={styles.degrauLogoPopup} onLoad={onLoad} /> 
            <div className={styles.popupTextLeft}>
            <p>Desculpe, mas estamos enfrentando dificuldades para carregar esse relatório. Por favor, tente o seguinte:</p>
                <ul style={{ listStylePosition: 'inside' }}>
                    <li>Verifique sua conexão de internet.</li>
                    <li>Certifique-se de que você tem acesso a este relatório ou está logado, se for um usuário.</li>
                </ul>
            <p>Se o problema persistir, por favor, entre em contato conosco pelo e-mail <b>suporte@degrau.co</b>. Estamos aqui para ajudar!</p>
        </div>
        </>
    );
};

export default ReportLoadIssueContent;