import React from 'react';
import PropTypes from 'prop-types';
import { useCodeContext } from '../../contexts/CodeContext';
import styles from './CodeFontSize.module.css';

const CodeFontSize = () => {
    const { editorFontSize, setEditorFontSize, theme } = useCodeContext();
    const themeClass = theme === 'dark' ? styles.dark : styles.light;

    return (
        <div className={`${styles.fontSizeControls} ${themeClass}`}>
            <i 
                className="fas fa-minus" 
                onClick={() => setEditorFontSize(Math.max(10, editorFontSize - 2))} 
                style={{ cursor: 'pointer' }}
            ></i>
            <span className={styles.fontSizeIndicator}>{editorFontSize}</span>
            <i 
                className="fas fa-plus" 
                onClick={() => setEditorFontSize(Math.min(20, editorFontSize + 2))} 
                style={{ cursor: 'pointer' }}
            ></i>
        </div>
    );
};

export default CodeFontSize;
