const freeTrialMessage = {
    title: 'Experimente grátis',
    details: [
        '28 dias',
        '10 entrevistas',
        'IA entevistadora e availiadora',
        'Feedback detalhado',
        '100% automatizado',
        'Sem cartão de crédito e contrato',

    ]
};

export default freeTrialMessage;
