const formatDatabaseSchema = (schema) => {
    // Check if schema is an array and not empty
    if (!Array.isArray(schema) || schema.length === 0) {
        return 'Invalid schema';
    }

    return schema.map(table => {
        // Check if table has a name and columns
        if (!table.name || !Array.isArray(table.columns)) {
            return 'Invalid table structure';
        }

        const columns = table.columns.map(col => {
            // Check if column has a name and type
            if (!col.name || !col.type) {
                return 'Invalid column structure';
            }
            return `            { name: '${col.name}', type: '${col.type}' }`;
        }).join(',\n');

        return `    {\n        name: '${table.name}',\n        columns: [\n${columns}\n        ]\n    }`;
    }).join(',\n');
};

export default formatDatabaseSchema;
