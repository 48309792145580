// Track user feedback across multiple components
import React, { createContext, useEffect, useState } from 'react';

// Create a context to share feedback state across components
const FeedbackContext = createContext(); // Name your context

// Create a provider component to wrap your app
export const FeedbackProvider = ({ children }) => {
    const [feedbackMap, setFeedbackMap] = useState({});

    const updateFeedback = (key, feedbackDetails) => {
    
        setFeedbackMap((prev) => ({
            ...prev,
            [key]: {
                ...prev[key], // Retain existing properties
                feedbackId: feedbackDetails.feedbackId, // Use feedbackId, not 'id'
                eval: feedbackDetails.eval,
                text: prev[key]?.text || '', // Retain or initialize text
                content: feedbackDetails.content,
                location: feedbackDetails.location,
                test_attempt_id: feedbackDetails.test_attempt_id,
                source: feedbackDetails.source,
                prompt_id: feedbackDetails.prompt_id,
                timestamp: feedbackDetails.timestamp,
            },
        }));
    };    

    const updateFeedbackText = (key, text) => {
        setFeedbackMap((prev) => ({
            ...prev,
            [key]: { ...prev[key], text }, // Update text while preserving the previous structure
        }));
    };

    return (
        <FeedbackContext.Provider value={{ feedbackMap, updateFeedback, updateFeedbackText }}>
            {children}
        </FeedbackContext.Provider>
    );
};

export default FeedbackContext; // Export the context to use it in other components
