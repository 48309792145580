/**
 * Checks if a user has a specific permission at a given level.
 * 
 * @param {Array} userPermissions - Array of user permissions in the format 'PERMISSION:LEVEL'
 * @param {string} permission - The permission to check, e.g., 'VIEW_USERS', 'ADD_USER'
 * @param {string} [level] - The level to check, either 'GLOBAL' or 'ORGANISATION'. If not provided, it checks for the permission at any level.
 * @returns {boolean} - True if the user has the permission at the specified level or at any level if no level is provided, false otherwise
 */
export const hasPermission = (userPermissions, permission, level) => {
    
    // Basic validation for userPermissions
    if (!Array.isArray(userPermissions)) {
        return false;
    }

    if (typeof permission !== 'string' || permission.trim() === '') {
        return false;
    }

    if (level && (typeof level !== 'string' || (level !== 'GLOBAL' && level !== 'ORGANISATION'))) {
        return false;
    }

    // Check if the user has the required permission
    if (level) {
        return userPermissions.includes(`${permission}:${level}`);
    } else {
        return userPermissions.some(userPermission => userPermission.startsWith(`${permission}:`));
    }
};
