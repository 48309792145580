// Table with the results of the interviews for the given company
export const getCompanyResultsTableColumns = (isGlobalDashboard, companies) => [
    { key: 'candidate_name', label: 'Nome', sortable: true, align: 'left' },
    ...(isGlobalDashboard && companies && companies.length > 0 ? [
        { key: 'company_code', label: 'Empresa', sortable: true, align: 'left' }
    ] : []),
    { key: 'role_code', label: 'Cargo', sortable: true, align: 'left' },
    { key: 'total_score', label: 'Nota', sortable: true, align: 'center' },
    { key: 'time_concluded', label: 'Teste realizado', sortable: true, align: 'left' },
    ...(isGlobalDashboard ? [
        { key: 'approved', label: 'Approved', sortable: true, align: 'center' },
        { key: 'test_attempt_id', label: 'Test attempt', sortable: true, align: 'center' },
        { key: 'results_id', label: 'Results ID', sortable: true, align: 'center' },
        { key: 'version', label: 'Version', sortable: true, align: 'center' }
    ] : []),
    { key: 'tags', label: 'Tags', sortable: false, align: 'left' },
    { key: 'actions', label: 'Ações', sortable: false, align: 'center' }
];
