import { useState, useEffect } from 'react';

const useFilterData = (initialData, resultTagsMap) => {
    const [filteredData, setFilteredData] = useState(initialData);
    const [roleFilters, setRoleFilters] = useState([]); // Filter by role
    const [companyFilters, setCompanyFilters] = useState([]); // Filter by company
    const [approvedFilters, setApprovedFilters] = useState([]); // Filter by approved status
    const [tagFilters, setTagFilters] = useState([]);
    const [selectAll, setSelectAll] = useState(true);
    const [candidateNameFilter, setCandidateNameFilter] = useState("");

    useEffect(() => {
        updateFilteredData(initialData, roleFilters, companyFilters, approvedFilters, candidateNameFilter, selectAll, tagFilters);
    }, [roleFilters, companyFilters, approvedFilters, candidateNameFilter, selectAll, tagFilters, initialData]);

    const updateFilteredData = (data, roleFilters, companyFilters, approvedFilters, nameFilter, selectAll, tagFilters) => {
        let newData = [...data];
        if (!selectAll) {
            if (roleFilters.length > 0) {
                newData = newData.filter((d) => roleFilters.includes(d.role_code));
            }
            if (companyFilters.length > 0) {
                newData = newData.filter((d) => companyFilters.includes(d.company_code));
            }
            if (approvedFilters.length > 0) {
                newData = newData.filter((d) => approvedFilters.includes(d.approved.toString()));
            }
        }
        if (nameFilter) {
            newData = newData.filter((d) =>
                d.candidate_name.toLowerCase().includes(nameFilter.toLowerCase())
            );
        }
        // Tag-based filtering
        if (tagFilters.length > 0) {
            newData = newData.filter((d) => {
                const resultTags = resultTagsMap[d.url_code]?.map((tag) => tag.tag_name) || [];
                return tagFilters.some((tagFilter) => resultTags.includes(tagFilter));
            });
        }
        setFilteredData(newData);
    };

    return {
        filteredData,
        roleFilters,
        companyFilters,
        approvedFilters,
        tagFilters,
        selectAll,
        candidateNameFilter,
        setRoleFilters,
        setCompanyFilters,
        setApprovedFilters,
        setTagFilters,
        setSelectAll,
        setCandidateNameFilter,
        handleRoleFilterChange: (roleCode) => {
            if (roleFilters.includes(roleCode)) {
                setRoleFilters(roleFilters.filter((code) => code !== roleCode));
            } else {
                setRoleFilters([...roleFilters, roleCode]);
            }
            setSelectAll(false);
        },
        handleCompanyFilterChange: (companyCode) => { 
            if (companyFilters.includes(companyCode)) {
                setCompanyFilters(companyFilters.filter((code) => code !== companyCode));
            } else {
                setCompanyFilters([...companyFilters, companyCode]);
            }
            setSelectAll(false);
        },
        handleApprovedFilterChange: (approved) => {
            const approvedString = approved.toString();
            if (approvedFilters.includes(approvedString)) {
                setApprovedFilters(approvedFilters.filter((a) => a !== approvedString));
            } else {
                setApprovedFilters([...approvedFilters, approvedString]);
            }
            setSelectAll(false);
        },
        handleTagFilterChange: (tagName) => {
            if (tagFilters.includes(tagName)) {
                setTagFilters(tagFilters.filter((tag) => tag !== tagName));
            } else {
                setTagFilters([...tagFilters, tagName]);
            }
            setSelectAll(false);
        },
        handleSelectAll: () => {
            setSelectAll(true);
            setRoleFilters([]);
            setTagFilters([]);
            setCompanyFilters([]);
            setApprovedFilters([]);
        },
        handleCandidateNameChange: (event) => {
            setCandidateNameFilter(event.target.value);
        }
    };
};

export default useFilterData;
