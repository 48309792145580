// Service to capture and save photos of candidates
import { logEvent, logException, logTrace } from './loggerFront';

const fileName = 'PhotoCaptureService' // For logging

// Take the photo and calculate the canvas size to match the video
const capturePhoto = (canvasRef, videoRef) => {
    if (canvasRef.current && videoRef.current) {
        const videoElement = videoRef.current;

        // Set the canvas dimensions to match the video stream's resolution for high-quality capture
        canvasRef.current.width = videoElement.videoWidth;
        canvasRef.current.height = videoElement.videoHeight;

        const context = canvasRef.current.getContext('2d');
        context.drawImage(videoElement, 0, 0, videoElement.videoWidth, videoElement.videoHeight);
    }
};

// Send the photo (blob) and identifying data to the backend to save in Azure Blob Services with a searchable but anonymous name. Also update the global photoUrl so we can save in testAttempt
const savePhoto = (canvasRef, candidateId, testAttemptId, setPhotoUrl ) => {
    return new Promise((resolve, reject) => {
        canvasRef.current.toBlob(blob => {
            const formData = new FormData();
            formData.append('photo', blob);
            formData.append('photoType', 'initial');
            formData.append('candidateId', candidateId);
            formData.append('testAttemptId', testAttemptId);

            fetch('/api/upload-photo', {
                method: 'POST',
                body: formData
            })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    setPhotoUrl(data.blobName);
                    resolve(data); // Resolve with response data
                } else {
                    logException('Photo upload failure', {
                        status: 'Failed',
                        type: 'Photo',
                        errorMessage: data.message,
                        candidateId: candidateId,
                        testAttemptId: testAttemptId,
                        fileName: fileName
                    });
                    reject(data.message);
                }
            })
            .catch(error => {
                logException('Photo upload error', {
                    status: 'Failed',
                    type: 'Photo',
                    errorMessage: error.toString(),
                    candidateId: candidateId,
                    testAttemptId: testAttemptId,
                    fileName: fileName
                });
                reject(error);
            });
        }, 'image/png');
    });
};

// For taking the ongoing photos (using the almost hidden video and canvas, not PhotoCheck.js). Managed in AppContext
const ongoingPhotoCapture = async (canvasRef, videoRef, candidateId, testAttemptId) => {
    // Check if the canvas or video references are missing
    if (!canvasRef.current || !videoRef.current) {
        logException('Missing canvas or video reference', {
            status: 'Failed',
            type: 'Photo',
            candidateId: candidateId,
            testAttemptId: testAttemptId,
            fileName: fileName
        });
        return;
    }
    if (videoRef.current.readyState !== 4) {
        logException('Video state error', {
            status: 'Failed',
            type: 'Photo',
            videState: videoRef.current.readyState,
            candidateId: candidateId,
            testAttemptId: testAttemptId,
            fileName: fileName
        });
        return;
    }

    // Check if the video stream is inactive; if so, try to reacquire it
    if (!videoRef.current.srcObject || !videoRef.current.srcObject.active) {
        try {
            const newStream = await navigator.mediaDevices.getUserMedia({ video: true });
            videoRef.current.srcObject = newStream;
            // Wait for the video to play before drawing to canvas
            await videoRef.current.play();
        } catch (error) {
            logException('Failed to acquire video stream', {
                status: 'Failed',
                type: 'Photo',
                errorMessage: error.toString(),
                candidateId: candidateId,
                testAttemptId: testAttemptId,
                fileName: fileName
            });
            return;
        }
    }

    // Make sure the video is playing
    if (videoRef.current.paused) {
        try {
            await videoRef.current.play();
        } catch (error) {
            logException('Video play exception', {
                status: 'Failed',
                type: 'Photo', 
                errorMessage: error.toString(),
                candidateId: candidateId,
                testAttemptId: testAttemptId,
                fileName: fileName
            });
            return;
        }
    }

    // Introduce a slight delay to allow the video stream to stabilize
    await new Promise(resolve => setTimeout(resolve, 1000)); // Delay for 1 second

    // Now that we have an active stream, proceed with capturing the photo
    const maxCanvasWidth = 720; // Maximum canvas width
    const aspectRatio = videoRef.current.videoWidth / videoRef.current.videoHeight;
    const canvasHeight = maxCanvasWidth / aspectRatio; // Calculate height to maintain aspect ratio

    canvasRef.current.width = maxCanvasWidth; // Set the canvas width
    canvasRef.current.height = canvasHeight; // Set the canvas height based on aspect ratio

    const context = canvasRef.current.getContext('2d');
    context.drawImage(videoRef.current, 0, 0, maxCanvasWidth, canvasHeight);

    // Make as a blob to store in Azure blob services 
    canvasRef.current.toBlob(blob => {
        const formData = new FormData();
        formData.append('photo', blob);
        formData.append('photoType', 'ongoing');
        formData.append('candidateId', candidateId);
        formData.append('testAttemptId', testAttemptId);

        fetch('/api/upload-photo', {
            method: 'POST',
            body: formData
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
            } else {
                logException('Ongoing photo upload failure', {
                    status: 'Failed',
                    type: 'Photo',
                    errorMessage: data.message,
                    candidateId: candidateId,
                    testAttemptId: testAttemptId,
                    fileName: fileName
                });
            }
        })
        .catch(error => logException('Ongoing photo upload error', {
            status: 'Failed',
            type: 'Photo',
            errorMessage: error.toString(),
            candidateId: candidateId,
            testAttemptId: testAttemptId,
            fileName: fileName
        }));
    }, 'image/png');
};

// Function to stop the video stream
const stopVideoStream = (videoRef) => {
    if (videoRef.current && videoRef.current.srcObject) {
        const stream = videoRef.current.srcObject;
        const tracks = stream.getTracks();

        tracks.forEach(track => {
            track.stop(); // Safe to call even if already stopped; effectively a no-op
        });

        videoRef.current.srcObject = null; // Also safe, ensures the video element isn't holding an inactive stream

        logTrace("Video stream stopped successfully", {
            type: 'Photo', 
            fileName: fileName
        });
    }
};

export { capturePhoto, savePhoto, ongoingPhotoCapture, stopVideoStream };

