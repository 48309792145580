import { useState, useEffect } from 'react';
import { getTagsByResult, addCompanyTag, updateCompanyTag, removeCompanyTag } from '../services/databaseService';

const useReportTags = (companyId, setResultTagsMap, fetchCompanyTags) => {

    // Adds or updates a personalised tag for the company
    const addOrUpdateTag = async ({ tag_id, name, color, textColor }, onTagUpdated) => {
        if (tag_id) {
            await updateCompanyTag({
                tagId: tag_id,
                tagName: name,
                companyId,
                colour: color,
                textColour: textColor,
            });
        } else {
            await addCompanyTag({
                tagName: name,
                companyId,
                colour: color,
                textColour: textColor,
            });
        }
        await fetchCompanyTags(companyId);
        if (onTagUpdated) onTagUpdated();
    };

    // Deletes a personalised tag for the company
    const deleteTag = async (tagId, onTagDeleted) => {
        await removeCompanyTag(tagId);
        await fetchCompanyTags(companyId);
        if (onTagDeleted) onTagDeleted();
    };

    // Updates the tags for all results
    const updateResultTagsMap = async (urlCodes) => {
        try {
            const tagsMap = await getTagsByResult(urlCodes);
            setResultTagsMap(tagsMap);
        } catch (error) {
            // Optionally handle the error, e.g., notify the user or log the error
        }
    };
    

    return {
        addOrUpdateTag,
        deleteTag,
        updateResultTagsMap,
    };
};

export default useReportTags;
