// Show the terms and conditions to the user
import React from 'react';
import { TooltipProvider } from '../contexts/TooltipContext'; // Needed for header to work
import Header from '../components/common/Header';
import LegalText from '../components/common/LegalText';

const TermsConditionsPage = () => {

  return (
    <TooltipProvider>
      <Header />
      <LegalText variant="termsAndConditions" />
    </TooltipProvider>
  );
};

export default TermsConditionsPage;
