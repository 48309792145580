// useScreenShareAccess.js
import { useCallback } from 'react';

const useScreenShareAccess = () => {
    const handleEnableScreenShare = useCallback(async (onSuccess, onDecline, onError) => {
        const displayMediaOptions = {
            video: {
                cursor: "always",
                displaySurface: "monitor", // Preference for the entire monitor
            },
            audio: false // Don't need to capture audio for screen sharing
        };
        try {
            const stream = await navigator.mediaDevices.getDisplayMedia(displayMediaOptions);
            onSuccess(stream); // Call the onSuccess callback
        } catch (error) {
            if (error.name === "NotAllowedError") {
                // This error occurs when the user declines the permission request
                onDecline(); // Call the onDecline callback
            } else {
                // Other errors indicate an issue with getting access to screen share
                onError(error); // Call the onError callback
            }
        }
    }, []);    
      return { handleEnableScreenShare };
};

export default useScreenShareAccess;