// utils/canActOnRole.js
export const canActOnRole = (userRole, targetRole) => {
    const roleHierarchy = {
        'GlobalAdmin': 5,
        'SuperAdmin': 4,
        'Admin': 3,
        'GlobalUser': 2,
        'User': 1,
    };

    // If the userRole is higher or equal to the targetRole, they can act on it
    return roleHierarchy[userRole] >= roleHierarchy[targetRole];
};
