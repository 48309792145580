// Table with list of subskills and the rationale (when clicked) that goes beside the transcript)
import React, { useState, useContext } from 'react';
import FeedbackContext from '../../contexts/FeedbackContext';
import FeedbackInput from '../common/FeedbackInput'; // Import the FeedbackInput component
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'; // filled icons
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons'; // non-filled icons
import styles from './SubSkillDetail.module.css'; // Ensure this is correctly pointing to your CSS module

const SubSkillDetail = ({ skillName, skillScore, subSkills, pdfMode = false, className, testAttemptId, evalId }) => {
    const [visibleSubSkillIndex, setVisibleSubSkillIndex] = useState(null);
    const [isHoveringRationale, setIsHoveringRationale] = useState(null);
    const { feedbackMap } = useContext(FeedbackContext);
    const adjustedSkillScore = Math.round(skillScore * 100);

    const getFillColor = (percentage) => {
        if (percentage >= 80) return '#00B050'; 
        else if (percentage >= 60) return '#9acd32'; 
        else if (percentage >= 40) return '#ffbf00'; 
        else if (percentage >= 20) return '#ff7f00'; 
        return '#ff0000';
    };

    const toggleSubSkillVisibility = (index) => {
        setVisibleSubSkillIndex(visibleSubSkillIndex === index ? null : index);
    };

    return (
        <div className={`${styles.subSkillDetailContainer} ${className ? styles[className] : ''} ${pdfMode ? styles.subSkillDetailContainerPdfMode : ''}`}>
            <div className={styles.skillHeader}>
                <span className={styles.skillName}>{skillName}</span>
            </div>
            {subSkills.map((subSkill, index) => {
                const percentage = Math.max(3, (subSkill.score / subSkill.maxScore) * 100); // Minimum 5% so there is some red when the score = 0
                const fillColor = getFillColor(percentage);
                const key = `${testAttemptId}_${subSkill.name}_${evalId}`;
                const shouldRenderFeedbackInput = feedbackMap[key]?.eval || (isHoveringRationale === index && !pdfMode); // Show the feedback icons if they are hovering the rationale or they have clicked thumbs up or down

                return (
                    <React.Fragment key={index}>
                        <div className={styles.subSkillColumn}>
                            <div className={styles.subSkillNameContainer}>
                                <div className={styles.subSkillName}>{subSkill.name}</div>
                                {!pdfMode && (
                                    <div className={styles.questionMarkIcon}>
                                    <FontAwesomeIcon
                                        icon={faQuestionCircle}
                                        className={styles.infoIcon}
                                    />
                                    <span className={styles.tooltipText}>{subSkill.definition}</span>
                                    </div>
                                )}
                            </div>
                            <div className={styles.barAndArrowContainer}>
                                <div className={styles.subSkillBarContainer} >
                                    <div
                                        className={styles.subSkillBar} 
                                        style={{ width: `${percentage}%`, backgroundColor: fillColor }}
                                    ></div>
                                </div>
                                <div 
                                    className={styles.detailsArrow}
                                    onClick={() => toggleSubSkillVisibility(index)}
                                >
                                    <FontAwesomeIcon icon={visibleSubSkillIndex === index ? faChevronUp : faChevronDown} />
                                </div>
                            </div>
                        </div>
                        {(pdfMode || visibleSubSkillIndex === index) && (
                            <div className={styles.subSkillExpandedContent}>
                                <p className={styles.scoreDescription} style={{color: fillColor}}>
                                    {subSkill.scoreDescription}
                                </p>
                                <div 
                                    className={styles.rationaleContainer}
                                    onMouseEnter={() => setIsHoveringRationale(index)}
                                    onMouseLeave={() => setIsHoveringRationale(null)}
                                >
                                    <p className={`${styles.rationale} ${pdfMode ? styles.rationalePdfMode : ''}`}>
                                        {subSkill.rationale}
                                        <span className={styles.trailingSpace}></span> {/* Adds trailing space using CSS */}
                                    </p>
                                    
                                    {/*feedback functionality*/}
                                    {shouldRenderFeedbackInput && (
                                        <FeedbackInput 
                                            feedbackDetails={{ source: 'CandidateEvaluation', test_attempt_id: testAttemptId, prompt_id: evalId, location: subSkill.name, content: subSkill.rationale }} 
                                            index={index} 
                                            popout={false}
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                    </React.Fragment>
                );
            })}
        </div>
    );
};

export default SubSkillDetail;
