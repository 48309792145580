//Chatbox component which focuses on rendering the UI and the functions to add to it like adding system messages or preparing the chatinput depending on what userinput is needed
import React, { useState, useEffect, useContext, useRef } from 'react';
import ChatMessage from './ChatMessage';
import ChatInput from './ChatInput';
import PhotoContainer from '../inputs/PhotoCheck';
import ScreenShare from '../inputs/ScreenShare'
import Button from '../common/Button';
import RadioButtonInput from '../inputs/RadioButtonInput';
import Tooltip from '../tooltip/Tooltip';
import { useAppContext } from '../../contexts/AppContext';
import { TooltipContext } from '../../contexts/TooltipContext';
import { useChatContext } from '../../contexts/ChatContext';
import { useTestContext } from '../../contexts/TestContext';
import styles from './Chatbox.module.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; // For the arrow of the scroll down button
import { FadeLoader } from 'react-spinners'; // For the loading icon waiting for GPT to respond

const Chatbox = ({ style }) => {
    const { 
        chats, setChats, currentChatId, userInputCount, inputValue, setInputValue, registerScrollToBottomCallback, isPhotoCheckVisible, isScreenShareVisible,
        inputVisible, inputType, showTimeUpButtons, isAccessibilityQuestionInput, isUndoAccessibilityButtonVisible, showLoader, hidePhotoContainer, hideScreenShareContainer,
        addSystemMessage, handleAccessibilityResponse, handleUndoAccessibilityResponse, prepareNextAction, handleProceedClick,
        fetchNextChatInstruction, currentChatInstructionIndex, chatInstructions
    } = useChatContext();
    const { testStage, isFinalTimeUp, isTestStarted, showProceedButton } = useTestContext();
    
    // TOOLTIPS //

    // Test retrieval including tooltip
    const { activeTooltips } = useContext(TooltipContext);

    // Check if the tooltips should be visible
    const showRetrievalTooltip = activeTooltips['retrievedSession'];

    // SCROLLING //
    const messagesEndRef = useRef(null); //for scrolling down to

    // Scroll to the bottom of the chat, smoothly
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    // Scroll to bottom if the callback is triggered
    useEffect(() => {
        registerScrollToBottomCallback(scrollToBottom); // Pass scrollToBottom as the callback to the parent
    }, []);
    
    // STYLES AND CLASSES //

    const introChatMessagesHeight = isPhotoCheckVisible ? 'calc(100% - 330px)' : 'calc(100% - 85px)'; //decides how much space below the messages in the introchat

    // Allow dynamic styles if the chatbox is being used during the introChat or the test
    const chatboxClass = `${styles.chatbox} ${
        testStage === 'introChat' ? styles.chatboxIntro : styles.chatboxTest
    }`;

    // Allow dynamic styles if the chatbox is being used during the introChat or the test
    const chatboxMessagesClass = `${styles.chatboxMessages} ${
        testStage === 'introChat' ? styles.introChatMessages : styles.testChatMessages
    }`;

    // Allow dynamic styles if the chatbox is being used during the introChat or the test
    const chatboxMessagesStyle = testStage === 'introChat'
        ? { height: introChatMessagesHeight }
        : {};

    // Ensure the current chat is not empty and has messages
    const currentMessages = chats[currentChatId] || [];

        return (
            <div className={chatboxClass} style={style}>
                {showRetrievalTooltip && <Tooltip variant="retrievedSession" />}
    
                <div className={chatboxMessagesClass} style={chatboxMessagesStyle}>
                    {
                        currentMessages.length === 0 && !(testStage === 'test' && !isTestStarted) ? (
                            <div className={styles.chatMessagesLoader} >
                                <FadeLoader size={10} color={"#246E24"} />
                            </div>
                        ) : (
                            currentMessages.filter(msg => msg.isVisible !== false).map((msg) => (
                                <ChatMessage key={msg.id} data={msg} />
                            ))
                        )
                    }
                    {/* Components to handle user inputs needed */}
                    {isPhotoCheckVisible && (
                        <PhotoContainer
                            fetchNextChatInstruction={() => fetchNextChatInstruction(currentChatInstructionIndex, chatInstructions, addSystemMessage, prepareNextAction, userInputCount, chats, setChats )}
                            hidePhotoContainer={hidePhotoContainer}
                        />
                    )}
                    {showTimeUpButtons && (
                        <div className={styles.timeUpButtons}>
                            <Button 
                                type="proceed" 
                                className="startTest"
                                onClick={handleSendDraftAndProceed}
                            >
                                Envie rascunho e finalize teste
                            </Button>
                            <Button 
                                type="subtle" 
                                className="startTest"
                                onClick={handleProceedClick}
                            >
                                Não envie rascunho e finalize teste
                            </Button>
                        </div>
                    )}
                    {inputVisible && (
                        <ChatInput 
                            inputType={inputType} 
                            isTimeUp={isFinalTimeUp}
                        />
                    )}
                    {isAccessibilityQuestionInput && (
                        <div className={styles.accessibilityQuestionButtons}>
                            <Button 
                                type="proceed" 
                                onClick={() => handleAccessibilityResponse('Sim')}
                            >
                                Sim
                            </Button>
                            <Button 
                                type="proceed" 
                                onClick={() => handleAccessibilityResponse('Não')}
                            >
                                Não
                            </Button>
                        </div>
                    )}
                    {isUndoAccessibilityButtonVisible && (
                        <button
                            onClick={handleUndoAccessibilityResponse}
                            className={styles.undoAccessibilityResponseButton}
                            >
                            Desfazer seleção de acessibilidade
                        </button>
                    )}
                    <div ref={messagesEndRef} style={{ float: 'left', clear: 'both' }} />
                </div>
    
                <>
                    {isScreenShareVisible && (
                        <ScreenShare
                            fetchNextChatInstruction={() => fetchNextChatInstruction(currentChatInstructionIndex, chatInstructions, addSystemMessage, prepareNextAction, userInputCount, chats, setChats )}
                            hideScreenShareContainer={hideScreenShareContainer}
                        />
                    )}
                    {showProceedButton && (
                        <Button 
                            type="proceed" 
                            className="startTest"
                            onClick={handleProceedClick}
                        >
                            {testStage === 'introChat' ? 'Iniciar Teste' : 'Finalizar Teste'}
                        </Button>
                    )}
                    {showLoader && (
                        <div className={styles.timeUpSendingLoader}>
                            <FadeLoader size={10} color={"#246E24"} />
                        </div>
                    )}
                </>
            </div>
        );
    };

export default Chatbox;