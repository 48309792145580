// Manage time states and logic including timeLeft that is passed to the Timer component to display the time remaining to the user. Connected to TestContext.
import { useState, useEffect, useRef } from 'react';
import { useAppContext } from '../contexts/AppContext';
import { logTrace } from '../services/loggerFront';

const useTimer = ( isTestStarted, testSections, currentSection, testAttemptId, candidateId, testStage, isTestInterrupted, isLastSection, retrievedSession, retrievedTimeLeft, accessibilityMode ) => {
    const { globalState, setSwitchScreenCount } = useAppContext();
    const focusWarning = globalState.focusWarning; // Flag when user leaves or rejoins site
    const [initialStartTime, setInitialStartTime] = useState(null); // The datetime the test began, to measure totalTimeTakenand for records
    const [sectionStartTime, setSectionStartTime] = useState(null); // The datetime the section began, to measure timeLeft
    const [sectionTimeLimit, setSectionTimeLimit] = useState(null); // The time limit for the current section
    const sectionTimeLimitRef = useRef(); // Get most up to date
    const [maxTime, setMaxTime] = useState(null); // The maximum time allowed for the test
    const [timeLeft, setTimeLeft] = useState(null); // Remaining test time
    const [timeTaken, setTimeTaken] = useState(0); // How much time has gone in this timer
    const [totalTimeTaken, setTotalTimeTaken] = useState(0); // How much time has gone overall
    const [ timeAdded, setTimeAdded ] = useState(false); // Time added to the timer
    const [retrievedTimeSet, setRetrievedTimeSet] = useState(false); // Flag to check if the time has been retrieved to just set the time once
    // Milestones
    const [sectionTimeUp, setSectionTimeUp] = useState(false); // Flag when the section has ran out of time
    const sectionTimeUpRef = useRef(); // Get most up to date when in retry loops
    const isLastSectionRef = useRef(); // Get most up to date when in retry loops
    const [is1MinsLeft, setIs1MinsLeft] = useState(false); // Flag when time is out
    const [is10MinsLeft, setIs10MinsLeft] = useState(false);
    const [is15MinsLeft, setIs15MinsLeft] = useState(false);
    const [is20MinsLeft, setIs20MinsLeft] = useState(false); 
    const [isFinalTimeUp, setIsFinalTimeUp] = useState(false); // When the time is up after the last section
    const isFinalTimeUpRef = useRef(); // Get most up to date
    // Stop clock
    const [stopTimer, setStopTimer] = useState(false); // Flag enable the timer to be stopped, e.g. when chatbox gets to the end of the test
    const stopTimerRef = useRef();
    const [lostTime, setLostTime] = useState(0); // Time lost due to interruptions
    const [timerId, setTimerId] = useState(null); // ID of the timer for tracking lost time
    const lostTimeRef = useRef(); // Get most up to date when in retry loops
    const fileName = 'useTimer.js'; // Filename for logging

    // Update the time when the currentSection changes, adjust if a retrieved session
    const setSectionTimer = (currentSection, testStage) => {
        if (currentSection) {
            // If it is a retrieved session and the time has not been set, set the time
            if (retrievedSession && !retrievedTimeSet && retrievedTimeLeft !== null && testStage === 'test') {
                setSectionTimeLimit({type: 'setTimeTo', value: retrievedTimeLeft}); // Set the sectionTimeLimit to the retrievedTimeLeft
                setRetrievedTimeSet(true); // Set the retrievedTimeSet to true so it does not run again

            } else { // If it is not a retrieved session or the retrieved time has already been set
                const { time } = currentSection; // Get the time object from the current section config
                if (time) {
                    const { setTimeTo, minimumTime } = time; // Get the setTimeTo or minimumTime from the time object

                    if (setTimeTo !== undefined) { // If setTimeTo is defined
                        // if it is accessibility mode, set the time to 6 hours
                        const adjustedTimeTo = accessibilityMode ? 21600 : setTimeTo;
                        setSectionTimeLimit({ type: 'setTimeTo', value: adjustedTimeTo }); // Set the sectionTimeLimit to adjustedTimeTo value

                    } else if (minimumTime !== undefined) { // If minimumTime is defined
                        const remainingTime = timeLeft !== null ? timeLeft : 0; // Get the remaining time
                        const newTimeLimit = Math.max(minimumTime, remainingTime); // Set the newTimeLimit to the maximum of minimumTime and remainingTime
                        setSectionTimeLimit({ type: 'minimumTime', value: newTimeLimit });

                        // if time was added, update the timeAdded flag
                        if (minimumTime > remainingTime) {
                            setTimeAdded(true);
                        }
                    }
                }
            }
        }
    }

    // Update the sectionTimeLimitRef
    useEffect(() => {
        sectionTimeLimitRef.current = sectionTimeLimit;
    }, [sectionTimeLimit]);

    // Calculate the maximum time allowed for the test, for record keeping
    useEffect(() => {
        const calculateMaximumTime = () => {
            let totalMaxTime = 0;
    
            testSections.forEach((section) => {
                if (section.time) {
                    const { setTimeTo, minimumTime } = section.time;
                    if (!isNaN(Number(setTimeTo))) {
                        totalMaxTime += Number(setTimeTo);
                    } else if (!isNaN(Number(minimumTime))) {
                        totalMaxTime += Number(minimumTime);
                    }
                }
            });
            setMaxTime(totalMaxTime);
        };
    
        calculateMaximumTime();
    }, [testSections]);     

    // Update isFinalTimeUpRef
    useEffect(() => {
        isFinalTimeUpRef.current = isFinalTimeUp;
    }, [isFinalTimeUp]); 

    // Update stopTimer
    useEffect(() => {
        stopTimerRef.current = stopTimer;
    }, [stopTimer]); 

    // Update sectionTimeUp
    useEffect(() => {
        sectionTimeUpRef.current = sectionTimeUp;
    }, [sectionTimeUp]); 

    // Update isLastSection
    useEffect(() => {
        isLastSectionRef.current = isLastSection;
    }, [isLastSection]);

    // Update lostTime so it can be used in the totalTime adjustment calc
    useEffect(() => {
        lostTimeRef.current = lostTime;
    }, [lostTime]); // Update ref whenever lostTime changes
    
    // If the test is interrupted, stop the timer and count how long has passed to add to the total timer. Restart timer when it is resolved
    useEffect(() => {
        // Determine if either test or chat is interrupted
        const isInterrupted = isTestInterrupted.interrupted;

        // Start tracking lost time if interrupted and not already tracking
        if (isInterrupted && timerId === null) {
            const id = setInterval(() => {
                setLostTime((prevLostTime) => prevLostTime + 1);
            }, 1000);
            setTimerId(id);
            setStopTimer(true);
        } else if (!isInterrupted && timerId !== null) {
            // Stop tracking lost time when no longer interrupted
            clearInterval(timerId);
            setTimerId(null);
            setStopTimer(false);
        }

        // Cleanup on unmount or when both interruptions are cleared
        return () => {
            if (timerId !== null) {
                clearInterval(timerId);
            }
        };
    }, [isTestInterrupted.interrupted]);

    // Function to calculate time passed and update timeLeft and timeTaken. Blocked if stopTimer has been triggered (in Chatbox when the end of the test is reached)
    const updateTimer = () => {
        if (sectionStartTime && !stopTimerRef.current && !isFinalTimeUpRef.current) {
            const currentTime = new Date(); // Get the current datetime
            const elapsedTime = Math.floor((currentTime - sectionStartTime) / 1000); // Time passed in seconds using datetimes
            const fullTime = sectionTimeLimit.value; // The full time allowed for the section
            const adjustedTotalTime = fullTime + lostTimeRef.current; // Adjust total time by lost time
            const remainingTime = (adjustedTotalTime - elapsedTime); // Time left in seconds

            setTimeLeft(remainingTime); // This is what appears in the timer
            setTimeTaken(elapsedTime); // The time gone in this timer, used to trigger saves

            // If time runs out run relevant action
            if (remainingTime === 0) {
                if (isLastSectionRef.current) { // If it is the last section
                    onFinalTimeUp(); // Trigger the final time up
                } else if (!sectionTimeUpRef.current) { // If its not the last section
                    setSectionTimeUp(true); // Set the section time out
                }
            } else {
                // Otherwsie check is it is a time milestones
                handleTimeMilestones(remainingTime);
            }
            // Calc and save the total time taken
            const totalTimeElapsed = Math.floor((currentTime - initialStartTime) / 1000); // Time elasped in seconds
            setTotalTimeTaken(totalTimeElapsed);
        }
    };

    // Trigger actions depending on the time left, these tend to trigger tooltips in TooltipContext.js
    const handleTimeMilestones = (remainingTime) => {
        const sectionName = currentSection ? currentSection.sectionName : 'unknown section';
        switch (remainingTime) {
            case 30: 
                clearTimerMilestones();
                break;
            case 60: 
                setIs1MinsLeft(true);
                logTrace("1 min warning", { testAttemptId, sectionName });
                break;
            case 600:
                setIs10MinsLeft(true);
                logTrace("10 min warning", { testAttemptId, sectionName });
                break;
            case 900:
                setIs15MinsLeft(true);
                logTrace("15 min warning", { testAttemptId, sectionName });
                break;
            case 1200:
                setIs20MinsLeft(true);
                logTrace("20 min warning", { testAttemptId, sectionName });
                break;
            default:
                break;
        }
    };

    // Start the main timer when the test begins by setting total time as starttime and calling the interval to count it down
    useEffect(() => {
        let intervalId;
        // Initialize the timer when the test starts
        if (testStage === 'test' && sectionStartTime === null && isTestStarted) {

            const currentTime = new Date(); // Capture the current time as the start time
            setSectionStartTime(currentTime); // This is updated per new timer
            setInitialStartTime(currentTime); // This is the start time saved in the db and used to calc total time
            setTimeLeft(sectionTimeLimit.value); // Set the time left to the section time limit
        }

        // Countdown logic
        if (sectionStartTime) {
            intervalId = setInterval(() => {
                updateTimer();
            }, 1000);
        }

        // Reset timer when not in test stage
        if (testStage !== 'test' && sectionStartTime !== null) {
            setSectionStartTime(null);
            setTimeLeft(0);
            setTimeTaken(0);
        }

        return () => clearInterval(intervalId);
    }, [isTestStarted, sectionStartTime, testStage, isFinalTimeUpRef.current]);

    // Reset the total time when we move section
    useEffect(() => {
        if (isTestStarted) {
            const currentTime = new Date(); // Capture the current time
            setSectionStartTime(currentTime); // Set as the new start time
            if (sectionTimeLimitRef.current) {
                setTimeLeft(sectionTimeLimitRef.current.value); // Set the time left to limit of the section
            } else {
                setTimeLeft(0); // Set the time left to limit of the section
            }
            // Reset the triggers as new time starting
            clearTimerMilestones();
        }
    }, [sectionTimeLimitRef.current]);

    // Trigger isFinalTimeUp and stop the clock. completeTest will be triggered after the instruction have reached an end
    const onFinalTimeUp = () => {
        // Check if time up has already been handled
        if (isFinalTimeUpRef.current) {
            return; // Exit the function early if time up has already been processed
        }

        setIsFinalTimeUp(true); // Flag stops photo taking, screen sharing, saving and trigger the chat to send the last message and end
        setStopTimer(true); // Stop the timer

        // Logging
        
        logTrace("Final time is up", { 
            testAttemptId: testAttemptId,
            candidateId: candidateId,
            type: 'Time',
            fileName: fileName,
        });

    };

    // Clear the time milestones
    const clearTimerMilestones = () => {
        // Reset the triggers
        setIs20MinsLeft(false);
        setIs15MinsLeft(false);
        setIs10MinsLeft(false);
        setIs1MinsLeft(false);
    }

    // FOCUS //
    const [timeNotActive, setTimeNotActive] = useState(0); // Time how long user switches screens / tabs etc in seconds
    const [inactiveTimerId, setInactiveTimerId] = useState(null); // Time how long user switches screens / tabs etc
    const [inactiveStartTime, setInactiveStartTime] = useState(null); // To time how long they left
    const inactiveStartTimeRef = useRef(null); 

    // Update inactiveStartTimeRef
    useEffect(() => {
        inactiveStartTimeRef.current = inactiveStartTime;
    }, [inactiveStartTime]); 

    // When the user leaves or return to the page, start or stop the timer to track the amount of time and times they leave
    useEffect(() => {
        // Start tracking lost time if interrupted and not already tracking
        if (focusWarning && inactiveStartTimeRef.current === null && testStage === 'test') { // If they leave the site
            const now = Date.now();
            setInactiveStartTime(now); // Set the time they left the site
            inactiveStartTimeRef.current = now; // Set ref for synchronous access
            setSwitchScreenCount(prevCount => prevCount + 1); // Increment the count of times they left
        } else if (!focusWarning && inactiveStartTimeRef.current !== null && testStage === 'test') { // If they return to the site
            const inactiveDuration = Date.now() - inactiveStartTimeRef.current; // Calculate how long they were gone
            setTimeNotActive(prevTime => prevTime + inactiveDuration / 1000); // Add to the total time they were gone
            setInactiveStartTime(null); // Reset the time they left
            inactiveStartTimeRef.current = null; // Reset ref
        }

        // Cleanup on unmount or when both interruptions are cleared
        return () => {
            if (inactiveStartTimeRef.current !== null) {
                clearInterval(inactiveStartTimeRef.current);
            }
        };
    }, [focusWarning]);

    return {
        sectionStartTime,
        setSectionTimer,
        timeLeft,
        sectionTimeLimit,
        timeTaken,
        maxTime,
        totalTimeTaken,
        timeAdded,
        setTimeAdded,
        is1MinsLeft,
        is10MinsLeft,
        is15MinsLeft,
        is20MinsLeft,
        isFinalTimeUp,
        stopTimer,
        lostTime,
        setTimeLeft,
        setTimeTaken,
        setTotalTimeTaken,
        setIs1MinsLeft,
        setIs10MinsLeft,
        setIs15MinsLeft,
        setIs20MinsLeft,
        setIsFinalTimeUp,
        setStopTimer,
        setLostTime,
        sectionTimeUp,
        setSectionTimeUp,
        timeNotActive,
        setTimeNotActive,
    };
};

export default useTimer;