import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag, faTimes } from '@fortawesome/free-solid-svg-icons';
import styles from './TagSelector.module.css';

const TagSelector = ({ availableTags, onSelectTag, onClose }) => {
    return (
        <div className={styles.tagSelector} onClick={(e) => e.stopPropagation()}>
            <button onClick={onClose} className={styles.closeButton}>
                <FontAwesomeIcon icon={faTimes} />
            </button>
            <ul className={styles.tagList}>
                {availableTags.map(tag => (
                    <li 
                        key={tag.tag_id} 
                        className={styles.tagItem} 
                        style={{ backgroundColor: tag.colour, color: tag.text_colour }}
                        onClick={() => onSelectTag(tag)}
                    >
                        <FontAwesomeIcon icon={faTag} /> {tag.tag_name}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default TagSelector;
