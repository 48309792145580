import React, { useEffect, useRef, useState } from 'react';
import Button from '../common/Button'; // Import the custom Button component
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import styles from './DashboardTagModal.module.css'; 

const DashboardTagModal = ({ editingTag, addCustomCompanyTag, setIsTagModalVisible, tagModalVariant }) => {
    const [tagName, setTagName] = useState('');
    const [tagColor, setTagColor] = useState('#FFFFFF'); // default color
    const [textColor, setTextColor] = useState('#000'); // default color
    const [selectedColor, setSelectedColor] = useState(null); // Track the selected color

    const modalRef = useRef(null);

    // Set the default values if there is an editing tag
    useEffect(() => {
        if (editingTag) {
            setTagName(editingTag.tag_name);
            setTagColor(editingTag.colour);
            setTextColor(editingTag.text_colour);
        } else {
            setTagName('');
            setTagColor('#FFFFFF'); // Default background color
            setTextColor('#000');   // Default text color
        }
    }, [editingTag]);

    // Preset colors for easy selection
    const presetColors = [
        { bgColor: '#8B0000', textColor: '#FFF' },
        { bgColor: '#FF0000', textColor: '#FFF' },
        { bgColor: '#FF8A80', textColor: '#000' },
        { bgColor: '#FF6E40', textColor: '#000' },
        { bgColor: '#FFD700', textColor: '#000' },
        { bgColor: '#B8860B', textColor: '#FFF' },
        { bgColor: '#008000', textColor: '#FFF' },
        { bgColor: '#00E676', textColor: '#000' },
        { bgColor: '#00BFA5', textColor: '#000' },
        { bgColor: '#000080', textColor: '#FFF' },
        { bgColor: '#0000FF', textColor: '#FFF' },
        { bgColor: '#80D8FF', textColor: '#000' },
        { bgColor: '#B388FF', textColor: '#000' },
        { bgColor: '#8C9EFF', textColor: '#000' },
        { bgColor: '#FF69B4', textColor: '#000' },
        { bgColor: '#000000', textColor: '#FFF' },
        { bgColor: '#A9A9A9', textColor: '#000' },
        { bgColor: '#CFD8DC', textColor: '#000' }
    ]; 
    
    // Close the modal
    const handleClose = () => {
        setIsTagModalVisible(false);
    };

    // Pass details to the addCustomCompanyTag function
    const handleAddOrEditTag = () => {
        if (tagName.trim() === '') {
            alert('O nome não pode ficar em branco');
            return;
        }

        const tagData = {
            tag_id: editingTag ? editingTag.tag_id : null,
            name: tagName,
            color: tagColor,
            textColor: textColor
        };
        
        addCustomCompanyTag(tagData);
    
        setTagName('');
        setTagColor('#FFFFFF');
        setTextColor('#000');
    };

    // Handle preset color selection
    const handleColorSelect = (color) => {
        setTagColor(color.bgColor);
        setTextColor(color.textColor);
        setSelectedColor(color.bgColor);
    };

    // Handle custom color selection for the text
    const handleTextColorSelect = (color) => {
        setTextColor(color);
    };

    // Focus management
    useEffect(() => {
        if (modalRef.current) {
            const focusableElements = modalRef.current.querySelectorAll(
                'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
            );
            if (focusableElements.length) {
                focusableElements[0].focus();
            }
        }
    }, []);

    return (
        <div 
            className={styles.modalOverlay}
            role="dialog" 
            aria-labelledby="tagModalTitle" 
            aria-describedby="tagModalMessage"
            aria-modal="true"
        >
            <div 
                className={styles.tagModalContainer} 
                ref={modalRef} 
                tabIndex="-1"
            >
                <button onClick={handleClose} className={styles.closeButton}>
                    <FontAwesomeIcon icon={faTimes} />
                </button>

                <h4 id="tagModalTitle" className={styles.subheader}>
                    {tagModalVariant === 'edit' ? 'Editar Tag Personalizada' : 'Criar Nova Tag Personalizada'}
                </h4>
                <div id="tagModalMessage" className={styles.tagAndButtonContainer}>
                    <div className={styles.tagAndColourContainer}>
                        <input 
                            type="text"
                            className={styles.input}
                            value={tagName}
                            onChange={e => setTagName(e.target.value)}
                            placeholder="Nome da Tag"
                        />

                        <div className={styles.colorPicker}>
                            {presetColors.map(color => (
                                <button 
                                    key={color.bgColor}
                                    className={`${styles.colorOption} ${selectedColor === color.bgColor ? styles.selected : ''}`}
                                    style={{ backgroundColor: color.bgColor, color: color.textColor }}
                                    onClick={() => handleColorSelect(color)}
                                >
                                    A
                                </button>
                            ))}
                            <div className={styles.customColorContainer}>
                                <div className={styles.colorPickerLabel}>Cor customizada:</div>
                                <input
                                    type="color"
                                    value={tagColor}
                                    onChange={e => setTagColor(e.target.value)}
                                    className={styles.customColorPicker}
                                />
                                <div className={styles.textColorSelector}>
                                    <button 
                                        class={`${styles.textColorButton} ${textColor === '#000' ? styles.selected : ''}`}
                                        style={{ backgroundColor: '#FFF', color: '#000' }}
                                        onClick={() => handleTextColorSelect('#000')}
                                    >
                                        A
                                    </button>
                                    <button 
                                        class={`${styles.textColorButton} ${textColor === '#FFF' ? styles.selected : ''}`}
                                        style={{ backgroundColor: '#000', color: '#FFF' }}
                                        onClick={() => handleTextColorSelect('#FFF')}
                                    >
                                        A
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.buttonContainer}>
                        <Button onClick={handleAddOrEditTag} type="tooltip">
                            {tagModalVariant === 'edit' ? 'Atualizar' : 'Criar'}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashboardTagModal;
