// Hook for Chatbox. Calls for the chat instructions to be fetched from the database and sends for them to be processed in processChatInstruction
import { useState, useEffect, useContext, useRef } from 'react';
import { useAppContext } from '../contexts/AppContext';
import { useTestContext } from '../contexts/TestContext';
import { getIntroChatInstructions, getTestChat, getRetrievedIntroChatInstructions } from '../services/databaseService';
import { logEvent, logException, logTrace } from '../services/loggerFront';
import processChatInstruction from '../services/processChatInstruction';

const useChatInstructions = (onChatInstructionsComplete, finalCode, boilerplateCode, initialDatabaseSchema, databaseSchema, saveTestChatTranscript, codeLanguage, setIsGptResponseDelayed, isTimeUpCallback, saveTestChatInstructions, addSystemMessage, addGptSystemMessage, prepareNextAction, chats, setChats, userInputCount, gptCodeEvaluation, setGptCodeEvaluation, instructionsText, preEvalCriteria, currentChatId, accessibilityMode, additionalActions = {}) => {
    const [chatInstructions, setChatInstructions] = useState([]);
    const [testChatInstructionsLoaded, setTestChatInstructionsLoaded] = useState(false);
    const [introChatInstructionsLoaded, setIntroChatInstructionsLoaded] = useState(false);
    const [currentChatInstructionIndex, setCurrentChatInstructionIndex] = useState(0);
    const [currentInstructionKey, setCurrentInstructionKey] = useState(''); // Key of the current instruction being processed
    const { testStage, setTestStage, testAttemptId, retrievedSession, retrievedInstructionIndex, retrievedCode, retrievedMessages, isTestStarted, activeTest, setIsTestInterrupted, caseInstructions, testSections, currentSection, moveToNextSection, additionalTime, sectionStartTime } = useTestContext();
    const { isAdmin, webSocketUrl, environment } = useAppContext();
    const [updatedRetrievedVariables, setUpdatedRetrievedVariables] = useState(false); // Enables uodating messages and finalCode the first time the hook is called with the retrieved variables
    const fileName = 'useChatInstructions' // for logging

    // Update the instruction key when currentSection updates. If autoStart is true, start the next 
    useEffect(() => {
        if (currentSection && currentSection.chat && currentSection.chat.instructions) {
            const newInstructionKey = currentSection.chat.instructions;
            setCurrentInstructionKey(newInstructionKey); // Set the key to the current section's instructions
            setCurrentChatInstructionIndex(-1); // Reset the index when the key changes. -1 as fetchNextChatInstruction increments when called
            // In the first instruction of the new set, f autoStart is true, trigger fetchnextinstruction
            if (testChatInstructionsLoaded && chatInstructions[newInstructionKey][0].autoStart) {
                fetchNextChatInstruction(-1, chatInstructions, newInstructionKey, userInputCount, chats, setChats);
            } 
        } else {
            setCurrentInstructionKey(''); // Set to blank if no chat or instructions
        }
    }, [currentSection]);

    // Load instructions if it is intro or test test and they havent been previously loaded
    useEffect(() => {
        if (testStage === 'introChat' && !introChatInstructionsLoaded) {
            loadIntroChatInstructions();
        } else if (testStage === 'test' && isTestStarted && !testChatInstructionsLoaded) {
            loadTestChatInstructions();
        }
    }, [testStage, isTestStarted]);

    // Calls for the data (intro or test chat instructions) with retry mecanisms and returns with the data
    const fetchDataWithRetry = async (fetchFunction, onSuccess, onFailure) => {
        try {
            const data = await fetchFunction();
            onSuccess(data); // Call onSuccess handler if fetch succeeds
        } catch (error) {
            logException('Error: first loading of instructions', {
                errorType: 'FetchInstructionsError',
                errorMessage: error.message,
                errorStack: error.stack, 
                fetchFunction: fetchFunction,
                fileName: fileName,
            });
            setTimeout(async () => {
                try {
                    const data = await fetchFunction();
                    onSuccess(data); // Retry fetch and call onSuccess handler if successful
                } catch (retryError) {
                    logException('Error: loading instructions retry', {
                        errorType: 'FetchInstructionsError',
                        errorMessage: error.message,
                        errorStack: error.stack, 
                        fetchFunction: fetchFunction,
                        fileName: fileName,
                    });
                    onFailure(); // Call onFailure handler if retry fails
                }
            }, 5000); // Wait 5 seconds before retrying
        }
    };

    // Load the introChatInstructions from the DB and call for the first one to be processed
    const loadIntroChatInstructions = () => {
        // Fetch the right intructions depending on if it is a retrieved session or not
        const fetchFunction = retrievedSession ? getRetrievedIntroChatInstructions : getIntroChatInstructions;

        fetchDataWithRetry(
            fetchFunction,
            (data) => { // onSuccess handler
                setChatInstructions(data);
                setIntroChatInstructionsLoaded(true);
                // Call from instruction to be processed if it is fetched successfully
                if (data.length > 0) {
                    processChatInstruction(data[0], data, 0, addSystemMessage, addGptSystemMessage, fetchNextChatInstruction, prepareNextAction, testStage, chats, instructionsText, preEvalCriteria, finalCode, boilerplateCode, initialDatabaseSchema, databaseSchema, userInputCount, gptCodeEvaluation, setGptCodeEvaluation, setChats, saveTestChatTranscript, sectionStartTime, codeLanguage, setIsGptResponseDelayed, setIsTestInterrupted, webSocketUrl, isTimeUpCallback, additionalTime, testAttemptId, isAdmin, currentChatId, moveToNextSection, currentInstructionKey, currentSection, onChatInstructionsComplete, accessibilityMode, additionalActions);
            }},
            () => { // onFailure go to fail state
                setTestStage('loadFailed')
            }
        );
    };

    // Load the TestChatInstructions and call for the returning data to be processed starting at 0 unless it is a retrieved session and it decides where to start from
    const loadTestChatInstructions = () => {
        // Extract instruction keys from testSections to identify which sets of instruction to load
        const instructionKeys = testSections.map(section => section.chat.instructions);

        // Define a fetch function that passes the keys to getTestChat
        const fetchTestChatWithKeys = () => getTestChat(instructionKeys, environment);

        fetchDataWithRetry(
            fetchTestChatWithKeys,
            (data) => { // onSuccess handler

                // Map the returned data to the correct instruction keys
                const instructionsMap = instructionKeys.reduce((acc, key) => {
                    const matchingConfig = data.find(config => config.identifier === key);
                    if (matchingConfig) {
                        acc[key] = matchingConfig.config;
                    }
                    return acc;
                }, {});
                
                setChatInstructions(instructionsMap);
                setTestChatInstructionsLoaded(true);
                saveTestChatInstructions(instructionsMap); // call for them to be saved back in chatbox
                        
                // Call from instruction to be processed if it is fetched successfully
                if (instructionsMap) {
                    const startIndex = retrievedSession ? retrievedInstructionIndex : 0;
            
                    // If it is a retreived session, use the retrieved variables (loaded in TestContext) instead of the regular states which wont be updated on time
                    if (retrievedSession && !updatedRetrievedVariables) {
                        // Directly use retrieved values for processChatInstruction
                        setCurrentChatInstructionIndex(startIndex);
                        processChatInstruction(instructionsMap[currentInstructionKey][startIndex], instructionsMap, startIndex, addSystemMessage, addGptSystemMessage, fetchNextChatInstruction, prepareNextAction, testStage, chats, instructionsText, preEvalCriteria, retrievedCode, boilerplateCode, initialDatabaseSchema, databaseSchema, userInputCount, gptCodeEvaluation, setGptCodeEvaluation, setChats, saveTestChatTranscript, sectionStartTime, codeLanguage, setIsGptResponseDelayed, setIsTestInterrupted, webSocketUrl, isTimeUpCallback, additionalTime, testAttemptId, isAdmin, currentChatId, moveToNextSection, currentInstructionKey, currentSection, onChatInstructionsComplete, accessibilityMode, additionalActions);
                        setUpdatedRetrievedVariables(true); // Mark retrieved variables as processed
                    } else {
                        // Use current messages and finalCode state for processChatInstruction
                        setCurrentChatInstructionIndex(startIndex);
                        processChatInstruction(instructionsMap[currentInstructionKey][startIndex], instructionsMap, startIndex, addSystemMessage, addGptSystemMessage, fetchNextChatInstruction, prepareNextAction, testStage, chats, instructionsText, preEvalCriteria, finalCode, boilerplateCode, initialDatabaseSchema, databaseSchema, userInputCount, gptCodeEvaluation, setGptCodeEvaluation, setChats, saveTestChatTranscript, sectionStartTime, codeLanguage, setIsGptResponseDelayed, setIsTestInterrupted, webSocketUrl, isTimeUpCallback, additionalTime, testAttemptId, isAdmin, currentChatId, moveToNextSection, currentInstructionKey, currentSection, onChatInstructionsComplete, accessibilityMode, additionalActions);
                    }
                }},
            () => { // onFailure go to fail state
                setTestStage('loadFailed')
            }
        );
    };

    // Get the next instruction and send to be processed. Pass it currentInstructionKey so we can have the most up to date when the section changes
    const fetchNextChatInstruction = (currentIndex, chatInstructions, currentInstructionKey, userInputCount, updatedChats, setChats ) => {
        // If an active test is identified, dont proceed
        if (activeTest) {
            return;
        }

        const nextIndex = currentIndex + 1;
        if (testStage === 'introChat') { // If it is intro chat, no need to provide a key
            if (nextIndex < chatInstructions.length) {
                setCurrentChatInstructionIndex(nextIndex);
                processChatInstruction(chatInstructions[nextIndex], chatInstructions, nextIndex, addSystemMessage, addGptSystemMessage, fetchNextChatInstruction, prepareNextAction, testStage, updatedChats, instructionsText, preEvalCriteria, finalCode, boilerplateCode, initialDatabaseSchema, databaseSchema, userInputCount, gptCodeEvaluation, setGptCodeEvaluation, setChats, saveTestChatTranscript, sectionStartTime, codeLanguage, setIsGptResponseDelayed, setIsTestInterrupted, webSocketUrl, isTimeUpCallback, additionalTime, testAttemptId, isAdmin, currentChatId, moveToNextSection, currentInstructionKey, currentSection, onChatInstructionsComplete, accessibilityMode, additionalActions);
            } else if (nextIndex >= chatInstructions.length) {
                onChatInstructionsComplete();
            }
        } else if (testStage === 'test') { 
            if (nextIndex < chatInstructions[currentInstructionKey].length) {
                setCurrentChatInstructionIndex(nextIndex);
                processChatInstruction(chatInstructions[currentInstructionKey][nextIndex], chatInstructions, nextIndex, addSystemMessage, addGptSystemMessage, fetchNextChatInstruction, prepareNextAction, testStage, updatedChats, instructionsText, preEvalCriteria, finalCode, boilerplateCode, initialDatabaseSchema, databaseSchema, userInputCount, gptCodeEvaluation, setGptCodeEvaluation, setChats, saveTestChatTranscript, sectionStartTime, codeLanguage, setIsGptResponseDelayed, setIsTestInterrupted, webSocketUrl, isTimeUpCallback, additionalTime, testAttemptId, isAdmin, currentChatId, moveToNextSection, currentInstructionKey, currentSection, onChatInstructionsComplete, accessibilityMode, additionalActions);
            } else if (nextIndex >= chatInstructions[currentInstructionKey].length) {
            }
        }
    };
    
    return { chatInstructions, currentChatInstructionIndex, currentInstructionKey, fetchNextChatInstruction };
};

export default useChatInstructions;
