import React, { useState } from 'react';
import Logo from '../common/DegrauLogo';
import Button from '../common/Button';
import styles from './Popup.module.css';

const ScreenShareFailContent = ({ onConfirmReject, onCancelReject, onLoad, logoLoaded, ...otherProps }) => {
    const [reason, setReason] = useState('');

    const handleConfirm = () => {
        setReason('moved on in fail/reject stete')
        onConfirmReject(reason); // Pass the reason back to PhotoCheck.js
    };

    const handleCancel = () => {
        onCancelReject(); // Hide the popup
    };

    return (
        <>
            <Logo className={styles.degrauLogoPopup} onLoad={onLoad} />
            <p className={styles.popupText}>    
                Estamos enfrentando problemas para gravar sua tela, por favor, tente:
            </p>
            <div className={styles.leftAlignedList}>
                <ul>
                    <li>Pressionar o botão "Tentar novamente" para tentar acessar a câmera novamente;</li>
                    <li>Recarregar a página;</li>
                </ul>
            </div>
            <p className={styles.popupText}>
                Se isso não resolver pode continuar sem compartilhar sua tela ou envie um e-mail para <b>suporte@degrau.co</b>
            </p>
            
            <div className={styles.rejectPhotoButtonContainer}>
                <Button onClick={handleCancel} type="proceed" className={styles.cancelRejectPhoto}>
                    Tentar novamente
                </Button>
                <Button onClick={handleConfirm} type="subtle" className={styles.confirmRejectPhoto}>
                    Continuar sem compartilhar sua tela
                </Button>
            </div>
        </>
    );
};

export default ScreenShareFailContent;
