import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HoverPopout from './HoverPopout';
import styles from './ActionButton.module.css';

const ActionButton = ({ 
    icon, 
    text, 
    label, 
    onClick,
    showHoverPopout = false,
    popoutText = null, 
    popoutHeading = null, 
    popoutPosition = 'right',
    textColor = 'default', // Default text color
    disabled = false,
    cursorPointer = true,
    hoverComponent = null // Custom hover component
}) => {
    const [isHovered, setIsHovered] = useState(false);

    // Determine the class for text color
    const textColorClass = textColor === 'red' 
        ? styles.redText 
        : textColor === 'green' 
        ? styles.greenText 
        : '';

    const cursorClass = cursorPointer ? styles.cursorPointer : '';

    return (
        <div 
            className={`${styles.buttonWithPopout} ${cursorClass}`}
            onMouseEnter={() => setIsHovered(true)} 
            onMouseLeave={() => setIsHovered(false)}
            onFocus={() => setIsHovered(true)}
            onBlur={() => setIsHovered(false)}
        >
            <button 
                className={`${styles.actionButton} ${textColorClass}`} 
                onClick={onClick} 
                aria-label={label}
                disabled={disabled}
            >
                {icon ? <FontAwesomeIcon icon={icon} /> : text}
            </button>
            {showHoverPopout && isHovered && (hoverComponent || popoutText) && (
                hoverComponent ? (
                    hoverComponent
                ) : (
                    <HoverPopout 
                        text={popoutText} 
                        heading={popoutHeading} 
                        position={popoutPosition} 
                    />
                )
            )}
        </div>
    );
};

ActionButton.propTypes = {
    icon: PropTypes.object, // FontAwesomeIcon icon object (optional)
    text: PropTypes.string, // Text to be displayed on the button (optional)
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func, // hover ones dont have on clicks
    showHoverPopout: PropTypes.bool,
    popoutText: PropTypes.string,
    popoutHeading: PropTypes.string,
    popoutPosition: PropTypes.oneOf(['left', 'right']),
    textColor: PropTypes.oneOf(['red', 'green', 'default']),
    disabled: PropTypes.bool,
    cursorPointer: PropTypes.bool,
    hoverComponent: PropTypes.node, // A custom component to show on hover instead of the default popout
};

export default ActionButton;