//Element for user to interact with chat
import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTestContext } from '../../contexts/TestContext';
import { ChatContext } from '../../contexts/ChatContext';
import { useTooltip } from '../../contexts/TooltipContext';
import Button from '../common/Button';
import Tooltip from '../tooltip/Tooltip'
import { CopyPasteContext } from '../../contexts/CopyPasteContext';
import styles from './ChatInput.module.css';

const ChatInput = ({ inputType = "textarea", isTimeUp = false, isVisible = true }) => {
    const { inputValue, chatInputError, placeholderText, isChatInputDisabled, userHasInteracted, isHoveringSend, setIsHoveringSend, handleInputChange, handleSubmit, inputRef } = useContext(ChatContext);
    const { testStage } = useTestContext();
    const { getTooltipConfig, activeTooltips  } = useTooltip();
    const placeholder = placeholderText[inputType] || "Escreva sua mensagem aqui";
    const { copyStatus, canPaste, limitPaste } = useContext(CopyPasteContext);
        
    // COPY AND PASTE RULES //

    // Prevent pasting content from outside the application
    useEffect(() => {
        const handlePaste = (event) => {
            let pasteContent = (event.clipboardData || window.clipboardData).getData('text');

            if (copyStatus === 'ok' && pasteContent.trim() !== canPaste.trim()) {
                alert('Conteúdo copiado de fora detectado.');
                event.preventDefault(); // Prevent the paste
            } else if (copyStatus === 'not ok') {
                // Replace the clipboard content with `limitPaste`
                event.preventDefault(); // Prevent the default paste
                document.execCommand('insertText', false, limitPaste); // Insert 'limitPaste' text
            }
            // If copyStatus is 'ok' and content matches `canPaste`, allow the paste without interference
        };

        // Attach the event listener to the document to capture all paste events
        document.addEventListener('paste', handlePaste);

        return () => {
            // Cleanup
            document.removeEventListener('paste', handlePaste);
        };
    }, [copyStatus, canPaste, limitPaste]); 

    // MOUSE AND BUTTON HANDLERS //

    // Make ctrl + ctrl enter send the message
    const handleKeyDown = (e) => {
        // Check if the Enter key is pressed with the Ctrl key or Cmd key (for Mac)
        if (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
            e.preventDefault(); // Prevent the default action
            handleSubmit(inputType); // Call your send message function
        }
    };

    // Show send button popup when button is hovered
    const handleSendMouseEnter = () => {
        setIsHoveringSend(true);
    };

    // Stopping showing when they stop hovering
    const handleSendMouseLeave = () => setIsHoveringSend(false);
    
    // STYLE AND VARIANT //

    // Styling for the input component depending on the inputType
    const inputClass = inputType === "textarea" 
        ? `${styles.chatInput} ${styles.chatTextarea}` 
        : `${styles.chatInput} ${styles.chatInputField}`;

    // Determine the input component based on the inputType
    const inputComponent = inputType === "textarea" ? (
        <textarea
            ref={inputRef}
            className={`${inputClass} ${isChatInputDisabled || isTimeUp ? styles.chatInputDisabled : ''}`}
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown} 
            placeholder={isChatInputDisabled ? '' : placeholder}
            disabled={isTimeUp} 
            style={{ maxHeight: '65px' }} // Set maximum height
        />
    ) : (
        <input
            type={inputType} 
            ref={inputRef}
            className={`${inputClass} ${isChatInputDisabled || isTimeUp ? styles.chatInputDisabled : ''}`}
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown} 
            placeholder={isChatInputDisabled ? '' : placeholder}
            disabled={isTimeUp} 
        />
    );

    // TOOLTIPS //

    // Select the appropriate tooltip variant based on the testStage
    const tooltipVariant = testStage === 'introChat' ? 'userInputIntro' 
                        : testStage === 'test' ? 'userInputTest' 
                        : null;

    // Get the configuration for the current tooltip
    const tooltipConfig = getTooltipConfig(tooltipVariant);

    // Check if the current active tooltip
    const isTooltipActive = activeTooltips[tooltipVariant];

    // Only show the tooltip if the user has not interacted with the input
    const shouldShowTooltip = isTooltipActive && !userHasInteracted;

    if (!isVisible) return null;

    return (
        <div className={styles.chatInputContainer}>
            {shouldShowTooltip && (
                <Tooltip variant={tooltipVariant} />
            )}
            <div className={`${styles.chatInputInnerContainer} ${isChatInputDisabled ? styles.chatInputInnerContainerDisabled : ''}`}>
                {inputComponent}
                <Button 
                    onClick={() => handleSubmit(inputType)}
                    type="send" 
                    disabled={isChatInputDisabled || !inputValue}
                    onMouseEnter={handleSendMouseEnter}
                    onMouseLeave={handleSendMouseLeave}>
                </Button>
                {isHoveringSend && (
                    <div className={styles.sendButtonHoverMessage}>
                        Ctrl+Enter
                    </div>
                )}
            </div>
            {chatInputError && <div className={styles.errorMessage}>{chatInputError}</div>}
        </div>
    );
};

export default ChatInput;
