// ReportSidebar.js
import React, { useState, useMemo, useEffect } from 'react';
import styles from './ReportSidebar.module.css'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

const ReportSidebar = ({ companySummaryData, isOpen, onToggle, onRowClick, activeTestattempt }) => {
    const [sortConfig, setSortConfig] = useState({ key: 'time_concluded', direction: 'descending' }); // Sort the order of the reports
    const [searchTerm, setSearchTerm] = useState(''); // For user to search for a name

    // Format datetime in a user-friendly way, just showing date only
    const formatDate = (isoString) => {
        const date = new Date(isoString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = date.toLocaleString('pt-BR', { month: 'short' }).replace('.', '');
        return `${day}/${month}`;
    };    

    // When the user searches in the box
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
    };
    
    // Filter and sort data based on search term and sort configuration
    const filteredAndSortedData = useMemo(() => {
        let filteredData = companySummaryData.filter(report => report.candidate_name.toLowerCase().includes(searchTerm));
        
        return filteredData.sort((a, b) => {
            let comparison = 0;
            if (a[sortConfig.key] < b[sortConfig.key]) comparison = -1;
            if (a[sortConfig.key] > b[sortConfig.key]) comparison = 1;
            return sortConfig.direction === 'ascending' ? comparison : -comparison;
        });
    }, [companySummaryData, sortConfig, searchTerm]);

    const handleSortChange = (event) => {
        const [key, direction] = event.target.value.split(',');
        setSortConfig({ key, direction });
    };

    // Colour the score
    const getScoreColor = (score) => {
        if (score >= 80) return '#00B050'; // green
        else if (score >= 60) return '#9acd32'; // Lime green
        else if (score >= 40) return '#ffbf00'; // Amber
        else if (score >= 20) return '#ff7f00'; // Orange
        return '#ff0000'; // Red
    };

    // Icons for the open/close buttons
    const desktopIcon = isOpen ? faChevronLeft : faChevronRight;
    const mobileIcon = isOpen ? faTimes : faBars;
  
    return (
        <div className={`${styles.sidebar} ${isOpen ? styles.open : ''}`}>
            {/* Desktop toggle button */}
            <button className={styles.toggleBtn} onClick={onToggle}>
                <FontAwesomeIcon icon={desktopIcon} />
            </button>
            {/* Mobile toggle button */}
            <button className={styles.mobileToggleBtn} onClick={onToggle}>
                <FontAwesomeIcon icon={mobileIcon} />
            </button>
            <div className={styles.sidebarContent}>
                <div className={styles.filters}>
                    <input 
                        type="text" 
                        placeholder="Buscar por nome..." 
                        onChange={handleSearchChange} 
                        className={styles.searchInput}
                    />
                    <select onChange={handleSortChange} className={styles.sortSelect}> 
                        <option value="time_concluded,descending">Mais recente</option>
                        <option value="time_concluded,ascending">Menos recente</option>
                        <option value="candidate_name,ascending">A - Z</option>
                        <option value="candidate_name,descending">Z - A</option>
                        <option value="total_score,descending">Maior pontuação</option>
                        <option value="total_score,ascending">Menor pontuação</option>
                    </select>
                </div>
                <table className={styles.totalTable}>
                        {filteredAndSortedData.map((report, index) => (
                            <React.Fragment key={index}>
                                <div className={`${styles.tableEntry} ${report.test_attempt_id === activeTestattempt ? styles.activeEntry : ''}`} onClick={() => onRowClick(report.url_code)} >
                                    <tr className={styles.tableColumn1}>
                                    <td className={styles.sidebarTd}>
                                        <span className={styles.candidateName}>{report.candidate_name} </span>
                                        <span className={styles.testDate}>{formatDate(report.time_concluded)}</span>
                                    </td>
                                    </tr>
                                    <tr className={styles.tableColumn2}>
                                        <td className={styles.sidebarTd} style={{ color: getScoreColor(report.total_score) }}>
                                            {Math.round(report.total_score)}
                                        </td>
                                    </tr>
                                </div>
                            </React.Fragment>
                        ))}
                </table>
            </div>
        </div>
    );
}

export default ReportSidebar;