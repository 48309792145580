import { Navigate, Route, Routes } from "react-router-dom";

// Page components to be routed to
import AuthPage from "../pages/AuthPage"; // For logging in and signing up
import CandidateFeedbackPage from "../pages/CandidateFeedbackPage"; // Page with iframe to google forms for candidates after the interview
import PrivacyPolicyPage from "../pages/PrivacyPolicyPage"; // Simple page with privacy policy
import TermsConditionsPage from "../pages/TermsConditionsPage"; // Simple page with terms and conditions
import TestPage from "../pages/TestPage"; // Main page for running interviews
import CandidateReportPage from "../pages/CandidateReportPage"; // Show the candidates detailed results
import UserManagementPage from "../pages/UserManagementPage"; // For the admin to manage users
import ReportAccessManagementPage from "../pages/ReportAccessManagementPage";
import MyAccountPage from "../pages/MyAccountPage";

export default function PublicRoutes() {
  return (
    <Routes>
      <Route element={<Navigate replace to="/login" />} path="*" />
      <Route element={<CandidateReportPage />} path="/candidate-report" />
      <Route element={<AuthPage />} path="/criar-conta" />
      <Route element={<TestPage />} path="/entrevista" />
      <Route element={<CandidateFeedbackPage />} path="/feedback" />
      <Route element={<AuthPage />} path="/login" />
      <Route element={<PrivacyPolicyPage />} path="/politica-de-privacidade" />
      <Route element={<PrivacyPolicyPage />} path="/privacy-policy" />
      <Route element={<AuthPage />} path="/recuperar-senha" />
      <Route element={<AuthPage />} path="/registrar" />
      <Route element={<CandidateReportPage />} path="/relatorio" />
      <Route element={<ReportAccessManagementPage />} path="/acesso-a-relatorios" />
      <Route element={<TermsConditionsPage />} path="/termos-e-condicoes" />
      <Route element={<TermsConditionsPage />} path="/terms-and-conditions" />
      <Route element={<UserManagementPage />} path="/contas" />
      <Route element={<MyAccountPage />} path="/minha-conta" />
    </Routes>
  );
}
