// Request camera access triggering a popup for the user to give permission
import { useState, useEffect } from 'react';
import { logException } from '../services/loggerFront';

const useCameraAccess = () => {
    const [videoStream, setVideoStream] = useState(null);
    const [cameraError, setCameraError] = useState(null);

    const requestCameraAccess = async () => {
        try {

            if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
                throw new Error('getUserMedia is not supported in this browser');
            }

            const streamPromise = navigator.mediaDevices.getUserMedia({ video: true });

            // Add a timeout to getUserMedia
            const timeoutPromise = new Promise((_, reject) =>
                setTimeout(() => reject(new Error('getUserMedia request timed out')), 10000) // 10 seconds timeout
            );

            const stream = await Promise.race([streamPromise, timeoutPromise]);
            setVideoStream(stream);
            setCameraError(null); // Reset error state on successful access
        } catch (error) {
            console.error('Error accessing camera:', error);
            setCameraError(error); // Update state with the encountered error
            logException('CameraAccessException', error, { fatal: false });
        }
    };

    useEffect(() => {
        requestCameraAccess();

        return () => {
            if (videoStream) {
                videoStream.getTracks().forEach(track => track.stop());
            }
        };
    }, []); // run once when rendered

    return { videoStream, cameraError, requestCameraAccess };
};

export default useCameraAccess;
