// Capture the current feed of the screen record and save it
import { logException, logTrace } from './loggerFront';

const fileName = 'ScreenCaptureService'; // For logging

// Function to capture screen and draw it onto a canvas
const captureScreen = (stream, canvasRef, maxCanvasWidth = 1920) => {
    return new Promise((resolve, reject) => {
        if (!canvasRef.current) {
            logException('Canvas reference not found', {
                status: 'Failed',
                fileName: fileName
            });
            reject('Canvas reference not found');
            return;
        }

        const videoElement = document.createElement('video');
        videoElement.srcObject = stream;
        videoElement.play();
        
        const context = canvasRef.current.getContext('2d');
        videoElement.onloadedmetadata = () => {
            // Calculate the aspect ratio of the screen capture
            const aspectRatio = videoElement.videoWidth / videoElement.videoHeight;
            // Calculate the canvas height to maintain the aspect ratio
            const canvasHeight = maxCanvasWidth / aspectRatio;

            // Set the canvas width to the maximum or the video's width, whichever is smaller
            canvasRef.current.width = Math.min(maxCanvasWidth, videoElement.videoWidth);
            // Set the canvas height based on the calculated value or the video's height, whichever is smaller
            canvasRef.current.height = Math.min(canvasHeight, videoElement.videoHeight);

            // Draw the image onto the canvas, resizing it if necessary
            context.drawImage(videoElement, 0, 0, canvasRef.current.width, canvasRef.current.height);
            resolve(); // Resolve the promise after drawing the frame
        };
        }).catch(err => {
            logException('Error playing video stream', {
                status: 'Failed',
                errorMessage: err.toString(),
                fileName: fileName
            });
            reject(err);
        });
};

// Function to save the captured image from the canvas
const saveCapture = (canvasRef, candidateId, testAttemptId, screenId) => {
    return new Promise((resolve, reject) => {
        if (canvasRef.current) {
            canvasRef.current.toBlob(blob => {
                const formData = new FormData();
                formData.append('screenshot', blob);
                formData.append('candidateId', candidateId);
                formData.append('testAttemptId', testAttemptId);
                formData.append('screenId', screenId);

                fetch('/api/upload-screenshot', {
                    method: 'POST',
                    body: formData
                })
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        resolve(data); // Resolve with response data
                    } else {
                        logException('Screenshot upload failure', {
                            status: 'Failed',
                            errorMessage: data.message,
                            fileName: fileName
                        });
                        reject(data.message);
                    }
                })
                .catch(error => {
                    logException('Screenshot upload error', {
                        status: 'Failed',
                        errorMessage: error.toString(),
                        fileName: fileName
                    });
                    reject(error);
                });
            }, 'image/png');
        } else {
            logException('Canvas reference not found', {
                status: 'Failed',
                fileName: fileName
            });
            reject('Canvas reference not found');
        }
    });
};

// Stop the screen sharing
const stopScreenShareStreams = (screenStream1, setScreenStream1, screenStream2, setScreenStream2) => {
    // Stop screenStream1
    if (screenStream1) {
        const tracks1 = screenStream1.getTracks();
        tracks1.forEach(track => track.stop());
        setScreenStream1(null);
        logTrace("Screen 1 stream stopped successfully", {
            type: 'Screen Share', 
            fileName: fileName
        });
    }

    // Stop screenStream2
    if (screenStream2) {
        const tracks2 = screenStream2.getTracks();
        tracks2.forEach(track => track.stop());
        setScreenStream2(null);
        logTrace("Screen 2 stream stopped successfully", {
            type: 'Screen Share', 
            fileName: fileName
        });
    }
};

export { captureScreen, saveCapture, stopScreenShareStreams };
