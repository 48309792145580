/**
 * Checks the highest level of a specific permission that a user has.
 * 
 * @param {Array} userPermissions - Array of user permissions in the format 'PERMISSION:LEVEL'
 * @param {string} permission - The permission to check, e.g., 'VIEW_USERS', 'ADD_USER'
 * @returns {string} - Returns 'GLOBAL' if the user has global permission, 'ORGANISATION' if the user has organisation-level permission, or 'NONE' if the user has neither.
 */
export const getPermissionLevel = (userPermissions, permission) => {
    
    // Basic validation for userPermissions
    if (!Array.isArray(userPermissions)) {
        return 'NONE';
    }

    if (typeof permission !== 'string' || permission.trim() === '') {
        return 'NONE';
    }

    // Check for GLOBAL level permission first
    if (userPermissions.includes(`${permission}:GLOBAL`)) {
        return 'GLOBAL';
    }

    // Check for ORGANISATION level permission next
    if (userPermissions.includes(`${permission}:ORGANISATION`)) {
        return 'ORGANISATION';
    }

    // Return NONE if no permission found
    return 'NONE';
};
