import { getAppInsights } from './appInsightsService';

// Enhanced logging wrapper to include delay and retry logic
const safelyLogWithRetry = (logFunction, retryCount = 2, delay = 2000) => {
    const attemptLogging = (attempt, ...args) => {
        try {
            const appInsights = getAppInsights();
            if (!appInsights) {
                if (attempt < retryCount) {
                    // If App Insights is not initialized, wait for a bit and retry
                    setTimeout(() => attemptLogging(attempt + 1, ...args), delay);
                } 
            } else {
                // If App Insights is initialized, proceed with logging
                logFunction(appInsights, ...args);
            }
        } catch (error) {
        }
    };

    return (...args) => attemptLogging(0, ...args);
};


export const logEvent = safelyLogWithRetry((appInsights, name, properties = {}) => {
    appInsights.trackEvent({ name, properties });
});

export const logException = safelyLogWithRetry((appInsights, exception, properties = {}) => {
    appInsights.trackException({ exception: new Error(exception), properties });
});

export const logTrace = safelyLogWithRetry((appInsights, message, properties = {}) => {
    appInsights.trackTrace({ message, properties });
});
