import { useState, useEffect, useCallback } from 'react';
import { 
    fetchReportAccessSummary, 
    addReportAccess, 
    removeReportAccess, 
    editReportAccess, 
    fetchReportAssignments, 
    getAllCompanies,
    fetchUserSummary
} from '../services/databaseService';
import { hasPermission } from "../utils/hasPermission";
import { getPermissionLevel } from '../utils/getPermissionLevel';
import { logException } from '../services/loggerFront';

const useReportAccessManagement = (companyId, userPermissions, userFullName) => {
    const [reportAccesses, setReportAccesses] = useState([]); // List of existing report accesses
    const [users, setUsers] = useState([]); // List of users to assign report access
    const [allCompanies, setAllCompanies] = useState([]);
    const [loading, setLoading] = useState(true);
    const [modalLoading, setModalLoading] = useState(false);
    const [error, setError] = useState(null);
    const [positionAssignments, setPositionAssignments] = useState([]); // List of Positions that can be given access to
    const [companyAssignments, setCompanyAssignments] = useState([]); // List of Companies that can be given access to if any
    const [uniqueRoles, setUniqueRoles] = useState([]); // Unique Positions for filtering
    const [uniqueCompanies, setUniqueCompanies] = useState([]); // Unique companies for filtering
    const [uniqueGrantingUsers, setUniqueGrantingUsers] = useState([]); // Unique granting users for filtering
    const fileName = 'useReportAccessManagement.js';

    // Fetch report accesses when the hook is first used or when the companyId changes
    const fetchReportAccesses = useCallback(async () => {
        setLoading(true);
        setError(null);
    
        try {
            const permissionLevel = getPermissionLevel(userPermissions, 'VIEW_PERMISSIONS');
            let data;
    
            if (permissionLevel === 'GLOBAL') {
                data = await fetchReportAccessSummary(); // Fetch all report accesses globally
                // For Global Admins, fetch all companies
                const companies = await getAllCompanies();
                setAllCompanies(companies);
            } else if (permissionLevel === 'ORGANISATION' && companyId) {
                data = await fetchReportAccessSummary(companyId); // Fetch report accesses for the specific company
            } else {
                throw new Error("Você não tem permissão para visualizar acessos ao relatório.");
            }
    
            // Flatten and transform the data for easier handling
            const flattenedData = data.map(access => ({
                ...access,
                companyName: access.ValidCompany.companyName,
                grantingUserName: access.grantingUser.name,
                roleName: access.role.roleName,
                userName: access.user.name,
                userEmail: access.user.email,
            }));
    
            setReportAccesses(flattenedData);
    
            // Extract unique values for filters
            const roles = [...new Set(flattenedData.map(access => access.roleName))];
            const companies = [...new Set(flattenedData.map(access => access.companyName))];
            const grantingUsers = [...new Set(flattenedData.map(access => access.grantingUserName))];
            setUniqueRoles(roles);
            setUniqueCompanies(companies);
            setUniqueGrantingUsers(grantingUsers);
    
        } catch (err) {
            logException('Error fetching report accesses:', {
                errorMessage: err.message,
                errorStack: err.stack,
                fileName: fileName,
                companyId
            });
            setError(err.message);
        } finally {
            setLoading(false);
        }
    }, [companyId, userPermissions]);
    
    // Fetch the list of users to be able to assign report access
    const fetchUsers = useCallback(async () => {
        setLoading(true);
        setError(null);
    
        try {
            const permissionLevel = getPermissionLevel(userPermissions, 'VIEW_USERS');
            let data;
    
            if (permissionLevel === 'GLOBAL') {
                data = await fetchUserSummary(); // Fetch all users globally
            } else if (permissionLevel === 'ORGANISATION' && companyId) {
                data = await fetchUserSummary(companyId); // Fetch users for the specific company
            } else {
                throw new Error("You do not have permission to view users.");
            }
    
            setUsers(data);

        } catch (err) {
            logException('Error fetching users:', {
                errorMessage: err.message,
                errorStack: err.stack,
                fileName: fileName,
                companyId
            });
            setError(err.message);
        } finally {
            setLoading(false);
        }
    }, [companyId, userPermissions]);      

    useEffect(() => {
        fetchReportAccesses();
        fetchUsers();
    }, [fetchReportAccesses, companyId]);

    // Function to add a report access
    const handleAddReportAccess = async (accessData, roleName, companyName, users) => {
        if (!hasPermission(userPermissions, 'ADD_PERMISSION')) {
            const errorMessage = "Você não tem permissão para adicionar acesso ao relatório.";
            setError(errorMessage);
            throw new Error(errorMessage);
        }
    
        setLoading(true);
        setError(null);
    
        try {
            const response = await addReportAccess(accessData);
            if (response) {
                const newAccess = response.access.map(access => {
                    const user = users.find(u => u.id === access.userId);
                    return {
                        ...access,
                        roleName, // Add roleName to each access object
                        companyName, // Add companyName to each access object
                        userName: user ? user.name : '', // Add userName
                        userEmail: user ? user.email : '', // Add userEmail
                        grantingUserName: userFullName, // Add the name of the user granting access
                    };
                });
    
                // Update the state with the newly added access
                setReportAccesses((prevAccesses) => [...prevAccesses, ...newAccess]);
                return true; // Indicate success
            } else {
                throw new Error('Failed to add report access');
            }
        } catch (err) {
            const errorMessage = err.message || 'Falha ao adicionar acesso ao relatório. Por favor, tente novamente.';
            setError(errorMessage);
            logException('Error adding report access:', {
                errorMessage: err.message,
                errorStack: err.stack,
                fileName: fileName,
            });
            throw err; // Re-throw the error to indicate failure
        } finally {
            setLoading(false);
        }
    };
    
     
    // Remove a report access by ID
    const handleRemoveReportAccess = async (reportId) => {
        if (!hasPermission(userPermissions, 'REMOVE_PERMISSION')) {
            setError("Você não tem permissão para remover acesso ao relatório.");
            return;
        }

        setLoading(true);
        setError(null);

        try {
            await removeReportAccess(reportId);
            setReportAccesses((prevAccesses) => prevAccesses.filter(access => access.id !== reportId));
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    // Edit the report access details
    const handleEditReportAccess = async (reportId, accessData) => {
        setModalLoading(true);
        if (!hasPermission(userPermissions, 'EDIT_PERMISSION')) {
            setError("Você não tem permissão para editar acesso ao relatório.");
            return;
        }
    
        setError(null);
    
        try {
            const response = await editReportAccess(reportId, accessData);
            const updatedAccess = response.access;
    
            // Merge the updated fields into the existing report access object
            setReportAccesses((prevAccesses) =>
                prevAccesses.map(access =>
                    access.id === reportId ? { ...access, ...updatedAccess } : access
                )
            );
            setModalLoading(false);
        } catch (err) {
            setModalLoading(false);
            // error message for user in Brasil
            const errorMessage = 'Falha ao editar acesso. Por favor, tente novamente.';
            setError(errorMessage);
            setTimeout(() => {
                setError(null);
            }, 5000);
            logException('Error editing report access:', {
                errorMessage: err.message,
                errorStack: err.stack,
                fileName: fileName,
            });
        }
    };   

    // On load get the report assignments
    useEffect(() => {
        const fetchReportAssignmentsData = async () => {

            if (!hasPermission(userPermissions, 'ADD_PERMISSION')) {
                setError("Você não tem permissão para adicionar acesso ao relatório.");
                return;
            }

            try {
                const permissionLevel = getPermissionLevel(userPermissions, 'ADD_PERMISSION');
                let data;
        
                if (permissionLevel === 'GLOBAL') {
                    data = await fetchReportAssignments(); // Fetch all report accesses globally
                    // For global admins, fetch all companies that have results
                    const companyAssignments = data.companies;
                    setCompanyAssignments(companyAssignments);
                } else if (permissionLevel === 'ORGANISATION' && companyId) {
                    data = await fetchReportAssignments(companyId); // Fetch report accesses for the specific company
                } else {
                    throw new Error("Você não tem permissão para addicionar acesso.");
                }
        
                // For all users get the positions
                const positionAssignments = data.roles;
                setPositionAssignments(positionAssignments);
            } catch (err) {
                logException('Error fetching position assignments:', {
                    errorMessage: err.message,
                    errorStack: err.stack,
                    fileName: fileName,
                    companyId
                });
                setError(err.message);
            } 
        }

        fetchReportAssignmentsData();
    }, []);

    return {
        reportAccesses,
        users,
        allCompanies,
        uniqueRoles,
        uniqueCompanies,
        uniqueGrantingUsers,
        positionAssignments,
        companyAssignments,
        loading,
        modalLoading,
        error,
        setError,
        fetchReportAccesses,
        handleAddReportAccess,
        handleRemoveReportAccess,
        handleEditReportAccess,
    };
};

export default useReportAccessManagement;
