// Summary of the permissions each role has
export const roleSummaryTableHeaders = ['User', 'Admin', 'SuperAdmin'];

export const roleSummaryTableRows = [
    {
        label: 'Visualizar relatórios',
        data: [true, true, true],  // All roles have access
    },
    {
        label: 'Compartilhar relatórios',
        data: [true, true, true],  // All roles have access
    },
    {
        label: 'Adicionar tags ao relatório',
        data: [true, true, true],  // All roles have access
    },
    {
        label: 'Adicionar/Remover/Editar contas',
        data: [false, 'Apenas Users', 'Users e Admins'],  // Specific permissions per role
    },
    {
        label: 'Adicionar acesso ao relatório',
        data: [false, true, true],  // Only Admin and SuperAdmin have access
    }
];
