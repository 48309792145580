// Table of subskills, score pie and rationale, that popup when the arrow is click on the score
import React, { useState, useEffect, useRef, useContext } from 'react';
import FeedbackContext from '../../contexts/FeedbackContext';
import styles from './SubSkillSummary.module.css'; // Path to your CSS module
import FeedbackInput from '../common/FeedbackInput'; // Import the FeedbackInput component
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faThumbsDown, faThumbsUp } from '@fortawesome/free-regular-svg-icons'; // non-filled icons
import { faChevronDown, faChevronUp, faTimes } from '@fortawesome/free-solid-svg-icons'; // filled icons

const SubSkillSummary = ({ subSkills, updateMaxHeight, maxHeights, pdfMode = false, isFirst, isLast, testAttemptId, evalId }) => {
    const [visibleSubSkillIndex, setVisibleSubSkillIndex] = useState(null);
    const nameRefs = useRef(subSkills.map(() => React.createRef())); // For defining the height of the skill name so it is uniform across rows
    const [isHoveringRationale, setIsHoveringRationale] = useState(null);
    const { feedbackMap } = useContext(FeedbackContext);   

    // Function to determine the fill color based on the score percentage
    const getFillColor = (percentage) => {
        if (percentage >= 80) return '#00B050'; // Dark green
        else if (percentage >= 60) return '#9acd32'; // Lime green
        else if (percentage >= 40) return '#ffbf00'; // Amber
        else if (percentage >= 20) return '#ff7f00'; // Orange
        return '#ff0000'; // Red
    };

    // Identify the max height of each subSkillName and apply to the rest in the row
    useEffect(() => {
        if(pdfMode) {
            return;
        }
        const updateHeights = () => {
            nameRefs.current.forEach((ref, index) => {
                if (ref.current) {
                    updateMaxHeight(index, ref.current.clientHeight);
                }
            });
        };
    
        setTimeout(updateHeights, 100); // Adjust delay as needed
    
        window.addEventListener('resize', updateHeights);
    
        return () => window.removeEventListener('resize', updateHeights);
    }, [updateMaxHeight, subSkills, pdfMode ]);      

    const toggleSubSkillVisibility = (index) => {
        setVisibleSubSkillIndex(visibleSubSkillIndex === index ? null : index);
    };

    return (
        <div className={styles.subSkillSummaryContainer}>
            {subSkills.map((subSkill, index) => {
                const percentage = Math.max(3, (subSkill.score / subSkill.maxScore) * 100); // Minimum 5% so there is some red when the score = 0
                const fillColor = getFillColor(percentage);
                const key = `${testAttemptId}_${subSkill.name}_${evalId}`;
                const shouldRenderFeedbackInput = feedbackMap[key]?.eval || (isHoveringRationale === index && !pdfMode); // Show the feedback icons if they are hovering the rationale or they have clicked thumbs up or down
                
                return (
                    <div key={index} className={`${styles.subSkillColumn} ${pdfMode ? styles.subSkillColumnPdfMode : ''}`}> 
                        <div className={styles.subSkillNameContainer}>
                            {!pdfMode && (
                                <div className={styles.questionMarkIcon}>
                                    <FontAwesomeIcon
                                        icon={faQuestionCircle}
                                        className={styles.infoIcon}
                                    />
                                     <span className={`${styles.tooltipText} ${isFirst ? styles.firstTooltipText : isLast ? styles.lastTooltipText : ''}`}>{subSkill.definition}</span>
                                </div>
                            )}
                            <div
                                ref={nameRefs.current[index]}
                                className={`${styles.subSkillName} ${pdfMode ? styles.subSkillNamePdfMode : ''}`}
                                style={{ minHeight: `${maxHeights[index] || 0}px` }}
                            >
                                {subSkill.name}
                            </div>
                        </div>
                        <div className={styles.barAndArrowContainer}>
                            <div className={styles.subSkillBarContainer}>
                                <div 
                                    className={styles.subSkillBar} 
                                    style={{ 
                                        width: `${percentage}%`, 
                                        backgroundColor: fillColor,
                                    }}
                                ></div>
                            </div>
                            <div 
                                className={styles.detailsArrow}
                                onClick={() => toggleSubSkillVisibility(index)}
                            >
                                <FontAwesomeIcon icon={visibleSubSkillIndex === index ? faChevronUp : faChevronDown} />
                            </div>
                        </div>
                        {(pdfMode || visibleSubSkillIndex === index) && (
                            <div className={styles.subSkillExpandedContent}>
                                <p className={`${styles.scoreDescription} ${pdfMode ? styles.scoreDescriptionPdfMode : ''}`} style={{color: fillColor}}>
                                    {subSkill.scoreDescription}
                                </p>
                                <div 
                                    className={styles.rationaleContainer}
                                    onMouseEnter={() => setIsHoveringRationale(index)}
                                    onMouseLeave={() => setIsHoveringRationale(null)}
                                >
                                    <p className={`${styles.rationale} ${pdfMode ? styles.rationalePdfMode : ''}`}>
                                        {subSkill.rationale}
                                        <span className={styles.trailingSpace}></span> {/* Adds trailing space using CSS */}
                                    </p>

                                    {/*feedback functionality*/}
                                    {shouldRenderFeedbackInput && (
                                        <FeedbackInput 
                                            feedbackDetails={{ source: 'CandidateEvaluation', test_attempt_id: testAttemptId, prompt_id: evalId, location: subSkill.name, content: subSkill.rationale }} 
                                            index={index} 
                                            popout={false}
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default SubSkillSummary;
