// Entry point to the front-end app
import React, { useContext, useEffect } from "react";
import { BrowserRouter, useLocation } from "react-router-dom";
import { initializeAppInsights } from "./services/appInsightsService"; // Enable sending logs to Azure App Insights

// Contexts to wrap the app in
import { AppContext, AppProvider } from "./contexts/AppContext";
import { ChatProvider } from "./contexts/ChatContext";
import { CodeProvider } from "./contexts/CodeContext";
import { CopyPasteProvider, useCopyPaste } from "./contexts/CopyPasteContext";
import { TestProvider } from "./contexts/TestContext";

import useAuth from "../src/hooks/useAuth";

// Routes
import RoutesProvider from "./routes";

const App = () => {
  const { isTokenExpired, signOut } = useAuth();

  useEffect(() => {
    if (isTokenExpired()) {
      signOut();
    }
  }, [isTokenExpired, signOut]);

  return (
    <BrowserRouter>
      <AppProvider location={location}>
        <CopyPasteProvider location={location}>
          <TestProvider location={location}>
            <CodeProvider>
              <ChatProvider>
                <AppContent />
              </ChatProvider>
            </CodeProvider>
          </TestProvider>
        </CopyPasteProvider>
      </AppProvider>
    </BrowserRouter>
  );
};

const AppContent = () => {
  const location = useLocation();
  const { setGlobalState } = useContext(AppContext);

  // Initialize App Insights to enable logging
  useEffect(() => {
    initializeAppInsights().then(() => {
      setGlobalState((prevState) => ({
        ...prevState,
        appInsightsInitialized: true,
      }));
    });
  }, [setGlobalState]);

  return (
    <>
      <GlobalCopyAndPasteListeners />
      <RoutesProvider />
    </>
  );
};

// Global copy and paste listeners to enable anti-fraud copy paste rules
const GlobalCopyAndPasteListeners = () => {
  const { handleCopy, handlePaste } = useCopyPaste();

  useEffect(() => {
    const onCopy = (event) => {
      const selectedText = window.getSelection().toString();
      const source = determineCopySource(event);
      handleCopy(selectedText, source);
    };

    const onCut = (event) => {
      const selectedText = window.getSelection().toString();
      handleCopy(selectedText, determineCopySource(event));
    };

    const onPaste = (event) => {
      handlePaste(event);
    };

    document.addEventListener("copy", onCopy);
    document.addEventListener("cut", onCut);
    document.addEventListener("paste", onPaste);

    return () => {
      document.removeEventListener("copy", onCopy);
      document.removeEventListener("cut", onCut);
      document.removeEventListener("paste", onPaste);
    };
  }, [handleCopy, handlePaste]);

  return null;
};

// Determine the source of copy events, to make copy paste rules more specific
function determineCopySource(event) {
  if (event.target.closest("#instructionsContainer")) {
    return "other";
  }
  return "other";
}

export default App;
