// The console where the response from the compiler appears. The runnings of the code happens in IDE.js
import React, { useEffect, useRef } from 'react';
import { useCodeContext } from '../../contexts/CodeContext';
import { FadeLoader } from 'react-spinners'; // React spinner for animation when code is compiling
import styles from './CodeOutput.module.css';

const CodeOutput = ({ compilerResponse, currentlyCompiling, candidateReport = false }) => {
    const outputRef = useRef(null);
    const { theme } = useCodeContext();
    const themeClass = theme === 'dark' ? styles.dark : styles.light; // Decide the colours of components based on the theme to match the editor theme
    const spinnerColor = theme === 'dark' ? '#fff' : '#000'; // Determine spinner color based on theme

    // Adjust the height of the output area based on the content. Adjust immediately when 'executeCode' is clicked and the loader appears, and again when the content from the compiler response comes
    useEffect(() => {
        if (!candidateReport) {
            setTimeout(() => {
                if (outputRef.current) {
                    // Reset min-height to 0 or the absolute minimum you want before calculating
                    outputRef.current.style.minHeight = '80px'; 
                    const padding = 6; // Adjust this value as needed for padding
                    const scrollHeight = outputRef.current.scrollHeight + padding;
                    const minHeight = 80; // Minimum height in pixels
                    const maxHeight = 1000; // Maximum height in pixels
                    outputRef.current.style.minHeight = `${Math.min(Math.max(scrollHeight, minHeight), maxHeight)}px`;
                }
            }, 0);
        } else {
            if (outputRef.current) {
                outputRef.current.style.minHeight = '50px';
            }
        }
    }, [compilerResponse, currentlyCompiling]); // Update when compilerResponse changes

    return (
        <div className={`${styles.outputArea} ${themeClass}`} ref={outputRef}>
            <div className={styles.codeOutputHeader}>
                <h4>{candidateReport ? 'Saída do código submetido:' : 'Console:'}</h4>
            </div>
            <div className={styles.compilerResponse}>
                {currentlyCompiling ? (
                    <span><FadeLoader color={spinnerColor} size={5} /></span> 
                ) : (
                    <pre>{compilerResponse}</pre>
                )}
            </div>
        </div>
    );
};

export default CodeOutput;
