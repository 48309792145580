import React, { useEffect } from 'react';
import { useCodeContext } from '../../contexts/CodeContext';
import PropTypes from 'prop-types';
import styles from './SqlOutput.module.css';

const SqlOutput = ({ data }) => {
    const { theme } = useCodeContext();
    const themeClass = theme === 'dark' ? styles.dark : styles.light;
    
    const renderTable = (data) => {
        if (!data || data.length === 0) {
            return <div>No results</div>;
        }

        const columns = Object.keys(data[0]);
        return (
            <table className={styles.table}>
                <thead>
                    <tr>
                        {columns.map((col, index) => (
                            <th key={index}>{col}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, rowIndex) => (
                        <tr key={rowIndex} className={themeClass}>
                            {columns.map((col, colIndex) => (
                                <td key={colIndex}>{row[col]}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };

    return (
        <div className={`${styles.outputArea} ${themeClass}`}>
            <div className={styles.mainHeading}>
                RESULTADOS:
            </div>
            {renderTable(data)}
        </div>
    );
};

SqlOutput.propTypes = {
    data: PropTypes.array.isRequired
};

export default SqlOutput;
