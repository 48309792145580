import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { TooltipProvider } from "../contexts/TooltipContext";
import { useAppContext } from "../contexts/AppContext";
import { useTestContext } from "../contexts/TestContext";
import useCompanyReportData from "../hooks/useCompanyReportData";
import useEnsureUserData from '../hooks/useEnsureUserData';
import useReportTags from "../hooks/useReportTags";
import useFilterData from "../hooks/useFilterReportSummary";
import { addTagToResult, removeTagFromResult } from "../services/databaseService";
import { logException, logEvent, logTrace } from "../services/loggerFront";
// Standard page components
import Header from "../components/common/Header";
import TopRibbon from "../components/common/TopRibbon";
import ReportIssue from "../components/common/ReportIssue";
import FadeLoader from '../components/common/FadeLoader';
import CompanyPageSidebar from '../components/common/CompanyPageSidebar';
import CompanyPageSidebarToggle from '../components/common/CompanyPageSidebarToggle';

import ResultsSummaryTable from "../components/report/ResultsSummaryTable";
import DashboardTagModal from "../components/report/DashboardTagModal";
import ReportSummarySidebarFilters from "../components/report/ReportSummarySidebarFilters";
import ActionButton from "../components/common/ActionButton";
import ConfirmModal from "../components/common/ConfirmModal";
import { faChevronLeft, faChevronRight, faBars, faSync } from "@fortawesome/free-solid-svg-icons";
import styles from "../sharedStyles/CompanyAdminPages.module.css"; 

const CompanyDashboard = () => {
    const navigate = useNavigate(); // Function to navigate to a different page
    const { userPermissions, isOrgAdmin, companyId } = useAppContext();
    const { companyDetails } = useTestContext();
    const { fetchUserData } = useEnsureUserData();
    // on load fetch user data
    useEffect(() => {
        fetchUserData();
    }, []);
    const [isGlobalDashboard, setIsGlobalDashboard] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null); // Error message to display when there is an error
    const { companyList, roleList, 
        companySummaryData, urlCodes, resultTagsMap, setResultTagsMap, companyCustomTags,
        totalCount, roleCounts, companyCounts, approvedCounts, tagCounts,
        isLoadingInitialData, refreshData, fetchTagsForResults, fetchCompanyTags
    } = useCompanyReportData(isGlobalDashboard, isOrgAdmin, companyDetails, setErrorMessage); // Hook to fetch the company data
    const { updateResultTagsMap, addOrUpdateTag, deleteTag } = useReportTags(companyId, setResultTagsMap, fetchCompanyTags); // Hook to manage tags for the company
    const { 
        filteredData, 
        roleFilters,
        companyFilters,
        approvedFilters,
        tagFilters, 
        selectAll, 
        candidateNameFilter, 
        handleRoleFilterChange,
        handleCompanyFilterChange,
        handleApprovedFilterChange,
        handleTagFilterChange, 
        handleSelectAll, 
        handleCandidateNameChange,
    } = useFilterData(companySummaryData, resultTagsMap); // Hook to filter the data based on the selected roles and tags
    const [showConfirmDeleteTagModal, setShowConfirmDeleteTagModal] = useState(false);
    const [isSidebarVisible, setIsSidebarVisible] = useState(true); // Manage the visibility of the sidebar with filters
    const [isTagModalVisible, setIsTagModalVisible] = useState(false); // Manage the visibility of the tag modal for user to add or edit tags
    const [tagModalVariant, setTagModalVariant] = useState("add"); // Variant of the tag modal - add or edit
    const [selectedTag, setSelectedTag] = useState(null); // Tag to delete when the user confirms the delete action
    const [editingTag, setEditingTag] = useState(null); // Tag to edit when the user clicks on the edit button

    const fileName = "CompanyDashboardPage.js"; 

    // CHECK ACCESS LEVELS

    // Check if the dashbaord permission is a Global
    const checkGlobalDashboardPermission = async () => {
        let viewDashboardPermission = null;

        if (userPermissions) {
            viewDashboardPermission = userPermissions.find(permission => permission.startsWith('ACCESS_DASHBOARD'));
        } else {
            return;
        }

        if (!viewDashboardPermission) {
            logException('User does not have ACCESS_DASHBOARD permission.', { fileName });
            navigate('/login');
            return;
        }

        const [, level] = viewDashboardPermission.split(':');
        setIsGlobalDashboard(level === 'GLOBAL');
    };

    // call on load
    useEffect(() => {
        checkGlobalDashboardPermission();
    }, [userPermissions]);

    // Toggle the visibility of the sidebar
    const handleSidebarToggle = () => {
        setIsSidebarVisible(!isSidebarVisible);
    };

    /* TAGS */

    // Open the tag modal to add a new tag
    const handleAddCompanyTag = () => {
        setEditingTag(null);
        setTagModalVariant("add");
        setIsTagModalVisible(true);
    };

    // Open the tag modal to edit a tag
    const handleEditCompanyTag = (tagId) => {
        const tagToEdit = companyCustomTags.find((tag) => tag.tag_id === tagId);
        setTagModalVariant("edit");
        setEditingTag(tagToEdit);
        setIsTagModalVisible(true);
    };

    // Handle the addition or update of a tag
    const handleAddOrUpdateTag = async (tagDetails) => {
        await addOrUpdateTag(tagDetails, () => {
            setIsTagModalVisible(false);
            fetchTagsForResults(filteredData); // Update the tags for the results
        });
    };

    // confirm before deleting a tag

    //First save the tag and ask to confirm
    const handleDeleteTagClick = (tagId) => {
        setSelectedTag(tagId);
        setShowConfirmDeleteTagModal(true);
    };

    // If the user cancels the deletion of a tag
    const handleCancelDeleteTag = () => {
        setShowConfirmDeleteTagModal(false);
    };

    // Handle the deletion of a tag aftwr user confirms
    const handleDeleteTag = async () => {
        if (selectedTag) {
            await deleteTag(selectedTag, () => {
                fetchTagsForResults(filteredData); // Update the tags for the results
            });
            setShowConfirmDeleteTagModal(false);
        }
    };

    return (
        <>
            <TooltipProvider>
            <Header />
            <ReportIssue/>
                <div className={styles.outerContainer}>
                    <TopRibbon title="Painel de resultados" />
                    {/* Confirm Modal for deleting tags */}
                    <ConfirmModal
                        show={showConfirmDeleteTagModal}
                        title="Excluir Tag"
                        message="Tem certeza de que deseja excluir esta tag? Isso removerá todas as instâncias da tag nos relatórios."
                        onConfirm={handleDeleteTag}
                        onCancel={handleCancelDeleteTag}
                        confirmLabel="Confirmar"
                        cancelLabel="Cancelar"
                    />
                    {isTagModalVisible && (
                        <DashboardTagModal
                            editingTag={editingTag}
                            addCustomCompanyTag={handleAddOrUpdateTag}
                            setIsTagModalVisible={setIsTagModalVisible}
                            tagModalVariant={tagModalVariant}
                        />
                    )}
                    {isLoadingInitialData ? (
                        <FadeLoader text="Carregando dados..." />
                    ) : (
                        <div className={styles.mainContainer}>
                            {isSidebarVisible && companySummaryData.length > 0 && (
                                <CompanyPageSidebar
                                    header={
                                        <>
                                            <span>Filtros</span>
                                            <ActionButton 
                                                icon={faSync} 
                                                label="Atualizar Dados" 
                                                onClick={refreshData}
                                                showHoverPopout={true}
                                                popoutText="Atualizar dados"
                                                popoutPosition="left"
                                            />
                                        </>
                                    }
                                    isVisible={isSidebarVisible}
                                >
                                    <ReportSummarySidebarFilters
                                        roleList={roleList}
                                        roleFilters={roleFilters}
                                        handleRoleFilterChange={handleRoleFilterChange}
                                        totalCount={totalCount}
                                        roleCounts={roleCounts}
                                        tagFilters={tagFilters}
                                        handleTagFilterChange={handleTagFilterChange}
                                        tagCounts={tagCounts}
                                        handleAddCompanyTag={handleAddCompanyTag}
                                        companyCustomTags={companyCustomTags}
                                        handleEditCompanyTag={handleEditCompanyTag}
                                        handleRemoveTag={handleDeleteTagClick}
                                        handleSelectAll={handleSelectAll}
                                        selectAll={selectAll}
                                        candidateNameFilter={candidateNameFilter}
                                        handleCandidateNameChange={handleCandidateNameChange}
                                        companies={companyList}
                                        companyFilters={companyFilters}
                                        handleCompanyFilterChange={handleCompanyFilterChange}
                                        companyCounts={companyCounts}
                                        approvedFilters={approvedFilters}
                                        handleApprovedFilterChange={handleApprovedFilterChange}
                                        approvedCounts={approvedCounts}
                                        isGlobalDashboard={isGlobalDashboard}
                                        refreshData={refreshData}
                                    />
                                </CompanyPageSidebar>
                            )}
                            {companySummaryData.length > 0 && (
                                <CompanyPageSidebarToggle
                                    onToggle={handleSidebarToggle}
                                    toggleIcon={isSidebarVisible ? faChevronLeft : faChevronRight}
                                    toggleLabel={isSidebarVisible ? "Ocultar Barra Lateral" : "Mostrar Barra Lateral"}
                                />
                            )}
                            <div className={styles.contentContainer} aria-label="Tabela resumo dos resultados dos candidatos" tabIndex="0">
                                <ResultsSummaryTable
                                    companySummaryData={filteredData}
                                    urlCodes={urlCodes}
                                    roles={roleList}
                                    companies={companyList}
                                    resultTagsMap={resultTagsMap}
                                    removeTagFromResult={removeTagFromResult}
                                    addTagToResult={addTagToResult}
                                    availableTags={companyCustomTags}
                                    updateResultTagsMap={updateResultTagsMap}
                                    isGlobalDashboard={isGlobalDashboard}
                                    refreshData={refreshData}
                                    errorMessage={errorMessage}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </TooltipProvider>
        </>
    );
};

export default CompanyDashboard;
