//Show progress to user during test
import React, { useContext, useEffect } from 'react';
import { SectionContext } from '../../contexts/SectionContext';
import { useTestContext } from '../../contexts/TestContext';
import styles from './ProgressBar.module.css'; 

const ProgressBar = () => {
    const { testSections, currentSection } = useTestContext();
    const sectionName = currentSection ? currentSection.sectionName : '';

    // Calculate the total weight of all sections
    const totalWeight = testSections.reduce((total, section) => {
        return total + (section.sectionWeight || 0);
    }, 0);

    return (
        <div className={styles.progressBarContainer}>
            <div className={styles.sectionLabel}>
                {currentSection ? currentSection.sectionNameUser : 'Carregando...'}
            </div>
            <div className={styles.progressBar}>
                {testSections.map((section) => {
                    const weight = section.sectionWeight || 0;
                    const widthPercentage = (weight / totalWeight) * 100;
                    let barClass = styles.upcomingSection;
                    if (section.sectionName === sectionName) {
                        barClass = styles.currentSection;
                    } else if (testSections.indexOf(currentSection) > testSections.indexOf(section)) {
                        barClass = styles.passedSection;
                    }
                    return <div key={section.sectionName} className={`${styles.sectionBar} ${barClass}`} style={{ width: `${widthPercentage}%` }}></div>;
                })}
            </div>
        </div>
    );
};

export default ProgressBar;