// Initialise appInsights to save logs
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

let appInsights = null;

export const initializeAppInsights = async () => {
    try {
        const response = await fetch('/api/appinsights-string');
        const data = await response.json();
        if (data.string) {
            appInsights = new ApplicationInsights({
                config: {
                    connectionString: data.string,
                    disableFetchTracking: false,
                    maxAjaxCallsPerView: 1000,
                }
            });
            appInsights.loadAppInsights();
        } else {
        }
    } catch (error) {
        console.error('Failed to initialize Application Insights:', error);
    }
};

export const getAppInsights = () => {
    return appInsights;
};

