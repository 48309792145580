// Store states and functions for the IDE and SqlInterface components
import React, { createContext, useState, useEffect, useRef, useContext, useCallback } from 'react';
import { useAppContext } from './AppContext';
import { useTestContext } from './TestContext';
import throttle from '../utils/throttle';
import { updateTestAttempt, saveCodeResponse, updateCodeResponse } from '../services/databaseService';
import { executeCode, fetchSchema } from '../services/compileCodeService';
import { logEvent, logException, logTrace } from '../services/loggerFront';
import useCodeActivityTracker from '../hooks/useCodeActivityTracker';
import { languageMappings } from '../components/coding/LanguageMapping';
import { debounce, set, uniqueId } from 'lodash';
import * as Babel from '@babel/standalone'; // For transpiling code in the browser

export const CodeContext = createContext();

export const CodeProvider = ({ children }) => {
    // Global states
    const { globalState } = useAppContext();
    const { formatDateForMySQL, INTERRUPTION_RETRY_MAX, INTERRUPTION_RETRY_INTERVAL } = useAppContext(); //coding language selected and associated mapping for compiling and for the editor. Details in LanguagesDropdown.js
    const { testStage, setTestStage, currentSection, sectionTimeUp, testAttemptId, candidateId, codeResponseId, setCodeResponseId, isTestInterrupted, setIsTestInterrupted, retrievedCode, retrievedSqlLogs, languageToRestore, setFinalCode, setCodeSubmitted, setFinalConsoleOutput, roleDetails } = useTestContext();
    const roleCode = roleDetails?.roleCode || ''; // Role code for the compiler
    // IDE states
    const [availableCodeLanguages, setAvailableCodeLanguages] = useState(["all"]); // Default to all languages
    const [singleIdeLanguage, setSingleIdeLanguage] = useState(false); // If the test has only one language to be used in the IDE
    const [isMultiFile, setIsMultiFile] = useState(false); // If the test has multiple files to be submitted
    const isMultiFileRef = useRef(isMultiFile);
    const [activeFile, setActiveFile] = useState(null); // Track the active file in the multi file test
    const activeFileRef = useRef(activeFile);
    const [currentIdeCode, setCurrentIdeCode] = useState(''); // All the code for the IDE, can be multifile
    const [editorContent, setEditorContent] = useState(''); // The specific code to be in the editor
    const codeRef = useRef(currentIdeCode);
    const currentIdeCodeRef = useRef(currentIdeCode);
    const [showEmptyEditorAlert, setShowEmptyEditorAlert] = useState(false); // Popup to say editor cnt be empty if running or submitting code
    const [showConfirmModal, setShowConfirmModal] = useState(false); // For popup to confirm submission instead of standard browser one which exits full screen
    const [ideCompilerResponse, setIdeCompilerResponse] = useState(''); // response in the console
    const ideCompilerResponseRef = useRef(ideCompilerResponse);
    const [showIdeCodeOutput, setShowIdeCodeOutput] = useState(false); // Hide the console until they run code
    const [ideCurrentlyCompiling, setIdeCurrentlyCompiling] = useState(false); // For CodeOutput to give a user message when the code is running
    const [codeExecutionCount, setCodeExecutionCount] = useState(0); // Track the number of code executions to not pass the max
    const maxCodeExecutions = 100; // So we dont hit jdoodle limits and to prevent infinite loops
    const [previewContent, setPreviewContent] = useState(''); // The transpiled code to be shown in the preview area
    const [defaultFile, setDefaultFile] = useState(null); // Default file for the multi-file test
    
    // SQL states
    const [currentSqlCode, setCurrentSqlCode] = useState(''); // Track the current SQL code in the editor
    const [sqlLogs, setSqlLogs] = useState([]); // Track the logs of the SQL terminal executions
    const sqlLogsRef = useRef(sqlLogs);
    const [databaseSchema, setDatabaseSchema] = useState({}); // Track the database schema to be visualised in the SQL interface tree view
    const databaseSchemaRef = useRef(databaseSchema); // Ref to keep the database schema up to date
    const [loadingDatabaseSchema, setLoadingDatabaseSchema] = useState(false); // Track if the database schema is being loaded
    const [sqlResults, setSqlResults] = useState([]); // Track the results of the SQL queries
    const [sqlQueryData, setSqlQueryData] = useState([]); // Track the data of the SQL queries
    const [currentlyCompilingSql, setCurrentlyCompilingSql] = useState(false);
    const [isFirstSqlRun, setIsFirstSqlRun] = useState(true); // Track if the SQL interface is being run for the first time
    // Compiler
    const [ compiler, setCompiler ] = useState('jdoodle'); // Compiler to use for code execution [jdoodle, degrau]
    // Saving
    const [codeResponseInitiated, setCodeResponseInitiated] = useState(false); // To not initiate multiple code response entries
    const isTestInterruptedRef = useRef({ interrupted: false, reason: null }) // Get most up to date when in retry loops
    const codeResponseIdRef = useRef(); // To get the most up to date codeResponseId
    const saveIntervalRef = useRef(null);
    const currentIdeLanguageRef = useRef('');
    const [excludeTranscript, setExcludeTranscript] = useState(false); // If the transcript is too large, stop saving it and continue saving the code itself
    const [saveActivitiesFailedCount, setSaveActivitiesFailedCount] = useState(0); // Count the number of failed save attempts
    // Record the user's actions in the code editor
    const { activities, trackKeystrokes, trackButtonClick, trackCompilerResponse, clearActivities, trackFocusChange, currentCodeLog } = useCodeActivityTracker(); //hook to record user activity
    const activitiesRef = useRef(); // up to date log of keystrokes and button presses

    const fileName = 'CodeContext.js'; // For logging purposes
    
    // COMPILER //

    // Set the compiler on load to know which API to use
    useEffect(() => {
        if (currentSection?.coding?.compiler) {
            setCompiler(currentSection.coding.compiler);
        }
    }, [currentSection]);

    // EDITOR SETTING //

    // Font Size //
    const [editorFontSize, setEditorFontSize] = useState(12);
    
    // Themes for editors and interface
    const [monacoEditorTheme, setMonacoEditorTheme] = useState('vs-dark'); // theme for Monaco Editor
    const [aceEditorTheme, setAceEditorTheme] = useState('monokai'); // theme for Ace Editor
    const [theme, setTheme] = useState('dark'); // theme for the wider site (needs to move to AppContext)
    const [themeDropdownValue, setThemeDropdownValue] = useState('Escuro'); // Default theme dropdown value	

    // Dropdown options for theme
    const themeDropdownOptions = [
        { value: 'Escuro', label: 'Escuro' },
        { value: 'Claro', label: 'Claro' },
        { value: 'AltoContraste', label: 'Alto Contraste' },
    ];

    // map themes for editors
    const editorThemes = {
        Escuro: { monaco: 'vs-dark', ace: 'monokai' },
        Claro: { monaco: 'vs-light', ace: 'chrome' },
        AltoContraste: { monaco: 'hc-black', ace: 'vibrant_ink' }
    };

    // Handle theme change
    const handleThemeChange = (e) => {
        const themeKey = e.target.value;
        setThemeDropdownValue(themeKey); // Set the dropdown value
        setMonacoEditorTheme(editorThemes[themeKey].monaco); // Set the Monaco Editor theme based on the theme key
        setAceEditorTheme(editorThemes[themeKey].ace); // Set the Ace Editor theme based on the theme key
        setTheme(themeKey === 'Claro' ? 'light' : 'dark'); // Set the wider site theme based on the theme key

        trackButtonClick({ 
            buttonId: 'themeSelector', 
            selectedTheme: themeKey,
            currentCode: btoa(currentIdeCode)
        });
    };

    // IDE

    // IDE - CODE

    // Get latest isMultiFile value
    useEffect(() => {
        isMultiFileRef.current = isMultiFile;
    }, [isMultiFile]);

    // Get latest ActiveFile value
    useEffect(() => {
        activeFileRef.current = activeFile;
    }, [activeFile]);

    // Get the latest currentIdeCode value
    useEffect(() => {
        currentIdeCodeRef.current = currentIdeCode;
    }, [currentIdeCode]);

    // Check if a mutlifile test is present
    useEffect(() => {
        if (currentSection?.coding?.multiFileIde?.enabled) {
            const file = currentSection?.coding?.multiFileIde?.defaultFile
            setIsMultiFile(true);
            setActiveFile(file);
            setDefaultFile(file);
        }
    }, [currentSection]);

    // Set the default code on load
    useEffect(() => {
        if (currentSection?.coding?.ideDefaultCode) {
            setCurrentIdeCode(currentSection?.coding?.ideDefaultCode || '');
        }
    }, [currentSection]);

    // Debounce the setCode function to avoid rapid state updates to enable autocomplete
    const debouncedSetCode = useRef(debounce((newCode) => {
        if (isMultiFileRef.current) {
            // Update the code of the activeFile, updating it with the code with the newCode
            if (activeFileRef.current) {
                const filePathArray = activeFileRef.current.split('/');
                let fileContent = currentIdeCodeRef.current;
                for (let i = 0; i < filePathArray.length - 1; i++) {
                    fileContent = fileContent[filePathArray[i]];
                }
                fileContent[filePathArray[filePathArray.length - 1]] = newCode;
                setCurrentIdeCode({ ...currentIdeCodeRef.current });
            }
        } else {
            setCurrentIdeCode(newCode); // Update code directly as there is only one file
        }
    }, 300)).current;

    // Function to handle changes in the code editor
    const handleCodeChange = useCallback((newCode, changeDetails) => {
        debouncedSetCode(newCode);
        trackKeystrokes(changeDetails);
    }, [debouncedSetCode, trackKeystrokes]);   

    // Update the editor content based on whether it is a multi file test or not and the activefile
    useEffect(() => {
        // If it is not multi-file, set the editor content to the current code
        if (!isMultiFileRef.current) {
            setEditorContent(currentIdeCode); // Set the editor content to the current code
            return;
        } else if (isMultiFileRef.current && activeFileRef.current) { // If it is a multi-file test and there is an active file
            const filePathArray = activeFileRef.current.split('/'); // Split the active file path into an array
            let fileContent = currentIdeCode; // Start with the top-level code object
            for (const pathPart of filePathArray) {
                fileContent = fileContent[pathPart]; // Traverse down the path
            }
            fileContent = typeof fileContent === 'string' ? fileContent : ''; // Ensure fileContent is a string
            setEditorContent(fileContent); // Set the editor content to the active file content
        }
    }, [currentIdeCode, isMultiFileRef.current, activeFileRef.current]);    

    // Manage the editor language

    // Update the available languages for the code editor when the section changes
    useEffect(() => {
        if (currentSection?.coding?.codeLanguages) {
            const languages = currentSection.coding.codeLanguages;
            setAvailableCodeLanguages(languages);
            if (languages.length === 1 && languages[0] !== 'all') {
                setSingleIdeLanguage(true);
            } else {
                setSingleIdeLanguage(false);
            }
        } else {
            setAvailableCodeLanguages(["all"]);
            setSingleIdeLanguage(false);
        }
    }, [currentSection]);

    // Code language: Default state
    const initialLanguageKey = currentSection?.coding?.defaultLanguage || 'Javascript (Nodejs)';
    const initialLanguageDetails = languageMappings[initialLanguageKey] || languageMappings['Javascript (Nodejs)'];

    const [currentIdeLanguage, setCurrentIdeLanguage] = useState({
        key: initialLanguageKey, 
        ...initialLanguageDetails
    });

    // Update the IDE language when the section changes
    useEffect(() => {
        if (currentSection?.coding?.defaultLanguage && !retrievedCode) {
            updateCurrentIdeLanguage(currentSection.coding.defaultLanguage);
        }
    }, [currentSection]);

    // Update the IDE language when the user selects a new active file
    useEffect(() => {
        const languageKey = getLanguageKeyFromExtension(activeFile);
        if (languageKey) {
            updateCurrentIdeLanguage(languageKey);
        }
    }, [activeFile]);

    // Get the language key from the file extension
    const getLanguageKeyFromExtension = (filePath) => {

        if (!filePath) {return null;} // If there is no file path, return null

        const extension = filePath.split('.').pop();
        for (const [key, details] of Object.entries(languageMappings)) {
            if (details.extension.includes(`.${extension}`)) {
                return key;
            }
        }
        return null;
    };

    // Update the IDE programming language to effect the editor and compiler
    const updateCurrentIdeLanguage = (languageKey) => {
        const languageDetails = languageMappings[languageKey];
        setCurrentIdeLanguage({
            key: languageKey,
            ...languageDetails
        });
    };

    // IDE - SUBMIT & EXECUTE CODE

    // SUBMIT & EXECUTE CODE //

    // First function when user presses submit code to check code is present and then ask them to confirm
    const confirmSubmitCode = () => {
        if (!currentIdeCode || currentIdeCode.trim() === '') {
            setShowEmptyEditorAlert(true); // Show custom alert modal
            return;
        } else {
            setShowConfirmModal(true); // Show custom confirm modal
        }
    };
    
    // When the user confirms, hide the popup and trigger handleSubmitCode
    const handleConfirmIdeSubmission = () => {
        setShowConfirmModal(false); // Close the confirmation modal
        handleSubmitCode(); // Proceed with code submission
    };
    
    // After user presses submit code confirm, save it as finalCode and trigger setCodeSubmitted (which hides the IDE elements)
    const handleSubmitCode = () => {
        // Log the event
        logTrace(`SubmitCode pressed`, { 
            testAttemptId: testAttemptId,
            codeLanguage: currentIdeLanguageRef.current.key,
            code: currentIdeCode,
        });

        handleExecuteCode(undefined, true); // Execute the code one last time for the report to access the final output
    
        setFinalCode(currentIdeCode); // Save finalCode
        setCodeSubmitted(true); // Notify backendinterface and chatbox to deal with sending it as a message in the UI and calling next intructions

        trackButtonClick({ 
            buttonId: 'submitCode', 
            currentCode: currentIdeCode 
        });

        // stop the save interval
        if (saveIntervalRef.current) {
            clearInterval(saveIntervalRef.current);
        }

        saveActivities(); // Save immediately
    };

    // If time runs up set the code in the Editor as the final code and trigger codeSubmitted to hide the IDE and instructions
    useEffect(() => {
        if (sectionTimeUp && currentSection?.time?.onTimeUp?.submitCode) {
            handleSubmitCode(currentIdeCode);
        }
        }, [sectionTimeUp]);

    // After user presses execute button, send it to the compiler and get the results in CodeOutput.js
    const handleExecuteCode = async (scrollToBottomIde, finalExecution = false) => {

        logTrace(`ExecuteCode pressed`, { 
            testAttemptId: testAttemptId,
            codeLanguage: currentIdeLanguage.key,
            code: currentIdeCode,
            codeExecutionCount: codeExecutionCount,
            finalExecution: finalExecution,
            fileName: fileName,
        });

        // Check if the user has reached the max number of executions
        if (codeExecutionCount >= maxCodeExecutions) {
            setIdeCompilerResponse('Desculpe, mas você atingiu o limite máximo de tentativas de execução do código.');
            return; // Stop the function execution here
        }

        // Retrieve code for single-file or the active multi-file so we can see it is not blank
        let codeToExecute;
        if (typeof currentIdeCode === 'string') {
            codeToExecute = currentIdeCode.trim(); // Trim the code to remove leading and trailing whitespace
        } else if (typeof currentIdeCode === 'object' && activeFile) {
            const filePathArray = activeFile.split('/'); // Split the active file path into an array
            let fileContent = currentIdeCode; // Start with the top-level code object
            for (const part of filePathArray) { // Traverse down the path to the active file
                fileContent = fileContent[part]; // Traverse down the path, e.g. code['src']['index.js']
            }
            codeToExecute = typeof fileContent === 'string' ? fileContent.trim() : '';
        }

        // Alert user that the code can't be blank when executing, if it is blank
        if (!codeToExecute || codeToExecute === '') {
            setShowEmptyEditorAlert(true); // Show custom alert modal
            return;
        }
        
        // Save the interaction
        trackButtonClick({ 
            buttonId: 'executeCode', 
            currentCode: currentIdeCode 
        });

        // Make the console visible if it is not already
        if (!showIdeCodeOutput) {
            setShowIdeCodeOutput(true);
        }
        
        setIdeCurrentlyCompiling(true); // Makes the loader appear so the users knows it is working in the background
        if (scrollToBottomIde) {
            setTimeout(() => { scrollToBottomIde();}, 200); // Scroll down to show the console giving time for the loader to appear
        }

         // If the compiler is 'babel' then compile it in the browser
         if (compiler === 'babel') {
            compileInBrowser(scrollToBottomIde);
            return;
        }

        // JDoodle configs
        const compilerLanguage = currentIdeLanguage.compiler;
        const compilerVersion = currentIdeLanguage.version;

        // Decide version
        let version = '';
        if (compiler === 'jdoodle') {
            version = compilerVersion;
        }

        // Degrau compiler settings
        const opts = {"dependencies": currentSection?.coding?.ideDependencies || {} }; // Dependencies for the code execution

        // State to enable adjusting code before sending, e.g. Kotlin we append code to the start for it to work
        let modifiedCode = codeToExecute; // Default to the original code

        // Check if the language is Kotlin and append the annotation if necessary
        if (compilerLanguage === 'kotlin') {
            modifiedCode = `@file:JvmName("JDoodle")\n${currentIdeCode}`;
        }
        
        try {
            let result;
            // Send TypeScript code for transpilation first
            if (compilerLanguage === 'typescript') {
                const transpileResponse = await fetch('/api/transpile-typescript', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ code: modifiedCode })
                });
                const transpiledCode = await transpileResponse.text();
            
                // Forward the transpiled JavaScript code to the compiler via our backend
                result = await executeCode(compiler, 'nodejs', transpiledCode, roleCode, '4', testAttemptId, opts); // Version index for Node.js
            } else {
                // Regular execution for other languages
                result = await executeCode(compiler, compilerLanguage, modifiedCode, roleCode, version, testAttemptId, opts);
            }
                // Set the response so the user reveives in the console and update the transcript
                setIdeCompilerResponse(result.output || result.error);
                ideCompilerResponseRef.current = result.output || result.error;
                // if it is a final execution, update the final code output
                if (finalExecution) {
                    setFinalConsoleOutput(result.output || result.error);
                }
                trackCompilerResponse({
                    compilerLanguage: compilerLanguage,
                    compilerResponse: result,
                });
                // Increment count limiting number of calls
                if (codeExecutionCount < maxCodeExecutions) {
                    setCodeExecutionCount(prevCount => prevCount + 1);
                } else {
                    // Optionally handle the case where the max count has been reached
                }
        } catch (error) {
            // If there is an error update the response so the user reveives an error message
            const compilerMessage = 'Desculpe, ocorreu um erro ao executar o código, tente novamente';
            setIdeCompilerResponse(compilerMessage);
            ideCompilerResponseRef.current = compilerMessage;
            // if it is a final execution, update the final code output
            if (finalExecution) {
                setFinalConsoleOutput(result.output || result.error);
            }
            trackCompilerResponse({
                compilerLanguage: compilerLanguage,
                compilerResponse: compilerMessage,
            });
            logException(`Error: ExecuteCode`, { 
                errorMessage: error.message,
                errorStack: error.stack, 
                code: currentIdeCode,
                fileName: fileName,
            });
        } finally {
            // Indicate that compilation has finished
            setIdeCurrentlyCompiling(false);
            if (scrollToBottomIde) {
                setTimeout(() => { scrollToBottomIde();}, 100);
            }
        }
        saveActivities();
    }; 

    // Compile using babel in the browser (move to service file)
    const compileInBrowser = async (scrollToBottomIde) => {
        try {
            const files = currentIdeCodeRef.current.src;
            let bundledCode = ''; // This will contain all the transpiled code to be stored in the window object
    
            // Transpile ts and js files so they can be executed in the browser. Recursively transpile all files in the directory.
            const transpileFiles = (currentFiles, basePath = '') => {
                Object.keys(currentFiles).forEach((key) => {
                    const filePath = basePath ? `${basePath}/${key}` : key;

                    if (typeof currentFiles[key] === 'string') { // If the file is a string (therefore a code file)
                        // Inclusive filter: only process .js, .jsx, .ts, .tsx files
                        if (!filePath.match(/\.(js|jsx|ts|tsx)$/)) {
                            return; // Skip the file if it is not a JavaScript or TypeScript file
                        }
                        // Transpile file with filename
                        const transpiledCode = Babel.transform(currentFiles[key], {
                            presets: ['env', 'react', 'typescript'],
                            filename: filePath
                        }).code;
                        bundledCode += `window['${filePath}'] = function(exports, require, module) {${transpiledCode}};\n`;

                    } else if (typeof currentFiles[key] === 'object') { // If the file is an object (therefore a directory)
                        transpileFiles(currentFiles[key], filePath); // Run the function recursively for the directory to transpile all files
                    }
                });
            };

            // Start transpiling from the root
            transpileFiles(files);

            // Dynamically resolves and loads modules in the browser
            const requireScript = `
                const externals = {
                    'react': window.React,
                    'react-dom': window.ReactDOM
                };

                function resolvePath(base, relative) {
                    const stack = base ? base.split('/') : [];
                    const parts = relative.split('/');
                    for (let i = 0; i < parts.length; i++) {
                        if (parts[i] === '.') continue;
                        if (parts[i] === '..') stack.pop();
                        else stack.push(parts[i]);
                    }
                    // Ensure no leading slash
                    const resolvedPath = stack.join('/');
                    return resolvedPath;
                };

                function tryResolveModule(moduleId) {
                    const extensions = ['.js', '.jsx', '.ts', '.tsx'];
                    if (window[moduleId]) {
                        return moduleId;
                    }
                    for (const ext of extensions) {
                        if (window[moduleId + ext]) {
                            return moduleId + ext;
                        }
                    }
                    return null;
                }

                function require(moduleId, basePath = '') {
                    if (externals[moduleId]) {
                        return externals[moduleId];
                    }
                    const resolvedModuleId = resolvePath(basePath, moduleId);
                    const moduleIdWithExt = tryResolveModule(resolvedModuleId);
                    if (!moduleIdWithExt) {
                        throw new Error('Module not found: ' + resolvedModuleId);
                    }
                    const module = { exports: {} };
                    if (!window[moduleIdWithExt]) {
                        throw new Error('Module not found: ' + moduleIdWithExt);
                    }
                    if (window[moduleIdWithExt].__required) {
                        return window[moduleIdWithExt].__exports;
                    }
                    window[moduleIdWithExt].__required = true;
                    window[moduleIdWithExt].__exports = module.exports;
                    window[moduleIdWithExt](
                        module.exports,
                        (mod) => {
                            const newBasePath = moduleIdWithExt.substring(0, moduleIdWithExt.lastIndexOf('/'));
                            return require(mod, newBasePath);
                        },
                        module
                    );
                    return module.exports;
                };
            `;

            const htmlTemplate = `
                <!DOCTYPE html>
                <html lang="pt-BR">
                <head>
                    <meta charset="UTF-8">
                    <meta name="viewport" content="width=device-width, initial-scale=1.0">
                    <title>Live Preview</title>
                </head>
                <body>
                    <div id="root"></div>
                    <script src="https://unpkg.com/react/umd/react.production.min.js"></script>
                    <script src="https://unpkg.com/react-dom/umd/react-dom.production.min.js"></script>
                    <script>
                        window.React = React;
                        window.ReactDOM = ReactDOM;
                        ${bundledCode}
                        ${requireScript}
                        const App = require('App.tsx').default;
                        ReactDOM.render(
                            React.createElement(App),
                            document.getElementById('root')
                        );
                    </script>
                </body>
                </html>
            `;

            // Update the preview area with the transpiled code
            setPreviewContent(htmlTemplate);
        } catch (error) {
            setIdeCompilerResponse('Error transpiling code.');
            logException(`Error: CompileInBrowser`, {
                errorMessage: error.message,
                errorStack: error.stack,
                code: currentIdeCode,
                testAttemptId: testAttemptId,
                fileName: fileName,
            });
        } finally {
            setIdeCurrentlyCompiling(false);
            if (scrollToBottomIde) {
                setTimeout(() => { scrollToBottomIde(); }, 100);
            }
        }
    };

    // SQL 

    // Set the default code on load
    useEffect(() => {
        if (currentSection?.coding?.sqlDefaultCode) {
            setCurrentSqlCode(currentSection?.coding?.sqlDefaultCode || '');
        }
    }, [currentSection]);

    // Update the database schema and log on load (TODO: replace with actual data from the backend)
    const runQuery = async () => {
        setCurrentlyCompilingSql(true); // Set the loading spinner
        setSqlQueryData([]); // Clear the data

        // Prepare the data
        const code = currentSqlCode;
        const uniqueId = testAttemptId; // unique id for the sqlite table
        const language = 'sqlite';
        const version = null;
        const opts = {}; // for the compiler
        const time = new Date().toLocaleTimeString(); // timestamp for the logs
    
        try {
            const response = await executeCode(compiler, language, code, roleCode, version, uniqueId, opts);

            // TODO: Save the schema that is returned
    
            // Prepare the new log
            const queryStatus = response.compilationStatus; // Set green tick or red X in logs
            const queryMessage = response.output; // The message to display in the logs
            const newLog = {
                id: sqlLogs.length + 1,
                time: time,
                action: code,
                message: queryMessage,
                success: queryStatus,
            };
            setSqlLogs([...sqlLogs, newLog]); // Set the new log in the logs state to appear in SqlLogOutput

            setCurrentlyCompilingSql(false); // Remove the loading spinner
    
            // if the response has output.data, set as setSqlQueryData
            if (response.hasDataResults) {
                setSqlQueryData(response.results);
            } else {
                setSqlQueryData([]); // Clear the data if there are no results
            }

            // if the data has a databse schema, set as setDatabaseSchema and update the ref
            if (response.databaseSchema) {
                setDatabaseSchema(response.databaseSchema);
                databaseSchemaRef.current = response.databaseSchema;
            }

        } catch (error) {
            logException('Error: RunQuery', {
                errorMessage: error.message,
                errorStack: error.stack,
                code: currentSqlCode,
                testAttemptId: testAttemptId,
                fileName: fileName,
            });
            
            // Prepare the new log
            const queryMessage = error.response ? error.response.statusText : error.message; // The message to display in the logs
            const queryStatus = false; // Set red X in logs
            const newLog = {
                id: sqlLogs.length + 1,
                time: time,
                action: code,
                message: `Error: ${queryMessage}`,
                success: queryStatus,
            };
            setSqlLogs([...sqlLogs, newLog]); // Set the new log in the logs state to appear in SqlLogOutput

            setCurrentlyCompilingSql(false); // Remove the loading spinner
        }
    };

    // Run initial setup query to have th initial database schema waiting for the user, linked to their testAttemptId
    const runSetupQuery = async () => {
        setIsFirstSqlRun(true);
        setCurrentlyCompilingSql(true); // Set the loading spinner

        // Prepare the data
        const code = '';
        const uniqueId = testAttemptId; // unique id for the sqlite table
        const language = 'sqlite';
        const version = null;
        const opts = {}; // for the compiler
        const time = new Date().toLocaleTimeString(); // timestamp for the logs
        const isSetup = true; // Flag to indicate that it is a setup query
    
        try {
            const response = await executeCode(compiler, language, code, roleCode, version, uniqueId, opts, isSetup);
            logTrace('Setup query response:', {response, testAttemptId, fileName: fileName});

            // TODO: Save the schema that is returned
    
            // Prepare the new log
            const queryStatus = response.status; // Set green tick or red X in logs
            const queryMessage = response.message; // The message to display in the logs
            const newLog = {
                id: sqlLogs.length + 1,
                time: time,
                action: 'Setup',
                message: queryMessage,
                success: queryStatus,
            };
            setSqlLogs([...sqlLogs, newLog]); // Set the new log in the logs state to appear in SqlLogOutput

            setCurrentlyCompilingSql(false); // Remove the loading spinner
            setIsFirstSqlRun(false);
            fetchLatestSchema(); // Fetch the latest schema after the setup query is run

            // if the data has a databse schema, set as setDatabaseSchema and update the ref
            if (response.databaseSchema) {
                setDatabaseSchema(response.databaseSchema);
                databaseSchemaRef.current = response.databaseSchema;
            }
    
        } catch (error) {
            // Setup retry mechanism
            logException('Error: RunSetupQuery', {
                errorMessage: error.message,
                errorStack: error.stack,
                code: currentSqlCode,
                testAttemptId: testAttemptId,
                fileName: fileName,
            });
            
            // Prepare the new log
            const queryMessage = error.response ? error.response.statusText : error.message; // The message to display in the logs
            const queryStatus = false; // Set red X in logs
            const newLog = {
                id: sqlLogs.length + 1,
                time: time,
                action: 'Setup',
                message: `Error: ${queryMessage}`,
                success: queryStatus,
            };
            setSqlLogs([...sqlLogs, newLog]); // Set the new log in the logs state to appear in SqlLogOutput

            setCurrentlyCompilingSql(false); // Remove the loading spinner
            setIsFirstSqlRun(false);
        }
    };

    // Update the database schema
    const fetchLatestSchema = async () => {
        setLoadingDatabaseSchema(true)
        // Prepare the data
        const uniqueId = testAttemptId; // unique id for the sqlite table

        try {
            const response = await fetchSchema(uniqueId);
            setDatabaseSchema(response);
            databaseSchemaRef.current = response.databaseSchema;
            setLoadingDatabaseSchema(false);
        } catch (error) {
            logException('Error: FetchLatestSchema', {
                errorMessage: error.message,
                errorStack: error.stack,
                testAttemptId: testAttemptId,
                fileName: fileName,
            });
            setLoadingDatabaseSchema(false);
        }
    };

    // If currentSection.UI.SQLComponent is true, run the query when testStage changes to 'test'
    useEffect(() => {
        if (currentSection?.UI?.SQLComponent?.enabled && testStage === 'test') {
            runSetupQuery();
        }
    }, [testStage]);

    // SAVING //

    // Save the initial codeResponse details when the test starts save the returned id, unless it is a retrieved session. If it fails move to error handling state
    useEffect(() => {
        if (testStage === 'test' && candidateId && testAttemptId && !codeResponseInitiated) { // Check if the test has started and the code response has not been initiated
            const attemptSaveCodeResponse = async (retryCount = 0) => {
                try {
                    const responseId = await saveCodeResponse({
                        candidate_id: candidateId,
                        test_attempt_id: testAttemptId,
                        start_time: formatDateForMySQL(new Date()),
                    });
                    setCodeResponseInitiated(true); // Set the flag to prevent multiple attempts
                    setCodeResponseId(responseId); // Update the global state with the code response id
                    startSaveInterval(); // Start the save interval after the initial save succeeds
                } catch (error) {
                    if (retryCount < 2) { // Retry up to 2 times
                        logException('Error saving code response:', {errorMessage: error.message, errorStack: error.stack, candidateId, testAttemptId, retryCount});
                        setTimeout(() => attemptSaveCodeResponse(retryCount + 1), 5000); // Wait 5 seconds before retrying
                    } else {
                        setTestStage('saveFailed'); // Move to the error handling state
                    }
                }
            };
            attemptSaveCodeResponse(); // Start the initial save attempt
        }
    }, [testStage, candidateId, testAttemptId, codeResponseInitiated, saveCodeResponse, formatDateForMySQL, setTestStage]);

    // Call for the code and transcript every X minutes if not triggered by another function
    const startSaveInterval = () => {
        // Clear existing interval if any
        if (saveIntervalRef.current) {
            clearInterval(saveIntervalRef.current);
        }

        // Set up a new interval to save the activities every X seconds
        saveIntervalRef.current = setInterval(() => {
            saveActivities();
        }, 30000); // 1000 = 1 second
    };

    // Clean up the interval when the component unmounts
    useEffect(() => {
        return () => {
            if (saveIntervalRef.current) {
                clearInterval(saveIntervalRef.current); // Clean up on unmount
            }
        };
    }, []);

    // Update coderesponse entry with latest code and transcript. First state the limits of retries (within the same save attempt) and save attempts before caling error handling state
    const RETRY_LIMIT_FOR_CODE = 1; // Maximum number of retries for a single save attempt (1 retry in addition to the initial attempt)
    const MAX_FAILED_SAVES_FOR_CODE = 2; // Maximum allowed cumulative failed save attempts
    let isSaveActivitiesRunning = false; // So we dont call multiple instances (if the time interval and the execute code is pressed simultaneously)

    // Update the refs needed to the save loop gets the latest states
    useEffect(() => {
        codeRef.current = currentIdeCode;
    }, [currentIdeCode]); 

    useEffect(() => {
        codeResponseIdRef.current = codeResponseId;
    }, [codeResponseId]); 

    useEffect(() => {
        activitiesRef.current = activities;
    }, [activities]); 

    useEffect(() => {
        isTestInterruptedRef.current = isTestInterrupted;
    }, [isTestInterrupted]);

    useEffect(() => {
        sqlLogsRef.current = sqlLogs;
    }, [sqlLogs]);

    useEffect(() => {
        currentIdeLanguageRef.current = currentIdeLanguage;
    }, [currentIdeLanguage]); 
    
    // Save the activities to the coderesponse database
    const saveActivities = () => {
        // Dont proceed with the function if we are in testInterrupted mode
        if (isTestInterruptedRef.current.interrupted) {
            return;
        }
        
        if (!codeResponseIdRef.current) {
            return;
        }

        // Check if saveActivities is already running
        if (isSaveActivitiesRunning) {
            return; // Exit if another save operation is in progress
        }
        isSaveActivitiesRunning = true; // Set the flag as true to prevent another call
    
        let currentCodeToSave;

        // Determine if currentCode is single file or multi-file
        if (typeof codeRef.current === 'string') {
            currentCodeToSave = { 'single-file': codeRef.current };
        } else if (typeof codeRef.current === 'object') {
            currentCodeToSave = codeRef.current; // Multi-file project
        } else {
            throw new Error('Unsupported code format');
        }

        const performSave = async (retryCount = 0, afterInterruptionRetryCount = 0) => {

             // Prepare the data
            let updateData = {
                end_time: formatDateForMySQL(new Date()),
                final_code: JSON.stringify(currentCodeToSave),
                code_language: currentIdeLanguageRef.current.key,
                code_transcript: activitiesRef.current,
                sql_logs: sqlLogsRef.current,
                db_schema: databaseSchemaRef.current,
                console_output: ideCompilerResponseRef.current,
            };

            // Exclude code_transcript if flagged
            if (excludeTranscript) {
                delete updateData.code_transcript;
            }
            
            try {
                await updateCodeResponse(codeResponseIdRef.current, updateData);
                // If it works reset counters and ensure we exit the test interrupted state            
                clearActivities(); // Clear the activity log as we will append each new entry in the db
                currentCodeLog(currentCodeToSave, currentIdeLanguageRef.current.key); // Create a log of the current code
                setSaveActivitiesFailedCount(0); 
                setIsTestInterrupted({ interrupted: false, reason: null }); 
            } catch (error) {
                logException('Error: SaveActivities', {
                    errorMessage: error.message,
                    errorStack: error.stack,
                    testAttemptId: testAttemptId,
                    updateData: updateData,
                    retryCount: retryCount,
                    afterInterruptionRetryCount: afterInterruptionRetryCount,
                    fileName: fileName,
                });
                // Check for Payload Too Large error and hasn't already excluded code_transcript
                if (error.message.includes('413') && !excludeTranscript) {
                    setExcludeTranscript(true); // Set flag to exclude code_transcript in future saves
                    performSave(retryCount + 1, afterInterruptionRetryCount); // Retry immediately without code_transcript
                    return;
                }

                // If we havent reached the retry limit, try again
                if (retryCount < RETRY_LIMIT_FOR_CODE) {
                    setTimeout(() => performSave(retryCount + 1, afterInterruptionRetryCount), 5000); // Retry after 5 seconds
                } else {
                    // If out of retries increment the failure count
                    setSaveActivitiesFailedCount(prevCount => {
                        const newCount = prevCount + 1;
                        // If we reached the limit trigger testInterrupted and go to error handling mode
                        if (newCount >= MAX_FAILED_SAVES_FOR_CODE && !isTestInterruptedRef.current.interrupted) {
                            setIsTestInterrupted({ interrupted: true, reason: 'coderesponse database IDE' });
                            setTimeout(() => performSave(RETRY_LIMIT_FOR_CODE + 1, afterInterruptionRetryCount + 1), INTERRUPTION_RETRY_INTERVAL * 1000);
                        }
                        // Trigger the extended retry logic if within limits and test is interrupted
                        if (isTestInterruptedRef.current.interrupted && afterInterruptionRetryCount < INTERRUPTION_RETRY_MAX) {
                            setTimeout(() => performSave(retryCount, afterInterruptionRetryCount + 1), INTERRUPTION_RETRY_INTERVAL * 1000);
                        }
                        return newCount;
                    });
                }
            }
        };
        performSave().finally(() => {
            isSaveActivitiesRunning = false; // Reset the flag upon completion
        });
    };

    // Record for the activity log when the user moves from the test tab
    useEffect(() => {
        const focusStatus = globalState.focusWarning ? 'left' : 'returned';
        trackFocusChange(focusStatus);
    }, [globalState.focusWarning, trackFocusChange]);

    // TEST RETRIEVAL //

    // Update code if a session is restored (AppContext will update retrievedMessages with the messages saved in the DB for that session)
    useEffect(() => {
        if (retrievedCode) {
            let parsedCode;
            try {
                parsedCode = JSON.parse(retrievedCode);
                logTrace('Retrieved code:', {parsedCode, fileName});
            } catch (error) {
                logException('Error: Parsing retrieved code', {
                    errorMessage: error.message,
                    errorStack: error.stack,
                    retrievedCode: retrievedCode,
                    testAttemptId: testAttemptId,
                    fileName: fileName,
                });
                return;
            }
            
            // if it is a single file test, set the code that is in the 'single-file' key
            if (!isMultiFile) {
                if (parsedCode["single-file"] !== undefined) {
                    setCurrentIdeCode(parsedCode["single-file"]);
                } else {
                    logException('Error: Parsing retrieved code', {
                        errorMessage: 'No single-file key found in the retrieved code',
                        retrievedCode: retrievedCode,
                        testAttemptId: testAttemptId,
                        fileName: fileName,
                    });
                }
            } else {
                setCurrentIdeCode(parsedCode);
            }
        }
    }, [retrievedCode]);

    // Update the language if a session is restored
    useEffect(() => {
        if (languageToRestore) {
            updateCurrentIdeLanguage(languageToRestore);
        }
    }, [languageToRestore]);

    // Update the sqlLogs if there is a session restored
    useEffect(() => {
        if (retrievedSqlLogs) {
            setSqlLogs(retrievedSqlLogs);
        }
    }, [retrievedSqlLogs]);
    
    return (
        <CodeContext.Provider value={{
            currentIdeLanguage,
            updateCurrentIdeLanguage,
            availableCodeLanguages,
            singleIdeLanguage,
            currentSqlCode,
            setCurrentSqlCode,
            sqlLogs,
            setSqlLogs,
            sqlQueryData,
            databaseSchema,
            sqlResults,
            runQuery,
            fetchLatestSchema,
            loadingDatabaseSchema,
            isFirstSqlRun,
            currentlyCompilingSql,
            currentIdeCode,
            editorContent,
            previewContent,
            activeFile,
            setActiveFile,
            defaultFile,
            isMultiFile,
            setCurrentIdeCode,
            editorFontSize,
            setEditorFontSize,
            themeDropdownOptions,
            handleThemeChange,
            theme,
            themeDropdownValue,
            monacoEditorTheme,
            aceEditorTheme,
            handleCodeChange,
            compiler,
            confirmSubmitCode,
            handleExecuteCode,
            handleConfirmIdeSubmission,
            showEmptyEditorAlert,
            setShowEmptyEditorAlert,
            showConfirmModal,
            setShowConfirmModal,
            ideCompilerResponse,
            showIdeCodeOutput,
            ideCurrentlyCompiling,
            // Other states and functions to be exposed
        }}>
            {children}
        </CodeContext.Provider>
    );
};

export const useCodeContext = () => {
    return useContext(CodeContext);
};
