export const passwordRules = {
    minLength: 8,
    requiresUpperLowercase: true,
    requiresDigit: true,
    requiresSpecialChar: true,
};

export const passwordRulesDescriptions = [
    `${passwordRules.minLength} caracteres.`,
    passwordRules.requiresUpperLowercase && "Uma letra maiúscula e uma letra minúscula.",
    passwordRules.requiresDigit && "Um dígito.",
    passwordRules.requiresSpecialChar && "Um caractere especial (ex: !@#$%^&*)."
].filter(Boolean);

export const validatePassword = async (password) => {
    if (password.length < passwordRules.minLength) {
        throw new Error(`A senha deve ter pelo menos ${passwordRules.minLength} caracteres.`);
    }
    if (passwordRules.requiresUpperLowercase && (!/[A-Z]/.test(password) || !/[a-z]/.test(password))) {
        throw new Error("A senha deve conter pelo menos uma letra maiúscula e uma letra minúscula.");
    }
    if (passwordRules.requiresDigit && !/\d/.test(password)) {
        throw new Error("A senha deve conter pelo menos um dígito.");
    }
    if (passwordRules.requiresSpecialChar && !/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
        throw new Error("A senha deve conter pelo menos um caractere especial.");
    }
};
