import React from 'react';
import Logo from '../common/DegrauLogo';
import styles from './Popup.module.css'; 

const ShareableLinkExpiredContent = ({ onLoad, logoLoaded, ...otherProps }) => {
    return (
        <>
            <Logo className={styles.degrauLogoPopup} onLoad={onLoad} /> 
            <div className={styles.popupTextLeft}>
            <p>Desculpe, mas este link expirou, por favor, solicite um novo:</p>
            <p>Se você tiver algum problema, por favor, entre em contato conosco pelo e-mail <b>suporte@degrau.co</b>. Estamos aqui para ajudar!</p>
        </div>
        </>
    );
};

export default ShareableLinkExpiredContent;