// Send and receive messages to the backend Language Server to provide code completions, hover information, code actions, and diagnostics for the Monaco Editor.
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom'; 
import { useAppContext } from '../contexts/AppContext';
import { useTestContext } from '../contexts/TestContext';
import { useCodeContext } from '../contexts/CodeContext';
import { logEvent, logException, logTrace } from '../services/loggerFront';

const useAutocomplete = (editorRef, language, monacoInstance, defaultValue, setIsLanguageServerReady, monacoLanguages, setMonacoLanguages, setMonacoLanguage) => {
    const location = useLocation(); // Get the current location
    // WS connection
    const { webSocketUrl } = useAppContext(); // Get the base of the WebSocket URL from the AppContext
    const [ws, setWs] = useState(null); // State to store the WebSocket connection
    const wsRef = useRef(ws);
    const [autocompleteRequired, setAutocompleteRequired] = useState(false); // State to store if autocomplete is required for the editor
    // Autocomplete elements
    const [completionItems, setCompletionItems] = useState([]); // State to store completion items for the editor
    const [diagnostics, setDiagnostics] = useState([]); // State to store diagnostics for the editor
    const [hoverInfo, setHoverInfo] = useState(null); // State to store hover information for the editor
    const diagnosticsRef = useRef(diagnostics); // Used for codeActions
    const [completionProviderRegistration, setCompletionProviderRegistration] = useState(null);
    const [hoverProviderRegistration, setHoverProviderRegistration] = useState(null);

    const { isTestStarted, testAttemptId } = useTestContext();
    const monacoRef = useRef(monacoInstance); // Store monaco in a ref
    const { availableCodeLanguages } = useCodeContext(); // Get the available code languages from the CodeContext
    const languageServerLanguages = ['python'] // Removed java and kotlin for now
    const languageRef = useRef(language); // Store monaco in a ref
    const [languageServerStatus, setLanguageServerStatus] = useState([]); // Store available languages for the language server
    const fileName = 'useAutocomplete.js';

    // UPDATE REFS //

    // Ensure the monaco instance is stable across renders
    useEffect(() => {
        monacoRef.current = monacoInstance;
    }, [monacoInstance]);

    // Ensure the language is stable across renders
    useEffect(() => {
        languageRef.current = language;
    }, [language]);

     // Update diagnostics ref
     useEffect(() => {
        diagnosticsRef.current = diagnostics;
    }, [diagnostics]);

    // Update wsRef when ws changes
    useEffect(() => {
        wsRef.current = ws;
    }, [ws]);

    // Check if autocomplete is required for the editor
    useEffect(() => {
        if (availableCodeLanguages.includes('all') || availableCodeLanguages.some(lang => languageServerLanguages.includes(lang))) {
            setAutocompleteRequired(true);
        } else {
            setAutocompleteRequired(false);
        }
    }, [availableCodeLanguages]);

    // OPEN THE WEBSOCKET //
    let WEBSOCKET_URL;
    WEBSOCKET_URL = webSocketUrl || 'ws://localhost:3001';
    // Append the specific endpoint to the base URL
    WEBSOCKET_URL += '/autocomplete';

    // Variables in case of reconnecting
    let reconnectAttempts = 0;
    const maxReconnectAttempts = 5;

    // Setup WebSocket connection
    const setupWebSocket = () => {
        if (location.pathname === '/relatorio') {
            return;
        }
        const websocket = new WebSocket(WEBSOCKET_URL);
        wsRef.current = websocket; // Store the WebSocket reference
        // When it opens log the connection
        websocket.onopen = () => {
            logTrace('Autocomplete Client WebSocket Connected', { 
                url: WEBSOCKET_URL,
                testAttemptId,
                fileName
            });
            reconnectAttempts = 0; // Reset reconnection attempts on successful connection
        };
        websocket.onmessage = event => processMessage(event);

        websocket.onerror = error => 
            logException('Autocomplete Client WebSocket Error', {
                errorMessage: error.message,
                errorStack: error.stack,
                testAttemptId,
                fileName
            });

        websocket.onclose = event => {
            logTrace('Autocomplete Client WebSocket Disconnected', {
                code: event.code,
                reason: event.reason,
                wasClean: event.wasClean,
                testAttemptId,
                fileName
            });
            if (!event.wasClean && reconnectAttempts < maxReconnectAttempts) {
                reconnectAttempts += 1;
                const reconnectDelay = Math.min(1000 * Math.pow(2, reconnectAttempts), 30000); // Exponential backoff
                setTimeout(setupWebSocket, reconnectDelay);
            }
        };
        return websocket;
    };

    // On load, setup the WebSocket connection and cleanup on unmount, if one of the available languages is a languageServerLanguages 
    useEffect(() => {
        logTrace('Checking languages for autocomple', { availableCodeLanguages, languageServerLanguages, testAttemptId, fileName});
        // check if if one of the available languages is a languageServerLanguages
        if (autocompleteRequired) {
            // Setup WebSocket connection if not already connected
            if (!wsRef.current || wsRef.current.readyState === WebSocket.CLOSED) {
                setupWebSocket();
            }
            // Cleanup function to close WebSocket on unmount or when dependencies change
            return () => {
                if (wsRef.current && wsRef.current.readyState !== WebSocket.CLOSED) {
                    wsRef.current.close();
                }
            };
        } else {
            logTrace('Autocomplete not connecting as no available language requires it', {
                availableCodeLanguages,
                testAttemptId,
                fileName
            });
        }
    }, []);

    // Effect to send initial details when language changes so the server can connect to the correct language server. Also if the ws is closed, reopen it.
    useEffect(() => {
        if (!autocompleteRequired) return; // No action needed if autocomplete is not required
        setIsLanguageServerReady(true); // Reset language server status
        // Setup WebSocket connection if not already connected
        if (!wsRef.current || wsRef.current.readyState === WebSocket.CLOSED) {
            setupWebSocket();
        }

        // For some languages, send the initial details to the server so it knows which language server to start
        if (isTestStarted && languageRef.current === 'python') { // Removed java and kotlin
            if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
                const initialMessage = JSON.stringify({
                    method: 'initialDetails',
                    params: {
                        text: defaultValue,
                        language: languageRef.current
                    }
                });
                logTrace('Autocomplete Client Sending Initial Details', { 
                    message: initialMessage,
                    testAttemptId,
                    fileName
                });
                wsRef.current.send(initialMessage);
            }
        }
    }, [languageRef.current, isTestStarted]);

    // INCOMING MESSAGES //

    // Process incoming WebSocket messages
    const processMessage = (event) => {
        try {
            const data = JSON.parse(event.data);
            handleLSPResponses(data);
        } catch (error) {
            logException('Autocomplete Client Error Parsing Server Message', {
            errorMessage: error.message,
            errorStack: error.stack,
            testAttemptId,
            fileName
        });
        }
    };

    // Process the responses from the Language Server
    const handleLSPResponses = (data) => {
        if (
            data.method === 'window/logMessage' ||
            data.method === 'workspace/configuration' || 
            data.method === 'client/registerCapability' || 
            data.method === '$progress' || 
            data.method === 'initialize' || 
            data.method === 'initialized' || 
            data.method === 'window/workDoneProgress/create'
        ) {// no action needed
        } else if (data.result && data.result.items) {  // autocomplete suggestions
            updateCompletionItems(data.result.items);
        } else if (data.result && data.method === 'textDocument/hover') { // hover suggestions
            handleHoverSuggestions(data); 
        } else if (data.method === 'textDocument/publishDiagnostics') {  // Highlights errors and suggests fixes
            setDiagnostics(data.params.diagnostics); // Setting it so it can be sent as part of codeAction
            updateEditorDiagnostics(editorRef.current, data.params.diagnostics); // Go to the UI
        } else if (data.error) {  // Handle any errors from the Language Server
            logException('Autocomplete Client Error from Language Server', {
                errorMessage: data.error.message,
                errorStack: data.error.stack,
                testAttemptId,
                fileName
            });
        } else if (data.method === 'LanguageServerStatus') {
            logTrace('LanguageServerStatus:', {
                status: data.params,
                testAttemptId,
                fileName
            });
            handleLanguageServerStatus(data.params);
        } else if (data.method === 'ServerUpdate') {
            logTrace('ServerUpdate:', {
                language: data.params.language,
                status: data.params.status,
                testAttemptId,
                fileName
            });
            handleLanguageServerUpdate(data.params.language, data.params.status);
        } else { 
           logTrace('Autocomplete Client Received Other Message', {
                data,
                testAttemptId,
                fileName
            });
        }
    };

    // Process inital response with status of language servers
    const handleLanguageServerStatus = (params) => {
        if (languageRef.current !== 'python' ) return; // removed kotlin and java
        setLanguageServerStatus(params);
        // look for the current language in the params if the status is 'ready'  
        const currentLanguageStatus = params.languages.find(lang => lang.language === languageRef.current);
        logTrace('Current language server status:', {
            language: languageRef.current,
            status: currentLanguageStatus,
            testAttemptId,
            fileName
        });
        if (currentLanguageStatus && currentLanguageStatus.status === 'ready') {
            setIsLanguageServerReady(true);
        } else {
            setIsLanguageServerReady(false);
        }
    };

    // Process updates to the language server status
    const handleLanguageServerUpdate = (updatedLanguage, status) => {
        if (updatedLanguage === languageRef.current) {
            setIsLanguageServerReady(true);
            if (status === 'failed') {
                setIsLanguageServerReady(false);
                // Remove python from the monaco languages list if it fails
                if (updatedLanguage === 'python') {
                    const updatedLanguages = monacoLanguages.filter(lang => lang !== 'python');
                    logException('Python language server failed. Removing python from monaco languages.', {
                        updatedLanguages,
                        testAttemptId,
                        fileName
                    });
                    setMonacoLanguages(updatedLanguages); // Update the monaco languages list
                    // trigger reset of monaco languge so Ace editor will be used (as python has been removed from the monaco languages)
                    setMonacoLanguage('plaintext'); // Set the language to plaintext
                    setTimeout(() => {
                        setMonacoLanguage('python'); // Reset the language to python
                    }, 100);

                }
            }
        }
    };
                
    // COMPLETION ITEMS //

    // Create a completion provider for the Monaco Editor to show autocomplete suggestions
    const createCompletionProvider = (completionItems) => {
    
        if (!completionItems || completionItems.length === 0) {
            return { provideCompletionItems: () => ({ suggestions: [] }) };
        }
    
        return {
            provideCompletionItems: function(model, position) {
    
                try {
                    const word = model.getWordUntilPosition(position);
    
                    const range = {
                        startLineNumber: position.lineNumber,
                        endLineNumber: position.lineNumber,
                        startColumn: word.startColumn,
                        endColumn: word.endColumn
                    };
    
                    const suggestions = completionItems.map(item => {
                        const suggestion = {
                            label: item.label,
                            kind: item.kind,
                            insertText: item.insertText,
                            range: range,
                            detail: item.detail,
                            documentation: item.documentation,
                            sortText: item.sortText,
                            filterText: item.filterText || item.label
                        };
                        return suggestion;
                    });
    
                    return {
                        suggestions: suggestions
                    };
                } catch (error) {
                    logException('Error providing completion items', {
                        errorMessage: error.message,
                        errorStack: error.stack,
                        testAttemptId,
                        fileName
                    });
                    return { suggestions: [] };
                }
            }
        };
    };

    // Update the completion items for the Monaco Editor
    const updateCompletionItems = (items) => {
        const parsedItems = items.map(item => ({
            label: item.label,
            kind: monacoRef.current.languages.CompletionItemKind.Function,
            insertText: item.insertText || item.label,
            range: null,  // Define range dynamically when providing completions
            detail: item.detail,
            documentation: item.documentation,
            sortText: item.sortText
        }));
        setCompletionItems(parsedItems);
    };

    // When completions are updated, call provider to refresh
    useEffect(() => {
        if (!monacoRef.current) return;
        // Dispose the previous provider if it exists
        if (completionProviderRegistration) {
            completionProviderRegistration.dispose(); 
        }
        // Create a new completion provider with the updated completion items
        const completionProvider = createCompletionProvider(completionItems);
        const newCompletionProviderRegistration = monacoRef.current.languages.registerCompletionItemProvider(language, completionProvider);

        // Update state with new provider registration for cleanup
        setCompletionProviderRegistration(newCompletionProviderRegistration);

        // Cleanup function to dispose of the provider when the component unmounts or dependencies change
        return () => {
            if (newCompletionProviderRegistration) {
                newCompletionProviderRegistration.dispose();
            }
        };
    }, [completionItems]);

    // DIAGNOSTICS //

    // Convert Pyright diagnostics to Monaco markers
    const convertDiagnosticsToMarkers = (diagnostics) => {
        // Filter out specific diagnostic messages
        const filteredDiagnostics = diagnostics.filter(diagnostic => {
            return !diagnostic.message.includes("The public type") || !diagnostic.message.includes("must be defined in its own file"); // Specific message to filter out as we do single file Java
        });
    
        return filteredDiagnostics.map(diagnostic => ({
            severity: diagnostic.severity === 1 ? monacoRef.current.MarkerSeverity.Error : monacoRef.current.MarkerSeverity.Warning,
            startLineNumber: diagnostic.range.start.line + 1,
            startColumn: diagnostic.range.start.character + 1,
            endLineNumber: diagnostic.range.end.line + 1,
            endColumn: diagnostic.range.end.character + 1,
            message: diagnostic.message,
            source: diagnostic.source
        }));
    };

    // Function to update editor with diagnostics
    const updateEditorDiagnostics = (editor, diagnostics) => {
        const model = editor.getModel();
        if (model) {
            const markers = convertDiagnosticsToMarkers(diagnostics);
            monacoRef.current.editor.setModelMarkers(model, 'owner', markers); // 'owner' can be any unique identifier
        }
    };
 
    // HOVER SUGGESTIONS //

    // Create a hover provider for the Monaco Editor to show information when hovering over code
    const createHoverProvider = (hoverInfo) => {
        if (!hoverInfo) return null;
        return {
            provideHover: function(model, position) {
                // Convert LSP range to Monaco range
                const monacoRange = new monacoRef.current.Range(
                    hoverInfo.range.start.line + 1,  // Monaco is 1-based
                    hoverInfo.range.start.character + 1,
                    hoverInfo.range.end.line + 1,
                    hoverInfo.range.end.character + 1
                );

                if (!monacoRange.containsPosition(position)) {
                    return null;
                }

                // Return the hover content if the position is within the range
                return {
                    range: monacoRange,
                    contents: [
                        { value: hoverInfo.contents }  // Assuming hoverInfo.contents has a 'value' property
                    ]
                };
            }
        };
    };

    // Process hover suggestions from the Language Server
    const handleHoverSuggestions = (data) => {
        // initialise hover contents
        let hoverContents = '';
        // Check if contents is an array and concatenate the strings
        if (Array.isArray(data.result.contents)) {
            hoverContents = data.result.contents.map(content => {
                if (typeof content === 'string') {
                    return content;
                } else if (content.value) {
                    return content.value;
                } else {
                    return '';
                }
            }).join('\n').trim();
        } else if (typeof data.result.contents === 'object' && data.result.contents.value) {
            hoverContents = data.result.contents.value.trim();
        } else if (typeof data.result.contents === 'string') {
            hoverContents = data.result.contents.trim();
        }

        // Only update hover suggestions if hoverContents is not empty
        if (hoverContents) {
            updateHoverSuggestions({
                contents: hoverContents,
                range: data.result.range
            });
        } 
    };

    // Update the hover suggestions for the Monaco Editor
    const updateHoverSuggestions = (result) => {
        setHoverInfo({
            contents: result.contents,
            range: result.range
        });
    };

    // When hoverInfo are updated, call provider to refresh
    useEffect(() => {
        if (!monacoRef.current || !hoverInfo) return;

        // Dispose the previous provider if it exists
        if (hoverProviderRegistration) {
            hoverProviderRegistration.dispose(); 
        }
        // Create a new completion provider with the updated completion items
        const hoverProvider = createHoverProvider(hoverInfo);
        const newHoverProviderRegistration = monacoRef.current.languages.registerHoverProvider(language, hoverProvider);

        // Update state with new provider registration for cleanup
        setHoverProviderRegistration(newHoverProviderRegistration);

        // Force editor layout validation
        editorRef.current.layout();

        // Cleanup function to dispose of the provider when the component unmounts or dependencies change
        return () => {
            if (newHoverProviderRegistration) {
                newHoverProviderRegistration.dispose();
            }
        };
    }, [hoverInfo]);
    
    // SENDING MESSAGES //

    // Notify the backend of any changes in code so it is working with the most up to date code
    const updateLanguageServerCode = (model, changes) => {
        const message = {
            jsonrpc: "2.0",
            method: "textDocument/didChange",
            params: {
                contentChanges: changes.map(change => ({
                    range: {
                        start: { line: change.range.startLineNumber - 1, character: change.range.startColumn - 1 },
                        end: { line: change.range.endLineNumber - 1, character: change.range.endColumn - 1 }
                    },
                    text: change.text,
                    rangeLength: change.rangeLength
                }))
            }
        };
        sendMessage(message);
    };
    
    // When the cursor moves request completions which populate the autocomplete list
    const requestCompletions = (model, range) => {
    
        // Ensure position values are not negative
        const position = {
            line: Math.max(range.startLineNumber - 1, 0),
            character: Math.max(range.startColumn - 1, 0)
        };
    
        const message = {
            jsonrpc: "2.0",
            method: "textDocument/completion",
            params: {
                position: position
            }
        };
    
        sendMessage(message);
    };    
    
    // When the user hovers over code, send a request to the LSP for hover information
    const sendHoverRequest = (model, position) => {
        const message = {
            jsonrpc: "2.0",
            method: "textDocument/hover",
            params: {
                position: { line: position.lineNumber - 1, character: position.column - 1 }
            }
        };
        sendMessage(message);
    };
    
    // General function to send WebSocket messages
    const sendMessage = (message) => {
        if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
            wsRef.current.send(JSON.stringify(message));
        }
    };

    return { updateLanguageServerCode, requestCompletions, sendHoverRequest, sendMessage};
};

export default useAutocomplete;
