// Entry point of test attempt
import React, { useContext, useEffect, useState } from "react";
import Header from "../components/common/Header";
import ReportIssue from "../components/common/ReportIssue";
import Popup from "../components/popup/Popup";
import ScreenCover from "../components/popup/ScreenCover";
import TestStageComponents from "../components/TestStageComponents"; // You will define this component next
import { useAppContext } from "../contexts/AppContext";
import { ChatProvider } from "../contexts/ChatContext"; // Used to manage the chat messages
import { CodeProvider } from "../contexts/CodeContext"; // Used to manage the code editor and compiler
import { FeedbackProvider } from "../contexts/FeedbackContext"; // USed in chat message to show feedback icons
import { SectionProvider } from "../contexts/SectionContext"; // Share information on sections within the test
import { useTestContext } from "../contexts/TestContext";
import { TooltipProvider } from "../contexts/TooltipContext"; // To decide which tolltip appears ot the user

const TestPage = () => {
  const { globalState } = useAppContext();
  const {
    testStage,
    isPhotoCheckActive,
    videoRef,
    canvasRef,
    screenCanvasRef1,
    screenCanvasRef2,
  } = useTestContext();
  const { screenWarning, focusWarning } = globalState;
  // Clases for the video and canvas to enable hiding or showing them
  const videoClass = isPhotoCheckActive ? "videoActive" : "videoHidden";
  const canvasClass = isPhotoCheckActive ? "canvasActive" : "canvasHidden";
  const cameraBackgroundClass = isPhotoCheckActive
    ? "cameraBackgroundActive"
    : "cameraBackgroundHidden";

  // CONFIRM USERS WANTS TO LEAVE PAGE //

  // Trigger popup to reconsider if user tries to close the page (move to appcontext with stage filter)
  useEffect(() => {
    // Function to handle the event
    const handleBeforeUnload = (e) => {
      e.preventDefault(); // Prevent the default unload behavior
      e.returnValue = ""; // Set returnValue to trigger the confirmation dialog
    };

    // Add the event listener when the component mounts
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup function to remove the event listener when the component unmounts
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, []);

  return (
    <TooltipProvider>
      <FeedbackProvider>
        <Header /> {/* Always render the header */}
        {/* Global hidden video and canvas elements for photo capture and screen share capture */}
        <div className={cameraBackgroundClass}>
          <video ref={videoRef} className={videoClass}></video>
          <canvas ref={canvasRef} className={canvasClass}></canvas>
          {/* canvas elements for screen capture */}
          <canvas ref={screenCanvasRef1} className="canvasHidden"></canvas>
          <canvas ref={screenCanvasRef2} className="canvasHidden"></canvas>
        </div>
        <ReportIssue />
        {/* Always render the report issue box */}
        {/* Regardless of the testStage, if the screen is too small then bring the popup warning */}
        {screenWarning && (
          <>
            <ScreenCover isVisible={true} variant="complete" zIndex={15} />
            <Popup variant="ScreenSizeIssue" isVisible={true} zIndex={16} />
          </>
        )}
        {/* During the test, if the user leaves the screen then blur it */}
        {focusWarning && testStage === "test" && (
          <ScreenCover isVisible={true} variant="blur" zIndex={20} />
        )}
        {/* Render the test stage components based on the testStage */}
        <TestStageComponents />
      </FeedbackProvider>
    </TooltipProvider>
  );
};

export default TestPage;
