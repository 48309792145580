import React, { useState, useEffect, useRef } from 'react';
import styles from './CandidatePhotoStream.module.css';
import Button from '../common/Button'; // Update the path as needed
import { FadeLoader } from 'react-spinners';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons'; // filled icons

const CandidatePhotoStream = ({ isLoading, error, photoStream, isSlideShowVisible, setIsSlideShowVisible }) => {
    const allTimestamps = Object.values(photoStream).flat().map(share => share.modified).sort((a, b) => a - b);
    const [currentTime, setCurrentTime] = useState(allTimestamps[2] || 0);
    const [play, setPlay] = useState(false);
    const [speed, setSpeed] = useState(1);
    const [currentImages, setCurrentImages] = useState({});
    const intervalRef = useRef(null);
    const sliderRef = useRef(null);
    const modalRef = useRef(null); // Ref for the modal to manage focus trapping

    useEffect(() => {
        if (!isLoading) {
            updateCurrentImages(currentTime);

            if (play) {
                intervalRef.current = setInterval(() => {
                    setCurrentTime((prevTime) => {
                        const maxTime = Math.max(...allTimestamps);
                        const currentIndex = allTimestamps.findIndex(time => time > prevTime);
                        const newTime = currentIndex !== -1 ? allTimestamps[currentIndex] : maxTime;
                        updateCurrentImages(newTime);

                        // Update the slider progress
                        const progress = (allTimestamps.indexOf(newTime) / (allTimestamps.length - 1)) * 100;
                        sliderRef.current.style.setProperty('--slider-progress', `${progress}%`);

                        return newTime;
                    });
                }, 1000 / speed); // Adjust interval based on speed
            } else {
                clearInterval(intervalRef.current);
            }
        }

        return () => clearInterval(intervalRef.current);
    }, [play, speed, photoStream, isLoading]);

    useEffect(() => {
        if (!isLoading && allTimestamps.length > 0) {
            let startIndex = 0;
            // if more than one screen start at 1
            if (Object.keys(photoStream).length > 1) {
                startIndex = 1;
            }
            setCurrentTime(allTimestamps[startIndex]);
            updateCurrentImages(allTimestamps[startIndex]);
        }
    }, [photoStream, isLoading]);

    // Update the current images based on the current time
    const updateCurrentImages = (time) => {
        const newCurrentImages = {};

        Object.keys(photoStream).forEach(screenNumber => {
            const screenShares = photoStream[screenNumber]
                .filter(screenShare => screenShare.modified <= time)
                .sort((a, b) => b.modified - a.modified);

            if (screenShares.length > 0) {
                newCurrentImages[screenNumber] = screenShares[0].imageUrl;
            }
        });

        setCurrentImages(newCurrentImages);
    };

    // When the slider is changed, update the current time and images
    const handleSliderChange = (event) => {
        const newIndex = parseInt(event.target.value, 10);
        const newTime = allTimestamps[newIndex];
        setCurrentTime(newTime);
        updateCurrentImages(newTime);

        // Update the slider progress
        const progress = (newIndex / (allTimestamps.length - 1)) * 100;
        sliderRef.current.style.setProperty('--slider-progress', `${progress}%`);
    };

    // Play or pause the slideshow
    const handlePlayPause = () => {
        setPlay((prevPlay) => !prevPlay);
    };

    // Change the speed of the slideshow
    const handleSpeedChange = (newSpeed) => {
        setSpeed(newSpeed);
    };

    // Close the slideshow
    const handleClose = () => {
        setIsSlideShowVisible(false);
    };

    // TABBING //
    useEffect(() => {
        if (isSlideShowVisible && modalRef.current) { // Ensure modalRef.current is not null
            const focusableElements = modalRef.current.querySelectorAll(
                'a, button, input, select, textarea, [tabindex]:not([tabindex="-1"])'
            );

            if (focusableElements.length === 0) return; // Check if there are focusable elements

            const firstElement = focusableElements[0];
            const lastElement = focusableElements[focusableElements.length - 1];

            const handleTabKey = (e) => {
                if (e.key === 'Tab') {
                    if (e.shiftKey) {
                        if (document.activeElement === firstElement) {
                            e.preventDefault();
                            lastElement.focus();
                        }
                    } else {
                        if (document.activeElement === lastElement) {
                            e.preventDefault();
                            firstElement.focus();
                        }
                    }
                }
            };

            const handleEscapeKey = (e) => {
                if (e.key === 'Escape') {
                    setIsSlideShowVisible(false);
                }
            };

            document.addEventListener('keydown', handleTabKey);
            document.addEventListener('keydown', handleEscapeKey);

            firstElement.focus(); // Set focus to the first element

            return () => {
                document.removeEventListener('keydown', handleTabKey);
                document.removeEventListener('keydown', handleEscapeKey);
            };
        }
    }, [isSlideShowVisible]);

    if (!isSlideShowVisible) return null;

    if (isLoading) {
        return (
            <div className={styles.photoStreamOuterContainer} ref={modalRef} role="dialog" aria-modal="true">
                <button onClick={handleClose} className={styles.closeButton} aria-label="Sair">
                    <FontAwesomeIcon icon={faTimes} />
                </button>
                <div className={styles.loaderContainer}>
                    <FadeLoader color="#246E24" />
                    <p>Carregando imagens, pode levar até 1 minuto...</p>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className={styles.photoStreamOuterContainer} ref={modalRef} role="dialog" aria-modal="true">
                <button onClick={handleClose} className={styles.closeButton} aria-label="Sair">
                    <FontAwesomeIcon icon={faTimes} />
                </button>
                <div className={styles.errorContainer}>
                    <p>Desculpe, ocorreu um erro ao carregar as imagens. Por favor, tente novamente ou entre em contato com suporte@degrau.co</p>
                </div>
            </div>
        );
    }

    return (
        <div className={styles.photoStreamOuterContainer} ref={modalRef} role="dialog" aria-modal="true">
            <button onClick={handleClose} className={styles.closeButton} aria-label="Sair">
                <FontAwesomeIcon icon={faTimes} />
            </button>
            <div className={styles.photoStreamContainer}>
                {Object.keys(currentImages).map((screenNumber, index) => (
                    <div key={screenNumber} className={styles.photoContainer}>
                        <p>{`Tela ${index + 1}`}</p>
                        <img src={currentImages[screenNumber]} alt={`Captura de tela ${screenNumber}`} className={styles.photo} />
                    </div>
                ))}
            </div>
            <div className={styles.controls}>
                <input
                    ref={sliderRef}
                    type="range"
                    min={0}
                    max={allTimestamps.length - 1}
                    value={allTimestamps.indexOf(currentTime)}
                    onChange={handleSliderChange}
                    className={styles.slider}
                />
                <Button 
                    type="tooltip" 
                    onClick={handlePlayPause} 
                    aria-pressed={play} // Indicates the toggle state
                    aria-label={play ? 'Pausar' : 'Play'} // Use Portuguese for Play/Pause
                    >
                    {play ? 'Pausar' : 'Play'}
                </Button>
                <Button 
                    type="tooltip" 
                    onClick={() => handleSpeedChange(1)} 
                    aria-label="Definir velocidade para 1x"
                    >
                    1x
                </Button>
                <Button 
                    type="tooltip" 
                    onClick={() => handleSpeedChange(2)} 
                    aria-label="Definir velocidade para 2x"
                    >
                    2x
                </Button>
                <Button 
                    type="tooltip" 
                    onClick={() => handleSpeedChange(5)} 
                    aria-label="Definir velocidade para 5x"
                    >
                    5x
                </Button>
            </div>
        </div>
    );
};

export default CandidatePhotoStream;
