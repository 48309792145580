import React from 'react';
import Logo from '../common/DegrauLogo';
import styles from './Popup.module.css'; 

const UniqueUrlFail = ({ onLoad, logoLoaded, ...otherProps }) => {
    return (
        <>
            <Logo className={styles.degrauLogoPopup} onLoad={onLoad} /> 
            <div className={styles.popupTextLeft}>
            <p>Lamentamos, mas estamos enfrentando dificuldades para carregar algumas informações necessárias no momento. Isso pode ser devido a um erro no link utilizado para acessar o site ou a problemas com nossos servidores ou sua conexão. Por favor, tente o seguinte:</p>
                <ul style={{ listStylePosition: 'inside' }}>
                    <li>Atualize a página do seu navegador.</li>
                    <li>Verifique se o link utilizado está correto ou entre em contato com quem o forneceu para confirmar.</li>
                </ul>
            <p>Se o problema persistir, por favor, entre em contato conosco pelo e-mail <b>suporte@degrau.co</b> para obter ajuda. Estamos aqui para ajudar!</p>
        </div>
        </>
    );
};

export default UniqueUrlFail;