import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import KanbanCard from './KanbanCard';
import styles from './KanbanColumn.module.css';

const KanbanColumn = ({ title, cards, droppableId }) => {
  return (
    <Droppable droppableId={droppableId}>
      {(provided) => (
        <div
          className={styles.column}
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          <h3 className={styles.columnTitle}>{title}</h3>
          {cards.map((card, index) => (
            <KanbanCard key={card.id} data={card} index={index} />
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default KanbanColumn;
