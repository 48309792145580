//Enable user to report issue
import React, { useState, useEffect, useRef, useContext } from 'react';
import { AppContext } from '../../contexts/AppContext'; 
import { useTestContext } from '../../contexts/TestContext';
import { saveIssue } from '../../services/databaseService';
import { logEvent, logException } from '../../services/loggerFront';
import Button from './Button';
import Tooltip from '../tooltip/Tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'; // Warning triangle for the icon of the button
import { FadeLoader } from 'react-spinners'; // Spinner for when the issue is being sent
import styles from './ReportIssue.module.css';

//Rules for ensuring email is valid and issue isnt blank
const validationRules = {
    email: (input) => /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(input.trim()),
    description: (input) => input.trim().length > 0
};

//Message to show if either isnt valid
const errorMessages = {
    email: "Insira um endereço de e-mail válido.",
    description: "O texto não pode estar em branco."
};

const ReportIssue = () => {
    const { pdfMode, userEmail } = useContext(AppContext);
    const { candidateId, candidateEmail, testAttemptId, codeSubmitted, testStage } = useTestContext();
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [description, setDescription] = useState('');
    const [showForm, setShowForm] = useState(false);
    const [errors, setErrors] = useState({});
    const formRef = useRef(); //Reference to the form for detecting outside clicks
    const [contactViaWhatsApp, setContactViaWhatsApp] = useState(false); // New state for WhatsApp contact preference

    // Messages post pressing send
    const [showSendingMessage, setShowSendingMessage] = useState(false);
    const [showThankYouMessage, setShowThankYouMessage] = useState(false);
    const [showErrorSendingMessage, setShowErrorSendingMessage] = useState(false);

    const fileName = 'ReportIssue' // for logging

    // Update email state when global candidateEmail or userEmail changes
    useEffect(() => {
        if (userEmail) {
            setEmail(userEmail);
        } else if (candidateEmail) {
            setEmail(candidateEmail);
        }
    }, [candidateEmail, userEmail]); 

    //Run validation checks
    const validateForm = () => {
        const newErrors = {};

        if (!validationRules.email(email)) {
            newErrors.email = errorMessages.email;
        }

        if (!validationRules.description(description)) {
            newErrors.description = errorMessages.description;
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    //Actions when user submits problem
    const handleSubmit = (e) => {
        e.preventDefault();
        const isValid = validateForm();

        if (isValid) {
            setShowSendingMessage(true); // Show sending message for immediate user feedback and preventing double sending
            const issueDetails = {
                candidate_id: candidateId || null,
                test_attempt_id: testAttemptId || null,
                email: email,
                mobile: mobile || null,
                issue_content: description,
                source: 'test',
                timestamp: new Date().toISOString().slice(0, 19).replace('T', ' ')
            };
    
            // Function to attempt saving the issue, with retry logic
            const attemptSave = (retryCount = 0) => {
                saveIssue(issueDetails)
                    .then(response => {
                        setShowSendingMessage(false); // Hide sending message that just appeared
                        setShowThankYouMessage(true); // Show thank you message instead so user knows it sent
                        setDescription(''); // Clear the other forms for the next time, except for email
                        setMobile('');
                    })
                    .catch(error => {
                        logException(`Error: Reporting issue`, { 
                            errorMessage: error.message,
                            errorStack: error.stack,  
                            fileName: fileName,
                            retryCount: retryCount, 
                        });
                        if (retryCount < 1) { // Retry once after 5 seconds if it fails
                            setTimeout(() => attemptSave(retryCount + 1), 5000);
                        } else {
                            // After retrying, show error message that directs user to email suporte
                            setShowSendingMessage(false);
                            setShowErrorSendingMessage(true);
                        }
                    });
            };
            attemptSave(); // Call attemptSave to initiate the save process

            logEvent(`ReportIssue handleSubmit`, { 
                issueDetails: issueDetails,
            });
        }
    };

    // Handle outside click
    useEffect(() => {
        function handleClickOutside(event) {
            if (formRef.current && !formRef.current.contains(event.target)) {
                setShowForm(false);
                // Hide any of the message states and make the form reappear
                setShowThankYouMessage(false); 
                setShowSendingMessage(false);
                setShowErrorSendingMessage(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [formRef]);

    // close form with escape key
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                setShowForm(false);
                setShowThankYouMessage(false);
                setShowSendingMessage(false);
                setShowErrorSendingMessage(false);
            }
        };
    
        if (showForm) {
            document.addEventListener("keydown", handleKeyDown);
        } else {
            document.removeEventListener("keydown", handleKeyDown);
        }
    
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [showForm]);
    

    // Determine the class name based of the button depnding if we are coding or not to move its position
    const buttonClassName = testStage === 'test' && !codeSubmitted
        ? 'reportIssueButtonCoding' // Use this class when the conditions are met
        : 'reportIssueButton' // Default class

    return (
        <>
            <Tooltip variant="reportIssue" arrowType="arrowBottomRight" />
            {!showForm && !pdfMode && (
                <Button 
                    className={buttonClassName}
                    onClick={() => {
                        setShowForm(true);
                        setShowThankYouMessage(false); // Reset thank you message
                    }}
                >
                    <FontAwesomeIcon icon={faExclamationTriangle} className={styles.problemIcon} />
                </Button>
            )}
            
            {showForm && ( // Only render reportIssueBox when showForm is true
                <div ref={formRef} className={styles.reportIssueBox}>
                    <h4> Contate-nos </h4>
                    {!showThankYouMessage && !showSendingMessage && !showErrorSendingMessage && (

                        <div> 
                            <p> Olá. Lamento saber que você está tendo problemas. Por favor, informe-nos seu e-mail e detalhes do problema e responderemos ao seu e-mail ou celular o mais breve possível </p>
        
                            <form className={styles.reportIssueForm} onSubmit={handleSubmit}>
                                <label htmlFor="problemEmail">Email*</label>
                                <input 
                                    type="email"
                                    id="problemEmail"
                                    name="problemEmail"
                                    placeholder="seuemail@email.com"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    aria-describedby={errors.email ? "email-error" : undefined}
                                    aria-invalid={errors.email ? "true" : "false"}
                                />
                                {errors.email && <p id="email-error" className={styles.errorMessage}>{errors.email}</p>}

                                {/* Checkbox for WhatsApp contact preference */}
                                <div className={styles.whatsAppCheckbox}>
                                    <input 
                                        type="checkbox"
                                        id="whatsAppContact"
                                        name="whatsAppContact"
                                        checked={contactViaWhatsApp}
                                        onChange={(e) => setContactViaWhatsApp(e.target.checked)}
                                    />
                                    <label htmlFor="whatsAppContact">Gostaria de ser contactado(a) por WhatsApp</label>
                                </div>

                                {/* Conditional mobile number input */}
                                {contactViaWhatsApp && (
                                    <div>
                                        <label htmlFor="mobileNumber">Número de celular </label>
                                        <input 
                                            type="tel"
                                            id="mobileNumber"
                                            name="mobileNumber"
                                            placeholder="(XX) XXXXX-XXXX"
                                            value={mobile}
                                            onChange={(e) => setMobile(e.target.value)}
                                        />
                                    </div>
                                )}
        
                                <label htmlFor="reportIssueDescription">Descreva o problema*</label>
                                <textarea 
                                    id="reportIssueDescription"
                                    name="reportIssueDescription"
                                    placeholder="Por favor, detalhe seu problema aqui"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                                {errors.description && <p className={styles.errorMessage}>{errors.description}</p>}
        
                                <Button 
                                    type="form"
                                    onClick={handleSubmit}>
                                    Enviar
                                </Button>
                            </form>
                        </div>
                    )}
                    {showThankYouMessage && (
                        <p role="alert"> Obrigado por submeter o problema. Responderemos ao seu e-mail ou celular o mais breve possível. Clique em qualquer lugar fora da caixa para ocultá-la. </p>
                    )}
                    {showSendingMessage && (
                        <div className={styles.sendingMessageContainer} role="status" aria-live="polite">
                            <FadeLoader size={60} color={"#246E24"} loading={showSendingMessage} />
                            <p>Enviando seu problema...</p>
                        </div>
                    )}
                    {showErrorSendingMessage && (
                        <p role="alert">Lamentamos, mas houve um problema com a conexão. Por favor, entre em contato conosco pelo e-mail <b>suporte@degrau.co</b></p>
                    )}
                </div>
            )}
        </>
    );
};

export default ReportIssue;