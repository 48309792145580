// Columns for the user management tables
import React from 'react';
import ActionButton from '../components/common/ActionButton';
import InfoTable from '../components/common/InfoTable';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

export const getPendingUserColumns = (viewPermissionLevel) => [
    { key: "email", label: "Email", sortable: false },
    ...(viewPermissionLevel === 'GLOBAL' ? [{ key: "companyName", label: "Empresa", sortable: false }] : []),
    { key: "role", label: "Tipo de conta", sortable: false },
    { key: "status", label: "Status da conta", sortable: false },
    { key: "createdAt", label: "Solicitado em", sortable: false },
    { key: "actions", label: "Ações", sortable: false, align: 'center' }
];

export const getUserColumns = (viewPermissionLevel, infoTableHeaders, infoTableRows) => [
    { key: "name", label: "Nome completo", sortable: true },
    { key: "email", label: "Email", sortable: true },
    ...(viewPermissionLevel === 'GLOBAL' ? [{ key: "companyName", label: "Empresa", sortable: true }] : []),
    {
        key: 'role',
        label: 'Tipo de conta',
        sortable: true,
        headerComponent: (
            <ActionButton 
                icon={faQuestionCircle} 
                label="Informação Tipo de Conta"
                showHoverPopout={true}
                popoutPosition="left"
                hoverComponent={<InfoTable headers={infoTableHeaders} rows={infoTableRows} popout={true} popoutPosition='left' />}
            />
        ),
    },
    { key: "status", label: "Status da conta", sortable: true },
    { key: "createdAt", label: "Adicionado em", sortable: true },
    { key: "actions", label: "Ações", sortable: false, align: 'center' }
];
