// Form for user to signup after being invited. Requires name, password, and password confirmation. Email is given as a hidden input.
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Logo from '../common/DegrauLogo';
import InputField from '../inputs/InputField';
import PasswordInputField from "../inputs/PasswordInputField";
import Button from '../common/Button';
import styles from './Popup.module.css';

const RegisterContent = ({
  email,
  firstNames,
  setFirstNames,
  lastNames,
  setLastNames,
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
  handleAction,
  errorMessage,
  successMessage,
  passwordRules,
  onLoad,
  logoLoaded,
  ...otherProps
}) => {
  const [showPasswordRules, setShowPasswordRules] = useState(false);

  // Show password rules if error message containr 'senha'
  useEffect(() => {
    if (errorMessage && errorMessage.toLowerCase().includes('senha')) {
      setShowPasswordRules(true);
    }
  }, [errorMessage]);
  return (
    <>
      <Logo className={styles.degrauLogoPopupLarge} onLoad={onLoad} />
      {logoLoaded ? (
        <>
          <p className={styles.popupTextLarge}>
            Bem-vindo a Degrau! Por favor, insira seu nome e sua senha.
          </p>
          <p className={styles.popupTextGrey}>
            Este convite foi enviado para <strong>{email}</strong>
          </p>
          <form
            className={styles.inputFieldContainerDouble}
            onSubmit={(e) => {
              e.preventDefault(); // Prevent the default form submission
              handleAction(); // Call the custom action handler
            }}
          >
            <div className={styles.doubleInputFieldContainer}>
              <div className={styles.inputFieldAndLabelNarrow}>
                <label htmlFor="firstNames">Nome(s)</label>
                <InputField
                  id="firstNames"
                  type="text"
                  value={firstNames}
                  onChange={(e) => setFirstNames(e.target.value)}
                  placeholder=""
                  aria-required="true"
                />
              </div>
              <div className={styles.inputFieldAndLabelNarrow}>
                <label htmlFor="lastNames">Sobrenome(s)</label>
                <InputField
                  id="lastNames"
                  type="text"
                  value={lastNames}
                  onChange={(e) => setLastNames(e.target.value)}
                  placeholder=""
                  aria-required="true"
                />
              </div>
            </div>
            <div className={styles.doubleInputFieldContainer}>
              <div className={styles.inputFieldAndLabelNarrow}>
                <label htmlFor="password">Senha</label>
                <PasswordInputField
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder=""
                  autoComplete="new-password"
                />
              </div>
              <div className={styles.inputFieldAndLabelNarrow}>
                <label htmlFor="confirmPassword">Confirmar senha</label>
                <PasswordInputField
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder=""
                  autoComplete="new-password" 
                />
              </div>
            </div>
            {showPasswordRules && (
              <div className={styles.passwordRules}>
                <p>A senha deve conter pelo menos:</p>
                <ul>
                  {passwordRules.map((rule, index) => (
                    <li key={index}>{rule}</li>
                  ))}
                </ul>
              </div>
            )}
            <input
              type="email"
              value={email}
              readOnly
              hidden
              autoComplete="username" // Associate the email with the password for browsers
            />
            <Button
              disabled={!firstNames || !lastNames || !password || !confirmPassword}
              type="form"
            >
              Registrar
            </Button>
          </form>
          {errorMessage && (
            <p className={styles.errorText} role="alert">
              {errorMessage}
            </p>
          )}
          {successMessage && (
            <p className={styles.successText} role="alert">
              {successMessage}
            </p>
          )}
        </>
      ) : null}
    </>
  );
};

RegisterContent.propTypes = {
  email: PropTypes.string.isRequired, 
  confirmPassword: PropTypes.string.isRequired,
  setConfirmPassword: PropTypes.func.isRequired,
  password: PropTypes.string.isRequired,
  setPassword: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  setName: PropTypes.func.isRequired,
  handleAction: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  successMessage: PropTypes.string,
};

export default RegisterContent;
