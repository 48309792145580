//When loading data as user arrives on site
import React from 'react';
import Logo from '../common/DegrauLogo';
import styles from './Popup.module.css'; 
import { ClimbingBoxLoader } from 'react-spinners'; //for the loading icon

const InitialLoadingContent = ({ onLoad, logoLoaded, ...otherProps }) => {
    return (
      <>
        <Logo className={styles.degrauLogoPopup} onLoad={onLoad} />
            <p className={styles.popupTextLarge}>
                Bem-vindo ao 'Degrau'. Aguarde enquanto carregamos seu teste.
            </p>
            <div className={styles.loadingIndicator}>
                <ClimbingBoxLoader size={10} color={"#246E24"}/>
            </div>
        </>
    );
};

export default InitialLoadingContent;
