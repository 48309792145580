import React from 'react';
import PasswordInputField from './PasswordInputField';
import styles from '../../sharedStyles/AdminFormsStyles.module.css';

const ChangePasswordForm = ({ currentPassword, newPassword, confirmNewPassword, setCurrentPassword, setNewPassword, setConfirmNewPassword, passwordRules }) => {
    return (
        <div>
            <div className={styles.inputGroup}>
                <label>Senha Atual</label>
                <PasswordInputField
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    placeholder=""
                    autoComplete="current-password"
                />
            </div>
            <div className={styles.inputGroup}>
                <label>Nova Senha</label>
                <PasswordInputField
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    placeholder=""
                    autoComplete="new-password"
                />
            </div>
            <div className={styles.inputGroup}>
                <label>Confirmar Nova Senha</label>
                <PasswordInputField
                    value={confirmNewPassword}
                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                    placeholder=""
                    autoComplete="new-password"
                />
            </div>
            {/* Password Rules Note */}
            <div className={styles.passwordRules}>
                <p>A senha deve conter pelo menos:</p>
                <ul>
                    {passwordRules.map((rule, index) => (
                        <li key={index}>{rule}</li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default ChangePasswordForm;
