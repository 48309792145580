//Button used for user inputs
import React from 'react';
import styles from './Button.module.css';
import sendIcon from '../../assets/images/sendIconWhite3.png'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'; // Import the send icon

const Button = ({ onClick, type = 'default', children, disabled = false, style = {}, className = '', onMouseEnter, onMouseLeave }) => {
    const buttonStyle = `${styles.button} ${styles[type]} ${styles[className]}`;

    // Default labels based on button type
    const defaultLabels = {
        send: <img src={sendIcon} alt="Enviar" className={styles.sendIcon} />, // Use the icon for the 'send' type
        feedback: <FontAwesomeIcon icon={faArrowRight} className={styles.feedbackIcon} />, // Smaller icon for feedback
        submitCode: 'Submeter Reposta',
        executeCode: 'Executar Código',
        tooltip: 'Ok',
        proceed: 'Continuar',
        subtle: 'Continuar',
        form: 'Continuar'
    };

    // Actual button type mapping to dictate the type attribute for html button
    const typeMapping = {
        default: 'button',
        send: 'button',
        feedback: 'button',
        submitCode: 'button',
        executeCode: 'button',
        tooltip: 'button',
        proceed: 'button',
        subtle: 'button',
        form: 'submit' // Map 'form' to 'submit'
    };

    const actualType = typeMapping[type] || 'button';

    // Use provided children or default label based on type
    const content = children || defaultLabels[type] || 'Button';

    return (
        <button 
            className={buttonStyle} 
            onClick={onClick} 
            type={actualType}
            disabled={disabled} 
            style={style}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            {content}
        </button>
    );
};

export default Button;
