// Show the past sql executions and their result
import React, { useEffect, useRef } from 'react';
import { useCodeContext } from '../../contexts/CodeContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import styles from './SqlLogOutput.module.css'; 

const SqlLogOutput = () => { 
    const { sqlLogs, theme } = useCodeContext(); // Load the SQL logs from the CodeContext
    const themeClass = theme === 'dark' ? styles.dark : styles.light; // Set the theme class based on the theme prop
    const logContainerRef = useRef(null); // Ref of container to enable auto-scrolling
    const logsEndRef = useRef(null); // Ref to the end of the messages for scrolling

    // Scroll to the bottom of the log container
    const scrollToBottom = () => {
        logsEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    // Scroll to the bottom of the log container when sqlLogs update
    useEffect(() => {
        scrollToBottom();
    }, [sqlLogs]);

    return (
        <div className={`${styles.sqlLogOutput} ${themeClass}`}>
            <div className={styles.mainHeading}>
                LOGS SQL:
            </div>
            <div className={styles.header}>
                <span className={styles.headerIcon}></span>
                <span className={styles.headerId}>ID</span>
                <span className={styles.headerTime}>Hora</span>
                <span className={styles.headerAction}>Ação</span>
                <span className={styles.headerMessage}>Mensagem</span>
            </div>
            <div className={styles.logEntryContainer} ref={logContainerRef}>
                {sqlLogs.map(log => (
                    <div key={log.id} className={`${styles.logEntry} ${themeClass}`}> 
                        <span className={styles.logIcon}>
                            <FontAwesomeIcon 
                                icon={log.success ? faCheck : faTimes} 
                                color={log.success ? 'lime' : 'red'} 
                            />
                        </span>
                        <span className={styles.logId}>{log.id}</span>
                        <span className={styles.logTime}>{log.time}</span>
                        <span 
                            className={styles.logAction} 
                            data-tooltip-id={`tooltip-action-${log.id}`} 
                            data-tooltip-content={log.action}
                        >
                            {log.action}
                        </span>
                        <span 
                            className={styles.logMessage} 
                            data-tooltip-id={`tooltip-message-${log.id}`} 
                            data-tooltip-content={log.message}
                        >
                            {log.message}
                        </span>
                        <ReactTooltip id={`tooltip-action-${log.id}`} place="bottom" />
                        <ReactTooltip id={`tooltip-message-${log.id}`} place="bottom" />
                    </div>
                ))}
                <div ref={logsEndRef} style={{ float: 'left', clear: 'both' }} />
            </div>
        </div>
    );
};

export default SqlLogOutput;
