// For the T&Cs and Pivacy Policy pages
import React from 'react';
import styles from './LegalText.module.css';

// Hardcoded content for the legal texts
const content = {
    privacyPolicy: (
      <div className={styles.legalText}>
      <h2>POLÍTICA DE PRIVACIDADE</h2>
      <p>Última atualização: fevereiro de 2024.</p>
      <p>Nós da Degrau Recrutamento (“Degrau” ou “Nós”), empresa com sede na Rua Barão de Capanema, nº 252, conjunto 72, São Paulo, SP, Brasil, nos preocupamos com a garantia da privacidade e com a proteção de Dados Pessoais dos Usuários (“Você”) na medida em que o nosso contato, o cadastro em nosso site, e o acesso e a utilização de nossa Plataforma por Você podem levar à coleta e ao tratamento de Dados Pessoais. Por isso, preparamos essa Política de Privacidade (“Política”) para te mostrar quais Dados Pessoais são tratados, de quem tratamos, como os utilizamos, com quem podemos compartilhá-los, e as medidas que tomamos para protegê-los e mantê-los seguros, observando sempre o previsto na Lei de Proteção de Dados (“LGPD”).</p>
      <p>Para melhor entender como funciona nossa Plataforma, a proteção dos seus Dados Pessoais e a garantia de sua privacidade, recomendamos sua leitura em conjunto com os Termos e Condições de Uso da Degrau </p>
      
      <h3>1. QUAIS TERMOS VOCÊ PRECISA SABER ANTES DE SEGUIR?</h3>
      <p>Antes de seguir com as diretrizes desta Política, seguem os seguintes termos definidos para sua melhor compreensão:</p>

      <ul>
        <li><b>Autoridade Nacional de Proteção de Dados (ANPD):</b> a ANPD é uma autarquia de natureza especial responsável por zelar pela proteção de Dados Pessoais e por regulamentar, implementar e fiscalizar o cumprimento da LGPD no Brasil;</li>
        <li><b>Cookies:</b> arquivos de navegação que armazenam temporariamente o que o Você está visitando em um determinado site ou aplicativo;</li>
        <li><b>Dados Pessoais:</b> são dados que identifiquem ou tornem identificável uma pessoa natural, como nome, endereço residencial, nº de RG, nº de CPF, etc.;</li>
        <li><b>Dados Pessoais Sensíveis:</b> são Dados Pessoais sobre origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico (como características faciais), quando vinculado a uma pessoa natural, como orientação sexual, biometria coletada para entrada/saída de ambientes, dados de saúde extraídos de exames;</li>
        <li><b>Inteligência Artificial ou “IA”:</b> refere-se aos sistemas de computador ou programas de software que têm a capacidade de analisar dados, identificar padrões e tomar decisões automatizadas com base nesses padrões, por meio da utilização algoritmos e técnicas avançadas;</li>
        <li><b>LGPD ou Lei Geral de Proteção de Dados Pessoais ou Lei Federal nº 13.709/2018:</b> legislação que regula o Tratamento de Dados Pessoais no quaisquer outras leis e regulamentos em relação ao tratamento, proteção e privacidade de Dados Pessoais, incluindo Dados do Usuário, que sejam aplicáveis e, se aplicáveis, todas as orientações, normas, regras, portarias, regulamentos e códigos de prática e conduta emitidos pela Autoridade Nacional de Proteção de Dados (“ANPD”) ou outra autoridade de supervisão ou proteção de Dados Pessoais pertinente;</li>
        <li><b>Terceiro:</b> clientes, parceiros, fornecedores, ou qualquer ente que seja externo à Degrau e tenha seus Dados Pessoais tratados;</li>
        <li><b>Titular:</b> pessoa natural a quem se referem os Dados Pessoais que são objeto de tratamento;</li>
        <li><b>Tratamento:</b> operações referem a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração de Dados Pessoais; e</li>
        <li><b>Você:</b> qualquer pessoa que realiza o seu cadastro na Plataforma da Degrau para usufruir das funcionalidades nela oferecidas a partir das condições previstas em nossos Termos de Uso e nesta Política de Privacidade.</li>
      </ul>
        
      <h3>2. COMO SÃO COLETADOS OS SEUS DADOS PESSOAIS?</h3>
      <p>Os seus Dados Pessoais são coletados por diversas fontes. Esses Dados Pessoais podem ser obtidos de diferentes maneiras, sendo:</p>

      <table className={styles.table}>
        <thead>
          <tr>
            <th>Quem fornece os Dados Pessoais?</th>
            <th>Para quê?</th>
            <th>Quais dados são fornecidos?</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Fornecidos diretamente por Você</td>
            <td>Envio de dados cadastrais durante a realização de sua entrevista para o nosso chatbot, pela disponibilização da câmera durante a realização de sua entrevista e para o recebimento de informações por parte da Degrau</td>
            <td>Nome, e-mail e imagem</td>
          </tr>
          <tr>
            <td>Automaticamente</td>
            <td>A partir da coleta de informações do dispositivo utilizado para acessar a Plataforma</td>
            <td>Dados de geolocalização, registros de acesso, padrões de uso da Plataforma, informações de cookies ou tecnologias similares, estatísticas e dados agregados</td>
          </tr>
        </tbody>
      </table>

      <h3>3. QUAIS DADOS PESSOAIS SÃO TRATADOS PELA DEGRAU?</h3>
      <p>Nós somos responsáveis pelo Tratamento de Dados Pessoais cadastrais a partir do seu acesso e utilização de nossa Plataforma, como mostra a tabela abaixo:</p>
      
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Categoria dos Dados Pessoais</th>
            <th>Quem é o Titular</th>
            <th>Como são os Dados Pessoais são coletados</th>
            <th>Quais Dados Pessoais são tratados</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Dados Cadastrais</td>
            <td>Você</td>
            <td>Dados fornecidos pelo Titular para o processo de realização da entrevista com o chatbot.</td>
            <td>Nome e e-mail.</td>
          </tr>
          <tr>
            <td>Dados Cadastrais</td>
            <td>Você</td>
            <td>Dados fornecidos pelo Titular durante o opt-in para receber contato da Degrau em momento futuro.</td>
            <td>Nome e e-mail.</td>
          </tr>
          <tr>
            <td>Registros de Acesso</td>
            <td>Você</td>
            <td>Coleta automática de todos os candidatos que acessam a Plataforma da Degrau.</td>
            <td>Endereço de IP, data e hora de acesso, geolocalização precisa ou aproximada, dados de GPS, log de acesso, tipo de browser, tipo de dispositivo, modelo de hardware, dados de rede, sistema operacional e navegador utilizado</td>
          </tr>
          <tr>
            <td>Dados coletados por meio de Cookies e tecnologias assemelhadas</td>
            <td>Você</td>
            <td>Coletados automaticamente por Nós a partir do uso do candidato da Plataforma. </td>
            <td>Poderemos utilizar Cookies e identificadores de dispositivos eletrônicos quando o candidato acessa a Plataforma. </td>
          </tr>
        </tbody>
      </table>

      <h3>4. PARA QUAIS FINALIDADES SÃO UTILIZADOS OS DADOS PESSOAIS?</h3>
      <p>Nós utilizaremos os Dados Pessoais descritos no item acima para as seguintes finalidades e de acordo com a base legal aplicável:</p>

      <table className={styles.table}>
        <thead>
          <tr>
            <th>Finalidade</th>
            <th>Explicação</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Realização de entrevista com o candidato (Você)</td>
            <td>Antes de iniciarmos a entrevista com nosso chatbot para cumprir com uma das etapas de seu processo seletivo, iremos perguntar quem Você é. Além disso, para evitar qualquer irregularidade durante sua etapa, também solicitamos que sua câmera esteja aberta durante toda essa etapa. Assim, nós conseguimos monitorar se você saiu da nossa tela.</td>
          </tr>
          <tr>
            <td>Elaboração de Relatório de Desempenho após a Entrevista</td>
            <td>Nós utilizaremos dados cadastrais para te identificar no relatório com o resultado após sua entrevista e, posteriormente, iremos enviá-lo à empresa que Você está fazendo o processo seletivo.</td>
          </tr>
          <tr>
            <td>Integrar com sistemas de inteligência artificial (“IA”) para realizar nosso serviço</td>
            <td>Utilizar os seus Dados Pessoais na ferramenta de IA utilizada por Nós para realizar a entrevista com Você.</td>
          </tr>
          <tr>
            <td>Cumprimento de obrigações legais ou regulatórias da Degrau</td>
            <td>Poderemos tratar Dados Pessoais do Usuário para cumprir com suas obrigações legais e/ou regulatórias na medida do que for necessário. Essas obrigações podem incluir, por exemplo, obrigações fiscais e tributárias, obrigações sobre manutenção de registros técnicos, documentação de serviços contratados, prestação de contas, entre outras.</td>
          </tr>
          <tr>
            <td>Exercício de direitos relacionados a processos judiciais e administrativos</td>
            <td>Poderemos tratar seus Dados Pessoais para exercer seus próprios direitos e/ou para defender seus direitos e interesses. Isso pode incluir tratativas extrajudiciais entre as partes, processos judiciais ou arbitrais, procedimentos administrativos, entre outros.</td>
          </tr>
        </tbody>
      </table>

      <p>Em relação às demais atividades que envolvem o Tratamento de Dados Pessoais, serão utilizadas as bases legais aplicáveis ao caso, como execução de um contrato ou providências preliminares; cumprimento de obrigações legais ou regulatórias; exercício e defesa de nossos direitos e interesses; ou com base em um interesse legítimo de nossa parte, sempre considerando e respeitando os seus direitos e garantias fundamentais e de seus filhos, também Titulares de Dados Pessoais. Além disso, poderemos solicitar seu consentimento expresso, quando necessário, pela Plataforma.</p>

      <h3>5. COMO UTILIZAMOS INTELIGÊNCIA ARTIFICIAL?</h3>
      <p>Nós utilizamos um sistema de inteligência artificial para realizar a entrevista com Você. Esse sistema é de chatbot, ou seja, é uma ferramenta automatizada de análise de dados e processamento de linguagem natural, desenvolvida para gerar novas amostras de textos, gráficos e conteúdos com base em dados e padrões pré-estabelecidos. Para podermos utilizá-lo, compartilharemos os seus Dados Pessoais cadastrais com a linguagem por Nós utilizada, como Você poderá ver no item 6 a seguir. </p>

      <h4>5.1. NOSSOS CUIDADOS DIANTE DO USO DE SISTEMA DE IA</h4>
      <p>A partir disso, Nós te informamos que o treinamento da IA é feito com base em grandes conjuntos de dados que incluem informações coletadas a partir de diversas fontes, incluindo a Internet. Estes dados podem conter estereótipos e opiniões negativas que foram previamente registrados e incorporados ao modelo de treinamento por terceiros não atrelados à Degrau.</p>
      <p>Feitas essas considerações, embora tenhamos nos esforçado para mitigar esses vieses durante o processo de treinamento, é importante destacar que a IA não possui a capacidade de compreender o contexto social, ético e moral de maneira abrangente e sensível como um ser humano, havendo a possibilidade de alguma informação presente na base de dados de treinamento ser utilizada em desacordo com sua finalidade original.</p>
      <p>Portanto, a geração de resultados discriminatórios ou opiniões que incitem o <u>ódio não reflete de forma alguma nossos valores e a nossa missão, e deve ser encarada como uma limitação inerente ao treinamento da Inteligência Artificial, uma vez que não temos controle, tampouco somos responsáveis pelas fontes da IA.</u></p>

      <h4>5.2. SUA CONTRIBUIÇÃO ENQUANTO USUÁRIO DE NOSSO SISTEMA</h4>
      <p>Você poderá nos ajudar ao colaborar ativamente nos fornecendo feedbacks, orientações e diretrizes claras para minimizar quaisquer impactos negativos e aprimorar continuamente a capacidade da IA de gerar resultados alinhados aos valores da sociedade como um todo. </p>
      <p>Caso Você passe por qualquer transtorno durante a sua entrevista e note que o chatbot foi discriminatório, incitou o ódio ou gerou qualquer tipo de comunicação em desacordo com valores éticos e morais, pedimos que nos informe imediatamente por meio do canal privacidade@degrau.co.</p>
      
      <h3>6. COM QUEM COMPARTILHAMOS OS SEUS DADOS PESSOAIS</h3>
      <p>Primeiro, te informamos que utilizamos sistema de Inteligência Artificial em nossa ferramenta para gerar entrevistas e relatórios para uma melhor experiência personalizada a Você. Caso tenha dúvidas quanto ao uso do sistema de IA, consulte nossos Termos e Condições de Uso.</p>
      <p>Dessa forma, o compartilhamento de Dados Pessoais envolve o envio e o recebimento de informações de um Terceiro, que possa estar localizado no Brasil ou em território estrangeiro.</p>
      <p>Em nosso caso, compartilhamos seus Dados Pessoais com:</p>
      <ul>
        <li><b>O sistema de IA generativa:</b> com o objetivo de alcançar as finalidades da Plataforma, utilizamos Inteligência Artificial para análise automatizada para gerar perguntas e respostas Para fornecer essa solução, contamos com a inteligência de linguagem generativa fornecida pela OpenAI. Como parte desse processo, seus Dados Pessoais serão compartilhados com a empresa, por isso,. ressaltamos que a responsabilidade pelo tratamento e proteção desses dados é exclusiva da OpenAI, conforme sua política de privacidade. É recomendável que Você revise e esteja ciente das políticas de privacidade dessa tecnologia antes de utilizar nossa ferramenta.</li>
      </ul>
      <p>Além das situações já mencionadas nesta Política de Privacidade, os Dados Pessoais podem ser compartilhados com terceiros nos seguintes casos:</p>
      <ul>
        <li><b>Empresa parceiras que buscam talentos para ocupação de vagas:</b> os Dados Pessoais serão compartilhados com empresas contratantes dos serviços da Degrau para o preenchimento de postos de trabalho, conforme necessário para a condução eficiente e transparente do processo seletivo. Esse compartilhamento ocorrerá exclusivamente com organizações que demonstrem um legítimo interesse no recrutamento e seleção de profissionais adequados para as vagas disponíveis.</li>
        <li><b>Exercício regular de direitos por vias administrativas, judiciais ou extrajudiciais:</b> poderemos compartilhar Dados Pessoais com terceiros, como escritórios de advocacia, consultorias, empresas de cobrança, escritórios de contabilidade e similares, com o objetivo de exercer seus próprios direitos ou garantir o cumprimento e a aplicação desta Política de Privacidade e dos Termos e Condições de Uso;</li>
        <li><b>Requisição Judicial ou Administrativa:</b> poderemos compartilhar Dados Pessoais em resposta a uma requisição judicial ou administrativa; e/ou</li>
        <li><b>Cumprimento de obrigação legal ou regulatória:</b> poderemos compartilhar Dados Pessoais com órgãos, autoridades e outras entidades governamentais, bem como com pessoas físicas ou jurídicas de natureza privada, para cumprir com obrigações legais ou regulatórias.</li>
      </ul>


      <h4>6.1 TRANSFERÊNCIA INTERNACIONAL DE DADOS PESSOAIS</h4>
      <p>Caso os seus Dados Pessoais sejam transferidos para outro país a fim de serem tratados e armazenados de acordo com os termos e finalidades estabelecidos nesta Política de Privacidade, bem como para a prestação dos serviços, Nós garantimos a conformidade com a LGPD e as requisições contidas no artigo 33 da Lei. </p>
      <p>Também ressaltamos que as leis de privacidade e proteção de Dados Pessoais variam de país para país, e os padrões de proteção de Dados Pessoais em outros países variar daquele existente no Brasil. Por isso, seguiremos o padrão máximo de conformidade e garantiremos a proteção de seus Dados Pessoais.POR QUANTO TEMPO ARMAZENAMOS OS SEUS DADOS PESSOAIS?</p>
      <p>Os Dados Pessoais serão mantidos pelo tempo necessário para as finalidades descritas nesta Política de Privacidade. Nós adotamos controles para garantir que os dados sejam retidos somente enquanto forem realmente necessários, sendo excluídos quando encerrado o Tratamento ou quando não se aplicar nenhuma base legal de retenção. Além disso, em algumas situações, os dados poderão ser mantidos por um período mais longo, como para:</p>
      <ul>
        <li>cumprir obrigações legais ou regulatórias, como casos de auditoria e controle de fraude;</li>
        <li>exercer regularmente direitos em processos administrativos, judiciais ou arbitrais;</li>
        <li>cumprir o período de vigência de um contrato.</li>
      </ul>
      <p>Na hipótese de exclusão dos Dados Pessoais, estes serão deletados quando não houver mais uma finalidade específica para o Tratamento dos dados pessoais, sendo removidos de nossos sistemas e registros ou anonimizados, de modo que a identificação dos indivíduos não seja mais possível.</p>

      <h3>7. COMO GARANTIMOS A SEGURANÇA DOS SEUS DADOS PESSOAIS?</h3>
      <p>Para os Dados Pessoais tratados por Nós e sob as finalidades destacadas no item 4, adotamos medidas técnicas e administrativas de segurança e proteção adequadas à natureza dos dados coletados, utilizados, armazenados ou de outra forma processados por Nós, em conformidade com as práticas de mercado.</p>
      <p>No entanto, nenhum método de transmissão ou armazenamento de dados eletrônicos é totalmente seguro e está livre de eventuais ataques externos. Por isso, a Degrau não pode garantir a absoluta segurança das medidas adotadas ou a ausência de interferência de terceiros (como hackers). Por sua natureza, apesar de nossos melhores esforços, qualquer medida de segurança pode falhar, resultando em eventual incidente de segurança envolvendo Dados Pessoais. Tais medidas se estendem aos Terceiros com os quais a Degrau compartilha Dados Pessoais, considerando nossa ausência de responsabilidade em caso de incidentes, mas nosso cuidado em garantir parcerias que possuam o mínimo de boas práticas em segurança da informação.</p>

      <h3>8. QUAIS SÃO OS SEUS DIREITOS E COMO PODERÁ EXERCÊ-LOS? </h3>
      <p>Nós disponibilizamos meios para que Você exerça os seus direitos legais sobre os seus Dados. Neste item descreveremos esses direitos e como os Titulares podem exercê-los:</p>

      <ul>
        <li><b>Confirmação da existência de tratamento:</b> Você pode confirmar se estamos tratando os seus Dados Pessoais;</li>
        <li><b>Acesso aos Dados Pessoais:</b> Você pode acessar os seus Dados Pessoais, inclusive solicitando cópias;</li>
        <li><b>Correção de Dados incompletos, inexatos ou desatualizados:</b> Você pode solicitar alteração ou correção dos seus Dados Pessoais que se apresentem incorretos;</li>
        <li><b>Anonimização, bloqueio ou eliminação:</b> Você pode solicitar a anonimização, bloqueio ou eliminação de Dados desnecessários, excessivos ou tratados em desconformidade com o disposto na Legislação de Proteção de Dados;</li>
        <li><b>Revisão de Decisão Automatizada.</b> Você pode solicitar a revisão de decisões tomadas unicamente com base em tratamento automatizado de dados pessoais que afetem seus interesses;</li>
        <li><b>Eliminação dos Dados Pessoais.</b> Você pode solicitar a exclusão de seus Dados Pessoais tratados pela Degrau quando estes forem coletados e tratados com base em seu consentimento, por meio da própria Plataforma ou por solicitação pelos canais de atendimento indicados nesta Política de Privacidade;</li>
        <li><b>Revogação do Consentimento.</b> Você pode, a qualquer momento e a seu critério, revogar o Consentimento anteriormente fornecido para tratamento de Dados Pessoais, permanecendo os tratamentos realizados com base nele, até tal momento, válidos.</li>
      </ul>

      <p>Os direitos mencionados acima e outros previstos na LGPD podem ser exercidos por Você pela nossa Plataforma, Você poderá entrar em contato conosco para requerer qualquer desses direitos por meio do e-mail privacidade@degrau.co .</p>

      <h3>9. O QUE SÃO COOKIES?</h3>
      <p>Cookies são pequenos arquivos de texto gerados pelos sites que Você visita a partir da coleta das suas informações. Esses arquivos possibilitam uma navegação mais personalizada ao relembrar se Você já esteve ali, quais páginas você abriu, identificar de qual tipo de dispositivo Você está acessando o nosso site, traçar um perfil sobre Você por suas preferências e até te recomendar publicidades direcionadas ao seu perfil de compra. Em razão das finalidades de uso, seus Dados Pessoais também podem ser coletados, como seu endereço de IP, seu registro de navegação por hora e data, sua localização geográfica, o idioma falado, seus dados comportamentais relacionados a hábitos de consumo. </p>
      <p>Os cookies podem ser divididos a partir de sua funcionalidade para o site. A tabela a seguir ilustra de maneira mais clara a categoria e descrição dos Cookies utilizados:</p>

      <table className={styles.table}>
        <thead>
          <tr>
            <th>CATEGORIA DO COOKIES</th>
            <th>DESCRIÇÃO</th>
            <th>DETALHES</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowSpan="2">Necessidade</td>
            <td>Necessários</td>
            <td>Cookies utilizados para que o site funcione corretamente e que sem eles Você e demais usuários não conseguiriam utilizar o principal serviço do aplicativo.</td>
          </tr>
          <tr>
            <td>Não necessários</td>
            <td>Cookies que, se desabilitados, não impedem a utilização do aplicativo de forma plena e o correto funcionamento.</td>
          </tr>
          <tr>
            <td rowSpan="3">Finalidade</td>
            <td>Analíticos</td>
            <td>Cookies que coletam informações sobre a navegação no aplicativo para fins estatísticos. Assim, iremos entender como Você utiliza o app. Luna, qual índice de erro do aplicativo com usuários, dentre outras informações.</td>
          </tr>
          <tr>
            <td>de Funcionalidade</td>
            <td>Cookies que oferecem o serviço básico do aplicativo e permitem lembrar preferências, como o seu nome de usuário, quando Você nos visitou, a sua localização e o seu idioma.</td>
          </tr>
          <tr>
            <td>de Publicidade/Marketing</td>
            <td>Cookies que constroem o seu perfil de usuário a partir de dados extraído do seu hábito de navegação e, a partir disso, conseguem direcionar anúncios e marketing a Você.</td>
          </tr>
          <tr>
            <td rowSpan="2">Período de armazenamento das informações</td>
            <td>Temporários</td>
            <td>Cookies descartados quando Você deixa o nosso aplicativo ao encerrar sua sessão.</td>
          </tr>
          <tr>
            <td>Persistentes</td>
            <td>Cookies que permanecem armazenados mesmo após o encerramento de sua sessão e podem ter estabelecido um período definido de armazenamento.</td>
          </tr>
        </tbody>
      </table>

      <p>Você dará o aceite sobre os  Cookies que deseja que sejam coletados durante a sua entrevista no momento em que aparecer um banner quando ingressar no ambiente online da Degrau. Caso Você opte para que os Cookies não sejam armazenados, suas informações e, eventualmente, Dados Pessoais, não serão tratados por Nós. A depender da sua escolha, pode ser que sua experiência não seja personalizada, que Você não receba publicidades direcionadas e que não tenhamos como traçar o seu perfil de navegação.  Você também pode recusar o armazenamento dos necessários, porém, o desempenho da platafora se tornará comprometido.</p>
      <p>Se desejar obter mais informações sobre Cookies e proteção de Dados Pessoais, consulte o Guia Orientativo publicado pela ANPD.</p>

      <h3>10. ATUALIZAÇÕES DA POLÍTICA DE PRIVACIDADE</h3>
      <p>Nossa Política de Privacidade pode sofrer modificações em razão das constantes atualizações legislativas relacionadas à proteção de Dados Pessoais e da constante melhoria e desenvolvimento de nossos serviços. Por isso, avisaremos em nossa Plataforma quando a Política for atualizada. Caso Você continue a utilizar o cadastro, entenderemos que estará ciente de todas as atualizações.</p>

      <h3>11. ALGUMA DÚVIDA?</h3>
      <p>Em caso de qualquer dúvida sobre o Tratamento de seus Dados Pessoais, Você pode nos contatar pelo e-mail privacidade@degrau.co.</p>

    </div>

    ),
    termsAndConditions: (
      <div className={styles.legalText}>
        <h2>TERMOS E CONDIÇÕES DE USO DA DEGRAU</h2>
        <p>Data de Revisão: 9 de Janeiro de 2024.</p>
        <p>Bem-vindo! Estes Termos e Condições de Uso ("Termos") são aplicáveis à sua interação com a plataforma e aos serviços providos pela DEGRAU RECRUTAMENTO LTDA., gestora do site www.degrau.co ("DEGRAU") e fornecedora dos serviços mencionados nestes Termos ("Serviços"). Sua concordância com estes Termos é essencial para o uso da DEGRAU e dos Serviços relacionados. Portanto, é fundamental que Você leia, compreenda e aceite todas as disposições aqui presentes.</p>
        <h3>Definições</h3>
        <p>Neste documento, ao nos referirmos a USUÁRIOS, estamos falando de você e dos(as) Candidatos(as) em geral. Para acessar a Plataforma e os Serviços, você, enquanto Candidato(a), deve ser maior de 16 anos.</p>
        <p>As EMPRESAS representam as organizações que contratam a Degrau para auxílio na seleção de talentos para suas equipes. Os(as) candidatos(as) são encaminhados à plataforma Degrau por variados canais de comunicação da EMPRESA.</p>
        <h3>Modificações nos Termos</h3>
        <p>Estes Termos estão sujeitos a alterações por motivos legítimos. Sempre que uma mudança significativa ocorrer, a DEGRAU se empenhará em notificá-lo e solicitar a aceitação da versão atualizada.</p>
        <h3>Serviços Oferecidos pela DEGRAU</h3>
        <p>A DEGRAU disponibiliza os seguintes Serviços às EMPRESAS:</p>
        <ul>
          <li>Acesso dos candidatos a um ambiente virtual para realização de entrevistas automatizadas, incluindo recursos antifraude, como monitoramento durante a entrevista.</li>
          <li>Análise das respostas dos candidatos e fornecimento de um relatório de desempenho automatizado às EMPRESAS, contendo avaliações quantitativas e qualitativas, notas por habilidades específicas e transcrição da entrevista.</li>
        </ul>
        <h3>Tratamento dos Seus Dados</h3>
        <p>Compartilhamento dos Dados: Seus dados pessoais serão processados e armazenados em um ambiente seguro pela DEGRAU, respeitando as tecnologias disponíveis. Eles serão compartilhados com as partes necessárias para viabilizar sua participação no processo seletivo, ou seja, com as EMPRESAS conduzindo o processo. Além da EMPRESA, consultorias de RH ou gestão de pessoas também podem participar do processo seletivo.</p>
        <p>Para correto uso da DEGRAU, iremos registrar seu histórico de navegação e interação na plataforma DEGRAU, com objetivo de identificar erros e falhas, entender suas preferências para melhorar a plataforma e criar novos produtos, possibilitar a oferta de melhor conteúdo, assim como atender a necessidades legais e administrativas em caso de fraude ou ordem judicial. Para mais informações sobre o compartilhamento de seus dados pessoais, consulte nossa Política de Privacidade.</p>
        <h3>Diretrizes para o Candidato</h3>
        <p>O Candidato (e o USUÁRIO em geral, quando aplicável) não pode:</p>
        <ul>
          <li>Realizar testes em nome de outrem;</li>
          <li>Comprometer a segurança da DEGRAU, como tentar sondar, escanear ou testar vulnerabilidades do sistema ou rede, ou violar as medidas de segurança ou autenticação;</li>
          <li>Falsificar cabeçalhos TCP/IP ou qualquer parte da informação de cabeçalho em emails ou postagens;</li>
          <li>Replicar ou duplicar partes da plataforma DEGRAU;</li>
          <li>Modificar ou disponibilizar o link para qualquer Conteúdo DEGRAU ou informações na plataforma, a menos que permitido por estes Termos;</li>
          <li>Publicar conteúdos ou materiais que promovam informações falsas, enganosas ou atividades ilegais;</li>
          <li>Acessar dados não autorizados, servidores ou contas;</li>
          <li>Publicar conteúdos contendo páginas restritas ou senha, ou páginas ou imagens ocultas;</li>
          <li>Interferir nos serviços a outros USUÁRIOS, administradores ou redes;</li>
          <li>Utilizar a Plataforma DEGRAU para atividades ilegais ou publicar conteúdo difamatório, ofensivo, vulgar, obsceno, ameaçador, abusivo, racista ou discriminatório.</li>
        </ul>
        <p>Violações à segurança do sistema e da rede podem levar a ações civis e/ou criminais. A DEGRAU investigará tais ocorrências e cooperará com autoridades legais na punição de USUÁRIOS envolvidos em tais violações. Falsidade ideológica ou fraude na Plataforma DEGRAU pode resultar no término da relação com o USUÁRIO.</p>
        <p>Candidatos(as) podem entrar em contato com o canal de suporte da DEGRAU pelo email suporte@degrau.co.</p>
        <h3>Responsabilidades da DEGRAU</h3>
        <p>A DEGRAU se compromete a:</p>
        <ul>
          <li>Empregar seus melhores esforços na prestação dos Serviços, utilizando as melhores práticas e técnicas disponíveis, visando sempre à satisfação das EMPRESAS;</li>
          <li>Implementar medidas de segurança da informação e proteção de dados pessoais, em conformidade com a legislação aplicável.</li>
        </ul>
        <p>A DEGRAU NÃO se responsabiliza por:</p>
        <ul>
          <li>Vagas abertas pelas EMPRESAS, não sendo a DEGRAU responsável pela qualidade, segurança ou legalidade das vagas ofertadas, a veracidade ou a precisão das listagens e habilidade das empresas em oferecer oportunidades de vagas para Candidatos(as);</li>
          <li>Dados fornecidos pelos(as) Candidatos(as), não podendo a DEGRAU ser responsabilizada por equívoco no preenchimento dos dados ou não veracidade destes e pelas consequências que possam decorrer disso, como a não seleção do(a) Candidato(a) para determinado processo seletivo, desqualificação posterior de processo seletivo em que estava participando;</li>
          <li>Seleção e escolha dos(as) Candidatos(as) no processo seletivo, que são de responsabilidade exclusiva das EMPRESAS.</li>
        </ul>
        <h3>Aceitação dos Termos</h3>
        <p>Ao aceitar estes Termos e iniciar o uso da plataforma DEGRAU, você se compromete a respeitar todas as condições aqui expostas e a usar os Serviços de maneira legal e proveitosa.</p>
        <h3>Foro</h3>
        <p>Quaisquer conflitos oriundos destes Termos serão resolvidos no Foro Central da Comarca de São Paulo/SP.</p>
      </div>      
    ),
  };

  const LegalText = ({ variant }) => {
    const textContent = content[variant];
  
    return (
      <div className={styles.legalTextContainer}> 
        {textContent}
      </div>
    );
  };
  
  export default LegalText;
