// Form for user to login. Requires email and password. Also includes a link to the forgot password page and a link to the request access page if they arrived from a report.
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "../common/Button";
import Logo from "../common/DegrauLogo";
import InputField from "../inputs/InputField";
import PasswordInputField from "../inputs/PasswordInputField";
import styles from "./Popup.module.css";

const LoginContent = ({
  email,
  setEmail,
  password,
  setPassword,
  handleAction,
  setVariant,
  errorMessage,
  successMessage,
  showRequestAccess,
  onLoad,
  logoLoaded,
  ...otherProps
}) => { 

  return (
    <>
      <Logo className={styles.degrauLogoPopupLarge} onLoad={onLoad} />
      {logoLoaded ? (
        <>
          <p className={styles.popupTextLarge}>
            Bem-vindo de volta! Por favor, faça login.
          </p>
          <form
            className={styles.inputFieldContainer}
            onSubmit={(e) => {
              e.preventDefault(); // Prevent the default form submission
              handleAction(); // Call the custom action handler
            }}
          >
            <div className={styles.inputFieldAndLabel}>
              <label htmlFor="email">Email</label>
              <InputField
                id="email"
                type="email"
                autocomplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder=""
                aria-required="true"
              />
            </div>
            <div className={styles.inputFieldAndLabel}>
              <label htmlFor="password">Senha</label>
              <div className={styles.passwordInputContainer}>
                <PasswordInputField
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder=""
                />
              </div>
            </div>
            <Button
              disabled={!email || !password}
              type="form"
            >
              Login
            </Button>
          </form>
          {errorMessage && (
            <p className={styles.errorText} role="alert">
              {errorMessage}
            </p>
          )}
          {successMessage && (
            <p className={styles.successText} role="alert">
              {successMessage}
            </p>
          )}
          <p
            className={styles.forgotPasswordText}
            onClick={() => setVariant("ForgotPassword")}
            role="button"
            tabIndex="0"
            aria-pressed="false"
          >
            Esqueci a minha senha
          </p>
          {showRequestAccess ? (            
            <p
              className={styles.forgotPasswordText}
              onClick={() => setVariant("RequestAccess")}
              role="button"
              tabIndex="0"
              aria-pressed="false"
            >
              Solicitar acesso
            </p>
          ): null}
        </>
      ) : null}
    </>
  );
};

LoginContent.propTypes = {
  email: PropTypes.string.isRequired,
  setEmail: PropTypes.func.isRequired,
  password: PropTypes.string.isRequired,
  setPassword: PropTypes.func.isRequired,
  handleAction: PropTypes.func.isRequired,
  setVariant: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  successMessage: PropTypes.string,
  showRequestAccess: PropTypes.bool,
};

export default LoginContent;
