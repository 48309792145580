import React from 'react';
import styles from "./CompanyPageSidebar.module.css";

const CompanyPageSidebar = ({ header, children, isVisible}) => {
    return (
        <>
            {isVisible && (
                <div className={styles.sidebarContainer}>
                    <div className={styles.sidebarHeader}>
                        {header}
                    </div>
                    {children}
                </div>
            )}
        </>
    );
};

export default CompanyPageSidebar;
