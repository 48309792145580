import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Button from '../common/Button';
import FadeLoader from '../common/FadeLoader';
import styles from './AdminModal.module.css';

const AdminModal = ({ 
    title, 
    children, 
    onClose, 
    onConfirm, 
    errorMessage,
    successMessage,
    isLoading,
    confirmLabel = "Confirmar", // Default value for confirmLabel
    secondaryAction,  // Optional secondary action
}) => {
    const modalRef = useRef(null);
    const closeButtonRef = useRef(null);

    useEffect(() => {
        const handleKeyDown = (e) => {
            const focusableElements = modalRef.current?.querySelectorAll(
                'a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])'
            ) || [];
    
            const firstFocusableElement = focusableElements[0];
            const lastFocusableElement = focusableElements[focusableElements.length - 1];
    
            const activeElementTag = document.activeElement.tagName.toLowerCase();
            const activeElementRole = document.activeElement.getAttribute('role');
    
            if (['ArrowDown', 'ArrowUp', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
                if (activeElementTag === 'select' || activeElementRole === 'menu') {
                    return;
                }
            }
    
            if (e.key === 'Escape') {
                onClose();
            }
    
            if (e.key === 'Tab') {
                if (e.shiftKey) {
                    if (document.activeElement === firstFocusableElement) {
                        e.preventDefault();
                        lastFocusableElement?.focus();
                    }
                } else {
                    if (document.activeElement === lastFocusableElement) {
                        e.preventDefault();
                        firstFocusableElement?.focus();
                    }
                }
            }
        };
    
        document.addEventListener('keydown', handleKeyDown);
    
        // Focus on the modal container when it opens
        if (modalRef.current) {
            modalRef.current.focus();
        }
    
        return () => {
            if (modalRef.current) {
                document.removeEventListener('keydown', handleKeyDown);
            }
        };
    }, []);
    
    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    return (
        <div 
            className={styles.modalOverlay} 
            role="dialog" 
            aria-modal="true" 
            onClick={handleOverlayClick} 
        >
            <div 
                className={styles.modalContainer} 
                ref={modalRef} 
                tabIndex="-1" // Make the modal container focusable
            >
                <div className={styles.modalHeader}>
                    <h2>{title}</h2>
                    <button 
                        ref={closeButtonRef} 
                        className={styles.closeButton} 
                        onClick={onClose} 
                        aria-label="Close Modal"
                    >
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                </div>
                <div className={styles.modalBody}>
                    {isLoading ? (
                        <FadeLoader text="" height="200px" />
                    ) : (
                        children
                    )}
                    {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
                    {successMessage && <div className={styles.successMessage}>{successMessage}</div>}
                </div>
                <div className={`${styles.modalFooter} ${secondaryAction ? styles.twoButtons : ''}`}>
                    <Button type="proceed" onClick={onConfirm} disabled={isLoading}>
                        {isLoading ? "Processando..." : confirmLabel}
                    </Button>
                    {secondaryAction && (
                        <Button 
                            type="cancel" 
                            onClick={secondaryAction.onClick} 
                            disabled={isLoading}
                        >
                            {secondaryAction.label}
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};

AdminModal.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,  
    isLoading: PropTypes.bool, 
    secondaryAction: PropTypes.shape({ 
        onClick: PropTypes.func.isRequired,
        label: PropTypes.string.isRequired,
    }),
};

export default AdminModal;
