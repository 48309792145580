import React from 'react';
import Logo from '../common/DegrauLogo';
import styles from './Popup.module.css'; 

const EndTestContent = ({ onLoad, logoLoaded, ...otherProps }) => {
    const englishMode = false; // Set to true to display in English (this will be imported from the AppContext)
    return (
        <>
            <Logo className={styles.degrauLogoPopup} onLoad={onLoad} />
            <div>
                {englishMode ? (
                    <>
                    <p className={styles.popupTextLarge}>
                        Your test was successfully sent, congrats!
                    </p>
                    <p className={styles.popupText}>
                        Your opinion is very valuable to us to help us improve, please click the button below to leave your feedback.
                    </p>
                    </>
                ) : (
                    <>
                    <p className={styles.popupTextLarge}>
                        Seu teste foi enviado com sucesso, parabéns!
                    </p>
                    <p className={styles.popupText}>
                        Sua opinião é extremamente valiosa para nós. Por favor, clique no link abaixo para nos fornecer seu feedback anonimamente.
                    </p>
                    </>
                )}
            </div>
        </>
    );
};

export default EndTestContent;
