//Timer for the test
import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import styles from './Timer.module.css';
import { useAppContext } from '../../contexts/AppContext';
import { useTestContext } from '../../contexts/TestContext';

const Timer = () => {
    const { isInitialTimeUp } = useAppContext();
    const { timeLeft, isTestStarted, sectionTimeLimit } = useTestContext();

    const formatTime = (time) => {
        time = Math.max(Math.round(time), 0);  // Round and ensure it's not negative
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    // Decide whether to show time left or total time based on whether the test has started
    let displayTime;
    displayTime = isTestStarted ? formatTime(timeLeft) : formatTime(sectionTimeLimit.value);

    // Change style if time left is 3 minutes or less if its in the coding challenge
    const timerStyle = (isTestStarted && timeLeft <= 180) || isInitialTimeUp ? styles.timerContainerRed : styles.timerContainer;

    return (
        <div className={timerStyle}>
            <FontAwesomeIcon icon={faClock} className={styles.clockIcon} />
            {displayTime}
        </div>
    );
};

export default Timer;


