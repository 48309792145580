import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './HoverPopout.module.css';

const HoverPopout = ({ text, heading, position }) => {
  const [minWidth, setMinWidth] = useState(0);
  const [maxWidth, setMaxWidth] = useState(200);
  const [shouldWrap, setShouldWrap] = useState(false);
  const textRef = useRef(null);

  useEffect(() => {
    if (textRef.current) {
      // Disable wrapping to measure the actual width
      setShouldWrap(false);

      const textWidth = textRef.current.scrollWidth; // Measure the width of the text

      if (textWidth < 200) {
        setMinWidth(textWidth);
        setMaxWidth(textWidth);
      } else {
        setMinWidth(200);
        setMaxWidth(200);
        setShouldWrap(true); // Enable wrapping if the text is wider than 200px
      }
    }
  }, [text]);

  return (
    <div
      className={`${styles.hoverMessage} ${styles[position]}`}
      style={{ minWidth: `${minWidth}px`, maxWidth: `${maxWidth}px` }}
    >
      {heading && <div className={styles.hoverPopoutHeading}>{heading}</div>}
      <div
        ref={textRef}
        className={`${styles.hoverPopoutText} ${shouldWrap ? styles.wrapText : styles.noWrapText}`}
      >
        {text}
      </div>
    </div>
  );
};

HoverPopout.propTypes = {
  text: PropTypes.string.isRequired,
  heading: PropTypes.string,
  position: PropTypes.oneOf(['left', 'right']).isRequired,
};

export default HoverPopout;
