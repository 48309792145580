import axios from "axios";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import Button from "../components/common/Button";
import {
  getAllConfigs,
  getConfig,
  getHistoricalConfigs,
  updateConfig,
} from "../services/databaseService";
import { logException } from "../services/loggerFront";
import styles from "./AdminPage.module.css";

const AdminPage = () => {
  // Macro settings
  const [viewType, setViewType] = useState("column"); // Select to view configs stacked or side-by-side
  const [configType, setConfigType] = useState("test"); // Select between test or chat or instructions configs

  // Main config
  const [identifiers, setIdentifiers] = useState([]); // List of config identifiers for the user to select from to choose which config to view/edit
  const [environments, setEnvironments] = useState([]); // List of environments for the user to select from to choose which specific config to view/edit
  const [selectedIdentifier, setSelectedIdentifier] = useState(""); // The selected identifier from the main dropdown
  const [selectedEnvironment, setSelectedEnvironment] = useState(""); // The selected environment from the main dropdown
  const [currentConfig, setCurrentConfig] = useState(""); // The resulting main config to be edited based on the selections
  const [currentTimestamp, setCurrentTimestamp] = useState(""); // Timestamp of when the current config was last updated
  const [currentCommitMessage, setCurrentCommitMessage] = useState(""); // Commit message of the current config

  // Saving the main config
  const [commitMessage, setCommitMessage] = useState(""); // NEw commit message to be added when the user saves the config
  const [status, setStatus] = useState(""); // Message to display after saving config

  // Secondary config
  const [comparisonConfigType, setComparisonConfigType] = useState("current"); // Allow user to select between comparing to current or historical configs

  // Historical configs
  const [maxHistoricalConfigs, setMaxHistoricalConfigs] = useState(5); // The maximum number of historical configs to display to reduce clutter and improve performance, the user can change this value
  const [selectedHistoricalConfig, setSelectedHistoricalConfig] = useState(""); // The resulting historical config selected by the user for comparison
  const [historicalConfigs, setHistoricalConfigs] = useState([]); // The set of historical configs for the selected identifier/environment

  // Current configs for comparison
  const [selectedComparisonIdentifier, setSelectedComparisonIdentifier] =
    useState(""); // The selected identifier from the secondary dropdown
  const [selectedComparisonEnvironment, setSelectedComparisonEnvironment] =
    useState(""); // The selected environment from the secondary dropdown
  const [comparisonConfig, setComparisonConfig] = useState(""); // The resulting comparison config selected by the user for comparison
  const [comparisonTimestamp, setComparisonTimestamp] = useState(""); // Timestamp of the comparison config update
  const [comparisonCommitMessage, setComparisonCommitMessage] = useState(""); // Commit message of the comparison config update

  // Other settings
  const fieldsToConvert = [
    "sqlDefaultCode",
    "ideDefaultCode",
    "ideDependencies",
    "systemPrompt",
    "closingPrompt",
    "initialDatabaseSchema",
    "userPrompt",
    "assistantPrompt",
  ]; // Fields to convert newlines to escaped newlines

  const fileName = "AdminPage.js"; // for logging

  // HELPER FUNCTIONS TO FORMAT DATA //

  // Format time
  const formatTimestamp = (timestamp) => {
    return format(new Date(timestamp), "yyyy-MM-dd HH:mm:ss");
  };

  const convertNewlinesToBreaks = (text) => {
    return text.replace(/\\n/g, "\n");
  };

  const convertNewlinesToActualNewlines = (text) => {
    return text.replace(/\\n/g, "\n");
  };

  const escapeNewlinesInString = (str) => {
    return str.replace(/\n/g, "\\n");
  };

  const unescapeNewlinesInString = (str) => {
    return str.replace(/\\n/g, "\n");
  };

  const transformNewlinesInFields = (jsonString, fields) => {
    const regex = /"(.*?[^\\])"/gs;

    const transformString = (str, regex, transformFunc) => {
      return str.replace(regex, (match, p1) => {
        const transformedValue = transformFunc(p1);
        return match.replace(p1, transformedValue);
      });
    };

    let updatedString = jsonString;

    // Escape all newlines to make the JSON string compatible
    updatedString = transformString(
      updatedString,
      regex,
      escapeNewlinesInString
    );

    let jsonObject;
    try {
      jsonObject = JSON.parse(updatedString);
    } catch (e) {
      console.error("Error parsing config JSON:", e);
      return jsonString;
    }

    const replaceInObject = (obj, fields, transformFunc) => {
      for (const key in obj) {
        if (fields.includes(key)) {
          if (typeof obj[key] === "string") {
            obj[key] = transformFunc(obj[key]);
          } else if (typeof obj[key] === "object" && obj[key] !== null) {
            replaceInObject(obj[key], fields, transformFunc);
          }
        } else if (typeof obj[key] === "object" && obj[key] !== null) {
          replaceInObject(obj[key], fields, transformFunc);
        }
      }
    };

    // Unescape newlines in specified fields
    replaceInObject(jsonObject, fields, unescapeNewlinesInString);

    return JSON.stringify(jsonObject, null, 2);
  };

  // AUTHENTICATION //

  // Trigger the authentication middleware
  useEffect(() => {
    axios
      .get("/admin")
      .then()
      .catch((error) => console.error("Error accessing admin route", error));
  }, []);

  // GATHERING DATA //

  // Fetch all identifiers and environments when component mounts to feed the dropdown list
  useEffect(() => {
    if (configType) {
      getAllConfigs(configType) // Fetch all identifiers and envs for the selected config type
        .then((data) => {
          const uniqueIdentifiers = [
            ...new Set(data.map((config) => config.identifier)),
          ]; // Extract unique identifiers
          setIdentifiers(uniqueIdentifiers); // Set so it can appear in the dropwdown for the user to select

          const environmentsByIdentifier = uniqueIdentifiers.reduce(
            (acc, identifier) => {
              // Extract environments for each identifier
              acc[identifier] = data
                .filter((config) => config.identifier === identifier)
                .map((config) => config.environment);
              return acc;
            },
            {}
          );

          setEnvironments(environmentsByIdentifier); // Set so it can appear in the dropdown for the user to select
        })
        .catch((error) => {
          console.error("Error fetching identifiers:", error);
          logException(error, "Error fetching identifiers", {
            errorMessage: error.message,
            errorStack: error.stack,
            errorResponse: error.response,
            fileName: fileName,
          }); // Log the error to the console and Sentry
          if (error.response && error.response.status === 401) {
            window.location.href = "/login";
          }
        });
    }
  }, [configType]);

  // Fetch the configuration for the selected role
  const fetchConfig = async (
    identifier,
    environment,
    setConfig,
    setCommitMessage,
    setTimestamp
  ) => {
    try {
      const data = await getConfig(configType, identifier, environment);

      // Check if data is an array or a single object
      let config;
      if (Array.isArray(data)) {
        config = data[0].config;
        setCommitMessage(data[0].commit_message);
        setTimestamp(formatTimestamp(data[0].updated_at));
      } else {
        config = data.config;
        setCommitMessage(data.commit_message);
        setTimestamp(formatTimestamp(data.updated_at));
      }

      // Stringify the config
      let stringifiedConfig = JSON.stringify(config, null, 2);

      // Convert \n to actual newlines for display
      stringifiedConfig = convertNewlinesToActualNewlines(stringifiedConfig);

      setConfig(stringifiedConfig);
    } catch (error) {
      console.error("Error fetching config:", error);
      if (error.response && error.response.status === 401) {
        window.location.href = "/login";
      }
    }
  };

  // Fetch the historical configurations for the selected role
  const fetchHistoricalConfigs = async (
    identifier,
    environment,
    maxHistoricalConfigs,
    setHistoricalConfigs
  ) => {
    try {
      const data = await getHistoricalConfigs(
        configType,
        identifier,
        environment,
        maxHistoricalConfigs
      );
      const formattedHistoricalConfigs = data.map((config) => ({
        ...config,
        config: convertNewlinesToBreaks(JSON.stringify(config.config, null, 2)),
      }));
      setHistoricalConfigs(formattedHistoricalConfigs);
    } catch (error) {
      console.error("Error fetching historical configs:", error);
    }
  };

  // Update the comparison config details when the historical config is selected
  useEffect(() => {
    // set the comparison commit message and timestamp
    if (selectedHistoricalConfig !== "") {
      const historicalConfig = historicalConfigs[selectedHistoricalConfig];
      setComparisonCommitMessage(historicalConfig.commit_message);
      setComparisonTimestamp(formatTimestamp(historicalConfig.created_at));
    }
  }, [selectedHistoricalConfig]);

  // Call the fetchConfig and fetchHistoricalConfigs functions when the user selects a role code in the dropdown
  useEffect(() => {
    if (selectedIdentifier && selectedEnvironment) {
      fetchConfig(
        selectedIdentifier,
        selectedEnvironment,
        setCurrentConfig,
        setCurrentCommitMessage,
        setCurrentTimestamp
      );
      fetchHistoricalConfigs(
        selectedIdentifier,
        selectedEnvironment,
        maxHistoricalConfigs,
        setHistoricalConfigs
      );
    } else {
      setCurrentConfig("");
      setHistoricalConfigs([]);
    }
  }, [selectedIdentifier, selectedEnvironment]);

  // Fetch the configuration for the selected current comparison role and environment
  useEffect(() => {
    if (selectedComparisonIdentifier && selectedComparisonEnvironment) {
      fetchConfig(
        selectedComparisonIdentifier,
        selectedComparisonEnvironment,
        setComparisonConfig,
        setComparisonCommitMessage,
        setComparisonTimestamp
      );
    } else {
      setComparisonConfig("");
    }
  }, [selectedComparisonIdentifier, selectedComparisonEnvironment]);

  // SAVING //

  // Update the selected role configuration
  const handleSave = () => {
    if (selectedIdentifier && selectedEnvironment) {
      const userConfirmed = window.confirm(
        "Are you sure you want to save this configuration?"
      ); // Prompt the user to confirm the save action
      if (userConfirmed) {
        try {
          // Manually handle the conversion for the specific fields
          const updatedString = transformNewlinesInFields(
            currentConfig,
            fieldsToConvert
          );

          // Send the stringified config to the backend
          updateConfig(
            configType,
            selectedIdentifier,
            updatedString,
            selectedEnvironment,
            commitMessage
          )
            .then(() => {
              setStatus("Configuration updated successfully");
              setTimeout(() => setStatus(""), 10000);
            })
            .catch((error) => {
              console.error("Error updating config:", error);
              setStatus(
                "Failed to update configuration, view console for details"
              );
            });
        } catch (error) {
          console.error("Error parsing config JSON:", error);
          setStatus("Failed to parse configuration, view console for details");
          setTimeout(() => setStatus(""), 10000);
        }
      }
    }
  };

  // BUTTON TOGGLES //

  const handleComparisonConfigTypeChange = (e) => {
    setComparisonConfigType(e.target.value);
  };

  return (
    <div className={styles.adminPage}>
      <h1 className={styles.header}>Admin Page</h1>

      {/* Dropdown for config type */}
      <Dropdown
        label="Config Type:"
        options={[
          { value: "test", label: "Test" },
          { value: "chat", label: "Chat" },
        ]}
        value={configType}
        onChange={(e) => {
          setConfigType(e.target.value);
          setSelectedIdentifier(""); // Reset selected identifier
          setSelectedEnvironment(""); // Reset selected environment
        }}
      />

      {/* Dropdown for view type */}
      <Dropdown
        label="View:"
        options={[
          { value: "row", label: "Side-by-side" },
          { value: "column", label: "Stacked" },
        ]}
        value={viewType}
        onChange={(e) => setViewType(e.target.value)}
      />

      <div
        className={`${styles.outerContainer} ${
          viewType === "row" ? styles.layoutRow : styles.layoutColumn
        }`}
      >
        {/* The main config */}
        <div className={styles.mainConfigContainer}>
          {/* Select the identifier */}
          <Dropdown
            label="Select identifier:"
            options={identifiers.map((identifier) => ({
              value: identifier,
              label: identifier,
            }))}
            value={selectedIdentifier}
            onChange={(e) => {
              setSelectedIdentifier(e.target.value);
              setSelectedEnvironment(null); // Reset the environment when identifier changes
            }}
            defaultOption="--Select identifier--"
          />
          {/* Select the env */}
          {selectedIdentifier && (
            <Dropdown
              label="Select environment:"
              options={
                selectedIdentifier && environments[selectedIdentifier]
                  ? environments[selectedIdentifier].map((environment) => ({
                      value: environment,
                      label: environment,
                    }))
                  : []
              }
              value={selectedEnvironment}
              onChange={(e) => setSelectedEnvironment(e.target.value)}
              defaultOption="--Select environment--"
            />
          )}

          {/* Show the main config */}
          {selectedIdentifier && selectedEnvironment && (
            <div className={styles.configContainer}>
              <h4 className={styles.configHeader}>
                Current config for {selectedIdentifier} ({selectedEnvironment})
              </h4>
              <p>Updated at: {currentTimestamp}</p>
              <p>Commit message: {currentCommitMessage}</p>
              <ConfigDisplay
                config={currentConfig}
                isEditable={true}
                onChange={(e) => setCurrentConfig(e.target.value)}
              />
              <input
                type="text"
                className={styles.commitMessage}
                placeholder="Add New Commit message"
                value={commitMessage}
                onChange={(e) => setCommitMessage(e.target.value)}
              />
              <Button onClick={handleSave}>Save Configuration</Button>
              {status && <p className={styles.statusMessage}>{status}</p>}
            </div>
          )}
        </div>

        {/* Comparison config */}
        <div className={styles.comparisonConfigContainer}>
          {/* Select whether to compare current or historical configs */}
          {currentConfig && (
            <div className={styles.comparisonDropdownContainer}>
              <Dropdown
                label="Select comparison type:"
                options={[
                  { value: "current", label: "Current" },
                  { value: "historical", label: "Historical" },
                ]}
                value={comparisonConfigType}
                onChange={handleComparisonConfigTypeChange}
                defaultOption="--Select comparison type--"
              />
            </div>
          )}

          {/* Historical configs */}
          {comparisonConfigType === "historical" &&
            historicalConfigs.length > 0 && (
              <div className={styles.outerConfigContainer}>
                <div className={styles.selectContainer}>
                  {/* Select based on the date */}
                  <Dropdown
                    label="Select historical config:"
                    options={historicalConfigs.map((config, index) => ({
                      value: index,
                      label: new Date(config.created_at).toLocaleString(),
                    }))}
                    value={selectedHistoricalConfig}
                    onChange={(e) =>
                      setSelectedHistoricalConfig(e.target.value)
                    }
                    defaultOption="--Select historical config--"
                  />
                  {/* Select the n of configs to load */}
                  <label className={styles.selectLabel}>
                    Load X historical configs:
                  </label>
                  <input
                    type="number"
                    className={styles.input}
                    value={maxHistoricalConfigs}
                    onChange={(e) => setMaxHistoricalConfigs(e.target.value)}
                  />
                </div>

                {/* Show the config */}
                {selectedHistoricalConfig !== "" && (
                  <div className={styles.configContainer}>
                    <h4 className={styles.configHeader}>
                      Historical Config for {selectedIdentifier} (
                      {selectedEnvironment})
                    </h4>
                    <p>Updated at: {comparisonTimestamp}</p>
                    <p>Commit message: {comparisonCommitMessage}</p>
                    <ConfigDisplay
                      config={
                        historicalConfigs[selectedHistoricalConfig].config
                      }
                      isEditable={false}
                    />
                  </div>
                )}
              </div>
            )}

          {/* Current configs for comparison */}
          {comparisonConfigType === "current" && currentConfig && (
            <div className={styles.outerConfigContainer}>
              {/* Select the identifier */}
              <Dropdown
                label="Select comparison config:"
                options={identifiers.map((identifier) => ({
                  value: identifier,
                  label: identifier,
                }))}
                value={selectedComparisonIdentifier}
                onChange={(e) =>
                  setSelectedComparisonIdentifier(e.target.value)
                }
                defaultOption="--Select comparison identifier--"
              />
              {/* Select the env */}
              {selectedComparisonIdentifier && (
                <Dropdown
                  label="Select comparison environment:"
                  options={
                    selectedComparisonIdentifier &&
                    environments[selectedComparisonIdentifier]
                      ? environments[selectedComparisonIdentifier].map(
                          (environment) => ({
                            value: environment,
                            label: environment,
                          })
                        )
                      : []
                  }
                  value={selectedComparisonEnvironment}
                  onChange={(e) =>
                    setSelectedComparisonEnvironment(e.target.value)
                  }
                  defaultOption="--Select comparison environment--"
                />
              )}

              {/* Show the config */}
              {selectedComparisonIdentifier &&
                selectedComparisonEnvironment && (
                  <div className={styles.configContainer}>
                    <h4 className={styles.configHeader}>
                      Current Config for {selectedComparisonIdentifier} (
                      {selectedComparisonEnvironment})
                    </h4>
                    <p>Updated at: {comparisonTimestamp}</p>
                    <p>Commit message: {comparisonCommitMessage}</p>
                    <ConfigDisplay
                      config={comparisonConfig}
                      isEditable={false}
                    />
                  </div>
                )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const Dropdown = ({ label, options, value, onChange, defaultOption }) => (
  <div className={styles.selectContainer}>
    <label className={styles.selectLabel}>{label}</label>
    <select className={styles.selectDropdown} value={value} onChange={onChange}>
      <option value="">{defaultOption}</option>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  </div>
);

const ConfigDisplay = ({ config, isEditable, onChange }) => (
  <textarea
    className={styles.textarea}
    value={config}
    onChange={isEditable ? onChange : null}
    readOnly={!isEditable}
    rows="20"
    cols="80"
    style={{ whiteSpace: "pre-wrap" }} // Ensure newlines are rendered properly
  />
);

export default AdminPage;
