import React, { useState } from 'react';
import Logo from '../common/DegrauLogo';
import styles from './Popup.module.css';

const CameraPermissionContent = ({ onLoad, logoLoaded, ...otherProps }) => {
    const [showGuide, setShowGuide] = useState(false);

    const toggleGuide = () => {
        setShowGuide(!showGuide);
    };

    return (
        <div className={styles.popup}>
            <Logo className={styles.degrauLogoPopup} onLoad={onLoad} />

            <div className={styles.cameraPermissionWrapper}>
                <p>Para fazer este teste você precisa habilitar sua câmera para validar sua identidade.</p>
                <p onClick={toggleGuide}>Se você recusou a permissão da câmera para este site, <span className={styles.showGuide}>leia este guia</span> sobre como ativá-la.</p>
                <p>Se o seu dispositivo não tiver câmera, reinicie o teste com um dispositivo que tenha.</p>
                <p>Se o problema persistir, por favor, limpe o cache do seu navegador e recarregue a página ou relatar problema</p>
            </div>

            {showGuide && (
                <div className={styles.guideModal}>
                    <div className={styles.modalContent}>
                        <span className={styles.close} onClick={toggleGuide}>×</span>
                        <h3>Como Ativar a Câmera</h3>
                        
                            <h4>Google Chrome:</h4>
                        <ol>
                            <li>Na barra de endereços, localize o ícone de cadeado à esquerda do URL.</li>
                            <li>Clique no ícone e aparecerá um menu dropdown.</li>
                            <li>Localize a opção “Câmera” e mude para “Permitir”.</li>
                            <li>Recarregue a página e tente novamente.</li>
                        </ol>
                        
                        <h4>Firefox:</h4>
                        <ol>
                            <li>Na barra de endereços, localize o ícone de cadeado à esquerda do URL.</li>
                            <li>Clique no ícone e depois em “Configurações de Permissões”.</li>
                            <li>Na seção “Câmera”, selecione “Permitir”.</li>
                            <li>Recarregue a página e tente novamente.</li>
                        </ol>
                        
                        <h4>Microsoft Edge:</h4>
                        <ol>
                            <li>Na barra de endereços, localize o ícone de cadeado à esquerda do URL.</li>
                            <li>Clique no ícone e aparecerá um menu dropdown.</li>
                            <li>Localize a opção “Câmera” e mude para “Permitir”.</li>
                            <li>Recarregue a página e tente novamente.</li>
                        </ol>
                
                        <h4>Safari:</h4>
                        <ol>
                            <li>No menu “Safari”, escolha “Preferências”.</li>
                            <li>Clique em “Websites” na aba superior.</li>
                            <li>Na coluna da esquerda, clique em “Câmera”.</li>
                            <li>Localize este site na lista e selecione “Permitir” no menu dropdown à direita.</li>
                            <li>Feche as preferências e recarregue a página.</li>
                        </ol>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CameraPermissionContent;
