// Context to decide which tooltips should be shown. It adds or remove a tooltip to the state activeTooltips when its conditions are met or unmet. When added to activeTooltips, components which use the context can then show the tooltip in the UI
// TODO: remove timerWarning states and pass timeLeft for UseTimer hook to have more dynamic time warnings
// TODO: add the setIsTestStarted to the last tooltip in a more scalable manner
import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import { useAppContext } from './AppContext';
import { useTestContext } from './TestContext';

export const TooltipContext = createContext();

export const TooltipProvider = ({ children }) => {
    const { retrievedSession, testStage, setIsTestStarted, isFollowUp, is1MinsLeft, is10MinsLeft, timeAdded, setTimeAdded, currentSection, accessibilityMode } = useTestContext();
    const [activeTooltips, setActiveTooltips] = useState({}); // State to store which tooltips are currently active which is passed to components using the context
    const [tooltipTimers, setTooltipTimers] = useState({}); // Enables delayed tooltip activation
    const [ followUpTimer, setFollowUpTimer ] = useState(false); // Enable to activiation of the follow-up timer tooltip
    const followUpTimerRef = useRef(followUpTimer); 
    const [ timerWarning1, setTimerWarning1 ] = useState(false); // Enable the activation of the 10 mins left tooltip
    const timerWarning1Ref = useRef(timerWarning1);
    const [ timerWarning2, setTimerWarning2 ] = useState(false); // Enable the activation of the 1 min left tooltip
    const timerWarning2Ref = useRef(timerWarning2);
    const [ tooltipSequence, setTooltipSequence ] = useState([]); // Store the sequence of tooltips to show in a particular section, mainly used to do UI tours
    const [ finalTooltips, setFinalTooltips ] = useState([]); // Store the final tooltips to show after the sequence is completed
    const testStageRef = useRef(testStage); // Ref to store the previous testStage value

    //each variants rules
    const baseTooltips = {
        // ReportIssue box
        reportIssue: {
            title: 'Relatar Problema',
            text: 'Se você tiver um problema, pode comunicá-lo aqui.',
            arrowType: 'arrowBottomRight',
            condition: () => testStageRef.current === 'introChat',
        },
        // ChatInput box
        userInputIntro: {
            title: 'Área de Resposta',
            text: 'Você pode responder aqui.',
            arrowType: 'arrowBottom',
            condition: () => testStageRef.current === 'introChat' && !accessibilityMode,
            delay: 30000, // 30 seconds
        },
        userInputTest: {
            title: 'Interagir com a IA',
            text: 'Você pode conversar com a IA aqui para entender melhor o desafio.',
            arrowType: 'arrowBottom',
            condition: () => testStageRef.current === 'test',
            delay: 240000, // 4 minutes
        },
        // Timer
        timer: {
            title: 'Timer',
            text: 'Aqui você pode ver quanto tempo falta. O tempo começará depois que você terminar a introdução. Você terá a oportunidade de explicar o que faria se tivesse mais tempo depois.',
            arrowType: 'arrowTopRight',
            condition: () => !retrievedSession && testStageRef.current === 'test' && tooltipSequence.includes('timer'),
        },
        /* no longer using
        retrievedTimer: {
            title: 'Timer',
            text: 'Aqui você pode ver quanto tempo falta para completar o teste.',
            arrowType: 'arrowTopRight',
            condition: () => retrievedSession && !accessibilityMode && testStageRef.current === 'test',
            buttonAction: () => setIsTestStarted(true),
        },
        */
        followUpTimer: {
            title: 'Alerta de Tempo',
            text: 'Seu tempo foi atualizado para você responder as perguntas.',
            arrowType: 'arrowTopRight',
            condition: () => followUpTimerRef.current && !retrievedSession,
        }, 
        timerWarning1: {
            title: 'Alerta de Tempo',
            text: 'Você está chegando ao fim. Quando o tempo acabar, o código no editor será enviado.',
            arrowType: 'arrowTopRight',
            condition: () => timerWarning1Ref.current && is10MinsLeft,
        }, 
        timerWarning2: {
            title: 'Alerta de Tempo',
            text: 'Quando o tempo acabar, a resposta será enviado',
            arrowType: 'arrowTopRight',
            condition: () => timerWarning2Ref.current && is1MinsLeft,
        },
        addedTime: {
            title: 'Alerta de Tempo',
            text: 'Adicionamos algum tempo para você terminar esta seção',
            arrowType: 'arrowTopRight',
            condition: () => timeAdded,
        },
        // Test UI Tour
        tabs: {
            title: 'Alternar Abas',
            text: 'Alterne entre diferentes ferramentas aqui.',
            arrowType: 'arrowTop',
            condition: () => tooltipSequence.includes('tabs'),
        },
        ideLanguage: {
            title: 'Seleção de Linguagem',
            text: 'Selecione sua linguagem de programação aqui. Você pode usar as bibliotecas padrão para cada linguagem.',
            arrowType: 'arrowTop',
            condition: () => tooltipSequence.includes('ideLanguage'),
        },
        codeEditor: {
            title: 'Editor de Código',
            text: 'Este é o seu ambiente de programação onde você pode completar o desafio. Não é permitido colar código de fora.',
            arrowType: 'arrowTop',
            condition: () => tooltipSequence.includes('codeEditor'),
        },       
        ideExecute: {
            title: 'Submeter Código',
            text: 'Quando estiver pronto, utilize este botão para enviar seu código.',
            arrowType: 'arrowBottomRight',
            condition: () => tooltipSequence.includes('ideExecute'),
        },  
        //skipping//
        ideSubmit: {
            title: 'Submeter Código',
            text: 'Quando estiver pronto, pressione aqui para enviar seu código.',
            arrowType: 'arrowTopRight',
            condition: () => tooltipSequence.includes('ideSubmit'),
        },
        // Add more tooltips as needed
    };

    // When the currentSection updates, set the timer and sequence tooltip states which control the activation of the tooltips
    useEffect(() => {
        if (currentSection) {
            // Time warning states show popups when specific time milestones are reached unless it is accessibilityMode
            if (currentSection.time && currentSection.time.warning && !accessibilityMode) {
                const timeWarnings = currentSection.time.warning;
                setFollowUpTimer(timeWarnings.followUpTimer);
                setTimerWarning1(timeWarnings.timerWarning1);
                setTimerWarning2(timeWarnings.timerWarning2);
            }
            // Sequence enables the UI tour of tooltips in a particular section
            if (currentSection.UI && currentSection.UI.tooltips) {
                let tooltipSequence = currentSection.UI.tooltips;
                // If it is accessibility mode, filter out the timer tooltip from the sequence
                if (accessibilityMode) {
                    tooltipSequence = currentSection.UI.tooltips.filter(tooltip => tooltip !== 'timer');
                    setTooltipSequence(tooltipSequence);
                } else {
                    setTooltipSequence(tooltipSequence);
                }

                // If there is a more than one tooltip in the sequence, dynamically set the final tooltips based on this
                if (tooltipSequence.length > 1) {
                    configureFinalTooltips(tooltipSequence);
                } else {
                    setFinalTooltips(baseTooltips); // Reset the final tooltips to the baseline if there's no tooltip sequence
                }
            } else {
                setTooltipSequence([]); // Reset the sequence if there's no tooltip sequence
                setFinalTooltips(baseTooltips); // Reset the final tooltips to the baseline if there's no tooltip sequence
            }
        // If there's no section, reset the sequence and final tooltips
        } else {
            setTooltipSequence([]); // Reset the sequence if there's no tooltip sequence
            setFinalTooltips(baseTooltips); // Reset the final tooltips to the baseline if there's no tooltip sequence
        }
    }, [currentSection]);

    // Configure the final tooltips based on the sequence, updating its next and uz properties
    const configureFinalTooltips = (tooltipSequence) => {
        let finalTooltips = { ...baseTooltips };

        // Loop through the tooltip sequence and set the next tooltip for each tooltip
        tooltipSequence.forEach((tooltipName, index) => {
            if (finalTooltips[tooltipName]) {
                if (tooltipSequence[index + 1]) {
                    finalTooltips[tooltipName].next = tooltipSequence[index + 1];
                }
    
                // Adding the 'number' parameter dynamically
                finalTooltips[tooltipName].number = `${index + 1}/${tooltipSequence.length}`;
            }
        });
        setFinalTooltips(finalTooltips);

        // Activate the first tooltip in the sequence
        if (tooltipSequence.length > 0) {
            setActiveTooltips(prev => ({ ...prev, [tooltipSequence[0]]: true }));
        }
    };

    // Check if a tooltip should be activted based on its condition(s). It receive the tooltip name as a parameter, and checks if the tooltip conditions are met before activating it
    const activateTooltip = (tooltipName) => {
        const tooltip = finalTooltips[tooltipName];
        if (tooltip && tooltip.condition()) {
            if (tooltip.delay) {
                if (tooltipTimers[tooltipName]) {
                    clearTimeout(tooltipTimers[tooltipName]);
                }
                const timer = setTimeout(() => {
                    setActiveTooltips(prev => ({ ...prev, [tooltipName]: true }));
                    setTooltipTimers(prev => ({ ...prev, [tooltipName]: null }));
                }, tooltip.delay);
                setTooltipTimers(prev => ({ ...prev, [tooltipName]: timer }));
            } else {
                setActiveTooltips(prev => ({ ...prev, [tooltipName]: true }));
            }
        }
    };

    // Function to get the configuration for a tooltip by its name, used in components to get the tooltip config and display it
    const getTooltipConfig = (tooltipName) => {
        return { ...finalTooltips[tooltipName], visible: activeTooltips[tooltipName] };
    };

    // When user clicks on a tooltip button, close it and show the next tooltip (if applicable)
    const onTooltipButtonClick = (currentTooltip) => {
        setActiveTooltips(prev => {
            const updatedTooltips = { ...prev };
            delete updatedTooltips[currentTooltip]; // Remove the current tooltip from the active tooltips so it disappears
    
            const tooltipConfig = finalTooltips[currentTooltip]; // Get the config for the current tooltip
            const nextTooltip = tooltipConfig?.next; // Get the next tooltip to show
    
            // Check and activate the next tooltip if conditions are met
            if (nextTooltip) {            
                updatedTooltips[nextTooltip] = true; // Activate the next tooltip
            } else if (tooltipSequence.includes(currentTooltip) && !nextTooltip && testStage === 'test') {
                // If there is no next tooltip, but the current tooltip is in the sequence, start the test
                setIsTestStarted(true);
            }
    
            // Execute the button action if it exists
            if (tooltipConfig?.buttonAction) {
                tooltipConfig.buttonAction();
            }
    
            return updatedTooltips;
        });
    };

    // Cleanup for all tooltip timers
    useEffect(() => {
        return () => {
            Object.values(tooltipTimers).forEach(timer => {
                if (timer) {
                    clearTimeout(timer);
                }
            });
        };
    }, [tooltipTimers]);

    // Get the latest testStage value and store it in the ref
    useEffect(() => {
        testStageRef.current = testStage;
    }, [testStage]);   

    // Get the latest followuptimer value and store it in the ref
    useEffect(() => {
        followUpTimerRef.current = followUpTimer;
    }, [followUpTimer]);   

    // SPECIFIC TRIGGERS //

    // When testStage changes, check if any tooltips should be activated by passing each to the activateTooltip function
    useEffect(() => {
        Object.keys(finalTooltips).forEach(tooltipName => {
            // Loop through the final tooltips and check if the condition is met
            const conditionMet = finalTooltips[tooltipName].condition();
            if (conditionMet) {
                activateTooltip(tooltipName); // Activate the tooltip if the condition is met
            }
        });
    }, [testStage]);

    // Trigger the 10 min left tooltip if the section config triggred the corresponding state and the time is 10 min left
    useEffect(() => {
        if (is10MinsLeft && timerWarning1) {
            activateTooltip('timerWarning1');
        }
    }, [is10MinsLeft, timerWarning1]);

    // Trigger the 1 min left tooltip if the section config triggred the corresponding state and the time is 1 min left
    useEffect(() => {
        if (is1MinsLeft && timerWarning2) {
            activateTooltip('timerWarning2');
        }
    }, [is1MinsLeft, timerWarning2]);

    // Trigger the follow-up timer tooltip immediately when the followUpTimer state (triggered when the section changes) is set to true
    useEffect(() => {
        if (followUpTimer) {
            activateTooltip('followUpTimer');
        }
    }, [followUpTimer]);

    // Trigger the added time tooltip when the timeAdded state is set to true (which occurs in the userTimer hook). Show the tooltip for X seconds
    useEffect(() => {
        if (timeAdded) {
            activateTooltip('addedTime'); // Show the tooltip
            setTimeout(() => {
                setTimeAdded(false); // Hide the tooltip after X seconds
            }, 5000);
        }
    }, [timeAdded]);

    return (
        <TooltipContext.Provider value={{ activeTooltips, getTooltipConfig, activateTooltip, onTooltipButtonClick }}>
            {children}
        </TooltipContext.Provider>
    );
};

export const useTooltip = () => useContext(TooltipContext);