// Mapping of code languages. Main key is what the user sees in the dropdown in IDE.js. monaco is for the Monaco editor. ace for the Ace editor. prism (see ChatMessage.js) used to style a chat message as code. compiler and version is used for jdoodle compiler API. Autocomplete says if there is autocomplete and if full ("Y") or limited ("Local").
import React, { useContext } from 'react';

//prism languages here: https://lucidar.me/en/web-dev/list-of-supported-languages-by-prism///
//jdoodle languages here: https://www.jdoodle.com/docs/jdoodle-apis/programming-languages-and-versions//
//ace editor here: https://cloud9-sdk.readme.io/docs/language-mode
//to get monaco languages: monaco.languages.getLanguages()
  
const languageMappings = {
    /* need to explore how is works in jdoodle as it gives a message saying the file is called jdoodle. 
    'Ada': {
        monaco: "ada", ace: "ada", autocomplete: "Y",
        compiler: "ada", version: 4, prism: "ada",
        extension: [".ada"]
    },
    */
    'C': {
        monaco: "c", ace: "c_cpp", autocomplete: "Y",
        compiler: "c", version: 5, prism: "c",
        extension: [".c"]
    },
    'Clojure': {
        monaco: "clojure", ace: "clojure", autocomplete: "Y",
        compiler: "clojure", version: 3, prism: "clojure",
        extension: [".clj", ".cljs", ".cljc"]
    },
    /* no need
    'CoffeeScript': {
        monaco: "coffee", ace: "coffee", autocomplete: "Y",
        compiler: "coffeescript", version: 4, prism: "coffeescript",
        extension: [".coffee"]
    },
    */
    'C++': {
        monaco: "cpp", ace: "c_cpp", autocomplete: "Y",
        compiler: "cpp", version: 5, prism: "cpp",
        extension: [".cpp", ".cc", ".cxx", ".hpp", ".h"]
    },
    'Cpp14': {
        monaco: "cpp", ace: "c_cpp", autocomplete: "Y",
        compiler: "cpp14", version: 4, prism: "cpp",
        extension: [".cpp", ".cc", ".cxx", ".hpp", ".h"]
    },
    'Cpp17': {
        monaco: "cpp", ace: "c_cpp", autocomplete: "Y",
        compiler: "cpp17", version: 1, prism: "cpp",
        extension: [".cpp", ".cc", ".cxx", ".hpp", ".h"]
    },
    'CSS': {
        monaco: "css", ace: "css", autocomplete: "Y",
        compiler: "css", version: 1, prism: "css",
        extension: [".css", ".module.css"]
    },
    'C#': {
        monaco: "csharp", ace: "csharp", autocomplete: "Y",
        compiler: "csharp", version: 4, prism: "csharp",
        extension: [".cs"]
    },
    /* no need
    'D': {
        monaco: "d", ace: "d", autocomplete: "Y",
        compiler: "d", version: 2, prism: "d",
        extension: [".d"]
    },
    */
    'Dart': {
        monaco: "dart", ace: "dart", autocomplete: "Y",
        compiler: "dart", version: 4, prism: "dart",
        extension: [".dart"]
    },
    'Elixir': {
        monaco: "elixir", ace: "elixir", autocomplete: "Local",
        compiler: "elixir", version: 4, prism: "elixir",
        extension: [".ex", ".exs"]
    },
    /* no working
    'Erlang': {
        monaco: "erlang", ace: "erlang", autocomplete: "Local",
        compiler: "erlang", version: 1, prism: "erlang",
        extension: [".erl", ".hrl"]
    },
    */
    'F#': {
        monaco: "fsharp", ace: "fsharp", autocomplete: "Y",
        compiler: "fsharp", version: 1, prism: "erlang",
        extension: [".fs", ".fsi", ".fsx", ".fsscript"]
    },
    'Go': {
        monaco: "go", ace: "golang", autocomplete: "Y",
        compiler: "go", version: 4, prism: "go",
        extension: [".go"]
    },
    'Groovy': {
        monaco: "groovy", ace: "groovy", autocomplete: "Y",
        compiler: "groovy", version: 4, prism: "groovy",
        extension: [".groovy", ".gvy", ".gy", ".gsh"]
    },
    'Haskell': {
        monaco: "haskell", ace: "haskell", autocomplete: "Local",
        compiler: "haskell", version: 4, prism: "haskell",
        extension: [".hs", ".lhs"]
    },
    'Java8': {
        monaco: "java", ace: "java", autocomplete: "Y",
        compiler: "java", version: 0, prism: "java",
        extension: [".java"]
    },
    'Java11': {
        monaco: "java", ace: "java", autocomplete: "Y",
        compiler: "java", version: 3, prism: "java",
        extension: [".java"]
    },
    'Java17': {
        monaco: "java", ace: "java", autocomplete: "Y",
        compiler: "java", version: 4, prism: "java",
        extension: [".java"]
    },
    // monaco
    'Javascript (Nodejs)': {
        monaco: "javascript", ace: "javascript", autocomplete: "Y",
        compiler: "nodejs", version: 4, prism: "javascript",
        extension: [".js", ".jsx"]
    },
    // monaco
    'Kotlin': {
        monaco: "kotlin", ace: "kotlin", autocomplete: "Local",
        compiler: "kotlin", version: 3, prism: "kotlin",
        extension: [".kt", ".kts"]
    },
    'Lua': {
        monaco: "lua", ace: "lua", autocomplete: "Y",
        compiler: "lua", version: 3, prism: "lua",
        extension: [".lua"]
    },
    'Objective-C': {
        monaco: "objectivec", ace: "objectivec", autocomplete: "Local",
        compiler: "objc", version: 4, prism: "objectivec",
        extension: [".m", ".mm"]
    },
    /* no need
    'OCaml': {
        monaco: "ocaml", ace: "ocaml", autocomplete: "Y",
        compiler: "ocaml", version: 2, prism: "ocaml",
        extension: [".ml", ".mli"]
    },
    'Pascal': {
        monaco: "pascal", ace: "pascal", autocomplete: "Y",
        compiler: "pascal", version: 3, prism: "pascal",
        extension: [".pas", ".pp"]
    },
    */
    'Perl': {
        monaco: "perl", ace: "perl", autocomplete: "Y",
        compiler: "perl", version: 4, prism: "perl",
        extension: [".pl", ".pm", ".t", ".pod"]
    },
    'PHP': {
        monaco: "php", ace: "php", autocomplete: "Y",
        compiler: "php", version: 4, prism: "php",
        extension: [".php"]
    },
    /* no need
    'Python2': {
        monaco: "python", ace: "python", autocomplete: "Y",
        compiler: "python2", version: 3, prism: "python",
        extension: [".py"]
    },
    */
    'Python3': {
        monaco: "python", ace: "python", autocomplete: "Y",
        compiler: "python3", version: 4, prism: "python",
        extension: [".py"]
    },
    /* no need - for stats
    'R': {
        monaco: "r", ace: "r", autocomplete: "Local",
        compiler: "r", version: 4, prism: "r",
        extension: [".r"]
    },
    */
    'Ruby': {
        monaco: "ruby", ace: "ruby", autocomplete: "Y",
        compiler: "ruby", version: 4, prism: "ruby",
        extension: [".rb", ".rbw"]
    },
    'Rust': {
        monaco: "rust", ace: "rust", autocomplete: "Local",
        compiler: "rust", version: 4, prism: "rust",
        extension: [".rs"]
    },
    'Scala': {
        monaco: "scala", ace: "scala", autocomplete: "Y",
        compiler: "scala", version: 4, prism: "scala",
        extension: [".scala"]
    },
    /* no need - for DB queries
    'SQL': {
        monaco: "sql", ace: "sql", autocomplete: "Y",
        compiler: "sql", version: 4, prism: "sql",
        extension: [".sql"]
    },
    */
    // monaco
    'Swift': {
        monaco: "swift", ace: "swift", autocomplete: "Local",
        compiler: "swift", version: 4, prism: "swift",
        extension: [".swift"]
    },
    /* no need
    'Tcl': {
        monaco: "tcl", ace: "tcl", autocomplete: "Local",
        compiler: "tcl", version: 4, prism: "tcl",
        extension: [".tcl"]
    },
    */
    // monaco
    'TypeScript (Nodejs)': {
        monaco: "typescript", ace: "typescript", autocomplete: "Y",
        compiler: "typescript", version: 4, prism: "typescript",
        extension: [".ts", ".tsx"]
    },
    'Visual Basic': {
        monaco: "vb", ace: "vbscript", autocomplete: "Local",
        compiler: "vbn", version: 4, prism: "vbnet",
        extension: [".vb"]
    },
    // Add more languages if needed
};

export { languageMappings };
