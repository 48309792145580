import { useEffect } from "react";
import { checkValidCompanyAndRole } from "../services/databaseService";

const useValidateTestType = (
  testStage,
  setCompanyDetails,
  setRoleDetails,
  setTestStage,
  page
) => {
  // Run the function to validate the test type when the testStage is 'initialLoading'
  useEffect(() => {
    const validateTestTypeFromURL = async () => {
      // if the page is not /entrevista, return
      if (page !== "/entrevista") return;

      // Check the url params for the company and role
      const urlParams = new URLSearchParams(window.location.search);

      // First check if there is a test attempt in the url, if so dont proceed as we need to retrieve the test data
      const testAttemptId = urlParams.get("tentativa");
      if (testAttemptId) return;

      // Gather the company and role codes from the url
      const companyCode = urlParams.get("empresa");
      let roleCode = urlParams.get("cargo");

      // overlay rolecode if companyCode is lg073
      if (companyCode === "lg073") {
        roleCode = "bdevdb946";
      }

      // If there is none, go to fail state
      if (!companyCode || !roleCode) {
        setTestStage("uniqueUrlFail");
        return;
      }

      // Fetch the full role and company data from the database
      const companyAndRoleData = await checkValidCompanyAndRole(
        companyCode,
        roleCode
      );
      const { isValid, company, role } = companyAndRoleData.isValid;

      // If it works, save the data so it is available throughout the test
      if (isValid) {
        setCompanyDetails(company), setRoleDetails(role);
      } else {
        // If it fails, go to fail state
        setTestStage("uniqueUrlFail");
      }
    };

    // Call the function to validate the test type if the testStage is 'initialLoading'
    if (testStage === "initialLoading") {
      validateTestTypeFromURL();
    }
  }, [testStage]);
};

export default useValidateTestType;
