// Visual representation of the database schema in the form of a tree view.
import React, { useEffect } from 'react';
import { useCodeContext } from '../../contexts/CodeContext';
import styles from './SqlTreeView.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTable, faColumns, faSync } from '@fortawesome/free-solid-svg-icons';
import { ClipLoader, FadeLoader } from 'react-spinners'; // For the loading icon waiting for db to setup

const SqlTreeView = () => {
    const { databaseSchema, theme, isFirstSqlRun, fetchLatestSchema, loadingDatabaseSchema } = useCodeContext();
    const themeClass = theme === 'dark' ? styles.dark : styles.light;

    // Function to render the columns of a table
    const renderColumns = (columns) => (
        <ul className={styles.columns}>
            {Array.isArray(columns) && columns.map((column, index) => (
                <li key={index} className={styles.column}>
                    <FontAwesomeIcon icon={faColumns} className={styles.columnIcon} /> {column.name} ({column.type})
                </li>
            ))}
        </ul>
    );

    // Function to render the tables in the schema
    const renderTables = (tables) => (
        <ul className={styles.tables}>
            {Array.isArray(tables) && tables.map((table, index) => (
                <li key={index} className={styles.table}>
                    <FontAwesomeIcon icon={faTable} className={styles.tableIcon} /> {table.name}
                    {renderColumns(table.columns)}
                </li>
            ))}
        </ul>
    );

    return (
        <div className={`${styles.treeView} ${themeClass}`}>
            <div className={styles.mainHeading}>
                SCHEMAS:
                {loadingDatabaseSchema ? (
                    <div className={`${styles.refreshButtonLoader} ${themeClass}`} onClick={fetchLatestSchema}>
                        <ClipLoader size={12} color={"#fff"} />
                    </div>
                ) : (
                    <button className={`${styles.refreshButton} ${themeClass}`} onClick={fetchLatestSchema}>
                        <FontAwesomeIcon icon={faSync} />
                    </button>
                )}
            </div>
            {isFirstSqlRun ? (
                <div className={styles.loader}>
                    <FadeLoader size={10} color={"#246E24"} />
                    <p>Carregando banco de dados</p>
                </div>
            ) : (
                databaseSchema ? (
                    renderTables(databaseSchema)
                ) : (
                    <div>Nenhum esquema de banco de dados disponível.</div>
                )
            )}
        </div>
    );
};

export default SqlTreeView;
