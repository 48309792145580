// Dropdown menu for coding interface
import React from 'react';
import PropTypes from 'prop-types';
import { useCodeContext } from '../../contexts/CodeContext';
import styles from './CodeDropdown.module.css';

const CodeDropdown = ({ options, onChange, currentValue }) => {
    const { theme } = useCodeContext();
    const themeClass = theme === 'dark' ? styles.dark : styles.light;

    return (
        <select 
            className={`${styles.codeDropdown} ${themeClass}`} 
            onChange={onChange}
            value={currentValue}
        >
            {options.map(option => (
                <option key={option.value} value={option.value}>
                    {option.label}
                </option>
            ))}
        </select>
    );
};

CodeDropdown.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
    })).isRequired,
    onChange: PropTypes.func.isRequired,
    currentValue: PropTypes.string.isRequired,
};

export default CodeDropdown;
