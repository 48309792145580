// Code editor, rendered in IDE.js. It receives the languages and themes from the IDE component. LanguageMapping has the details of the languages. 
import React, { useContext, useEffect, useRef, useState, useCallback } from 'react';
import { TooltipContext } from '../../contexts/TooltipContext';
import { AppContext } from '../../contexts/AppContext';
import { useTestContext } from '../../contexts/TestContext';
import { useCodeContext } from '../../contexts/CodeContext';
import { CopyPasteContext } from '../../contexts/CopyPasteContext';
import Tooltip from '../tooltip/Tooltip';
import styles from './SqlEditor.module.css';
import { logException } from '../../services/loggerFront';
import { FadeLoader } from 'react-spinners';

const SqlEditor = ({
    autocompleteEnabled,
    readOnly = false, // Used in the candidate report as the user doesnt need to edit the code
    pdfMode = false // Used in the candidate report when the user clicks to download pdf (it adjusts the editor height)
}) => {
    const { currentSqlCode, setCurrentSqlCode, editorFontSize, theme, aceEditorTheme } = useCodeContext();
    const currentSqlCodeRef = useRef(currentSqlCode);
    // Code editors
    const [aceScriptsLoaded, setAceScriptsLoaded] = useState(false); // To load Ace scripts just once
    const [ aceEditor, setAceEditor ] = useState(null); // Ace Editor instance
    const editorContainerRef = useRef(null); 
    const aceEditorRef = useRef(null);
    // Tooltips
    const { activeTooltips } = useContext(TooltipContext); // Enables triggering of the tooltip
    const showTooltip = activeTooltips['codeEditor']; // Show the tooltip if the codeEditor tooltip is active (more details in TooltipContext.js)
    // Retrieved session
    const { retrievedSession, retrievedCode, isTestStarted } = useTestContext(); // States to enable a retrieved session where we bring back the code the user had before the lost connection 
    const [ firstCodeRetrieval, setFirstCodeRetrieval ] = useState(true); // This enables inserting the retrieved code as the initial code in the ace editor when we retrieve a session
    // Copy and paste
    const { copyStatus, canPaste, limitPaste, handleCopy } = useContext(CopyPasteContext); // CopyPasteContext is used to manage the copy paste rules and functionality
    const limitPasteRef = useRef();
    // UI management
    const themeClass = theme === 'dark' ? styles.dark : styles.light;
    
    // Update the ref when limitPaste changes
    useEffect(() => {
        limitPasteRef.current = limitPaste;
    }, [limitPaste]);

    useEffect(() => {
        currentSqlCodeRef.current = currentSqlCode;
    }, [currentSqlCode]);

    // KEYSTROKES //

    // Organise user interaction data to save for playback (Ace) using the useCodeActivityTracker.js hook's trackKeystrokes function
    const handleAceChange = (editorInstance, delta) => {
        const newValue = editorInstance.getValue()
        const changeDetails = {
            changes: [{
                action: delta.action,
                startLineNumber: delta.start.row + 1,
                startColumn: delta.start.column + 1,
                endLineNumber: delta.end.row + 1,
                endColumn: delta.end.column + 1,
                text: delta.lines.join('\n'),
            }],
            source: 'ace'
        };
        setCurrentSqlCode(newValue);
        // onChange(newValue, changeDetails); // Connect to the useCodeActivityTracker.js hook
    };
      
    // INITIALIZE AND CONFIGURE ACE EDITOR //

    // Load the Ace Editor scripts
    const loadAceEditor = () => {
        return new Promise((resolve, reject) => {
            if (!aceScriptsLoaded) {
                const aceScript = document.createElement('script');
                aceScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/ace/1.4.12/ace.js';
                aceScript.async = true;
        
                aceScript.onload = () => {
                    // Set the base path immediately after the ace script is loaded
                    window.ace.config.set('basePath', 'https://cdnjs.cloudflare.com/ajax/libs/ace/1.4.12/');
                    const aceExtScript = document.createElement('script');
                    aceExtScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/ace/1.4.12/ext-language_tools.js';
                    aceExtScript.async = true;
    
                    document.body.appendChild(aceExtScript);
                    aceExtScript.onload = () => {
                        resolve(); // Resolve when both scripts are loaded
                        setAceScriptsLoaded(true);
                    };
    
                    aceExtScript.onerror = () => {
                        logException("Failed to load Ace language tools");
                        reject(new Error("Failed to load Ace language tools"));
                    };
                };
        
                aceScript.onerror = () => {
                    logException("Failed to load Ace Editor from CDN");
                    reject(new Error("Failed to load Ace Editor from CDN"));
                };
    
                document.body.appendChild(aceScript);
            } else {
                resolve(); // Resolve immediately if scripts are already loaded
            }
        });
    };       
    
    // Initializes the Ace Editor with the relevent settings like language and theme
    useEffect(() => {  
        (async () => {
            try {
                await loadAceEditor();

                if (!aceEditor) {

                    const aceEditorInstance = window.ace.edit(aceEditorRef.current);
                    setAceEditor(aceEditorInstance);

                    aceEditorInstance.setTheme(`ace/theme/${aceEditorTheme}`);
                    aceEditorInstance.session.setMode(`ace/mode/sql`);
                    aceEditorInstance.setValue(currentSqlCodeRef.current || '', -1);

                    // Setting the wrap option based on pdfMode
                    const wrapOption = pdfMode ? true : false;

                    aceEditorInstance.setOptions({
                        readOnly: readOnly,
                        enableBasicAutocompletion: autocompleteEnabled,
                        enableLiveAutocompletion: autocompleteEnabled,
                        enableSnippets: false,
                        fontSize: parseInt(editorFontSize, 10),
                        wrap: wrapOption, 
                    });

                    aceEditorInstance.session.on('change', (delta) => handleAceChange(aceEditorInstance, delta));
                } 
            } catch (error) {
            }
        })();
    }, []); 

    // Update ace setting when user interacts with settings
    useEffect(() => {
        if (aceEditor) {
            aceEditor.setTheme(`ace/theme/${aceEditorTheme}`);
            aceEditor.session.setMode(`ace/mode/sql`);
            aceEditor.setValue(currentSqlCodeRef.current || '', -1); // Only if you need to update the code value
    
            aceEditor.setOptions({
                readOnly: readOnly,
                enableBasicAutocompletion: autocompleteEnabled,
                enableLiveAutocompletion: autocompleteEnabled,
                enableSnippets: false,
                fontSize: parseInt(editorFontSize, 10),
            });
        }
    }, [aceEditorTheme, editorFontSize, autocompleteEnabled]);

    // COPY PASTE //

    // Ace paste functinality to only paste what was laste copied on site and prevent paste from outside. CopyPasteContext handle the and handleCopy, canPAste and handlePaste functions/states
    useEffect(() => {
        if (aceEditor) {
            // Define handlePaste inside useEffect to capture latest context values
            const handlePaste = (e) => {
                // Check if the pasting content is not what's allowed
                if (copyStatus === 'ok' && e.text.trim() !== canPaste.trim()) {
                    e.text = '';
                } else if (copyStatus === 'not ok') {
                    // If the copy status is 'not ok', replace the pasted content with `limitPaste`
                    e.text = ''; // Set the pasted text to blank as global copy paste rules will overridee with limitPaste
                }
                // If copyStatus is 'ok', add rules here
            };

            // Directly attach to Ace editor's event system
            aceEditor.on('paste', handlePaste);

            return () => {
                // Clean up: Remove event listener on cleanup
                if (aceEditor) {
                    aceEditor.off('paste', handlePaste);
                }
            };
        }
    }, [aceEditor, copyStatus, canPaste, limitPaste]); // Ensure useEffect reacts to changes in these dependencies

    // When the user copies in the Ace editor identify the text and call the copy functionality in CopyPasteContext
    useEffect(() => {
        const handleCopyInAce = () => {
            const selectedText = aceEditor.getSelectedText(); // Get the selected text

            // Process selectedText as needed for your application
            handleCopy(selectedText, 'other'); // Assuming handleCopy can process this accurately
        };

        if (aceEditor) {
            // Add a custom copy command to the Ace Editor
            aceEditor.commands.addCommand({
                name: 'copy',
                exec: handleCopyInAce,
                bindKey: { win: 'Ctrl-C', mac: 'Command-C' }
            });
        }

        return () => {
            if (aceEditor) {
                // Clean up by removing the custom copy command when the component unmounts or aceEditor changes
                aceEditor.commands.removeCommand('copy');
            }
        };
    }, [aceEditor, handleCopy]); // Ensure the effect runs again if aceEditor or handleCopy changes

    // Mananging cut functionality in ace editor to behave like copy paste rules except we remove from the editor the text that was cut
    useEffect(() => {
        const handleCutInAce = () => {
          const selectedText = aceEditor.getSelectedText(); // Get the selected text
          const selectionRange = aceEditor.getSelectionRange(); // Get the selection range
    
          // Process selectedText in CopyPasteContext
          handleCopy(selectedText, 'other'); 
    
          // Remove the selected text from the Ace Editor
          aceEditor.session.remove(selectionRange);
    
          // Then clear the selection as that is what cut does
          aceEditor.clearSelection();
        };
    
        if (aceEditor) {
          // Add a custom cut command to the Ace Editor
          aceEditor.commands.addCommand({
            name: 'cut',
            exec: handleCutInAce,
            bindKey: { win: 'Ctrl-X', mac: 'Command-X' }
          });
        }
    
        return () => {
          if (aceEditor) {
            // Clean up by removing the custom cut command when the component unmounts or aceEditor changes
            aceEditor.commands.removeCommand('cut');
          }
        };
    }, [aceEditor, handleCopy]); // Ensure the effect runs again if aceEditor or handleCopy changes    

    return (
        <div
            ref={editorContainerRef} 
            className={`${styles.editorContainer} ${readOnly ? styles.candidateReport : ''} ${themeClass}`} // Dynmaic styles to allow for dark or light mode and candidate report mode
        >
            {showTooltip && !retrievedSession && <Tooltip variant='codeEditor' />} 
            <div 
                id="aceEditor" 
                ref={aceEditorRef} 
                style={{ width: '100%', height: '100%',}}>
            </div>
        </div>
    );
};

export default SqlEditor;