import React, { useState } from 'react';
import Logo from '../common/DegrauLogo';
import Button from '../common/Button';
import styles from './Popup.module.css';

const ScreenShareRejectContent = ({ onConfirmReject, onCancelReject, onLoad, logoLoaded, ...otherProps }) => {
    const [reason, setReason] = useState('');
    const [error, setError] = useState('');

    const handleConfirm = () => {
        if (!reason.trim()) {
            setError('Por favor, informe o motivo pelo qual você não deseja compartilhar sua tela.');
            return;
        }
        setError('');
        onConfirmReject(reason); // Pass the reason back to PhotoCheck.js
    };

    const handleCancel = () => {
        onCancelReject(); // Hide the popup
    };

    return (
        <>
            <Logo className={styles.degrauLogoPopup} onLoad={onLoad} />
            <p className={styles.popupText}>
                Para assegurar a integridade do processo de seleção, pedimos que os candidatos compartilhar sua tela durante o teste. Fique tranquila(o), sua tela será acessível exclusivamente pela empresa contratante.<br /><br />
                Agradecemos sua compreensão.
            </p>
            <form className={styles.rejectPhotoForm}>
                <label htmlFor="rejectScreenShareDescription">Por favor, deixe-nos saber por que você não quer compartilhar sua tela</label>
                <textarea 
                    id="rejectScreenShareDescription" 
                    name="rejectScreenShareDescription" 
                    placeholder="Escreva aqui" 
                    required 
                    value={reason}
                    onChange={e => setReason(e.target.value)}
                    className={styles.rejectPhotoTextarea} 
                ></textarea>
                {error && <p className={styles.errorText}>{error}</p>}
            </form>
            <div className={styles.rejectPhotoButtonContainer}>
                <Button onClick={handleCancel} type="proceed" className={styles.cancelRejectPhoto}>
                    Voltar
                </Button>
                <Button onClick={handleConfirm} type="subtle" className={styles.confirmRejectPhoto}>
                    Confirmar
                </Button>
            </div>
        </>
    );
};

export default ScreenShareRejectContent;
