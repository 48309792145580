// Show the transpiled front-end code
import React, { useEffect, useRef } from 'react';
import { useAppContext } from '../../contexts/AppContext';
import PropTypes from 'prop-types';

const LivePreview = ({ content }) => {
    const { setIsInteractingWithIframe } = useAppContext();
    const iframeRef = useRef(null);
    
    useEffect(() => {
        if (iframeRef.current) {
            iframeRef.current.srcdoc = content;

            const handleIframeLoad = () => {
                const iframeDocument = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;
                console.log('iframeDocument ready');

                const handleFocus = () => {
                    console.log('iframe focus');
                    setIsInteractingWithIframe(true);
                };

                const handleBlur = () => {
                    console.log('iframe blur');
                    setIsInteractingWithIframe(false);
                };

                iframeDocument.addEventListener('focus', handleFocus, true);
                iframeDocument.addEventListener('blur', handleBlur, true);

                return () => {
                    iframeDocument.removeEventListener('focus', handleFocus, true);
                    iframeDocument.removeEventListener('blur', handleBlur, true);
                };
            };

            // Listen for the iframe load event to ensure the content is fully loaded
            iframeRef.current.onload = handleIframeLoad;
        }
    }, [content, setIsInteractingWithIframe]);


    return (
        <iframe
            ref={iframeRef}
            style={{ width: '100%', height: '100%', border: 'none', backgroundColor: 'white' }}
            title="Live Preview"
        ></iframe>
    );
};

LivePreview.propTypes = {
    content: PropTypes.string.isRequired,
};


export default LivePreview;
