import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../contexts/AppContext';
import { useTestContext } from '../contexts/TestContext';
import { TooltipProvider } from "../contexts/TooltipContext";
import {validatePassword, passwordRulesDescriptions} from '../utils/validatePassword';
import { getCompanyLogoPath } from '../utils/getCompanyLogo';
import { roleSummaryTableHeaders, roleSummaryTableRows } from '../dataInputs/roleSummaryTable'; // Data for the user role summary table
// Standard page components
import Header from "../components/common/Header";
import TopRibbon from '../components/common/TopRibbon';
import ReportIssue from "../components/common/ReportIssue";
import ActionButton from '../components/common/ActionButton';

import InfoTable from '../components/common/InfoTable';
import AdminModal from '../components/common/AdminModal';
import EditUserForm from '../components/inputs/EditUserForm';
import ChangePasswordForm from '../components/inputs/ChangePasswordForm';
import ConfirmModal from '../components/common/ConfirmModal';
import useEnsureUserData from '../hooks/useEnsureUserData';
import useAuth from '../hooks/useAuth';
import { removeUser, editProfile } from '../services/databaseService';
import { logException, logEvent, logTrace } from "../services/loggerFront";
import { changePassword } from '../services/authenticationService';
import { faQuestionCircle, faEdit } from "@fortawesome/free-solid-svg-icons";
import styles from "../sharedStyles/CompanyAdminPages.module.css"; 

const MyAccountPage = () => {
    const {
        userFirstName,
        userLastName,
        setUserFirstName,
        setUserLastName,
        userInitials,
        userId,
        userEmail,
        setUserEmail,
        userRoles,
    } = useAppContext(); // Access the user context
    const {companyDetails} = useTestContext(); // Access the company context
    const navigate = useNavigate();
    const { fetchUserData } = useEnsureUserData();
    // Edit details states
    const [newFirstName, setNewFirstName] = useState('');
    const [newLastName, setNewLastName] = useState('');
    const [newEmail, setNewEmail] = useState('');
    // UI states
    const [loading, setLoading] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false); // Control modal visibility for editing details
    const [showPasswordModal, setShowPasswordModal] = useState(false); // Control modal visibility for changing password
    const [showConfirmModal, setShowConfirmModal] = useState(false); // Control Confirm modal
    const partnerLogoPath = getCompanyLogoPath(companyDetails); // Get the company logo path
    // Password states
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    
    // on load fetch user data
    useEffect(() => {
        fetchUserData();
    }, []);
    const { signOut } = useAuth();

    const fileName = 'MyAccountPage.js'; // Define the filename for the logs

    // set the new values to the current user data
    useEffect(() => {
        setNewFirstName(userFirstName);
        setNewLastName(userLastName);
        setNewEmail(userEmail);
    }, [userFirstName, userLastName, userEmail]); 

    const handleEditProfileDetails = async () => {
        try {
            setLoading(true);
            await editProfile({ name: newFirstName, surname: newLastName, email: newEmail });
            setUserFirstName(newFirstName);
            setUserLastName(newLastName);
            setUserEmail(newEmail);
            setShowEditModal(false); // Close modal after save
        } catch (error) {
            logException('Failed to update user details', {
                errorMessage: error.message,
                errorStack: error.stack,
                userId: userId || 'No user ID',
                fileName,
            });
        } finally {
            setLoading(false);
        }
    };

    const handlePasswordChange = async () => {
        setError(''); // Clear any previous errors
        setSuccess(''); // Clear any previous success messages

        // Check if the password meets the requirements
        try {
            await validatePassword(newPassword);
        } catch (error) {
            setError(error.message);
            setTimeout(() => setError(''), 5000); // Clear the error message after 5 seconds
            return;
        }

        // Check if they match
        if (newPassword !== confirmNewPassword) {
            setError("As novas senhas não coincidem");
            setTimeout(() => setError(''), 5000); // Clear the error message after 5 seconds
            return;
        }

        try {
            setLoading(true);
            const response = await changePassword(currentPassword, newPassword);
            setSuccess(response.message); // Display success message
            setTimeout(() => setSuccess(''), 15000); // Clear the success message after 15 seconds
            setTimeout(() => setShowPasswordModal(false), 5000); // Close the modal after 5 seconds
            clearPasswordFields();
        } catch (error) {
            setError(error.message); // Display error message to the user
            setTimeout(() => setError(''), 5000); // Clear the error message after 5 seconds
        } finally {
            setLoading(false);
        }
    };

    const clearPasswordFields = () => {
        setCurrentPassword('');
        setNewPassword('');
        setConfirmNewPassword('');
    };

    const handleDelete = async () => {
        try {
            setLoading(true);
            await removeUser(userId);
            signOut();
            navigate('/login'); 
        } catch (error) {
            logException('Failed to delete account', {
                errorMessage: error.message,
                errorStack: error.stack,
                userId: userId || 'No user ID',
                fileName,
            });
            setError('Erro ao excluir a conta');
            setTimeout(() => setError(''), 5000); // Clear the error message after 5 seconds
        } finally {
            setLoading(false);
            setShowConfirmModal(false); // Close the confirmation modal
        }
    };

    return (
        <TooltipProvider>
            <Header />
            <ReportIssue/>
            <div className={styles.outerContainer}> 
                <TopRibbon title="Minha conta" />
                <div className={styles.mainContainer}>
                    <div className={styles.contentContainer}>
                        <div className={styles.userProfileContainer}>
                            <div className={styles.userOverview}>
                                <div className={styles.largeUserIcon}>{userInitials}</div>
                                <div className={styles.userDetails}>

                                    {/* name(s) */}
                                    <div className={styles.userDetailContainer}>
                                        <div className={styles.userDetail}>
                                            <div className={styles.userName}>{userFirstName} {userLastName}</div>
                                        </div>
                                        <ActionButton 
                                            icon={faEdit} 
                                            label="Editar meus detalhes"
                                            onClick={() => setShowEditModal(true)} // Show modal on click
                                            showHoverPopout={true}
                                            popoutText="Editar meus detalhes"
                                            popoutPosition="left"
                                        />
                                    </div>

                                    {/* email */}
                                    <div className={styles.userDetailContainer}>
                                        <div className={styles.userDetail}>
                                            <div className={styles.userDetailName}>Email: </div>
                                            <div className={styles.userDetailContent}>{userEmail}</div>
                                        </div>
                                        <ActionButton 
                                            icon={faEdit} 
                                            label="Editar meus detalhes"
                                            onClick={() => setShowEditModal(true)} // Show modal on click
                                            showHoverPopout={true}
                                            popoutText="Editar meus detalhes"
                                            popoutPosition="left"
                                        />
                                    </div>

                                    <div className={styles.userDetailContainer}>
                                        <div className={styles.userDetail}>
                                            <div className={styles.userDetailName}>Tipo de conta: </div>
                                            <div className={styles.userDetailContent}>{userRoles}</div>
                                        </div>
                                        <ActionButton 
                                            icon={faQuestionCircle} 
                                            label="Informação Tipo de Conta"
                                            showHoverPopout={true}
                                            popoutPosition="left"
                                            hoverComponent={<InfoTable headers={roleSummaryTableHeaders} rows={roleSummaryTableRows} popout={true} popoutPosition="left" />}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.companyOverview}>
                                {partnerLogoPath && (
                                    <div className={styles.userCompanyLogo}>
                                        <img
                                            src={partnerLogoPath}
                                            alt="Logotipo da empresa de contratação"
                                            className={styles.partnerLogo}
                                            aria-label="Logotipo da empresa de contratação"
                                        />
                                    </div>
                                )}
                                <div className={styles.userDetailContainer}>
                                    <div className={styles.userDetail}>
                                        <div className={styles.userDetailName}>Empresa: </div>
                                        <div className={styles.userDetailContent}>{companyDetails.companyName}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <p
                            className={styles.changePasswordButton}
                            onClick={() => setShowPasswordModal(true)}
                            role="button"
                            tabIndex="0"
                            aria-pressed="false"
                        >
                            Alterar senha
                        </p>
                        <p
                            className={styles.deleteAccountButton}
                            onClick={() => setShowConfirmModal(true)} // Open confirm modal
                            role="button"
                            tabIndex="0"
                            aria-pressed="false"
                        >
                            Excluir conta
                        </p>

                        {showPasswordModal && (
                            <AdminModal
                                title="Alterar Senha"
                                onClose={() => setShowPasswordModal(false)}
                                onConfirm={handlePasswordChange}
                                errorMessage={error}
                                successMessage={success}
                                isLoading={loading}
                            >
                                <ChangePasswordForm
                                    currentPassword={currentPassword}
                                    newPassword={newPassword}
                                    confirmNewPassword={confirmNewPassword}
                                    setCurrentPassword={setCurrentPassword}
                                    setNewPassword={setNewPassword}
                                    setConfirmNewPassword={setConfirmNewPassword}
                                    passwordRules={passwordRulesDescriptions}
                                />
                            </AdminModal>
                        )}

                        {showEditModal && (
                            <AdminModal
                                title="Editar meus detalhes"
                                onClose={() => setShowEditModal(false)} // Close modal
                                onConfirm={handleEditProfileDetails}
                                isLoading={loading}
                            >
                                <EditUserForm
                                    editedUserFirstName={newFirstName}
                                    editedUserLastName={newLastName}
                                    editedUserEmail={newEmail}
                                    setFirstName={setNewFirstName}
                                    setLastName={setNewLastName}
                                    setEmail={setNewEmail}
                                />
                            </AdminModal>
                        )}

                        {showConfirmModal && (
                            <ConfirmModal
                                show={showConfirmModal}
                                title="Confirmação de Exclusão"
                                message="Tem certeza de que deseja excluir sua conta?"
                                onConfirm={handleDelete} // Call handleDelete on confirm
                                onCancel={() => setShowConfirmModal(false)} // Close the confirm modal
                                confirmLabel="Excluir"
                                cancelLabel="Cancelar"
                            />
                        )}
                    </div>
                </div>
            </div>
        </TooltipProvider>
    );
};

export default MyAccountPage;