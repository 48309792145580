import React from 'react';
import Logo from '../common/DegrauLogo';
import styles from './Popup.module.css'; 

const TimeUpContent = ({ onLoad, logoLoaded, ...otherProps }) => {
    return (
        <>
            <Logo className={styles.degrauLogoPopup} onLoad={onLoad} /> 
            <p className={styles.popupTextLarge}>
                Obrigado por realizar nosso teste. O tempo permitido terminou. <br />
                Seu teste foi enviado com sucesso, parabéns!
            </p>
            <p className={styles.popupText}>
                Sua opinião é extremamente valiosa para nós. Por favor, clique no link abaixo para nos fornecer seu feedback.
            </p>
        </>
    );
};

export default TimeUpContent;