import React from 'react';
import Logo from '../common/DegrauLogo';
import styles from './Popup.module.css'; 

const ShareableLinkNoDataContent = ({ onLoad, logoLoaded, ...otherProps }) => {
    return (
        <>
            <Logo className={styles.degrauLogoPopup} onLoad={onLoad} /> 
            <div className={styles.popupText}>
            <p>Desculpe, mas este link não tem acesso a nenhum relatório. Por favor, verifique se você está usando o link correto.</p>
            <p>Se o problema persistir, por favor, entre em contato conosco pelo e-mail <b>suporte@degrau.co</b>. Estamos aqui para ajudar!</p>
        </div>
        </>
    );
};

export default ShareableLinkNoDataContent;