// Layout the messages (from chat instruction and the chatbox) into system, assistant or user messages to feed the GPT call
const formatGPTMessages = (chatData, section, chatInstruction, endOfConversation = false ) => {
    
    // Initialize the array with system and assistant prompts
    let formattedData = [];
    
    if (endOfConversation) {
        // Only process the closingPrompt as a system prompt if endOfConversation is true
        if (chatInstruction.closingPrompt) {
            formattedData.push({ role: 'system', content: chatInstruction.closingPrompt });
        }
    } else {
        if (chatInstruction.systemPrompt) {
            formattedData.push({ role: 'system', content: chatInstruction.systemPrompt });
        }
        if (chatInstruction.assistantPrompt) {
            formattedData.push({ role: 'assistant', content: chatInstruction.assistantPrompt });
        }
        if (chatInstruction.userPrompt) {
            formattedData.push({ role: 'user', content: chatInstruction.userPrompt });
        }
    }

    // Ensure chatData is an array before proceeding. Use an empty array if chatData is undefined.
    const safeChatData = Array.isArray(chatData) ? chatData : [];

    // Append and format the chat messages where system messages become assistant and user is user
    formattedData = formattedData.concat(
        safeChatData
            .filter(message => {
                const isIncluded = message.section === section;
                return isIncluded;
            })
            .map(message => {
                if (message.type === "system" || message.type === "assistant") {
                    return { role: "assistant", content: message.content };
                } else if (message.type === "user") {
                    return { role: "user", content: message.content };
                }
            })
    );

    return formattedData;
}

export default formatGPTMessages;