// Visualise the files available in the IDE and allow the user to open them.
import React, { useState, useEffect } from 'react';
import { useCodeContext } from '../../contexts/CodeContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faFolder, faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import styles from './FileExplorer.module.css';

const FileExplorer = ({ currentCode, activeFile, setActiveFile, defaultFile }) => {
  const { theme } = useCodeContext();
  const themeClass = theme === 'dark' ? styles.dark : styles.light;
  const [openFolders, setOpenFolders] = useState({}); // Keep track of which folders are open

  // Set the default file to be opened based on the defaultFile
  useEffect(() => {
    if (defaultFile) {
      console.log('defaultFile', defaultFile);
      const pathParts = defaultFile.split('/');
      const newOpenFolders = {};
      let currentPath = '';
      pathParts.slice(0, -1).forEach((part) => {
        currentPath = currentPath ? `${currentPath}/${part}` : part;
        newOpenFolders[currentPath] = true;
      });
      setOpenFolders(newOpenFolders);
    }
  }, [defaultFile]);

  const toggleFolder = (folderPath) => {
    setOpenFolders((prevState) => ({
      ...prevState,
      [folderPath]: !prevState[folderPath],
    }));
  };

  const renderFiles = (files, path = '') => {
    return Object.keys(files).map((key) => {
      const filePath = path ? `${path}/${key}` : key;
      const isFolder = typeof files[key] === 'object';

      return (
        <div key={filePath} className={styles.indent}>
          <div
            onClick={() => isFolder ? toggleFolder(filePath) : setActiveFile(filePath)}
            className={`${styles.fileItem} ${activeFile === filePath ? styles.activeFile : ''} ${themeClass}`}
          >
            <FontAwesomeIcon 
              icon={isFolder ? (openFolders[filePath] ? faFolderOpen : faFolder) : faFile} 
              className={`${styles.icon} ${isFolder ? styles.folderIcon : styles.fileIcon}`} 
            />
            <span>{key}</span>
          </div>
          {isFolder && openFolders[filePath] && renderFiles(files[key], filePath)}
        </div>
      );
    });
  };

  return <div className={`${styles.container} ${themeClass}`}>{renderFiles(currentCode)}</div>;
};

FileExplorer.propTypes = {
  currentCode: PropTypes.object.isRequired,
  activeFile: PropTypes.string.isRequired,
  setActiveFile: PropTypes.func.isRequired,
};

export default FileExplorer;
