// Connect to backend with WebSocket for fast data transfer (Autocomplete functions are not working)
import { logEvent, logException, logTrace } from './loggerFront';

let gptWs;
let autocompleteWs;
let gptMessageQueue = [];
let autocompleteMessageQueue = [];
let isGptWebSocketOpen = false;
let isAutocompleteWebSocketOpen = false;

const fileName = 'webSocketService' 

// GPT FUNCTIONS //

// Check if the WS is already open
export function isGptWebSocketCurrentlyOpen() {
    return isGptWebSocketOpen;
}

// OPEN THE WEBSOCKET //

// Establish a 2-way WebSocket connection between front-end app and back-end server to then send and receive messages. On failure retry as per attemptReconnect
export function openGptWebSocket(callbacks, webSocketUrl) {
    // If an existing WebSocket connection exists, close it to ensure a fresh connection
    if (gptWs) {
        gptWs.close();
    }

    // Initialize a new WebSocket connection to the specified URL
    let WEBSOCKET_URL;
    try {
        // Attempt to read the environment variable
        WEBSOCKET_URL = webSocketUrl || 'ws://localhost:3001';
        // Append the specific endpoint to the base URL
        WEBSOCKET_URL += '/gpt-chat';
    } catch (error) {
        // Log the error and use a default value if the variable is not defined
        logException('Error accessing webSocketUrl for gpt', {
            type: 'Gpt',
            errorMessage: error.message,
            errorStack: error.stack, 
            fileName: fileName,
            WEBSOCKET_URL: WEBSOCKET_URL,
            webSocketUrl: webSocketUrl,
        });
        WEBSOCKET_URL = 'ws://localhost:3001/gpt-chat';
    }
    
    gptWs = new WebSocket(WEBSOCKET_URL);

    // Once the WebSocket connection is successfully opened, send any queued messages
    gptWs.onopen = () => {
        isGptWebSocketOpen = true; // Flag to indicate the WebSocket is open
        gptMessageQueue.forEach(msg => gptWs.send(JSON.stringify(msg))); // Send each queued message
        gptMessageQueue = []; // Clear the message queue after sending
    };

    // Handle incoming messages from the WebSocket connection
    gptWs.onmessage = (event) => {
        const data = JSON.parse(event.data);
    
        // Handling acknowledgment messages so as not to resend the message
        if (data.runId && callbacks.onRunIdReceived) {
            callbacks.onRunIdReceived(data.runId); // Pass the `runId` to the callback
        } else if (data.type === 'ack' && data.id) {
            // Handling acknowledgment messages so as not to resend the message
            handleMessageAck(data.id); // Call the function to handle the acknowledgment
        } else if (data.error) {
            // Handling errors received from the server
            logException('Received error from GPT API', {
                type: 'Gpt',
                errorMessage: data.error.message,
                errorStack: data.error.stack, 
                fileName: fileName,
            });
            if (callbacks.onErrorReceived) {
                callbacks.onErrorReceived(data.error); // Invoke the error callback, if provided
            }
        } else {
            // Handling regular data and specific flags like end of stream
            if (data.endOfStream && callbacks.onEndOfStream) {
                callbacks.onEndOfStream("END_OF_STREAM");
            } else if (callbacks.onMessageReceived) {
                callbacks.onMessageReceived(data); // Pass regular data through the callback
            }
        }
    };    

    // Attempt to reconnect if an error occurs with the WebSocket connection
    gptWs.onerror = (error) => {
        logException('GPT WebSocket Error', {
            type: 'Gpt',
            errorMessage: `WebSocket error occurred. ReadyState: ${event.currentTarget.readyState}`,
            webSocketUrl: event.currentTarget.url,
            readyState: event.currentTarget.readyState,
            eventIsTrusted: event.isTrusted,
            eventType: event.type,
            timeStamp: event.timeStamp,
            fileName: fileName,
        });
        attemptReconnect(callbacks, webSocketUrl); // Defined below, attempts to reconnect with exponential backoff
    };

    // Handle the WebSocket connection closing
    gptWs.onclose = () => {
        isGptWebSocketOpen = false; // Update flag to indicate the WebSocket is closed
    };

    return gptWs; // Return the WebSocket instance
}

// Retry the connection if it fails
let retryDelay = 2000; // Start with a 2-second delay
let retryCount = 0; // Initialize retry count

// Retry openGptWebSocket
const attemptReconnect = (callbacks, webSocketUrl) => {
    if (retryCount < 11) { // Maximum of 11 retries
        logException(`WebSocket connection error. Attempting retry ${retryCount}.`, {
            type: 'Gpt',
            retryCount: retryCount,
            fileName: fileName,
        });
        setTimeout(() => {
            openGptWebSocket(callbacks, webSocketUrl); // Attempt to reconnect
            retryCount++; // Increment retry count
            retryDelay = Math.min(retryDelay * 2, 30000); // Double the delay, cap at 30 seconds
        }, retryDelay);
    } else {
        logException('Maximum WebSocket reconnection attempts reached.', {
            type: 'Gpt',
            fileName: fileName,
        });
    }
};

// SEND MESSAGE TO GPT //

let messageAckCallbacks = {}; // Object to hold callbacks for message acknowledgments
const generateMessageId = () => `msg_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`; // Function to generate a unique ID for each message to emable acknowledgement

// Function to handle message acknowledgments from the server, called in openGptWebSocket
const handleMessageAck = (ackId) => {
    const ackCallback = messageAckCallbacks[ackId];
    if (ackCallback) {
        ackCallback(); // Call the acknowledgment callback
        delete messageAckCallbacks[ackId]; // Remove the callback after calling
    }
};

// Send formatted prompts to the backend via the established WebScoket connection. On success acknowledgement will be received in openGptWebSocket. On failure retry
export function sendGptMessage(message, attempt = 0) {
    const MAX_RETRIES = 2;
    const RETRY_TIMEOUT = 5000; // 5 seconds

    if (gptWs && gptWs.readyState === WebSocket.OPEN) {
        const messageId = generateMessageId();

        // Add this message's ack callback
        messageAckCallbacks[messageId] = () => {
            // Additional logic upon acknowledgment can be placed here
        };

        // Send the message as a JSON with its ID
        gptWs.send(JSON.stringify({ ...message, id: messageId }));

        // Set up retry if acknowledgment is not received within timeout
        setTimeout(() => {
            if (messageAckCallbacks[messageId]) { // If the ack callback still exists, the message was not acknowledged
                delete messageAckCallbacks[messageId]; // Remove callback to prevent memory leak
                if (attempt < MAX_RETRIES) {
                    sendGptMessage(message, attempt + 1); // Retry sending the message
                } else {
                    // Handle max retry limit reached (e.g., notify user)
                }
            }
        }, RETRY_TIMEOUT);
    } else {
        gptMessageQueue.push(message); // Add the message to the queue
    }
}

// Close the WS and return a promise for handleGPTConversation to work with
export function closeGptWebSocket() {
    return new Promise((resolve, reject) => {
        if (gptWs && gptWs.readyState === WebSocket.OPEN) {

            // Set up an event listener to handle the WebSocket closing
            gptWs.onclose = (event) => {
                if (event.wasClean) {
                } else {
                }
                isGptWebSocketOpen = false;
                resolve(); // Resolve the promise when the WebSocket is closed
            };

            // Attempt to close the WebSocket
            gptWs.close();
        } else {
            resolve(); // Resolve immediately if there's nothing to close
        }
    });
}

// CONNECTION TESTING WEBSOCKET //

let connectionWs;
let isConnectionWebSocketOpen = false;
let isClosureIntentional = false; 
let connectionRetryCount = 0; // To track the number of retry attempts
let connectionMessageQueue = []; // Queue any messages not sent as ws closed

const connectionMaxRetries = 5; // Maximum number of retries
const retryDelayMultiplier = 2;

let heartbeatIntervalId; // For the ongoing pulse to check connection

// Function to calculate the delay before the next retry attempt
const calculateRetryDelay = (attempt) => {
    return Math.pow(retryDelayMultiplier, attempt) * 1000; // Delay in milliseconds
};

export function openConnectionWebSocket(webSocketUrl, initialData) {
    isClosureIntentional = false;
    // Close existing WebSocket connection if open
    if (connectionWs) {
        connectionWs.close();
    }

    // Initialize a new WebSocket connection to the specified URL
    let WEBSOCKET_URL;
    try {
        // Attempt to read the environment variable
        WEBSOCKET_URL = webSocketUrl || 'ws://localhost:3001';
        // Append the specific endpoint to the base URL
        WEBSOCKET_URL += '/test-interrupted';
    } catch (error) {
        // Log the error and use a default value if the variable is not defined
        logException('Error accessing webSocketUrl for connection testing', {
            type: 'Connection',
            errorMessage: error.message,
            errorStack: error.stack, 
            fileName: fileName,
            WEBSOCKET_URL: WEBSOCKET_URL,
            webSocketUrl: webSocketUrl,
        });
    }

    // Initialize a new WebSocket connection
    connectionWs = new WebSocket(WEBSOCKET_URL);

    // Open the ws and send the initial data to the server
    connectionWs.onopen = () => {
        isConnectionWebSocketOpen = true;
        // Send each queued message
        connectionMessageQueue.forEach((queuedMessage) => {
            connectionWs.send(queuedMessage);
        });
        connectionMessageQueue = []; // Clear the message queue
        retryCount = 0; // Reset retry count on successful connection
    
        // Send initial data to the server
        if (initialData) {
            const message = JSON.stringify({
                type: 'INITIAL_DATA', // Specify the type of this message
                data: initialData // Include the actual initial data
            });
            connectionWs.send(message);
            logTrace('Sent initial data in connection ws:', {
                data:message,
                type: 'Websocket',
                status: 'Success',
                fileName: fileName
            });
        }

        // Start the pulse check
        startHeartbeat();
    };    

    connectionWs.onmessage = (event) => {
        // Handle incoming messages related to connection status
        const data = JSON.parse(event.data);
        // Process the data (e.g., handle connection status updates)
    };

    connectionWs.onerror = (error) => {
        logException('Error making connection ws', {
            type: 'Websocket',
            errorMessage: error.message,
            errorStack: error.stack, 
            fileName: fileName,
            WEBSOCKET_URL: WEBSOCKET_URL,
            webSocketUrl: webSocketUrl,
        });
        // Handle WebSocket errors here
    };

    connectionWs.onclose = (event) => {
        isConnectionWebSocketOpen = false;
        stopHeartbeat(); // Stop the pulse check 
        if (!isClosureIntentional && connectionRetryCount < connectionMaxRetries) {
            const delay = calculateRetryDelay(retryCount);
            setTimeout(() => openConnectionWebSocket(webSocketUrl, initialData), delay);
            retryCount++;
        } else {
            // Maximum retries met, backend will act on lost connection in testInterruptedManager
        }
    };

    return connectionWs;
}

// Close the ws
export function closeConnectionWebSocket() {
    isClosureIntentional = true;
    if (connectionWs) {
        connectionWs.close();
        stopHeartbeat(); // stop the pulse check
        isConnectionWebSocketOpen = false;
    }
}

// Inform server that the test is complete correctly
export function signalIntentionalClosure() {
    if (connectionWs && connectionWs.readyState === WebSocket.OPEN) {
        const message = JSON.stringify({
            type: 'TEST_COMPLETED'
        });
        connectionWs.send(message);
    }
}

// Stop the pulse check with the server 
function stopHeartbeat() {
    if (heartbeatIntervalId) {
        clearInterval(heartbeatIntervalId);
    }
}

// Ongoing pulse function that check conection with server
function startHeartbeat() {
    // Define the frequency of heartbeat messages
    const heartbeatFrequency = 30000; // 30 seconds in milliseconds

    // Clear any existing heartbeat interval to avoid duplicates
    if (heartbeatIntervalId) {
        clearInterval(heartbeatIntervalId);
    }

    // Set up an interval to send a heartbeat message periodically
    heartbeatIntervalId = setInterval(() => {
        if (connectionWs && connectionWs.readyState === WebSocket.OPEN) {
            connectionWs.send(JSON.stringify({ type: 'HEARTBEAT' }));
        }
    }, heartbeatFrequency);
}

export function isConnectionWebSocketCurrentlyOpen() {
    return isConnectionWebSocketOpen;
}

// Send a critical error message to the backend when the site fails but connection remains. this is sent when the popups appear to the user informing of an issue and saying we are retrying
export function sendCriticalErrorMessage(testAttemptId, candidateId, candidateEmail, adminAccount, reason) {
    logEvent('sendCriticalErrorMessage', {
        testAttemptId, 
        candidateEmail, 
        candidateId,
        reason,
        adminAccount,
        fileName: fileName,
        type: 'Websocket'
    });
    const errorMessage = JSON.stringify({
        type: 'CRITICAL_ERROR',
        data: {
          testAttemptId: testAttemptId,
          candidateEmail: candidateEmail,
          reason: reason,
          adminAccount: adminAccount,
          candidateId: candidateId
        },
    });
    if (connectionWs && connectionWs.readyState === WebSocket.OPEN) {
      connectionWs.send(errorMessage);
    } else {
      // Handle the case where WebSocket is not open;
      connectionMessageQueue.push(errorMessage); // Add message to the queue
      logException(`WebSocket connection not open. added CRITICAL_ERROR message to queue.`, {
        testAttemptId,
        candidateId,
        reason
      });
    }
}

// Send an error resolved message to the backend that the critical error has been solved during the retry period
export function sendErrorResolvedMessage(testAttemptId, candidateId) {
    logEvent('sendErrorResolvedMessage', {
        testAttemptId, 
        candidateId,
        fileName: fileName,
        type: 'Websocket'
    });
    const resolvedMessage = JSON.stringify({
        type: 'ERROR_RESOLVED',
        data: {
          testAttemptId: testAttemptId,
          candidateId: candidateId,
        },
    });
    if (connectionWs && connectionWs.readyState === WebSocket.OPEN) {
      connectionWs.send(resolvedMessage);
    } else {
      // Handle the case where WebSocket is not open;
      connectionMessageQueue.push(resolvedMessage); // Add message to the queue
      logException(`WebSocket connection not open. added ERROR_RESOLVED message to queue.`, {
        testAttemptId,
        candidateId
      });
      // Implement reconnection logic or other fallback here if necessary
    }
}
 