// Show the privacy policy to the user
import React from 'react';
import { TooltipProvider } from '../contexts/TooltipContext'; // Needed for header to work
import Header from '../components/common/Header';
import LegalText from '../components/common/LegalText';

const PrivacyPolicyPage = () => {

  return (
    <TooltipProvider>
      <Header />
      <LegalText variant="privacyPolicy" />
    </TooltipProvider>
  );
};

export default PrivacyPolicyPage;
