import { logException } from '../services/loggerFront';

export const retryRequest = async (fn, retries = 3, delay = 1000, backoffFactor = 2) => {
  for (let attempt = 1; attempt <= retries; attempt++) {
    try {
      return await fn(); // Try the function
    } catch (error) {
      logException(`Error in function ${fn.name} - Attempt ${attempt} failed.`, {
        errorMessage: error.message,
        errorStack: error.stack,
        attempt,
        delay,
        functionName: fn.name,
      });

      if (attempt < retries) {
        console.warn(`Attempt ${attempt} failed. Retrying in ${delay}ms...`);
        await new Promise(res => setTimeout(res, delay)); // Wait before retrying
        delay *= backoffFactor; // Increase the delay exponentially
      } else {
        throw error; // If all retries fail, throw the error
      }
    }
  }
};
