//Gets camera permission, lets user take initial photo and save, calls ongoing photo to occur, saves camera taking status and rationale if rejected to DB
import React, { useContext, useEffect, useState } from "react";
import { FadeLoader } from "react-spinners"; //for the loading icon waiting for the camera
import { AppContext } from "../../contexts/AppContext";
import { useTestContext } from "../../contexts/TestContext";
import useCameraAccess from "../../hooks/useCameraAccess";
import { capturePhoto } from "../../services/PhotoCaptureService";
import { logEvent, logException, logTrace } from "../../services/loggerFront";
import Button from "../common/Button";
import Popup from "../popup/Popup";
import ScreenCover from "../popup/ScreenCover";
import styles from "./PhotoCheck.module.css";

const PhotoCheck = ({
  hidePhotoContainer,
  fetchNextChatInstruction,
  currentChatInstructionIndex,
  chatInstructions,
  addSystemMessage,
  prepareNextAction,
  userInputCount,
  messages,
  setMessages,
}) => {
  const { setIsPhotoCheckActive, setCameraStatus, videoRef, canvasRef, candidateId } =
    useTestContext();
  const [isCameraLoading, setIsCameraLoading] = useState(true); //is camera loading initially, show user loading status if so
  const { videoStream, cameraError, requestCameraAccess } = useCameraAccess(); //is there camera permission
  const [isPhotoCaptured, setIsPhotoCaptured] = useState(false); //has the user pressed take photo
  const [isScreenCoverVisible, setIsScreenCoverVisible] = useState(false);
  const [isCameraRejectPopupVisible, setIsCameraRejectPopupVisible] =
    useState(false);
  const [isCameraFailedPopupVisible, setIsCameraFailedPopupVisible] =
    useState(false);
  const fileName = "PhotoCheck"; // for logging

  useEffect(() => {
    // When PhotoCheck mounts
    setIsPhotoCheckActive(true);

    return () => {
      // When PhotoCheck unmounts
      setIsPhotoCheckActive(false);
    };
  }, [setIsPhotoCheckActive]);

  // When user clicks 'Tirar Foto' button call for the photo to be captured, update isPhotoCaptured so the UI shows the photo to the user, decide the canvas dimensions by making it equal to the video aspect ratio
  const handleCaptureClick = () => {
    if (videoRef.current && canvasRef.current) {
      // Call capturePhoto from PhotoCaptureService with desired width
      capturePhoto(canvasRef, videoRef, 320);

      setIsPhotoCaptured(true); // Update local state to indicate that a photo has been captured
      setIsCameraFailedPopupVisible(false);

      // Log in AppInsights
      logTrace("handleCaptureClick", { candidateId: candidateId });
    }
  };

  // When the user clicks the button under the canvas saying they dont want to take a photo. This calls a popup
  const handleRejectPhotoClick = () => {
    setIsScreenCoverVisible(true);
    setIsCameraRejectPopupVisible(true);
    setIsCameraFailedPopupVisible(false);

    // Log in AppInsights
    logTrace("handleRejectPhotoClick photoCheck", {
      candidateId: candidateId,
      fileName: fileName,
    });
  };

  //when they click the confirm not to take photo button
  const handleConfirmReject = (reason) => {
    // Logic to save the reason
    setIsScreenCoverVisible(false);
    setIsCameraRejectPopupVisible(false);
    setCameraStatus({ isActive: false, rationale: reason }); //Save that camera was opted out and the rationale filled in the popup
    hidePhotoContainer();
    fetchNextChatInstruction();

    // Log in AppInsights
    logTrace("handleConfirmReject photoCheck", {
      candidateId: candidateId,
      fileName: fileName,
    });
  };

  // If they return and dont follow through with rejecting the photo taking
  const handleCancelReject = () => {
    setIsScreenCoverVisible(false);
    setIsCameraRejectPopupVisible(false);
    setIsCameraFailedPopupVisible(false);
    setIsCameraLoading(true);

    // Stop any existing video stream before re-requesting camera access
    if (videoStream) {
      videoStream.getTracks().forEach((track) => track.stop());
    }

    // Re-request camera access in case it was an error the first time
    requestCameraAccess();

    // Log in AppInsights
    logTrace("handleCancelReject photoCheck", {
      candidateId: candidateId,
      fileName: fileName,
    });
  };

  // After salvar foto is pressed, move onto the next message and save the photo and start taking photo ongoing
  const handleSavePhoto = () => {
    // Update UI to remove video and canvas, move onto the next chat instruction, update camera status to store anti-fraud state. The actual saving is done when testAttemptId is received (in AppContext)
    setCameraStatus({ isActive: true, rationale: "" }); //Save that camera is active, this enables testContext to call save photo after the testAttemptId is received
    hidePhotoContainer();
    fetchNextChatInstruction(
      currentChatInstructionIndex,
      chatInstructions,
      addSystemMessage,
      prepareNextAction,
      userInputCount,
      messages,
      setMessages
    );

    // Log in AppInsights
    logTrace("Saving the candidate's photo", { candidateId: candidateId });
  };

  useEffect(() => {
    if (videoStream) {
      videoRef.current.srcObject = videoStream;
      videoRef.current
        .play()
        .then(() => setIsCameraLoading(false))
        .catch((e) => console.error("Error playing video stream:", e));
    } else if (cameraError) {
      console.error("Camera access error:", cameraError);
      logException("CameraAccessException", {
        error: cameraError,
        candidateId: candidateId,
        fileName: fileName,
      });
      // Handle the camera access error by showing the rejection popup
      setIsCameraLoading(false); // Stop showing loading indicator
      setIsScreenCoverVisible(true);
      setIsCameraFailedPopupVisible(true); // Show popup indicating camera access was rejected or failed
      setIsCameraRejectPopupVisible(false);
    }

    return () => {
      videoStream?.getTracks().forEach((track) => track.stop());
    };
  }, [videoStream, cameraError]);

  return (
    <div>
      {isCameraLoading ? (
        <div className={styles.loadingIndicator}>
          <FadeLoader size={80} color={"#246E24"} loading={isCameraLoading} />
          Solicitando câmera
        </div>
      ) : (
        <>
          {/* Render the Take Photo button only if isCameraLoading is false */}
          <Button
            onClick={handleCaptureClick}
            type="default"
            className="takePhoto"
          >
            {isPhotoCaptured ? "Retirar Foto" : "Tirar Foto"}
          </Button>
        </>
      )}
      <button
        onClick={handleRejectPhotoClick}
        className={styles.rejectPhotoText}
      >
        Não quero compartilhar minha imagem
      </button>
      {/*canvas will render here due to rules in global.css*/}
      {isPhotoCaptured && (
        <Button onClick={handleSavePhoto} type="default" className="savePhoto">
          Salvar Foto
        </Button>
      )}

      <ScreenCover
        isVisible={isScreenCoverVisible}
        variant="blur"
        zIndex={11}
      />
      <Popup
        variant="CameraReject"
        isVisible={isCameraRejectPopupVisible}
        zIndex={12}
        onClose={handleCancelReject}
        onConfirmReject={handleConfirmReject}
        onCancelReject={handleCancelReject}
        fetchNextChatInstruction={fetchNextChatInstruction}
      />
      <Popup
        variant="CameraFail"
        isVisible={isCameraFailedPopupVisible}
        zIndex={12}
        onClose={handleCancelReject}
        onConfirmReject={handleConfirmReject}
        onCancelReject={handleCancelReject}
        fetchNextChatInstruction={fetchNextChatInstruction}
      />
    </div>
  );
};

export default PhotoCheck;
