import React, { useState, useContext } from 'react';
import { AppContext } from '../../contexts/AppContext';
import FeedbackContext from '../../contexts/FeedbackContext';
import { logEvent, logException } from '../../services/loggerFront';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsDown, faThumbsUp } from '@fortawesome/free-regular-svg-icons'; // non-filled icons
import { faTimes } from '@fortawesome/free-solid-svg-icons'; // filled icons
import Button from '../common/Button';
import { saveUserFeedback, appendFeedbackComment, deleteUserFeedback } from '../../services/databaseService';
import styles from './FeedbackInput.module.css';

const FeedbackInput = ({ feedbackDetails, popout = false, chatMessage = false }) => {
    const [isFeedbackInputVisible, setIsFeedbackInputVisible] = useState(false);
    const [isHoveringThumbUp, setIsHoveringThumbUp] = useState(null); // Show popout when hovering
    const [isHoveringThumbDown, setIsHoveringThumbDown] = useState(null);
    const [feedbackText, setFeedbackText] = useState({});
    const [showFeedbackError, setShowFeedbackError] = useState(false);
    const [showThankYouMessage, setShowThankYouMessage] = useState(false);
    const [clickedThumbs, setClickedThumbs] = useState({});
    const { formatDateForMySQL } = useContext(AppContext);
    const { feedbackMap, updateFeedback, updateFeedbackText } = useContext(FeedbackContext);
    const key = `${feedbackDetails.test_attempt_id}_${feedbackDetails.location}_${feedbackDetails.prompt_id}`;
    const feedbackInfo = feedbackMap[key] || {}; // Get the feedback info for the specified key

    const fileName = 'FeedbackInput.js'

    const handleFeedbackClick = async (evaluation) => {
        setIsFeedbackInputVisible(key); // Show the feedback input
        const existingFeedback = feedbackMap[key];
        const timestamp = formatDateForMySQL(new Date());
    
        if (existingFeedback) {
            // If the existing feedback matches the clicked thumb, it means the user wants to cancel it
            if (existingFeedback.eval === evaluation) {
                try {
                    // Delete the feedback from the database
                    await deleteUserFeedback(existingFeedback.feedbackId); // Custom function to delete feedback by ID
                    
                    // Remove the feedback from the context
                    delete feedbackMap[key]; 
                    
                    // Unclick the thumb in the local state
                    setClickedThumbs({ ...clickedThumbs, [key]: null });
                    
                    // Hide the feedback input
                    setIsFeedbackInputVisible(false);
                    
                    return; // Exit the function, nothing else to do
                } catch (error) {
                    logException('Error cancelling feedback:', {
                        feedbackId: existingFeedback.feedbackId,
                        testAttemptId: feedbackDetails.testAttemptId,
                        location: feedbackDetails.location,
                        fileName,
                        errorMessage: error.message,
                        errorStack: error.stack,
                    });
                }
            } else {
                // If the feedback is different, delete the previous feedback
                try {
                    await deleteUserFeedback(existingFeedback.feedbackId); // Delete the previous feedback
                } catch (error) {
                    logException('Error deleting feedback:', {
                        feedbackId: existingFeedback.feedbackId,
                        testAttemptId: feedbackDetails.testAttemptId,
                        location: feedbackDetails.location,
                        fileName,
                        errorMessage: error.message,
                        errorStack: error.stack,
                    });
                }
            }
        }
    
        // Save the new feedback if the user clicked the oppopsite thumb
        const feedbackData = {
            ...feedbackDetails,
            eval: evaluation,
            timestamp,
        };
    
        try {
            const feedbackId = await saveUserFeedback(feedbackData); // Save the new feedback
            updateFeedback(key, { feedbackId, eval: evaluation, timestamp }); // Update the feedback map with the new feedback ID
            
            // Keep track of the clicked thumb
            setClickedThumbs({ ...clickedThumbs, [key]: evaluation });
        } catch (error) {
            logException('Error saving feedback:', {
                feedbackId: existingFeedback.feedbackId,
                testAttemptId: feedbackDetails.testAttemptId,
                location: feedbackDetails.location,
                fileName,
                errorMessage: error.message,
                errorStack: error.stack,
            });
        }
    };      

    const handleFeedbackTextChange = (e) => {
        const text = e.target.value;
        setFeedbackText({ ...feedbackText, [key]: text }); // Update local state
        updateFeedbackText(key, text); // Update the text in the context
    };    

    // When the user clicks the 'x' to close the comment space
    const handleFeedbackClose = () => {
        setIsFeedbackInputVisible(false);
    };

    // When user presses send in the comment input
    const handleFeedbackSubmit = async () => {
        const feedbackId = feedbackMap[key]?.feedbackId; // Get feedback ID for the specified key
        
        if (!feedbackText[key] || feedbackText[key].trim() === '') { // Check if the text is empty
            setShowFeedbackError(true);
            setTimeout(() => {
                setShowFeedbackError(false);
            }, 5000);
            return;
        }

        setIsFeedbackInputVisible(false); // Hide the feedback input after successful submission

        // Show thank you message for 3 seconds
        setShowThankYouMessage(true);
        setTimeout(() => {
            setShowThankYouMessage(false);
        }, 3000);
    
        try {
            await appendFeedbackComment(feedbackId, feedbackText[key]); // Append the comment
        } catch (error) {
            logException('Error appending feedback comment:', {
                feedbackId: feedbackId,
                testAttemptId: feedbackDetails.testAttemptId,
                location: feedbackDetails.location,
                comment: feedbackText[key],
                errorMessage: error.message,
                errorStack: error.stack, 
            });
        }
    };
    

    const feedbackComponentClass = popout ? `${styles.feedbackInputContainer} ${styles.popout}` : styles.feedbackInputContainer;
    const closeIconClass = popout ? `${styles.closeIcon} ${styles.popout}` : styles.closeIcon; // Move position of close icon for popout
    const feedbackIconContainerClass = chatMessage ? `${styles.feedbackIconContainer} ${styles.chatMessage}` : styles.feedbackIconContainer; // Change margins if chat message
    const feedbackThankYouClass = popout ? `${styles.feedbackThankYou} ${styles.popout}` : styles.feedbackThankYou; // Move position of thank you message for popout

    return (
        <div className={styles.feedbackComponent}>
            <div className={feedbackIconContainerClass}>
                <FontAwesomeIcon 
                    icon={faThumbsUp} 
                    className={`${styles.feedbackIcon} ${styles.feedbackIconPos} ${feedbackInfo.eval === 'good' ? styles.thumbUpClicked : ''}`} 
                    onClick={() => handleFeedbackClick('good')}
                    onMouseEnter={() => setIsHoveringThumbUp(true)}
                    onMouseLeave={() => setIsHoveringThumbUp(false)} 
                />
                {isHoveringThumbUp && <div className={styles.thumbUpHoverMessage}>{chatMessage ? 'Resposta boa' : 'Avaliação boa'}</div>}
                <FontAwesomeIcon 
                    icon={faThumbsDown} 
                    className={`${styles.feedbackIcon} ${styles.feedbackIconNeg} ${feedbackInfo.eval === 'bad' ? styles.thumbDownClicked : ''}`} 
                    onClick={() => handleFeedbackClick('bad')}
                    onMouseEnter={() => setIsHoveringThumbDown(true)}
                    onMouseLeave={() => setIsHoveringThumbDown(false)} 
                />
                {isHoveringThumbDown && <div className={styles.thumbDownHoverMessage}>{chatMessage ? 'Resposta ruim' : 'Avaliação ruim'}</div>}
            </div>
            {isFeedbackInputVisible === key && (
                <div className={feedbackComponentClass}>
                    <FontAwesomeIcon 
                        icon={faTimes} 
                        className={closeIconClass}
                        onClick={handleFeedbackClose}
                    />
                    <div className={styles.feedbackInput}>
                        <textarea 
                            className={styles.feedbackTextArea} 
                            placeholder="Obrigado, por favor, conte-nos mais (opcional)" 
                            value={feedbackMap[key]?.text || ''} // Use optional chaining and default value
                            onChange={handleFeedbackTextChange}
                        />
                        <Button 
                            onClick={handleFeedbackSubmit} 
                            type="feedback">
                        </Button>
                    </div>
                    {showFeedbackError && (
                        <div className={styles.feedbackError}>
                            O texto não pode estar em branco.
                        </div>
                    )}
                </div>
            )}
            {showThankYouMessage && (
                <div className={feedbackThankYouClass}>
                    Obrigado por ajudar a melhorar o produto!
                </div>
            )}
        </div>
    );
};

export default FeedbackInput;
