import React from 'react';
import ReactDOM from 'react-dom';
import './index.css'; // If you have global styles
import App from './App';
import reportWebVitals from './reportWebVitals'; // If you're using web vitals
import './global.css';
import ace from 'ace-builds/src-noconflict/ace';

// Set Ace Editor base path globally
ace.config.set('basePath', 'https://cdnjs.cloudflare.com/ajax/libs/ace/1.4.12/');

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you're using web vitals
reportWebVitals();
