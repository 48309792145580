import React, { useState } from 'react';
import { saveIssue } from '../../services/databaseService';
import Logo from '../common/DegrauLogo';
import Button from '../common/Button';
import styles from './Popup.module.css';
import { FadeLoader } from 'react-spinners'; // Spinner for when the request is being sent
import { logEvent, logException } from '../../services/loggerFront';

const ActiveTestContent = ({ candidateId, testAttemptId, status, email, onLoad, logoLoaded, ...otherProps }) => {
    const [reason, setReason] = useState('');
    const [error, setError] = useState('');
    const [showForm, setShowForm] = useState(false);
    const [showThankYouMessage, setShowThankYouMessage] = useState(false); // After they submit request to start from beginning
    const [showSendingMessage, setShowSendingMessage] = useState(false);
    const [showErrorSendingMessage, setShowErrorSendingMessage] = useState(false);
    const activeTestCompleted = status === 'complete' || status === 'timeUp';
    const fileName = 'ActiveTestContent.js';

    // Create the urlCode that will be used to retrieve the session
    const testAttemptIdReversed = String(testAttemptId).split('').reverse().join('');
    const urlCode = parseInt(testAttemptId * candidateId + testAttemptIdReversed);

    // If the user requests to start from the beginning, activate as per ReportIssue
    const handleConfirmRequest = () => {
        if (!reason.trim()) {
            setError('Por favor, informe o motivo por que você quer começar do começo.');
            return;
        }
        setError('');
        setShowForm(false); // Hide the form
        setShowThankYouMessage(false); // Hide the thank you message
        setShowErrorSendingMessage(false); // Hide the error message
        setShowSendingMessage(true); // Show the sending message loader

        // Gather the details of the issue
        const issueDetails = {
            candidate_id: candidateId,
            test_attempt_id: testAttemptId,
            email: email,
            issue_content: `User wants to start test from beginning as "${reason}"`,
            source: 'restart test popup',
            timestamp: new Date().toISOString().slice(0, 19).replace('T', ' ')
        };
        // Call db function to save the request and email admin
        const attemptSave = (retryCount = 0) => {
            saveIssue(issueDetails)
                .then(response => {
                    setShowSendingMessage(false); // Hide sending message
                    setShowThankYouMessage(true); // Show thank you message so user knows it sent
                })
                .catch(error => {
                    logException(`Error: Reporting issue`, { 
                        errorMessage: error.message,
                        errorStack: error.stack,  
                        fileName: fileName,
                        retryCount: retryCount, 
                    });
                    if (retryCount < 1) { // Retry once after 5 seconds if it fails
                        setTimeout(() => attemptSave(retryCount + 1), 5000);
                    } else {
                        // After retrying, show error message that directs user to email suporte
                        setShowSendingMessage(false);
                        setShowErrorSendingMessage(true);
                        setShowForm(true); // Show the form to try again
                    }
                });
        };
        attemptSave(); // Call attemptSave to initiate the save process

        logEvent(`User requested to start from start`, { 
            issueDetails: issueDetails,
        });
    };

    return (
        <>
            <Logo className={styles.degrauLogoPopup} onLoad={onLoad} />
            <p className={styles.popupText}>
                {activeTestCompleted ? 
                    "Identificamos uma entrevista concluída com seu registro. Você só pode realizar o teste uma vez por esta vaga." :
                    "Identificamos uma entrevista incompleta com seu registro. Clique abaixo para reiniciar de onde parou."}
            </p>
            <div className={styles.restartTestButtonContainer}>
                {!activeTestCompleted && (
                    <Button onClick={() => window.location.href = `/entrevista?tentativa=${urlCode}`} type="proceed" >
                        Reiniciar a entrevista
                    </Button>
                )}
                <Button onClick={() => setShowForm(true)} type="subtle">
                    Eu quero começar do começo
                </Button>
            </div>
            {showForm && (
                <div className={styles.restartFormAndButton}>
                    <form className={styles.startFromBeginningForm}>
                        <label htmlFor="startFromBeginingDescription">Por favor, deixe-nos saber por que você quer começar do começo e responderemos o mais rapidamente possível.</label>
                        <textarea 
                            id="startFromBeginingDescription" 
                            name="startFromBeginingDescription" 
                            placeholder="Escreva aqui" 
                            required 
                            value={reason}
                            onChange={e => setReason(e.target.value)}
                            className={styles.startFromBeginningTextarea} 
                        ></textarea>
                        {error && <p className={styles.errorText}>{error}</p>}
                    </form>
                    <Button onClick={handleConfirmRequest} >
                        Enviar pedido
                    </Button>
                </div>
            )}
            {showThankYouMessage && (
                <p className={styles.popupText}>
                    Obrigado por submeter o pedido. Responderemos ao seu e-mail o mais breve possível. 
                </p>
            )}
            {showSendingMessage && (
                <div className={styles.sendingMessageContainer}>
                    <FadeLoader size={60} color={"#246E24"} loading={showSendingMessage} />
                    <p>Enviando seu pedido...</p>
                </div>
            )}
            {showErrorSendingMessage && (
                <p className={styles.popupText}>
                    Lamentamos, mas houve um problema com a conexão. Por favor, entre em contato conosco pelo e-mail <b>suporte@degrau.co</b>
                </p>
            )}
        </>
    );
};

export default ActiveTestContent;
