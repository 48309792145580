// Popup stating test is already completed
import React from 'react';
import Logo from '../common/DegrauLogo';
import styles from './Popup.module.css'; 

const RetrievedTestCompleteContent = ({ onLoad, logoLoaded, ...otherProps }) => {
    return (
        <>
            <Logo className={styles.degrauLogoPopup} onLoad={onLoad} /> 
            <div className={styles.popupText}>
                <p>Nossos registros mostram que este teste já foi concluído.<br />Se você tiver algum problema, por favor, entre em contato conosco pelo e-mail <b>suporte@degrau.co</b> para obter ajuda. Estamos aqui para ajudar!</p>
            </div>
        </>
    );
};

export default RetrievedTestCompleteContent;
