import React from 'react';
import PropTypes from 'prop-types';
import Logo from '../common/DegrauLogo';
import PasswordInputField from '../inputs/PasswordInputField';
import Button from '../common/Button';
import styles from './Popup.module.css';

const ResetPasswordContent = ({
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
  handleAction,
  errorMessage,
  successMessage,
  passwordRules,
  onLoad,
  logoLoaded,
  ...otherProps
}) => {
  return (
    <>
      <Logo className={styles.degrauLogoPopupLarge} onLoad={onLoad} />
      {logoLoaded && (
        <>
          <p className={styles.popupTextLarge}>
            Bem-vindo de volta! Por favor, insira sua nova senha.
          </p>
          <form
            className={styles.inputFieldContainerWide}
            onSubmit={(e) => {
              e.preventDefault(); // Prevent the default form submission
              handleAction(); // Call the custom action handler
            }}
          >
            <div className={styles.inputFieldAndLabel}>
              <label htmlFor="new-password">Nova Senha</label>
              <PasswordInputField
                id="new-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder=""
                autoComplete="new-password"
                aria-required="true"
              />
            </div>
            <div className={styles.inputFieldAndLabel}>
              <label htmlFor="confirm-password">Confirmar Nova Senha</label>
              <PasswordInputField
                id="confirm-password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder=""
                autoComplete="new-password"
                aria-required="true"
              />
            </div>
            {/* Password Rules Note */}
            <div className={styles.passwordRules}>
              <p>A senha deve conter pelo menos:</p>
              <ul>
                {passwordRules.map((rule, index) => (
                  <li key={index}>{rule}</li>
                ))}
              </ul>
            </div>
            {errorMessage && (
              <p className={styles.errorText} role="alert">
                {errorMessage}
              </p>
            )}
            {successMessage && (
              <p className={styles.successText} role="alert">
                {successMessage}
              </p>
            )}
            <Button
              type="form"
              disabled={!password || !confirmPassword}
            >
              Redefinir Senha
            </Button>
          </form>
        </>
      )}
    </>
  );
};

// Specify the types of each prop passed to the component and if it's required or not
ResetPasswordContent.propTypes = {
  confirmPassword: PropTypes.string.isRequired,
  setConfirmPassword: PropTypes.func.isRequired,
  password: PropTypes.string.isRequired,
  setPassword: PropTypes.func.isRequired,
  handleAction: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  successMessage: PropTypes.string,
  passwordRules: PropTypes.array.isRequired,
  onLoad: PropTypes.func,
  logoLoaded: PropTypes.bool,
};

export default ResetPasswordContent;
