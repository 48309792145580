import React, { useState, useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import { HashLink as Link } from 'react-router-hash-link';
import { TooltipProvider } from "../contexts/TooltipContext";
import throttle from '../utils/throttle';
import {extractHeadingsFromMarkdown, slugify} from '../utils/extractHeadings';
import useEnsureUserData from '../hooks/useEnsureUserData';
// Standard page components
import Header from '../components/common/Header';
import TopRibbon from '../components/common/TopRibbon';
import ReportIssue from "../components/common/ReportIssue";
import CompanyPageSidebar from '../components/common/CompanyPageSidebar';
import CompanyPageSidebarToggle from '../components/common/CompanyPageSidebarToggle';

import { faChevronLeft, faChevronRight, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import remarkGfm from 'remark-gfm';
import rehypeStringify from 'rehype-stringify';
import styles from "../sharedStyles/CompanyAdminPages.module.css"; 
import howToDoc from '../dataInputs/howToDoc.md';

const combineText = (children) => {
    return React.Children.toArray(children).reduce((text, child) => {
        return typeof child === 'string' ? text + child : text;
    }, '');
};

// Scroll to Top Button Component
const ScrollToTopButton = ({ isVisible, onClick }) => {
    return (
        <button
            className={`${styles.scrollToTopButton} ${isVisible ? styles.visible : ''}`}
            onClick={onClick}
            aria-label="Voltar ao topo"
        >
            <i className="fas fa-arrow-up"></i>
        </button>
    );
};

// Main Page Component
const DocumentationPage = () => {
    const { fetchUserData } = useEnsureUserData(); // Ensure user data is fetched
    // on load fetch user data
    useEffect(() => {
        fetchUserData();
    }, []);
    const [isSidebarVisible, setIsSidebarVisible] = useState(true);
    const [content, setContent] = useState('');
    const [headings, setHeadings] = useState(null);
    const [activeHeading, setActiveHeading] = useState(''); 
    const [headerPositions, setHeaderPositions] = useState([]);
    const headerPositionsRef = useRef([]);
    const [isScrollTopVisible, setIsScrollTopVisible] = useState(false);

    const contentContainerRef = useRef(null);

    // update headerPositionsRef when headerPositions changes
    useEffect(() => {
        headerPositionsRef.current = headerPositions;
    }, [headerPositions]);

    useEffect(() => {
        // Fetch content and extract headings
        fetch(howToDoc)
            .then(response => response.text())
            .then(text => {
                setContent(text);
                setHeadings(extractHeadingsFromMarkdown(text));
            });
    }, []);

    useEffect(() => {
        const calculateHeaderPositions = () => {
            if (!contentContainerRef.current || content) return;
            const elements = contentContainerRef.current.querySelectorAll('h2, h3');
            const positions = [];
        
            elements.forEach((element) => {
                const containerTop = contentContainerRef.current.getBoundingClientRect().top;
                const elementTop = element.getBoundingClientRect().top;
        
                // Calculate Y position relative to the top of the main container
                const relativeYPosition = elementTop - containerTop;
        
                positions.push({
                    id: element.id,
                    yPosition: relativeYPosition,
                });
            });
        
            // If the first header's position is less than 10 (the padding), adjust all positions
            if (positions.length > 0 && positions[0].yPosition < 10) {
                const delta = 10 - positions[0].yPosition;
                positions.forEach((position) => {
                    position.yPosition += delta;
                });
            }
            setHeaderPositions(positions);
        };        
    
        const handleScroll = throttle(() => {
            const currentScrollPosition = contentContainerRef.current.scrollTop;
    
            if (headerPositionsRef.current.length === 0) return;
    
            // Rule 1: If currentScrollPosition < first element, first element is active
            if (currentScrollPosition < headerPositionsRef.current[0].yPosition) {
                if (activeHeading !== headerPositionsRef.current[0].id) {
                    setActiveHeading(headerPositionsRef.current[0].id);
                }
                return;
            }
    
            // Rule 2: If currentScrollPosition > last element, last element is active
            if (currentScrollPosition > headerPositionsRef.current[headerPositionsRef.current.length - 1].yPosition) {
                if (activeHeading !== headerPositionsRef.current[headerPositionsRef.current.length - 1].id) {
                    setActiveHeading(headerPositionsRef.current[headerPositionsRef.current.length - 1].id);
                }
                return;
            }
    
            // Rule 3: Find the closest element height vs currentScrollPosition + 10px for buffer
            const closestHeader = headerPositionsRef.current.reduce((closest, header) => {
                if (
                    currentScrollPosition + 10 >= header.yPosition &&
                    header.yPosition > closest.yPosition
                ) {
                    return header;
                }
                return closest;
            }, headerPositionsRef.current[0]);
    
            if (closestHeader && closestHeader.id !== activeHeading) {
                setActiveHeading(closestHeader.id);
            }
    
            // Toggle scroll-to-top button visibility
            setIsScrollTopVisible(currentScrollPosition > 300);
        }, 50); // Throttle to limit the function to once every Xms

        // Initial calculation
        setTimeout(() => {
            calculateHeaderPositions();
        }, 1000);
    
        // Recalculate header positions on resize
        const handleResize = () => {
            calculateHeaderPositions();
        };
    
        // Set up resize event listener
        const contentContainer = contentContainerRef.current;
        contentContainer.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);
    
        return () => {
            contentContainer.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, [content, headings, isSidebarVisible]);

    const handleScrollToTop = () => {
        contentContainerRef.current.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const handleSidebarToggle = () => {
        setIsSidebarVisible(!isSidebarVisible);
    };

    const handleLinkClick = (slug) => {
        document.getElementById(slug).scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <TooltipProvider>
            <Header />
            <ReportIssue/>
            <div className={styles.outerContainer}>
                <TopRibbon title="Documentação Degrau" />
                <div className={styles.mainContainer}>
                    {isSidebarVisible && headings && (
                        <CompanyPageSidebar
                            header="Seções"
                            isVisible={isSidebarVisible}
                        >
                            <nav className={styles.documentationNav}>
                                <ul>
                                    {headings.map(({ level, text, slug }) => (
                                        <li
                                            key={slug}
                                            className={`${level === 3 ? styles.navSubHeading : styles.navMainHeading} ${activeHeading === slug ? styles.activeNavItem : ''}`} 
                                            onClick={() => handleLinkClick(slug)}
                                            tabIndex={0}
                                            role="button"
                                            aria-label={`Navegar para ${text}`}
                                        >
                                            <Link smooth to={`#${slug}`} tabIndex={-1}>{text}</Link>
                                        </li>
                                    ))}
                                </ul>
                            </nav>
                        </CompanyPageSidebar>
                    )}
                    {headings && (
                        <CompanyPageSidebarToggle
                            onToggle={handleSidebarToggle}
                            toggleIcon={isSidebarVisible ? faChevronLeft : faChevronRight}
                            toggleLabel={isSidebarVisible ? "Ocultar Barra Lateral" : "Mostrar Barra Lateral"}
                        />
                    )}
                    <div className={styles.contentContainer} ref={contentContainerRef}>
                        <div className={styles.documentationContent}>
                            <ReactMarkdown 
                                remarkPlugins={[remarkGfm]} 
                                rehypePlugins={[rehypeStringify]}
                                components={{
                                    h1: ({ node, ...props }) => {
                                        const fullText = combineText(props.children);
                                        const slug = slugify(fullText);
                                        return <h1 id={slug} {...props} />;
                                    },
                                    h2: ({ node, ...props }) => {
                                        const fullText = combineText(props.children);
                                        const slug = slugify(fullText);
                                        return <h2 id={slug} {...props} />;
                                    },
                                    h3: ({ node, ...props }) => {
                                        const fullText = combineText(props.children);
                                        const slug = slugify(fullText);
                                        return <h3 id={slug} {...props} />;
                                    },
                                    h4: ({ node, ...props }) => {
                                        const fullText = combineText(props.children);
                                        const slug = slugify(fullText);
                                        return <h4 id={slug} {...props} />;
                                    },
                                    h5: ({ node, ...props }) => {
                                        const fullText = combineText(props.children);
                                        const slug = slugify(fullText);
                                        return <h5 id={slug} {...props} />;
                                    },
                                    h6: ({ node, ...props }) => {
                                        const fullText = combineText(props.children);
                                        const slug = slugify(fullText);
                                        return <h6 id={slug} {...props} />;
                                    },
                                }}
                            >
                                {content}
                            </ReactMarkdown>
                        </div>
                    </div>
                    {/* Scroll to Top Button */}
                    <ScrollToTopButton isVisible={isScrollTopVisible} onClick={handleScrollToTop} />
                </div>
            </div>
        </TooltipProvider>
    );
};

export default DocumentationPage;
