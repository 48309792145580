import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../contexts/AppContext';
import { fetchCompanyResults, // The summary results for a specific company, for company admin
    fetchAllResults, // All results, for global admin
    fetchUserReports, // The permitted reports for a user
    getTagsByResult, 
    fetchAllCompanyTags, 
    fetchTagsByCompanyId, 
    fetchCompanyDetails, 
    fetchRoleDetails 
} from '../services/databaseService';
import { logException } from '../services/loggerFront';

const useCompanyData = (isGlobalDashboard, isOrgAdmin, companyDetails, setErrorMessage) => {
    const [companyList, setCompanyList] = useState(null); // Used for filtering if more than 1
    const [roleList, setRoleList] = useState(null); // Used for filtering if more than 1
    const [companySummaryData, setCompanySummaryData] = useState([]);
    const [urlCodes, setUrlCodes] = useState([]); // Array of URL codes for all results
    const [resultTagsMap, setResultTagsMap] = useState({}); // Map of tags for each result
    const [companyCustomTags, setCompanyCustomTags] = useState([]); // Custom tags for the company
    const [totalCount, setTotalCount] = useState(0);
    const [roleCounts, setRoleCounts] = useState({});
    const [companyCounts, setCompanyCounts] = useState({});
    const [approvedCounts, setApprovedCounts] = useState({});
    const [tagCounts, setTagCounts] = useState({});
    const [isLoadingInitialData, setIsLoadingInitialData] = useState(true); // State to show loading spinner to user
    const [dataFetchCalled, setDataFetchCalled] = useState(false); // State to prevent multiple data fetches
    const navigate = useNavigate();
    const fileName = 'useCompanyData.js';

    // On load, identify the user's permissions and fetch the relevant dashboard data
    useEffect(() => {
        if (dataFetchCalled) return; // If data has already been fetched
        const getDashboardAccessLevel = async () => {
            try {
                // If some is missing, wait, as the useEffect will run again
                if (isGlobalDashboard === null || Object.keys(companyDetails).length === 0 || isOrgAdmin === null) {
                    logException('Missing required data when checking dashboard access:', { isGlobalDashboard, companyDetails, isOrgAdmin, fileName });
                    return;
                }

                // Fetch the data depending on the user's permissions
                if (isGlobalDashboard) { // If it is a global dashboard, fetch all results
                    fetchDashboardData(true, false, false);
                    return;
                } else if (isOrgAdmin) { // If it is an org admin, fetch all the company results
                    fetchDashboardData(false, true, false, companyDetails);
                    return;
                } else { // If it is a user, fetch the permitted reports associated to their userId
                    fetchDashboardData(false, false, true, companyDetails);
                    return;
                }
            } catch (err) {
                logException('Failed to fetch dashboard data:', {
                    errorMessage: err.message,
                    errorStack: err.stack,
                    companyDetails,
                    isGlobalDashboard,
                    isOrgAdmin,
                    fileName,
                });
                navigate('/login');
            }
        };

        getDashboardAccessLevel();
    }, [isGlobalDashboard, companyDetails, isOrgAdmin]);

    // Centralized data fetching logic
    const fetchDashboardData = useCallback(async (isGlobalAdmin, isOrgAdmin, isUser, companyDetails) => {
        setIsLoadingInitialData(true);
        setDataFetchCalled(true);
        let data = [];
        try {
            let tagsByResult = [];
            let companyTags = [];

            if (isGlobalAdmin) { // fetch all results for global admin
                data = await fetchAllResults();
                getCompanyDetails(data); // Fetch company details
                companyTags = await fetchAllTags();

            } else if (isOrgAdmin && companyDetails) { // fetch results for a specific company for company admin
                const companyCode = companyDetails.companyCode;
                const companyId = companyDetails.companyID;
                try {
                    const response = await fetchCompanyResults(companyCode);
                    data = response.data;
            
                    if (response.issue === "noData") {
                        setErrorMessage("Atualmente, você não tem nenhum relatório disponível.");
                    } else if (response.issue === "unknown") {
                        setErrorMessage("Desculpe, houve um erro. Por favor, tente novamente. Se o problema persistir, entre em contato com suporte@degrau.co.");
                    }
                } catch (error) {
                    throw error; 
                }
                companyTags = await fetchCompanyTags(companyId);            
            } else if (isUser && companyDetails) { // fetch permitted reports for user
                const companyId = companyDetails.companyID;
                try {
                    const response = await fetchUserReports();
                    data = response.data;
            
                    if (response.issue === "noAccesses") {
                        setErrorMessage("O direito de acesso contínuo a relatórios ainda não foi concedido por um Admin à sua conta. Você pode acessar links a relatórios individuais compartilhados por outras contas");
                    } else if (response.issue === "noData") {
                        setErrorMessage("Atualmente, você não tem nenhum relatório disponível. Você pode acessar links a relatórios individuais compartilhados por outras contas.");
                    } else if (response.issue === "unknown") {
                        setErrorMessage("Desculpe, houve um erro. Por favor, tente novamente. Se o problema persistir, entre em contato com suporte@degrau.co.");
                    }
                } catch (error) {
                    throw error; // Handle unexpected errors as before
                }
                companyTags = await fetchCompanyTags(companyId);
            }            
            setCompanySummaryData(data);

            // if there is data
            if (data.length > 0) {
                getRoleDetails(data); // Fetch role details
                // then fetch the tags for all results
                tagsByResult= await fetchTagsForResults(data);
                // then calculate the counts
                calculateCounts(data, tagsByResult, companyTags);
            }
        } catch (error) {
            logException('Error fetching company summary data:', {
                errorMessage: error.message,
                errorStack: error.stack,
                fileName,
            });
            setErrorMessage("Desculpe, houve um erro. Por favor, tente novamente. Se o problema persistir, entre em contato com suporte@degrau.co.");
            data = []; // Set to empty array instead of throwing an error
            setCompanySummaryData(data);
        } finally {
            setIsLoadingInitialData(false); // Set loading to false
        }
    }, [navigate]);

    // TAGS

    // Fetch tags for all results at once
    const fetchTagsForResults = async (data) => {
        const urlCodes = data.map(result => result.url_code); // Extract all URL codes
        setUrlCodes(urlCodes); // Store the URL codes in state
        try {
            const tagsMap = await getTagsByResult(urlCodes); // Call the new batch API
            setResultTagsMap(tagsMap); // Update the state with the fetched tags
            return tagsMap;
        } catch (error) {
            logException('Error fetching tags for results:', {
                errorMessage: error.message,
                errorStack: error.stack,
                fileName,
            });
        }
    };

    // Fetches the personalised tags for the company
    const fetchAllTags = async () => {
        const tags = await fetchAllCompanyTags();
        setCompanyCustomTags(tags);
        return tags;
    };

    // Fetches the personalised tags for the company
    const fetchCompanyTags = async (companyId) => {
        const tags = await fetchTagsByCompanyId(companyId);
        setCompanyCustomTags(tags);
        return tags;
    };

    // COMPANIES & ROLES LISTS

    // Identify all companies in the data using company_code and call API to get full company details
    const getCompanyDetails = async (data) => {
        // if there is no data, return
        if (data.length === 0) return;
        const companyCodes = [...new Set(data.map(item => item.company_code))]; // Get unique company codes
        const companies = await fetchCompanyDetails(companyCodes); // Api call passing companyCodes and returning company details
        setCompanyList(companies); // Update the state with the fetched companies
    };

    // Identify all roles in the data using role_code and call API to get full role details
    const getRoleDetails = async (data) => {
        // if there is no data, return
        if (data.length === 0) return;
        const roleCodes = [...new Set(data.map(item => item.role_code))]; // Get unique role codes
        const roles = await fetchRoleDetails(roleCodes); // Api call passing roleCodes and returning role details
        setRoleList(roles); // Update the state with the fetched roles
    };

    // Calculate role and company counts
    const calculateCounts = (summaryData, tagsByResult) => {
        const totalCount = summaryData.length;
        const roleCounts = {};
        const companyCounts = {};
        const approvedCounts = {};
        const tagCounts = {};

        // Get the role, company and approved counts from the summary data
        summaryData.forEach(item => {
            roleCounts[item.role_code] = (roleCounts[item.role_code] || 0) + 1;
            companyCounts[item.company_code] = (companyCounts[item.company_code] || 0) + 1;
            approvedCounts[item.approved] = (approvedCounts[item.approved] || 0) + 1;
        });

        // Calculate tag counts from tagsByResult
        Object.values(tagsByResult).forEach(tagsArray => {
            tagsArray.forEach(tag => {
                tagCounts[tag.tag_id] = (tagCounts[tag.tag_id] || 0) + 1;
            });
        });

        setTotalCount(totalCount);
        setRoleCounts(roleCounts);
        setCompanyCounts(companyCounts);
        setApprovedCounts(approvedCounts);
        setTagCounts(tagCounts);
    };

    // Expose a refresh function to re-fetch data
    const refreshData = useCallback(() => {
        if (isGlobalDashboard) {
            resetData();
            fetchDashboardData(true, false, false);
            return;
        } else if (isOrgAdmin) {
            resetData();
            fetchDashboardData(false, true, false, companyDetails);
            return;
        } else {
            resetData();
            fetchDashboardData(false, false, true, companyDetails);
            return;
        }
    }, [fetchDashboardData, isGlobalDashboard, companyDetails, isOrgAdmin]);

    const resetData = () => {
        setCompanyList(null);
        setRoleList(null);
        setCompanySummaryData([]);
        setUrlCodes([]);
        setResultTagsMap({});
        setCompanyCustomTags([]);
        setTotalCount(0);
        setRoleCounts({});
        setCompanyCounts({});
        setApprovedCounts({});
        setTagCounts({});
        setDataFetchCalled(false);
    };

    return {
        companyList,
        roleList,
        companySummaryData,
        urlCodes,
        resultTagsMap,
        setResultTagsMap,
        companyCustomTags,
        totalCount,
        roleCounts,
        companyCounts,
        approvedCounts,
        tagCounts,
        isLoadingInitialData,
        refreshData,
        fetchTagsForResults,
        fetchCompanyTags,
    };
};

export default useCompanyData;
