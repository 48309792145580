// Page to handle the authentication of the user (login, register, forgot password)
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";  // Decode the JWT token to extract user details
import { useAppContext } from "../contexts/AppContext";
import { useTestContext } from "../contexts/TestContext";
import {validatePassword, passwordRulesDescriptions} from '../utils/validatePassword';
import getInitials from "../utils/getInitials"; // Function to get the initials of the user's name
import {isBusinessEmail} from "../utils/isBusinessEmail"; // Function to check if the email is a business email
import Popup from "../components/popup/Popup"; // Popup with different variants which has the inputs for user
import ScreenCover from "../components/popup/ScreenCover"; // Darkens the screen behind the popup (can be "blur" or "complete")
import useAuth from "../hooks/useAuth"; // Custom hook to get the user's authentication status
import { registerUser, requestUserAccount } from "../services/databaseService"; // Function to call the API to register a new user
import {
  loginUser,
  resetPassword,
  generateRecoveryToken,
  createNewAccount,
} from "../services/authenticationService"; // Functions to call the API to login, register, and reset password
import { logException } from "../services/loggerFront";

const AuthPage = () => {
  const { setUserRoles, setUserPermissions, setUserFirstName, setUserLastName, setUserFullName, setUserInitials, setUserEmail, setUserId, setCompanyId } = useAppContext(); // Set the user's roles and permissions
  const { setCompanyDetails } = useTestContext(); // Set the company details (need to move to app context)
  // Navigation and location states
  const auth = useAuth(); // Get the user's authentication status
  const location = useLocation(); // Get the current location (URL)
  const navigate = useNavigate(); // Function to navigate to a different page
  const [referrerUrl, setReferrerUrl] = useState('/resultados'); // Default redirection path
  const [referralReport, setReferralReport] = useState(false); // The report that the user is trying to access
  // Variants to display different popups
  const [variant, setVariant] = useState("login"); // Can be "login", "register" (triggered by location), or "forgotPassword" (triggered by clicking a button in login popup)
  const [showRequestAccess, setShowRequestAccess] = useState(false); // Show the request access button in login popup
  // States to store the user's input
  const [inputEmail, setInputEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState(""); // Only used in the "ResetPassword" and "Register" variant
  const [name, setName] = useState(""); // Only used in the "Register" variant
  const [firstNames, setFirstNames] = useState(""); // Only used in the "Create Account" variant
  const [lastNames, setLastNames] = useState(""); // Only used in the "Create Account" variant
  const [companyName, setCompanyName] = useState(""); // Only used in the "Create Account" variant
  // Messages to the user
  const [errorMessage, setErrorMessage] = useState(null); // To display any error messages to the user
  const [successMessage, setSuccessMessage] = useState(null); // To display any success messages to the user
  // Token handling
  const [recoveryToken, setRecoveryToken] = useState(""); // Only used in the recuperar-senha variant
  const [registerToken, setRegisterToken] = useState(""); // Only used in the registrar variant

  // Handle reset password route
  const handleResetPasswordRoute = (queryParams) => {
    const token = queryParams.get('token');
    if (token) {
      setRecoveryToken(token);
      setVariant("ResetPassword");
      clearPage();
    } else {
      setErrorMessage("Token de recuperação inválido. Por favor, solicite um novo link de recuperação.");
      setTimeout(() => setErrorMessage(""), 5000);
      setVariant("ResetPassword");
    }
  };

  // Handle register route
  const handleRegisterRoute = (queryParams) => {
    const token = queryParams.get('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setRegisterToken(token);
        setInputEmail(decodedToken.email);
        setVariant("Register");
      } catch (error) {
        setErrorMessage("Token inválido. Por favor, solicite um novo convite.");
        setTimeout(() => setErrorMessage(""), 5000);
        setVariant("Register");
      }
    } else {
      setErrorMessage("Token inválido. Por favor, solicite um novo convite.");
      setTimeout(() => setErrorMessage(""), 5000);
      setVariant("Register");
    }
  };
  
  // Handle create account route
  const handleCreateAccountRoute = (queryParams) => {
    setVariant("CreateAccount");
  };

  // Handle default route
  const handleDefaultRoute = () => {
    setVariant("Login");
    setRecoveryToken("");
  };

  // Main useEffect for handling location changes
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    switch (location.pathname) {
      case "/recuperar-senha":
        handleResetPasswordRoute(queryParams);
        break;
      case "/registrar":
        handleRegisterRoute(queryParams);
        break;
      case "/criar-conta":
        handleCreateAccountRoute(queryParams);
        break;
      default:
        handleDefaultRoute();
        break;
    }
  }, [location]);

  // Store the url that redirected the user to the login page
  useEffect(() => {
    const from = location.state?.from;
    if (from) {
      setReferrerUrl(from); // Set the referrer URL if passed
      // if from contains relatorio, setShowRequestAccess to true so that the user can request access and save the tentativa param as setReferralReport
      if (from.includes("relatorio")) {
        setShowRequestAccess(true); // Show the request access button

        // Extract the query string part (everything after '?') from the from URL
        const queryString = from.split('?')[1];
        if (queryString) {
          const queryParams = new URLSearchParams(queryString);
          const report = queryParams.get('tentativa');
          setReferralReport(report);
        }
      } else {
        setShowRequestAccess(false); // Hide the request access button
      }
    } else {
      setReferrerUrl('/resultados'); // Default redirection path
      setShowRequestAccess(false); // Hide the request access button
    }
  }, [location]);

  // Clear the page's state variables
  const clearPage = () => {
    setErrorMessage("");
    setSuccessMessage("");
    setInputEmail("");
    setPassword("");
    setConfirmPassword("");
  };

  // FUNCTIONS TO HANDLE BUTTON ACTIONS

  // Login: check if the user exists and navigate to the company summary page
  const handleLogin = async () => {
    try {
      // Call the API to login the user and get their token with user details, roles and permissions
      const token = await loginUser(inputEmail, password);
      auth.signIn(token); // Set the user's token

      // Decode the JWT token to extract user details
      const decodedToken = jwtDecode(token);

      // Extract user details from the decoded token
      const { roles, permissions, name, surname, email, companyDetails, userId } = decodedToken;

      setUserRoles(roles); // Set the user's roles
      setUserPermissions(permissions); // Set the user's permissions
      setCompanyDetails(companyDetails); // Set the user's company ID
      setCompanyId(companyDetails.companyID); // Set the user's company ID
      setUserEmail(email); // Set the user's email
      setUserId(userId); // Set the user's ID

      // Set name and initials
      setUserFirstName(name); // Set the user's first name
      setUserLastName(surname); // Set the user's last name
      const fullName = `${name} ${surname}`; // Combine the first and last name
      setUserFullName(fullName); // Set the user's full name

      // Get the first letter of the first name and the first letter of the last name to display in the top right corner
      const initials = await getInitials(fullName); // Await the asynchronous getInitials
      setUserInitials(initials);

      // Check if a referrer URL is set and navigate to it, otherwise navigate to the default page
      if (referrerUrl) {
        navigate(referrerUrl);
      } else {
        navigate("/resultados"); // Default navigation if no referrer URL is found
      }
    } catch (error) {
      logException('Error: handling login', {
        errorMessage: error.message,
        errorStack: error.stack,
        status: "Failed",
        type: "Login",
        fileName: "AuthPage",
      });
      setErrorMessage("Falha ao fazer login");
      setTimeout(() => setErrorMessage(""), 5000);
    }
  };

  // Reset Password: check if the passwords match and update the users's password by sending the new password and the recovery token to the API
  const handleResetPassword = async () => {

  // Check if the new password meets the requirements
    try {
      await validatePassword(password);
    } catch (error) {
      setErrorMessage(error.message);
      setTimeout(() => setErrorMessage(''), 5000); // Clear the error message after 5 seconds
      return;
    }

    // Check if the passwords match
    if (password !== confirmPassword) {
      setErrorMessage("As senhas não coincidem");
      setTimeout(() => setErrorMessage(""), 5000);
      return;
    }

    try {
      await resetPassword(recoveryToken, password);
      // Give success message then navigate to the login page
      setSuccessMessage("Senha redefinida com sucesso. Você será redirecionado para a página de login.");
      setTimeout(() => {
        setSuccessMessage("");
        navigate("/login");
      }, 5000);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setErrorMessage("Token de recuperação inválido ou expirado.");
        setTimeout(() => setErrorMessage(""), 5000);
      } else {
        setErrorMessage("Erro ao redefinir a senha. Por favor, tente novamente mais tarde.");
        setTimeout(() => setErrorMessage(""), 5000);
      }
      setTimeout(() => setErrorMessage(""), 5000);
    }
  };

  // Recover password: send a link including recovery token to the user's email to reset the password. When clicked on the link, the user will be redirected to the reset page
  const handleRecoverPassword = async () => {
    try {
      await generateRecoveryToken(inputEmail);
      // Clear the email and give a success message telling them to check their email
      setInputEmail("");
      setSuccessMessage("Link de redefinição de senha enviado para seu email");
      setTimeout(() => setSuccessMessage(""), 10000);
    } catch (error) {
      // Assuming the API returns an error message we can display it to the user
      setErrorMessage("Erro ao enviar o email de recuperação. Por favor, tente novamente mais tarde ou entre em contato com o suporte: suporte@degrau.co");
      setTimeout(() => setErrorMessage(""), 10000);
    }
  };

  // Register User: check if the passwords match and register the users's name and password by sending them and the token to the API
  const handleRegisterUser = async () => {

    // Check if the name field is empty
    if (!firstNames.trim() || !lastNames.trim()) {
      setErrorMessage("O nome não pode estar vazio.");
      setTimeout(() => setErrorMessage(""), 5000);
      return;
    }

    // Check if the new password meets the requirements
    try {
      await validatePassword(password);
    } catch (error) {
      setErrorMessage(error.message);
      setTimeout(() => setErrorMessage(''), 5000); // Clear the error message after 5 seconds
      return;
    }

    // Check if the passwords match
    if (password !== confirmPassword) {
      setErrorMessage("As senhas não coincidem");
      setTimeout(() => setErrorMessage(""), 5000);
      return;
    }

    try {
      await registerUser(registerToken, firstNames, lastNames, password);
      // Give success message then navigate to the login page
      setSuccessMessage("Registrada com sucesso. Você será redirecionado para a página de login.");
      setTimeout(() => {
        setSuccessMessage("");
        navigate("/login");
      }, 5000);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setErrorMessage("Token de registrar inválido ou expirado.");
        setTimeout(() => setErrorMessage(""), 5000);
      } else {
        setErrorMessage("Erro ao registrar. Por favor, tente novamente mais tarde.");
        setTimeout(() => setErrorMessage(""), 5000);
      }
      setTimeout(() => setErrorMessage(""), 5000);
    }
  };

  // Request Access: send a request to the admin to get access to the platform
  const handleRequestAccess = async () => {
    // check email is not blank and follows the regex pattern
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!inputEmail.trim()) {
      setErrorMessage("O email não pode estar vazio.");
      setTimeout(() => setErrorMessage(""), 5000);
      return;
    }
    if (!emailRegex.test(inputEmail)) {
      setErrorMessage("Por favor, insira um email válido.");
      setTimeout(() => setErrorMessage(""), 5000);
      return;
    }

    try {
      await requestUserAccount(inputEmail, referralReport);
      setSuccessMessage("Solicitação de acesso enviada com sucesso. Você receberá um e-mail quando for aceito.");
      // clear the message after 10 seconds
      setTimeout(() => setSuccessMessage(""), 10000);
    } catch (error) {
      setErrorMessage(error.message || "Erro ao solicitar acesso. Por favor, tente novamente mais tarde.");
      setTimeout(() => setErrorMessage(""), 5000);
    }
  };

  // Create account: Create the user account in the user table and the company in the ValidCompany table
  const handleCreateAccount = async () => {

    // DATA VALIDATIONS

    // Ensure the email, name, password and companyName are not blank
    if (!inputEmail.trim() || !name.trim() || !password.trim() || !companyName.trim()) {
      // specific error messages for each field
      if (!inputEmail.trim()) {
        setErrorMessage("O email não pode estar vazio.");
        setTimeout(() => setErrorMessage(""), 5000);
      }
      if (!name.trim()) {
        setErrorMessage("O nome não pode estar vazio.");
        setTimeout(() => setErrorMessage(""), 5000);
      }
      if (!password.trim()) {
        setErrorMessage("A senha não pode estar vazia.");
        setTimeout(() => setErrorMessage(""), 5000);
      }
      if (!companyName.trim()) {
        setErrorMessage("O nome da empresa não pode estar vazio.");
        setTimeout(() => setErrorMessage(""), 5000);
      }
      return;
    }
    
    // EMAIL 

    // check email follows the regex pattern
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(inputEmail)) {
      setErrorMessage("Por favor, insira um email válido.");
      setTimeout(() => setErrorMessage(""), 5000);
      return;
    }
    // Ensure it is a business email
    if (!isBusinessEmail(inputEmail)) {
      setErrorMessage("Por favor, insira um email de negócios.");
      setTimeout(() => setErrorMessage(""), 5000);
      return;
    }

    // PASSWORD

    // Check if the new password meets the requirements
    try {
      await validatePassword(password);
    } catch (error) {
      setErrorMessage(error.message);
      setTimeout(() => setErrorMessage(''), 7000); // Clear the error message after Xms
      return;
    }

    try {
      // await createNewAccount(inputEmail, password, name, companyName);
      setSuccessMessage("Solicitação de acesso enviada com sucesso. Você receberá um e-mail quando for aceito.");
      // clear the message after 10 seconds
      setTimeout(() => setSuccessMessage(""), 10000);
    } catch (error) {
      setErrorMessage(error.message || "Erro ao solicitar acesso. Por favor, tente novamente mais tarde.");
      setTimeout(() => setErrorMessage(""), 5000);
    }
  };

  return (
    <>
      <ScreenCover isVisible={true} variant="complete" zIndex={11} />
      {variant === "Login" && (
        <Popup
          variant="Login"
          isVisible={true}
          zIndex={12}
          email={inputEmail}
          setEmail={setInputEmail}
          password={password}
          setPassword={setPassword}
          setVariant={setVariant}
          handleAction={handleLogin}
          errorMessage={errorMessage}
          successMessage={successMessage}
          showRequestAccess={showRequestAccess}
        />
      )}
      {variant === "ResetPassword" && (
        <Popup
          variant="ResetPassword"
          isVisible={true}
          zIndex={12}
          password={password}
          setPassword={setPassword}
          confirmPassword={confirmPassword}
          setConfirmPassword={setConfirmPassword}
          handleAction={handleResetPassword}
          errorMessage={errorMessage}
          successMessage={successMessage}
          passwordRules={passwordRulesDescriptions}
        />
      )}
      {variant === "ForgotPassword" && (
        <Popup
          variant="ForgotPassword"
          isVisible={true}
          zIndex={12}
          email={inputEmail}
          setEmail={setInputEmail}
          handleAction={handleRecoverPassword}
          errorMessage={errorMessage}
          successMessage={successMessage}
        />
      )}
      {variant === "Register" && (
        <Popup
          variant="Register"
          isVisible={true}
          zIndex={12}
          wide={true}
          email={inputEmail}
          firstNames={firstNames}
          setFirstNames={setFirstNames}
          lastNames={lastNames}
          setLastNames={setLastNames}
          password={password}
          setPassword={setPassword}
          confirmPassword={confirmPassword}
          setConfirmPassword={setConfirmPassword}
          handleAction={handleRegisterUser}
          errorMessage={errorMessage}
          successMessage={successMessage}
          passwordRules={passwordRulesDescriptions}
        />
      )}
      {variant === "RequestAccess" && (
        <Popup
          variant="RequestAccess"
          isVisible={true}
          zIndex={12}
          email={inputEmail}
          setEmail={setInputEmail}
          handleAction={handleRequestAccess}
          errorMessage={errorMessage}
          successMessage={successMessage}
        />
      )}
      {variant === "CreateAccount" && (
        <Popup
          variant="CreateAccount"
          isVisible={true}
          zIndex={12}
          wide={true}
          email={inputEmail}
          setEmail={setInputEmail}
          firstNames={firstNames}
          setFirstNames={setFirstNames}
          lastNames={lastNames}
          setLastNames={setLastNames}
          password={password}
          setPassword={setPassword}
          confirmPassword={confirmPassword}
          setConfirmPassword={setConfirmPassword}
          companyName={companyName}
          setCompanyName={setCompanyName}
          handleAction={handleCreateAccount}
          errorMessage={errorMessage}
          successMessage={successMessage}
          passwordRules={passwordRulesDescriptions}
        />
      )}
    </>
  );
};

export default AuthPage;
