import React from 'react';
import styles from './MarketingMessage.module.css';

const MarketingMessage = ({ information }) => {
    return (
        <div className={styles.marketingContainer}>
            <div className={styles.marketingHeader}>
                <div className={styles.marketingTitle}>{information.title}</div>
            </div>
            <div className={styles.marketingDetails}>
                <ul>
                    {information.details.map((detail, index) => (
                        <li className={styles.marketingDetail} key={index}>{detail}</li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default MarketingMessage;
