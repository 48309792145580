//Component to cover the screen either completely or blurred
import React from 'react';
import styles from './ScreenCover.module.css';

const ScreenCover = ({ isVisible, variant, zIndex }) => {
    if (!isVisible) return null;

    const coverStyle = `${styles.screenCover} ${styles[variant]}`
    const style = { zIndex: zIndex || 'auto' }; // Default to 'auto' if no zIndex is provided

    return <div className={coverStyle} style={style}></div>;
};

export default ScreenCover;