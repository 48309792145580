import React, { useState, useEffect } from 'react';
import styles from '../../sharedStyles/AdminFormsStyles.module.css';
import { getPermissionLevel } from '../../utils/getPermissionLevel';

const AddUserForm = ({ roles, userPermissions, emailEnding, setFormError, currentEmails, setAddUserDetails, companyId, allCompanies, moveToPermissionPage, setMoveToPermissionPage }) => {
    const [emails, setEmails] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [selectedRole, setSelectedRole] = useState(roles.length === 1 ? roles[0].id : ''); // Default to the only role if one role is provided
    const [selectedRoleName, setSelectedRoleName] = useState(roles.length === 1 ? roles[0].roleName : ''); // Default to the only role if one role is provided
    const [selectedCompanyId, setSelectedCompanyId] = useState(companyId); // Default to the only company if one company is provided
    const permissionLevel = getPermissionLevel(userPermissions, 'ADD_USER');

    // Validate the email and check if it already exists
    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            return "Por favor, insira um endereço de email válido.";
        }
        if (currentEmails.includes(email)) {
            return "Este email já está registrado.";
        }
        if (emailEnding && !email.endsWith(emailEnding) && permissionLevel === 'ORGANISATION') {
            return `O email deve terminar com ${emailEnding}.`;
        }
        return null;
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
        setFormError(''); 
    };

    // Add email when Enter, Space, or Tab is pressed (if input is not empty)
    const handleKeyDown = (e) => {
        if (e.key === 'Enter' || e.key === ' ' || (e.key === 'Tab' && inputValue)) {
            e.preventDefault();
            if (inputValue) {
                const validationError = validateEmail(inputValue);
                if (validationError) {
                    setFormError(validationError);
                } else if (!emails.includes(inputValue)) {
                    setEmails([...emails, inputValue]);
                    setInputValue('');
                }
            }
        }
        if (e.key === 'Backspace' && !inputValue && emails.length) {
            const newEmails = [...emails];
            newEmails.pop();
            setEmails(newEmails);
        }
    };

    // Add email when input field loses focus
    const handleBlur = () => {
        if (inputValue) {
            const validationError = validateEmail(inputValue);
            if (validationError) {
                setFormError(validationError);
            } else if (!emails.includes(inputValue)) {
                setEmails([...emails, inputValue]);
                setInputValue('');
            }
        }
    };

    const removeEmail = (index) => {
        setEmails(emails.filter((_, i) => i !== index));
    };

    const handleRoleChange = (e) => {
        const selectedRoleId = parseInt(e.target.value, 10);
        const selectedRoleObject = roles.find(role => role.id === selectedRoleId);
        
        setSelectedRole(selectedRoleId);
        setSelectedRoleName(selectedRoleObject ? selectedRoleObject.roleName : '');
    };    

    const handleCompanyChange = (e) => {
        setSelectedCompanyId(e.target.value);
    };

    const handleMoveToPermissionChange = (e) => {
        setMoveToPermissionPage(e.target.value === 'true'); // Set the state based on the selected option
    };

    // Set the default selected role when there are multiple roles
    useEffect(() => {
        if (roles.length > 1 && !selectedRole) {
            setSelectedRole(roles[0].id);  // Set the first role as default
            setSelectedRoleName(roles[0].roleName); // Set the first role name as default
        }
    }, [roles, selectedRole]);

    // Call setEmailsAndRole whenever emails or selectedRole changes
    useEffect(() => {
        setAddUserDetails(emails, selectedRole, selectedRoleName, selectedCompanyId);
    }, [emails, selectedRole, selectedRoleName, selectedCompanyId, setAddUserDetails]);

    return (
        <div>
            <div className={styles.inputGroup}>
                <label>Email</label>
                <div className={styles.emailInputWrapper}>
                    {emails.map((email, index) => (
                        <span key={index} className={styles.emailTag}>
                            {email}
                            <button type="button" onClick={() => removeEmail(index)}>x</button>
                        </span>
                    ))}
                    <input
                        type="text"
                        value={inputValue}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        onBlur={handleBlur}
                        placeholder="exemplo@empresa.com"
                    />
                </div>
            </div>

            {roles.length > 1 && (
                <div className={styles.inputGroup}>
                    <label>Tipo de Conta</label>
                    <select value={selectedRole} onChange={handleRoleChange}>
                        {roles.map((role, index) => (
                            <option key={index} value={role.id}>{role.roleName}</option>
                        ))}
                    </select>
                </div>
            )}

            {permissionLevel === 'GLOBAL' && (
                <div className={styles.inputGroup}>
                    <label>Empresa</label>
                    <select value={selectedCompanyId} onChange={handleCompanyChange}>
                        <option value="" disabled>Selecione uma empresa</option>
                        {allCompanies.map((company) => (
                            <option key={company.companyID} value={company.companyID}>{company.companyName}</option>
                        ))}
                    </select>
                </div>
            )}

            {/* Radio buttons for user role 6 'User' */}
            {selectedRole === 6 && (
                <div className={styles.headerAndRadioOptions}>
                    <div className={styles.radioGroupHeader}>Definir acesso:</div>
                    <div className={styles.radioGroup}>
                        <div className={styles.radioOptionWithDetail}>
                            <div className={styles.radioOption}>
                                <input 
                                    type="radio" 
                                    value="false" 
                                    checked={!moveToPermissionPage} 
                                    onChange={handleMoveToPermissionChange} 
                                />
                                <div>
                                    <strong>Acesso via Link</strong> 
                                </div>
                            </div>
                            <div className={styles.radioOptionDetail}>
                                <p>A conta poderá visualizar relatórios apenas através dos links compartilhados com eles.</p>
                            </div>
                        </div>
                        <div className={styles.radioOptionWithDetail}>
                            <div className={styles.radioOption}>
                                <input 
                                    type="radio" 
                                    value="true" 
                                    checked={moveToPermissionPage} 
                                    onChange={handleMoveToPermissionChange} 
                                />
                                <div>
                                    <strong>{emails.length > 1 ? 'Adicionar permissões às contas' : 'Adicionar permissãos à conta:'}</strong>
                                </div>
                            </div>
                            <div className={styles.radioOptionDetail}>
                                <p>Você procederá para selecionar os relatórios que a conta terá disponíveis em seu dashboard.</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AddUserForm;
