//tooltips to guide user around site. the instructions and text are set in TooltipContext
import React, { useContext, useEffect } from 'react';
import { useTooltip } from '../../contexts/TooltipContext';
import { AppContext } from '../../contexts/AppContext';
import { useTestContext } from '../../contexts/TestContext';
import Button from '../common/Button';
import styles from './Tooltip.module.css';

const Tooltip = ({ variant }) => {
    const { activeTooltips, getTooltipConfig, onTooltipButtonClick } = useTooltip();

    const tooltip = getTooltipConfig(variant);
    if (!tooltip || !activeTooltips[variant]) return null;

    // Check if the text property is a function and call it to get the text
    const tooltipText = typeof tooltip.text === 'function' ? tooltip.text() : tooltip.text;

    return (
        <div className={`${styles.tooltip} ${styles[variant]} ${styles[tooltip.arrowType]}`}>
            {tooltip.title && (
                <div className={styles.tooltipTitle}>
                    {tooltip.title}
                </div>
            )}
            <p>{tooltipText}</p> {/* Use the dynamically determined text here */}
            <div className={styles.tooltipBottomRow}>
                <Button type="tooltip" onClick={() => onTooltipButtonClick(variant)} tabIndex="0">
                    {/* Button content, like 'OK' or 'Next' */}
                </Button>
            </div>
            {tooltip.number && (
                <div className={styles.tooltipNumber}> {/* if the tooltip has a number bring it here */}
                    {tooltip.number}
                </div>
            )}
        </div>
    );
};

export default Tooltip;
