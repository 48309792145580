// Show the skill name and graph the score 
import React from 'react';
import styles from './SkillSummary.module.css'; // Make sure to update your CSS accordingly

const SkillSummary = ({ skillName, skillScore, skillWeight, onClick, isFirst, pdfMode = false }) => {
    const percentage = (skillScore / skillWeight) * 100;
    const percentageRounded = Math.round(percentage) // To show under the semi circle
    const percentageMin = Math.max(3, percentage); // To have a minimum piece of the graph coloured, if 0, so its red
    const radius = 20; // Radius of the semi-circle
    const circumference = Math.PI * radius; // Circumference of the full circle
    const strokeDasharray = `${circumference}`;
    const strokeDashoffset = ((100 - percentageMin) / 100) * circumference;

    const adjustedSkillWeight = Math.round(skillWeight);

    const getFillColor = (percentage) => {
        if (percentage >= 80) return '#00B050'; // Dark green
        else if (percentage >= 60) return '#9acd32'; // Lime green
        else if (percentage >= 40) return '#ffbf00'; // Amber
        else if (percentage >= 20) return '#ff7f00'; // Orange
        return '#ff0000'; // Red
    };

    return (
        <div className={`${styles.skillSummary} ${pdfMode ? styles.skillSummaryPdfMode : ''}`}>
            <div className={styles.skillHeader}>
                <div className={`${styles.skillName} ${pdfMode ? styles.skillNamePdfMode : ''}`}>{skillName}</div>
                <div className={`${styles.skillWeight} ${pdfMode ? styles.skillWeightPdfMode : ''}`}>
                    {isFirst ? 'Peso: ' : ''}
                    {adjustedSkillWeight}%
                </div>
            </div>
            <div className={styles.semiCircleContainer}>
                <svg 
                    className={styles.scoreSemiCircle}
                    viewBox="0 0 50 25" /* Adjust based on your path coordinates */
                    preserveAspectRatio="xMidYMid meet"
                    >
                    <path
                        d={`M5,25 A20,20 0 0,1 45,25`}
                        fill="none"
                        stroke="#ddd"
                        strokeWidth="7"
                    />
                    <path
                        d={`M5,25 A20,20 0 0,1 45,25`}
                        fill="none"
                        stroke={getFillColor(percentage)}
                        strokeWidth="7"
                        strokeDasharray={strokeDasharray}
                        strokeDashoffset={strokeDashoffset}
                    />
                </svg>
                <div className={styles.scoreText} onClick={onClick}>
                    {`${percentageRounded}`}
                </div>
            </div>
        </div>
    );
};

export default SkillSummary;
