import React from 'react';
import styles from './ReportSchemaViewer.module.css'; // Create a CSS module for styling

const ReportSchemaViewer = ({ schema }) => {
  return (
    <div className={styles.schemaContainer}>
        <div className={styles.mainHeading}>Schema Final Submetido:</div>
        {schema.map((table, index) => (
            <div key={index} className={styles.table}>
            <div className={styles.tableName}>Tabela: {table.name}</div>
            <table className={styles.tableSchema}>
                <thead>
                <tr>
                    <th>Column Name</th>
                    <th>Type</th>
                </tr>
                </thead>
                <tbody>
                {table.columns.map((column, colIndex) => (
                    <tr key={colIndex}  className={styles.reportSchemaTableRow}>
                    <td>{column.name}</td>
                    <td>{column.type}</td>
                    </tr>
                ))}
                </tbody>
            </table>
            </div>
        ))}
    </div>
  );
};

export default ReportSchemaViewer;
