// FeedbackForm.js
import React, { useState } from 'react';
import FadeLoader from 'react-spinners/FadeLoader';
import styles from './FeedbackForm.module.css'; // Import the CSS module

const FeedbackForm = ({ feedbackLink }) => {
  const [loading, setLoading] = useState(true);

  return (
    <div className={styles.container}>
      {loading && (
        <div className={styles.loader}>
          <FadeLoader size={30} color={"#246E24"} />
        </div>
      )}
      <iframe
        src={feedbackLink}
        width="100%"
        height="100%"
        border='none'
        onLoad={() => setLoading(false)}
        allowFullScreen
        style={{ border: 'none', outline: 'none' }}
        className={loading ? styles.hidden : ''} 
      ></iframe>
    </div>
  );
};

export default FeedbackForm;
