import React from 'react';
import ActionButton from './ActionButton';
import styles from "./CompanyPageSidebarToggle.module.css";

const CompanyPageSidebar = ({ onToggle, toggleIcon, toggleLabel }) => {
    return (
        <>
            <div className={styles.sidebarButtonContainer}>
                <ActionButton 
                    icon={toggleIcon} 
                    label={toggleLabel} 
                    onClick={onToggle}
                    showHoverPopout={true}
                    popoutText={toggleLabel} 
                    popoutPosition="right"
                />
            </div>
        </>
    );
};

export default CompanyPageSidebar;
