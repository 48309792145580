// Standard popup box (usually with a blur behind using ScreenCover.js) that enables user input or provides warning messages. The different variants have separate files as can be seen in the import section
import React, { useRef, useEffect, useState  } from "react";
import { useNavigate } from "react-router-dom"; // To navigate to the feedback page while passing context
import Button from "../common/Button"; // Standard button component
import styles from "./Popup.module.css";

// Contexts
import { useTestContext } from "../../contexts/TestContext";

// Each popup variant has its own content file
import ActiveTestContent from "./ActiveTestContent";
import CameraFailContent from "./CameraFailContent";
import CameraPermissionContent from "./CameraPermissionContent";
import CameraRejectContent from "./CameraRejectContent";
import ComingSoonContent from "./ComingSoonContent";
import EndTestContent from "./EndTestContent";
import ForgotPasswordContent from "./ForgotPasswordContent";
import FullScreenRejectContent from "./FullScreenRejectContent";
import InitialLoadingContent from "./InitialLoadingContent";
import LoadIssueContent from "./loadIssueContent";
import ReportLoadIssueContent from "./reportLoadIssueContent";
import ShareableLinkLoadFailedContent from "./ShareableLinkLoadFailedContent";
import ShareableLinkNoDataContent from "./ShareableLinkNoDataContent";
import ShareableLinkExpiredContent from "./ShareableLinkExpiredContent";
import LoginContent from "./LoginContent";
import RegisterContent from "./RegisterContent";
import CreateAccountContent from "./CreateAccountContent";
import ResetPasswordContent from "./ResetPasswordContent";
import RequestAccessContent from "./RequestAccessContent";
import RetrievalIssueContent from "./RetrievalIssueContent";
import RetrievedTestCompleteContent from "./RetrievedTestCompleteContent";
import SaveIssueContent from "./SaveIssueContent";
import ScreenShareFailContent from "./ScreenShareFailContent";
import ScreenShareRejectContent from "./ScreenShareRejectContent";
import ScreenSizeIssueContent from "./ScreenSizeIssueContent";
import TermsContent from "./TermsContent";
import TestDownContent from "./TestDownContent";
import TestInterruptedContent from "./TestInterruptedContent";
import TimeUpContent from "./TimeUpContent";
import UniqueUrlFailContent from "./UniqueUrlFailContent";

const Popup = ({
  variant,
  isVisible,
  zIndex,
  wide = false,
  onClose,
  onConfirmReject,
  onCancelReject,
  showCloseButton,
  buttonLink,
  ...otherProps
}) => {
  const { onConfirm } = otherProps; // Destructure onConfirm from otherProps
  const { activeTestDetails, candidateEmail, candidateId } = useTestContext(); // Test variables needed by some popups during the interview
  const activeTestId = activeTestDetails.Id; // Active test is when a user returns and they have a test in progress
  const activeTestStatus = activeTestDetails.Status;
  const [logoLoaded, setLogoLoaded] = useState(false); // To check if the logo has loaded before showing the popup
  const navigate = useNavigate(); // To pass context to the feedback page
  const popupRef = useRef(null);
  let content = null;

  // For certain popups, the logo needs to be loaded before the popup is shown
  const handleLogoLoad = () => {
    setLogoLoaded(true);
  };

  // Ref to focus on the popup when it appears
  useEffect(() => {
    if (isVisible && logoLoaded) {
      popupRef.current.focus();
    }
  }, [isVisible, logoLoaded]);

  if (!isVisible) return null;

  // Get the content based on the variant
  const getContent = () => {
    const contentProps = {
      ...otherProps,
      onLoad: handleLogoLoad,
      logoLoaded,
    };
    switch (variant) {
      case "ComingSoon":
        return <ComingSoonContent onLoad={handleLogoLoad} logoLoaded={logoLoaded} {...otherProps} />;
      case "InitialLoading":
        return <InitialLoadingContent onLoad={handleLogoLoad} logoLoaded={logoLoaded} {...otherProps} />;
      case "ScreenSizeIssue":
        return <ScreenSizeIssueContent onLoad={handleLogoLoad} logoLoaded={logoLoaded} {...otherProps} />;
      case "TermsContent":
        return <TermsContent onLoad={handleLogoLoad} logoLoaded={logoLoaded} {...otherProps} />;
      case "loadFailed":
        return <LoadIssueContent onLoad={handleLogoLoad} logoLoaded={logoLoaded} {...otherProps} />;
      case "ReportLoadIssue":
        return <ReportLoadIssueContent onLoad={handleLogoLoad} logoLoaded={logoLoaded} {...otherProps} />;
      case "ShareableLinkNoData":
        return <ShareableLinkNoDataContent onLoad={handleLogoLoad} logoLoaded={logoLoaded} {...otherProps} />;
      case "ShareableLinkLoadFailed":
        return <ShareableLinkLoadFailedContent onLoad={handleLogoLoad} logoLoaded={logoLoaded} {...otherProps} />;
      case "ShareableLinkExpired":
        return <ShareableLinkExpiredContent onLoad={handleLogoLoad} logoLoaded={logoLoaded} {...otherProps} />;
      case "TestDown":
        return <TestDownContent onLoad={handleLogoLoad} logoLoaded={logoLoaded} {...otherProps} />;
      case "retrievalFailed":
        return <RetrievalIssueContent onLoad={handleLogoLoad} logoLoaded={logoLoaded} {...otherProps} />;
      case "uniqueUrlFail":
        return <UniqueUrlFailContent onLoad={handleLogoLoad} logoLoaded={logoLoaded}{...otherProps} />;
      case "saveFailed":
        return <SaveIssueContent onLoad={handleLogoLoad} logoLoaded={logoLoaded}{...otherProps} />;
      case "TestInterrupted":
        return <TestInterruptedContent onLoad={handleLogoLoad} logoLoaded={logoLoaded}{...otherProps} />;
      case "retrievedTestComplete":
        return <RetrievedTestCompleteContent onLoad={handleLogoLoad} logoLoaded={logoLoaded} {...otherProps} />;
      case "EndTest":
        return <EndTestContent onLoad={handleLogoLoad} logoLoaded={logoLoaded}{...otherProps} />;
      case "TimeUp":
        return <TimeUpContent onLoad={handleLogoLoad} logoLoaded={logoLoaded} {...otherProps} />;
      case "CameraPermission":
        return <CameraPermissionContent onLoad={handleLogoLoad} logoLoaded={logoLoaded} {...otherProps} />;
      case "ActiveTest":
        return (
          <ActiveTestContent
            candidateId={candidateId}
            testAttemptId={activeTestId}
            status={activeTestStatus}
            email={candidateEmail}
            onLoad={handleLogoLoad}
            logoLoaded={logoLoaded}
            {...otherProps}
          />
        );
      case "CameraReject":
        return (
          <CameraRejectContent
            onConfirmReject={onConfirmReject}
            onCancelReject={onCancelReject}
            onLoad={handleLogoLoad}
            logoLoaded={logoLoaded}
            {...otherProps}
          />
        );
      case "ScreenShareReject":
        return (
          <ScreenShareRejectContent
            onConfirmReject={onConfirmReject}
            onCancelReject={onCancelReject}
            onLoad={handleLogoLoad}
            logoLoaded={logoLoaded}
            {...otherProps}
          />
        );
      case "FullScreenReject":
        return (
          <FullScreenRejectContent
            onConfirmReject={onConfirmReject}
            onCancelReject={onCancelReject}
            onLoad={handleLogoLoad}
            logoLoaded={logoLoaded}
            {...otherProps}
          />
        );
      case "CameraFail":
        return (
          <CameraFailContent
            onConfirmReject={onConfirmReject}
            onCancelReject={onCancelReject}
            onLoad={handleLogoLoad}
            logoLoaded={logoLoaded}
            {...otherProps}
          />
        );
      case "ScreenShareFail":
        return (
          <ScreenShareFailContent
            onConfirmReject={onConfirmReject}
            onCancelReject={onCancelReject}
            onLoad={handleLogoLoad}
            logoLoaded={logoLoaded}
            {...otherProps}
          />
        );
      case "Login":
        return <LoginContent {...otherProps} onLoad={handleLogoLoad} logoLoaded={logoLoaded} />;        
      case "Register":
        return <RegisterContent onLoad={handleLogoLoad} logoLoaded={logoLoaded} {...otherProps} />;
      case "ResetPassword":
        return <ResetPasswordContent onLoad={handleLogoLoad} logoLoaded={logoLoaded} {...otherProps} />;
      case "ForgotPassword":
        return <ForgotPasswordContent onLoad={handleLogoLoad} logoLoaded={logoLoaded} {...otherProps} />;
      case "RequestAccess":
        return <RequestAccessContent onLoad={handleLogoLoad} logoLoaded={logoLoaded} {...otherProps} />;
      case "CreateAccount":
        return <CreateAccountContent onLoad={handleLogoLoad} logoLoaded={logoLoaded} {...otherProps} />;
    }
  };

  // Handle the button action based on the buttonLink (e.g. going to feedback page when the test is finished) or onConfirm (e.g. to call function to accept t&cs)
  const handleButtonAction = () => {
    if (buttonLink) {
      navigate(buttonLink);
    } else if (onConfirm) {
      onConfirm();
    }
  };

  content = getContent();

  if (!content) return null;

  return (
    <div
      ref={popupRef}
      className={`${logoLoaded ? styles.popupContainer : styles.popupContainerLoading} ${wide ? styles.wide : ''}`}
      style={{ zIndex }}
      role="dialog"
      aria-labelledby="popup-title"
      aria-describedby="popup-description"
      tabIndex="-1"
    >
      {content}
      {showCloseButton && (
        <Button type="proceed" onClick={handleButtonAction} />
      )}
    </div>
  );
};

export default Popup;