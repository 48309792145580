import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Button from './Button';
import styles from './ConfirmModal.module.css';

const ConfirmModal = ({
  show,
  title = 'Aviso',
  message,
  onConfirm,
  onCancel,
  confirmLabel = 'Confirmar',
  cancelLabel = 'Cancelar',
  themeClass = '',
}) => {
  const modalRef = useRef(null);
  
  useEffect(() => {
    if (show) {
      // Focus the first focusable element inside the modal
      const focusableElements = modalRef.current?.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
      );
      if (focusableElements && focusableElements.length) {
        focusableElements[0].focus();
      }
    }
  }, [show]);

  const handleClickOutside = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      onCancel(); // Close modal if clicking outside
    }
  };

  useEffect(() => {
    if (show) {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [show]);

  if (!show) return null;

  return (
    <div 
      className={styles.confirmModalOverlay}
      role="dialog" 
      aria-labelledby="confirmModalTitle" 
      aria-describedby="confirmModalMessage"
      aria-modal="true"
    >
      <div 
        className={`${styles.confirmModal} ${themeClass}`} 
        ref={modalRef}
        tabIndex="-1"
      >
        <div id="confirmModalTitle" className={styles.confirmModalTitle}>
          {title}
        </div>
        <p id="confirmModalMessage" className={styles.confirmModalMessage}>
          {message}
        </p>
        <div className={styles.confirmModalButtons}>
          <Button
            onClick={onConfirm}
            type="proceed"
            className={styles.confirmButton}
          >
            {confirmLabel}
          </Button>
          <Button 
            onClick={onCancel} 
            type="subtle"
            className={styles.cancelButton}
          >
            {cancelLabel}
          </Button>
        </div>
      </div>
    </div>
  );
};

ConfirmModal.propTypes = {
  show: PropTypes.bool.isRequired,
  title: PropTypes.string,
  message: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  themeClass: PropTypes.string,
};

export default ConfirmModal;
