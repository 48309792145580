//Centralised file for all DB functions
import axios from "axios";
import { retryRequest } from '../utils/retryRequest';
import { logEvent, logException, logTrace } from "./loggerFront"; // To log errors
import useAuth from "../hooks/useAuth";

const fileName = "databaseService";

// Check unique url parameters vs database for validating the company and role. On failure AppContext moves to seelctTest state for users to select from dropdowns
export const checkValidCompanyAndRole = async (company, role) => {
  try {
    const response = await fetch(
      `/api/check-company-role?empresa=${company}&cargo=${role}`
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const result = await response.json();
    // Return the full result including isValid, company, and role details
    return result;
  } catch (error) {
    logException("Error checkValidCompanyAndRole", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName: fileName,
      company,
      role,
    });
    // Return indication of failure to check, potentially with reason
    return { isValid: false, reason: "Network or server error" }; // Or handle the error as needed
  }
};

// Fetch the detail of a specific company(ies)
export const fetchCompanyDetails = async (companyCodes) => {
  try {
    // Ensure companyCodes is an array
    if (!Array.isArray(companyCodes)) {
      companyCodes = [companyCodes];
    }
    const response = await fetch(
      `/api/company-details/${companyCodes.join(",")}`
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const companyDetails = await response.json();
    return companyDetails; // This should include all the company details
  } catch (error) {
    logException("Error fetchCompanyDetails", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName: companyCodes,
    });
    throw error; // Propagate the error for handling elsewhere
  }
};

// Fetch all companies for the dropdown in SelectTest state if unique url check doesn't work. On failure AppContext moves state to loadFailed with an error popup
export const getAllCompanies = async () => {
  try {
    const response = await fetch("/api/companies");
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    logException("Error getAllCompaniess", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName: fileName,
    });
    return []; // Handle the error as needed, e.g., return an empty list
  }
};

// Fetch the detail of a specific company(ies)
export const fetchRoleDetails = async (roleCodes) => {
  try {
    // Ensure companyCodes is an array
    if (!Array.isArray(roleCodes)) {
      roleCodes = [roleCodes];
    }
    const response = await fetch(`/api/role-details/${roleCodes.join(",")}`);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const roleDetails = await response.json();
    return roleDetails; // This should include all the company details
  } catch (error) {
    logException("Error fetchCompanyDetails", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName: roleCodes,
    });
    throw error; // Propagate the error for handling elsewhere
  }
};

// Fetch intro chat instructions in useChatInstructions hook. On failure move to loadFailed state
export const getIntroChatInstructions = async () => {
  try {
    const response = await fetch("/api/intro-chat");
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    logException("Error getIntroChatInstructions", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName: fileName,
    });
    throw error; // Or handle the error as needed
  }
};

// Fetch intro chat instructions when it is a retrieved test in useChatInstructions hook. On failure move to loadFailed state
export const getRetrievedIntroChatInstructions = async () => {
  try {
    const response = await fetch("/api/retrieved-intro-chat");
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    logException("Error getIntroChatInstructions", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName: fileName,
    });
    throw error; // Or handle the error as needed
  }
};

// CONFIGS //

// Utility function to handle API requests and log exceptions
const fetchData = async (url, options = {}, retries = 3, delay = 1000) => {
  logTrace("fetchData", { url, options, retries, delay, fileName });
  for (let attempt = 1; attempt <= retries; attempt++) {
    try {
      const response = await axios(url, options);
      return response.data;
    } catch (error) {
      if (attempt === retries) {
        logException("Error fetching data", {
          errorMessage: error.message,
          errorStack: error.stack,
          fileName: fileName,
        });
        throw error;
      }
      await new Promise((resolve) => setTimeout(resolve, delay));
    }
  }
};

// Generic function to get specific config
export const getConfig = async (type, identifier, environment) => {
  logTrace("getConfig", { type, identifier, environment, fileName });
  const url = `/api/${type}-config/${encodeURIComponent(
    identifier
  )}?environment=${encodeURIComponent(environment)}`;
  return fetchData(url);
};

// Generic function to get all configs
export const getAllConfigs = async (type) => {
  const url = `/api/${type}-configs`;
  return fetchData(url);
};

// Generic function to get historical configs
export const getHistoricalConfigs = async (
  type,
  identifier,
  environment,
  limit
) => {
  const url = `/api/${type}-config-history/${encodeURIComponent(identifier)}`;
  const options = { params: { limit, environment } };
  return fetchData(url, options);
};

// Generic function to update config
export const updateConfig = async (
  type,
  identifier,
  config,
  environment,
  commitMessage
) => {
  const url = `/api/${type}-config/${encodeURIComponent(identifier)}`;
  const options = {
    method: "PUT",
    auth: {
      username: process.env.REACT_APP_ADMIN_USER,
      password: process.env.REACT_APP_ADMIN_PASSWORD,
    },
    data: { config, environment, commitMessage, type },
  };
  return fetchData(url, options);
};

// CHAT INSTRUCTIONS //

// Fetch the relevent chat instructions based on the identifiers. On failure move to loadFailed state
export const getTestChat = async (identifiers, environment) => {
  try {
    const response = await fetch(
      `/api/test-chat?identifiers=${identifiers.join(
        ","
      )}&environment=${environment}`
    );
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    logException("Error getTestChat", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName: fileName,
    });
    return [];
  }
};

// Fetch test instructions in AppContext and set as a global variable. On failure move to loadFailed state (done in AppContext)
export const getTestInstructions = async (instructionName) => {
  try {
    const response = await fetch(
      `/api/test-instructions?instructionName=${encodeURIComponent(
        instructionName
      )}`
    );
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    logException("Error getTestInstructions", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName: fileName,
      instructionName,
    });
    throw error;
  }
};

// Fetch test code languages and return them. On failure, log the exception and use all (managed in AppContext)
export const getTestCodeLanguages = async () => {
  try {
    const response = await fetch("/api/test-code-languages");
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json(); // Parse the JSON response body
    return data;
  } catch (error) {
    logException("Error getTestCodeLanguages", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName: fileName,
    });
    throw error;
  }
};

// Create a new candidate entry if its a new email, or update the existing if its a previous email. Called in AppContext when the global user email gets updated from ChatInput. On Failure move to saveFailed state with an error popup
export const saveCandidateDetails = async (details, retryCount = 3) => {
  try {
    const response = await fetch("/api/candidateDetails", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(details),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const result = await response.json();
    return result;
  } catch (error) {
    if (retryCount > 0) {
      return saveCandidateDetails(details, retryCount - 1);
    } else {
      logException("Error: saveCandidateDetails", {
        fileName: fileName,
        details,
        errorMessage: error.message,
        errorStack: error.stack,
      });
      throw error; // Rethrow or handle as needed after all retries
    }
  }
};

// Create a new testAttempt entry. Called in AppContext when the introChat is completed (the start test button is pressed). On Failure move to saveFailed state
export const saveTestAttempt = async (details) => {
  logEvent("Attempting saveTestAttempt with details:", { details });
  try {
    const response = await fetch("/api/testAttempt", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(details),
    });

    const result = await response.json();

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return result.testAttemptId;
  } catch (error) {
    logException("Error: saveTestAttempt", {
      fileName: fileName,
      errorMessage: error.message,
      errorStack: error.stack,
    });
    throw error; // Handle error as needed
  }
};

// Update the current testAttempt entry with either time and anti-fraud measures (called in AppContext) or test chat transcript (called in Chatbox). If AppContext or Chatbox save fails X times (rules in respective files) popup with the unique link to restore the session while ocntinuing to try reconnect
export const updateTestAttempt = async (testAttemptId, updateDetails) => {
  try {
    const response = await fetch(`/api/testAttempt/${testAttemptId}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(updateDetails),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const result = await response.json();
    return result; // Handle the response as needed
  } catch (error) {
    logException("Error updateTestAttempt", {
      fileName: fileName,
      testAttemptId,
      errorMessage: error.message,
      errorStack: error.stack,
    });
    throw error;
  }
};

// Fetch data for a specific testAttempt by its ID to restore a session. On failure move to loadFailed state
export const fetchTestAttemptData = async (testAttemptId) => {
  try {
    const response = await fetch(`/api/testAttempt/${testAttemptId}`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const result = await response.json();
    return result; // This should include all the necessary details for restoration
  } catch (error) {
    logException("Error fetchTestAttemptData", {
      fileName: fileName,
      testAttemptId,
      errorMessage: error.message,
      errorStack: error.stack,
    });
    throw error;
  }
};

// Create a new codeResponse entry. Called in IDE when the IDE is rendered. On Failure move to saveFailed state
export const saveCodeResponse = async (details) => {
  logEvent("Attempting saveCodeResponse with details:", { details });
  try {
    const response = await fetch("/api/code-response", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(details),
    });

    const result = await response.json();

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return result.codeResponseId;
  } catch (error) {
    logException("Error: saveCodeResponse", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName: fileName,
      details,
    });
    throw error;
  }
};

// Update the current codeResponse entry with the most recent code and transcript, called in the IDE by a timer or user input triggers. If IDE routine save fails twice show popup with the unique link to restore the session while ocntinuing to try reconnect
export const updateCodeResponse = async (codeResponseId, updateDetails) => {
  try {
    const response = await fetch(`/api/code-response/${codeResponseId}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(updateDetails),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const result = await response.json();
    return result;
  } catch (error) {
    logException("Error updateCodeResponse", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName: fileName,
      codeResponseId,
    });
    throw error;
  }
};

// Fetch code response data for a specific testAttempt by its ID. On failure move to loadFailed state
export const fetchCodeResponseData = async (testAttemptId) => {
  try {
    const response = await fetch(`/api/codeResponse/${testAttemptId}`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const result = await response.json();
    return result; // This should include the code response data for restoration
  } catch (error) {
    logException("Error fetchCodeResponseData", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName: fileName,
      testAttemptId,
    });
    throw error;
  }
};

// Save a record of the retrieved session data. On Failure retry over extended time as you dont need immediate return of id
export const saveRetrievedSession = async (details) => {
  try {
    const response = await fetch("/api/retrievedSession", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(details),
    });

    if (!response.ok) {
      // If server response is not ok, throw an error
      throw new Error("Network response was not ok");
    }
    // return result; // Or some specific part of the result if needed
  } catch (error) {
    logException("Error: saveRetrievedSession", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName: fileName,
    });
    throw error; // Propagate the error for handling elsewhere
  }
};

// Fetch username for a specific candidate by their ID.
export const fetchCandidateDetailsById = async (candidateId) => {
  try {
    const response = await fetch(`/api/candidate-details/${candidateId}`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data.username; // Assuming the server returns an object with a username property
  } catch (error) {
    logException("Error fetching candidate details", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName: fileName,
      candidateId,
    });
    throw error;
  }
};

// Fetch the user's photo by the blob name
export const fetchPhotoUrlByBlobName = async (blobName) => {
  try {
    const response = await fetch(`/api/download-photo/${blobName}`, {
      method: "GET",
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const photoBlob = await response.blob();
    const photoUrl = URL.createObjectURL(photoBlob);
    return photoUrl; // This is the URL you can use as an image source
  } catch (error) {
    logException("Error fetching photo", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName: "photoFetch",
      blobName,
    });
    throw error;
  }
};

// Fetch the user's screen shares by the test attempt ID
export const fetchScreenShares = async (testAttemptId) => {
  testAttemptId = String(testAttemptId); // Ensure testAttemptId is a string
  try {
    const response = await fetch(
      `/api/download-screen-capture/${testAttemptId}`,
      {
        method: "GET",
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const jsonResponse = await response.json();
    logTrace("screenShareFetch", { jsonResponse });

    // Process the JSON response to extract image URLs and organize by screen
    const screenShares = jsonResponse.map((screenShare) => ({
      blobName: screenShare.blobName,
      imageUrl: screenShare.image,
      modified: screenShare.modified,
    }));
    logTrace("screenShareFetch", { screenShares });

    // Organize by screen number
    const organizedScreenShares = screenShares.reduce((acc, curr) => {
      const screenNumber = curr.blobName.split("_")[2];
      if (!acc[screenNumber]) {
        acc[screenNumber] = [];
      }
      acc[screenNumber].push({
        imageUrl: curr.imageUrl,
        modified: curr.modified,
      });
      return acc;
    }, {});

    logTrace("screenShareFetch", { organizedScreenShares });

    return organizedScreenShares;
  } catch (error) {
    logException("Error fetching screen shares", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName: "screenShareFetch",
      testAttemptId,
    });
    throw error;
  }
};

// Fetch retrieval configuration for a specific link code when retrieving sessions
export const fetchRetrievalConfig = async (linkCode) => {
  try {
    const response = await fetch(`/api/retrieval-link/${linkCode}`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    // Assuming the server returns an object with candidate_id, test_attempt_id, instruction_index, and time_left properties
    return data; // Now data contains the entire config object
  } catch (error) {
    logException("Error fetching retrieval configuration", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName: fileName,
      linkCode,
    });
    throw error; // Propagate the error for further handling, e.g., showing an error message to the user
  }
};

// Save issue user report in reportIssue box. on Failure ReportIssue shows error message to contact suporte@degrau.co
export const saveIssue = async (issueDetails) => {
  try {
    const response = await fetch("api/issue", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(issueDetails),
    });

    const result = await response.json();

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return result; // You may adjust this return value based on your server's response
  } catch (error) {
    logException("Error: saveIssue", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName: fileName,
      issueDetails,
    });
    throw error;
  }
};

// RESULTS DATA //

// Get the roles the user has access to 
export const getAllUserRoles = async () => {
  try {
    const { accessToken } = useAuth(); // Destructure accessToken from useAuth
    const response = await axios.get("/api/roles/list", {
      headers: {
        Authorization: `Bearer ${accessToken()}`,
      },
    });
    return response.data;
  } catch (error) {
    logException("Error getAllUserRoles", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName: fileName,
    });
    if (error.response && error.response.status === 403) {
      throw new Error('Unauthorized');
    }
    throw error; // Re-throw the error to be handled by the caller
  }
};

// Get all the data for the candidate report passing the user or shareable link tokens for authentication
export const fetchCandidateReportData = async (urlCode, shareableLinkToken = null, version = null ) => {
  logTrace("fetchCandidateReportData", { urlCode, shareableLinkToken, version });
  try {
    // Construct the query URL with the optional version and shareable link token parameters
    let query = `/api/get-all-report-data/${urlCode}`;
    if (shareableLinkToken) {
      query += `/${shareableLinkToken}`; // Append the token as a URL parameter
    }
    if (version) {
      query += (shareableLinkToken ? `?version=${version}` : `?version=${version}`); // Append the version to the query
    }

    const { accessToken } = useAuth();
    const token = accessToken();

    // Make the HTTP GET request
    const response = await fetch(query, {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Authorization": `Bearer ${token}`
      },
    });

    // Check if the response was successful
    if (!response.ok) {
      const errorResponse = await response.text(); // Optionally capture and log the server response
      logException("Fetch failed with response:", {
        urlCode,
        version,
        errorResponse, // Log the response body for debugging purposes
        statusCode: response.status,
      });

      if (response.status === 403) {
        if (errorResponse.includes("Token has expired")) {
          throw new Error("Token has expired");
        }
        throw new Error("Permission denied");
      }
      
      throw new Error(`Network response was not ok: ${response.status}`);
    }

    const result = await response.json(); // Parse the JSON from the response
    return result; // Return the parsed data
  } catch (error) {
    logException("Error in fetchCandidateReportData", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName,
      urlCode,
      version,
    });
    throw error; // Re-throw the error to be handled by the caller
  }
};

// Get the summary results for tests of a company, potentially filtered by roleCodes (for example in the report interface where you just want the results of that role) and  date. Optionally pass token for authentication
export const fetchCompanyResults = async (companyCode, startAt = null, roleCodes = [], shareableLinkToken = null) => {
  return retryRequest(async () => {
    logTrace("fetchCompanyResults", { companyCode, roleCodes, startAt });

    // Ensure roleCodes is an array
    if (!Array.isArray(roleCodes)) {
      roleCodes = [roleCodes]; // Wrap single role code into an array
    }

    // Join roleCodes array into a comma-separated string for the URL
    const roleCodeParam = roleCodes.length > 0 ? roleCodes.join(",") : "all"; // Use "all" as a placeholder for no specific role codes

    // Construct the URL
    let url = new URL(`/api/company-results/${companyCode}/${roleCodeParam}`, window.location.origin);

    // Append the shareable link token if provided
    if (shareableLinkToken) {
      url.pathname += `/${shareableLinkToken}`;
    }

    // Append the startAt parameter if provided
    if (startAt) {
      url.searchParams.append("startAt", startAt);
    }

    const { accessToken } = useAuth();
    const token = accessToken();

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        if (response.status === 404) {
          // Handle the case where no data is found
          return { data: [], issue: "noData" };
        } else {
          throw new Error("Network response was not ok");
        }
      }

      const results = await response.json();
      return { data: results, issue: null }; // Return the results with no issue
    } catch (error) {
      logException("Error fetchCompanyResultsByCompanyCode", {
        errorMessage: error.message,
        errorStack: error.stack,
        fileName: fileName,
        companyCode,
      });
      return { data: [], issue: "unknown" }; // Return an empty array with an "unknown" issue
    }
  });
};

// Get the summary results a 'user' has access to
export const fetchUserReports = async (roleCodes = null) => {
  return retryRequest(async () => {
    const { accessToken } = useAuth();
    const token = accessToken();

    let url = "/api/users/accessible-reports";

    if (roleCodes) {
      let roleCodesQuery;

      if (Array.isArray(roleCodes)) {
        if (roleCodes.length === 1) {
          roleCodesQuery = roleCodes[0]; // If there's only one role code in the array, use it directly
        } else {
          roleCodesQuery = roleCodes.join(','); // If there are multiple, join them with commas
        }
      } else {
        roleCodesQuery = roleCodes; // If roleCodes is a string, use it directly
      }

      url += `?roleCodes=${roleCodesQuery}`;
    }

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: { 
          "Authorization": `Bearer ${token}`
        }
      });

      if (response.status === 404) {
        const errorResult = await response.json();
        logTrace("fetchUserReports 404", { errorResult });
        
        if (errorResult.message.includes("No report accesses found for the user")) {
          return { data: [], issue: "noAccesses" };
        } else if (errorResult.message.includes("No accessible reports found")) {
          return { data: [], issue: "noData" };
        } else {
          return { data: [], issue: "unknown" }; // General case
        }
      }

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      return { data: result.summaries, issue: null };
    } catch (error) {
      logException("Error fetching user reports", {
        errorMessage: error.message,
        errorStack: error.stack,
        fileName,
      });
      throw error; // Rethrow the error to be handled by the caller
    }
  });
};

// Get the summary results for all tests. Only accessible to GlobalAdmin users.
export const fetchAllResults = async (startAt) => {
  return retryRequest(async () => {
    logTrace("fetchAllResults pre token", { startAt, fileName });

    let url = new URL('/api/all-results', window.location.origin);

    if (startAt) {
        url.searchParams.append("startAt", startAt);
    }

    const { accessToken } = useAuth();
    const token = accessToken();
    logTrace("fetchAllResults post token", { startAt, token, fileName });

    try {
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
            },
        });

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        const results = await response.json();
        return results; // This should include an array of all test results
    } catch (error) {
        logException("Error fetchAllResults", {
            errorMessage: error.message,
            errorStack: error.stack,
            fileName: fileName,
        });
        throw error;
    }
  });
};

// Approve specific result using result id
export const approveResult = async (resultId) => {
  console.log("Approving result with id:", resultId);
  try {
    const { accessToken } = useAuth();
    const token = accessToken(); // Retrieve the token

    const response = await fetch(`/api/approve-result/${resultId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`, // Add the Authorization header
      },
    });

    // Check if the response was successful
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const result = await response.json();
    console.log("Result approved:", result);
    return result; // Return the result from the backend
  } catch (error) {
    logException("Error: approveResult", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName: 'yourFileName.js',
      resultId,
    });
    throw error; // Handle the error as needed
  }
};

// Make specific result invalid using result id
export const invalidateResult = async (resultId) => {
  try {
    const { accessToken } = useAuth();
    const token = accessToken(); // Retrieve the token

    const response = await fetch(`/api/invalidate-result/${resultId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`, // Add the Authorization header
      },
    });

    // Check if the response was successful
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const result = await response.json();
    return result; // Return the result from the backend
  } catch (error) {
    logException("Error: invalidateResult", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName: 'yourFileName.js',
      resultId,
    });
    throw error; // Handle the error as needed
  }
};

// FEEDBACK //

// Save user feedback in the candidate report
export const saveUserFeedback = async (feedbackDetails) => {
  try {
    const response = await fetch("/api/user-feedback", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(feedbackDetails), // Send feedback details as JSON
    });

    const result = await response.json();

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return result.feedbackId; // Return the feedbackId from the backend response
  } catch (error) {
    logException("Error: saveUserFeedback", {
      fileName, // The file where the error occurred
      errorMessage: error.message, // Error message
      errorStack: error.stack, // Error stack trace
    });

    throw error; // Handle the error as needed
  }
};

// Append a comment to existing feedback
export const appendFeedbackComment = async (feedbackId, comment) => {
  try {
    const response = await fetch("/api/append-feedback-comment", {
      // API endpoint to append comments
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ feedbackId, comment }), // Pass the correct feedbackId
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const result = await response.json();
    logTrace("appending comment to feedback", {
      feedbackId,
      comment,
      result,
    });

    return result.message; // Return success message from backend
  } catch (error) {
    logException("Error: appendFeedbackComment", {
      fileName, // The file where the error occurred
      errorMessage: error.message,
      errorStack: error.stack,
    });

    throw error; // Handle the error as needed
  }
};

// Delete user feedback by feedbackId (used if the user unclicks or changes their evaluation)
export const deleteUserFeedback = async (feedbackId) => {
  try {
    const response = await fetch("/api/user-feedback", {
      method: "DELETE", // DELETE request to remove feedback
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ feedbackId }), // Send the feedbackId to delete
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const result = await response.json();
    logTrace("Feedback deleted:", {
      feedbackId,
      result,
    });

    return result.message; // Return success message from backend
  } catch (error) {
    logException("Error: deleteUserFeedback", {
      fileName: "databaseService.js", // Update with the correct file name
      errorMessage: error.message,
      errorStack: error.stack,
    });

    throw error; // Rethrow to handle errors
  }
};

// TAGS //

// Fetch all tags for a specific company
export const fetchTagsByCompanyId = async (companyId) => {
  return retryRequest(async () => {
    try {
      const response = await axios.get(`/api/tags?companyId=${companyId}`);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error("Failed to fetch tags");
      }
    } catch (error) {
      logException("Error fetching tags by company ID", {
        errorMessage: error.message,
        errorStack: error.stack,
        fileName: fileName,
        companyId,
      });
      return []; // Optionally return an empty array or handle the error as needed
    }
  });
};

// Fetch all tags for all companies
export const fetchAllCompanyTags = async () => {
  return retryRequest(async () => {
    try {
      const response = await axios.get('/api/all-company-tags');
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error("Failed to fetch all company tags");
      }
    } catch (error) {
      logException("Error fetching all company tags", {
        errorMessage: error.message,
        errorStack: error.stack,
        fileName: 'yourFileName.js',
      });
      return []; // Return an empty array in case of error
    }
  });
};

// Add a new tag
export const addCompanyTag = async ({
  tagName,
  companyId,
  colour,
  textColour,
}) => {
  try {
    const response = await axios.post("/api/tags", {
      tagName,
      companyId,
      colour,
      textColour,
    });
    if (response.status === 201) {
      return response.data;
    } else {
      throw new Error("Failed to add tag");
    }
  } catch (error) {
    logException("Error adding company tag", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName: fileName,
      tagName,
      companyId,
      colour,
      textColour,
    });
    return null; // Optionally handle the error differently
  }
};

// Update an existing tag
export const updateCompanyTag = async ({ tagId, tagName, companyId, colour, textColour }) => {
  try {
      const response = await axios.put(`/api/tags/${tagId}`, {
          tagName,
          companyId,
          colour,
          textColour,
      });
      if (response.status === 200) {
          return response.data;
      } else {
          throw new Error("Failed to update tag");
      }
  } catch (error) {
      logException("Error updating company tag", {
          errorMessage: error.message,
          errorStack: error.stack,
          fileName: fileName,
          tagId,
          tagName,
          companyId,
          colour,
          textColour,
      });
      return null;
  }
};

// Remove a tag by its ID
export const removeCompanyTag = async (tagId) => {
  try {
    const response = await axios.delete(`/api/tags/${tagId}`);
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error("Failed to remove tag");
    }
  } catch (error) {
    logException("Error removing company tag", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName: fileName,
      tagId,
    });
    return null; // Optionally handle the error differently
  }
};

// Add a tag to a result
export const addTagToResult = async (url_code, tag_id) => {
  try {
    const response = await axios.post("/api/add-tag-to-result", {
      url_code,
      tag_id,
    });
    if (response.status === 201) {
      return response.data; // Return the updated list of tags
    } else {
      throw new Error("Failed to add tag to result");
    }
  } catch (error) {
    logException("Error adding tag to result", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName: fileName,
      url_code,
      tag_id,
    });
    return null;
  }
};

// Remove a tag from a result
export const removeTagFromResult = async (url_code, tag_id) => {
  try {
    const response = await axios.delete("/api/remove-tag-from-result", {
      data: { url_code, tag_id },
    });
    if (response.status === 200) {
      return response.data; // Return the updated list of tags
    } else {
      throw new Error("Failed to remove tag from result");
    }
  } catch (error) {
    logException("Error removing tag from result", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName: fileName,
      url_code,
      tag_id,
    });
    return null;
  }
};

// Fetch tags for a specific result
export const getTagsForResult = async (url_code) => {
  try {
    const response = await axios.get(`/api/tags-for-result/${url_code}`);
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error("Failed to fetch tags for result");
    }
  } catch (error) {
    logException("Error fetching tags for result", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName: fileName,
      url_code,
    });
    return null;
  }
};

// Get tags for multiple results 
export const getTagsByResult = async (urlCodes) => {
  try {
    const response = await axios.post('/api/tags-by-result', { urlCodes });
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error("Failed to fetch tags for results");
    }
  } catch (error) {
    logException("Error fetching tags for results", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName: fileName,
      urlCodes,
    });
    return {};
  }
};

// SHAREABLE LINKS //

// Create a new shareable link
export const createShareableLink = async (shareableLinkDetails) => {
  logTrace("createShareableLink", { shareableLinkDetails });

  // Get the access token from the auth hook
  const { accessToken } = useAuth();
  const token = accessToken();

  try {
    const response = await fetch("/api/shareableLinks/create", {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}` // Include the auth token
      },
      body: JSON.stringify(shareableLinkDetails),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const result = await response.json();
    return result; // Handle the result as needed
  } catch (error) {
    logException("Error creating shareable link", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName,
      shareableLinkDetails,
    });
    throw error; // Handle the error as needed
  }
};

// USERS //

// Request account access for a user
export const requestUserAccount = async (email, referralReport) => {
  logTrace("requestAccount", { email, referralReport });

  try {
    const response = await fetch("/api/users/request-user-account", {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, referralReport }), // Send email and referralReport as the request body
    });

    // If the response is not ok, extract the error message from the response body
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Network response was not ok");
    }

    const result = await response.json();
    return result; // Handle the result as needed
  } catch (error) {
    logException("Error requesting account", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName: "authenticationService",
      email,
      referralReport,
    });
    throw error; // Propagate the error to be handled by the caller
  }
};

// Fetch user details. Their token provides their ID
export const fetchUserNameAndEmail = async () => {
  const { accessToken } = useAuth();
  const token = accessToken();

  try {
      const response = await axios.get('/api/users/name-email', {
          headers: {
              Authorization: `Bearer ${token}`, // Use the token to authenticate and get the user ID
          },
      });

      return response.data; // { name: 'User Name', email: 'user@example.com' }
  } catch (error) {
    logException("Error fetching user name and email", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName: fileName,
    });
    throw error;
  }
};

// COMPANY ADMIN - USERS //

// Function to fetch user summary with optional companyId
export const fetchUserSummary = async (companyId = null) => {

  const { accessToken } = useAuth();
  const token = accessToken();

  try {
    const url = companyId 
      ? `/api/company-admin/user-summary?companyId=${companyId}` 
      : `/api/company-admin/user-summary`;
      
    const response = await fetch(url, {
      method: "GET",
      headers: { 
        "Authorization": `Bearer ${token}` // Include the auth token
      }
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const result = await response.json();
    return result; // Handle the result as needed
  } catch (error) {
    logException("Error fetching user summary", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName,
      companyId,
    });
    throw error; // Handle the error as needed
  }
};

// Function to add a user
export const addUser = async (userData) => {
  logTrace("addUser", { userData });

  const { accessToken } = useAuth();
  const token = accessToken();

  try {
    const response = await fetch("/api/company-admin/add-user", {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}` // Include the auth token
      },
      body: JSON.stringify(userData),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const result = await response.json();
    return result; // Handle the result as needed
  } catch (error) {
    logException("Error adding user", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName,
      userData,
    });
    throw error; // Handle the error as needed
  }
};

// Accept a user request passing the user id to be accepted
export const acceptUserRequest = async (userId) => {
  logTrace("acceptUserRequest", { userId });

  const { accessToken } = useAuth();
  const token = accessToken();

  try {
    const response = await fetch(`/api/company-admin/accept-user-request`, {
      method: "PUT",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}` // Include the auth token
      },
      body: JSON.stringify({ userId }),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const result = await response.json();
    logEvent("User request accepted", { userId });
    return result; // Handle the result as needed
  } catch (error) {
    logException("Error accepting user request", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName,
      userId,
    });
    throw error; // Handle the error as needed
  }
};

// Function to remove a user
export const removeUser = async (userId) => {
  logTrace("removeUser", { userId });

  const { accessToken } = useAuth();
  const token = accessToken();

  try {
    const response = await fetch(`/api/company-admin/remove-user/${userId}`, {
      method: "DELETE",
      headers: { 
        "Authorization": `Bearer ${token}` // Include the auth token
      }
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const result = await response.json();
    return result; // Handle the result as needed
  } catch (error) {
    logException("Error removing user", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName,
      userId,
    });
    throw error; // Handle the error as needed
  }
};

// Function to edit a user
export const editUser = async (userId, userData) => {
  logTrace("editUser", { userId, userData });

  const { accessToken } = useAuth();
  const token = accessToken();

  try {
    const response = await fetch(`/api/company-admin/edit-user/${userId}`, {
      method: "PUT",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}` // Include the auth token
      },
      body: JSON.stringify(userData),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const result = await response.json();
    return result; // Handle the result as needed
  } catch (error) {
    logException("Error editing user", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName,
      userId,
      userData,
    });
    throw error; // Handle the error as needed
  }
};

// Function to edit your own profile
export const editProfile = async (userData) => {
  logTrace("editProfile", { userData });

  const { accessToken } = useAuth();
  const token = accessToken();

  try {
    const response = await fetch(`/api/users/edit-profile`, {
      method: "PUT",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}` // Include the auth token
      },
      body: JSON.stringify(userData),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const result = await response.json();
    return result; // Handle the result as needed
  } catch (error) {
    logException("Error editing profile", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName,
      userId,
      userData,
    });
    throw error; // Handle the error as needed
  }
};

// Register a user after they input their name and password
export const registerUser = async (registerToken, name, surname, password) => {
  logTrace("registerUser", { registerToken, name, surname });

  try {
    const response = await fetch("/api/users/register", {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ registerToken, name, surname, password }),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const result = await response.json();
    return result; // Handle the result as needed
  } catch (error) {
    logException("Error registering user", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName,
      registerToken,
    });
    throw error; // Handle the error as needed
  }
};

// Function to fetch role assignments for the current user
export const fetchRoleAssignments = async () => {
  const { accessToken } = useAuth();
  const token = accessToken();

  try {
    const url = `/api/company-admin/role-assignments`;
    
    const response = await fetch(url, {
      method: "GET",
      headers: { 
        "Authorization": `Bearer ${token}` // Include the auth token
      }
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const result = await response.json();
    return result; // Return the fetched role assignments
  } catch (error) {
    logException("Error fetching role assignments", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName,
    });
    throw error; // Rethrow the error to be handled by the caller
  }
};

// COMPANY ADMIN - REPORT ACCESS //

// Fetch report access summary with optional companyId
export const fetchReportAccessSummary = async (companyId = null) => {

  const { accessToken } = useAuth();
  const token = accessToken();

  try {
    const url = companyId 
      ? `/api/company-admin/report-access-summary?companyId=${companyId}` 
      : `/api/company-admin/report-access-summary`;

    const response = await fetch(url, {
      method: "GET",
      headers: { 
        "Authorization": `Bearer ${token}` // Include the auth token
      }
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const result = await response.json();
    return result; // Handle the result as needed
  } catch (error) {
    logException("Error fetching report access summary", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName,
      companyId,
    });
    throw error; // Handle the error as needed
  }
};

// Add a report access
export const addReportAccess = async (accessData) => {
  logTrace("addReportAccess", { accessData });

  const { accessToken } = useAuth();
  const token = accessToken();

  try {
    const response = await fetch("/api/company-admin/add-report-access", {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}` // Include the auth token
      },
      body: JSON.stringify(accessData),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Network response was not ok");
    }

    const result = await response.json();
    return result; // Handle the result as needed
  } catch (error) {
    logException("Error adding report access", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName,
      accessData,
    });
    throw error; // Handle the error as needed
  }
};

// Remove report access passing the id of the access to remove
export const removeReportAccess = async (reportId) => {
  logTrace("removeReportAccess", { reportId });

  const { accessToken } = useAuth();
  const token = accessToken();

  try {
    const response = await fetch(`/api/company-admin/remove-report-access/${reportId}`, {
      method: "DELETE",
      headers: { 
        "Authorization": `Bearer ${token}` // Include the auth token
      }
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const result = await response.json();
    return result; // Handle the result as needed
  } catch (error) {
    logException("Error removing report access", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName,
      reportId,
    });
    throw error; // Handle the error as needed
  }
};

// Edit report access passing the id of the access to edit and the new access data
export const editReportAccess = async (reportId, accessData) => {
  logTrace("editReportAccess", { reportId, accessData });

  const { accessToken } = useAuth();
  const token = accessToken();

  try {
    const response = await fetch(`/api/company-admin/edit-report-access/${reportId}`, {
      method: "PUT",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}` // Include the auth token
      },
      body: JSON.stringify(accessData),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const result = await response.json();
    return result; // Handle the result as needed
  } catch (error) {
    logException("Error editing report access", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName,
      reportId,
      accessData,
    });
    throw error; // Handle the error as needed
  }
};

// Get the list of roles and companies the user has access (so they can assign reports for example) to based on the unique list of roles in the results they have access to
export const fetchReportAssignments = async (companyId = null) => {
  const { accessToken } = useAuth();
  const token = accessToken();
  logTrace("fetchReportAssignments", { companyId, token });

  try {
    const url = companyId 
      ? `/api/company-admin/report-assignments?companyId=${companyId}` 
      : `/api/company-admin/report-assignments`;
    
    const response = await fetch(url, {
      method: "GET",
      headers: { 
        "Authorization": `Bearer ${token}` // Include the auth token
      }
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const result = await response.json();
    return result; // Return the fetched role assignments
  } catch (error) {
    logException("Error fetching report assignments", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName,
    });
    throw error; // Rethrow the error to be handled by the caller
  }
};


