//Interface for backend dev test
import React, { useEffect, useState, useContext, useRef } from 'react';
import ReactMarkdown from 'react-markdown'; // For styling the instructions
import { AppContext } from '../../contexts/AppContext';
import { useTooltip } from '../../contexts/TooltipContext'; 
import { useChatContext,ChatProvider } from '../../contexts/ChatContext';
import { useTestContext } from '../../contexts/TestContext';
import Tooltip from '../tooltip/Tooltip'; 
import Resizer from '../common/Resizer';  
import Chatbox from '../chat/Chatbox';
import IDE from '../coding/IDE';  
import SqlInterface from '../coding/SqlInterface'; 
import styles from './BackendInterface.module.css';
import { Tabs, Tab } from '../common/Tabs';
import useResize from '../../hooks/useResize';

const BackendInterface = ({ role }) => {
    const { caseInstructions, currentSection, codeSubmitted, setCodeSubmitted, isTestStarted, finalCode, setFinalCode } = useTestContext(); // Load in the instructions to show to the user
    const { horizontalPosition, verticalPosition, startResizing } = useResize(); //USed for the resizer
    const [instructionsText, setInstructionsText] = useState("Carregando instruções...");
    const { retrievedCodeSubmitted, retrievedCode } = useContext(AppContext); // If codeSubmitted needs to be set when a session is restored from a returning user
    const { activeTooltips, getTooltipConfig } = useTooltip(); // Use the Tooltip context
    const isTestInstructionsTooltipActive = activeTooltips['testInstructions'];
    const testInstructionsTooltipConfig = getTooltipConfig('testInstructions');
    const [ tabsPresent, setTabsPresent ] = useState(false); // Identify if there is tabs in order to adjust heights

    // Update codeSubmitted and the finalCode if a session is restored (AppContext will update retrievedMessages with the messages saved in the DB for that session)
    useEffect(() => {
        if (retrievedCodeSubmitted) {
            setFinalCode(retrievedCode); // Set the finalCode to the retrievedCode from the restored session
            setCodeSubmitted(true); // Ensure codeSubmitted is updated to reflect the restored session status
        }
    }, [retrievedCodeSubmitted, retrievedCode]);

    
    // Get the case instructions for the current section
    useEffect(() => {
        if (caseInstructions && currentSection && caseInstructions[currentSection.sectionName].instructions) {
            setInstructionsText(caseInstructions[currentSection.sectionName].instructions);
        } else {
            setInstructionsText(""); // Use blank if there are no instructions for the current section
        }
    }, [caseInstructions, currentSection]);

    // Determine the presence of tabs based on the current section
    useEffect(() => {
        if (currentSection && currentSection.UI) {
            const { IDEComponent = {}, SQLComponent = {}, JupyterComponent = {} } = currentSection.UI;
            const componentsCount = [IDEComponent.enabled, SQLComponent.enabled, JupyterComponent.enabled].filter(Boolean).length;
            setTabsPresent(componentsCount > 1);
        } else {
            setTabsPresent(false);
        }
    }, [currentSection]);

    // Render the content based on the current section
    const renderContent = () => {
        if (!currentSection || !currentSection.UI) return null;
    
        const { IDEComponent = {}, SQLComponent = {}, JupyterComponent = {} } = currentSection.UI;
    
        if (tabsPresent) {
            return (
                <div className={styles.codingTabs} style={{ width: `${100 - verticalPosition}%` }}>
                    <Tabs>
                        {IDEComponent.enabled && (
                            <Tab label="Editor de Código">
                                <IDE 
                                    tabsPresent={tabsPresent}
                                />
                            </Tab>
                        )}
                        {SQLComponent.enabled && (
                            <Tab label="Terminal SQL">
                                <SqlInterface />
                            </Tab>
                        )}
                    </Tabs>
                </div>
            );
        }

        if (IDEComponent.enabled) {
            return (
                <div className={styles.codingTabs} style={{ width: `${100 - verticalPosition}%` }}>
                    <IDE 
                        tabsPresent={tabsPresent}
                    />
                </div>
            );
        }

        if (SQLComponent.enabled) {
            return (
                <div className={styles.codingTabs} style={{ width: `${100 - verticalPosition}%` }}>
                    <SqlInterface />
                </div>
            );
        }

        // Fallback if no specific UI components are specified
        return (
            <div className={styles.defaultInterface} style={{ width: `${100 - verticalPosition}%` }}>
                {/* Default interface content */}
            </div>
        );
    };

    return (
        <div className={styles.backendInterface}>
            {!isTestStarted && (
                <div className={styles.backendInterfaceCover}></div>
            )}
            {/* Container for chat and instructions */}
            <div className={styles.chatAndInstructionsContainer} style={{ width: codeSubmitted ? '100%' : `${verticalPosition}%` }}>
                {/* Conditionally render Instructions container */}
                {!codeSubmitted && (
                    <div 
                        id="instructionsContainer" 
                        className={styles.instructionsContainer} 
                        style={{ height: `${horizontalPosition}%` }}
                    >
                        <div 
                            className={styles.fullInstructions} 
                            style={{ filter: isTestStarted ? 'none' : 'blur(3px)' }}
                        >
                            <ReactMarkdown>
                                {instructionsText}
                            </ReactMarkdown>
                        </div>
                        {isTestInstructionsTooltipActive && testInstructionsTooltipConfig && (
                            <Tooltip variant="testInstructions" />
                        )}
                    </div>
                )}
                {/* Conditionally render Horizontal Resizer */}
                {!codeSubmitted && (
                    <Resizer type="horizontal" onMouseDown={(e) => startResizing(e, true)} />
                )}

                {/* Chatbox component (always rendered) */}
                <Chatbox 
                    style={{ 
                        height: `${100 - horizontalPosition}%`, 
                        width: codeSubmitted ? '100%' : '', 
                        margin: codeSubmitted ? '5px auto 0px auto' : '' 
                    }} 
                />
            </div>

            {/* Conditionally render Vertical Resizer */}
            {!codeSubmitted && (
                <Resizer type="vertical" onMouseDown={(e) => startResizing(e, false)} />
            )}

            {/* Conditionally render content based on role */}
            {!codeSubmitted && renderContent()}
        </div>
    );
};

export default BackendInterface;