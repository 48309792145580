import { Navigate, Route, Routes } from "react-router-dom";

// Page components to be routed to
import AdminPage from "../pages/AdminPage"; // For updating configurations
import AuthPage from "../pages/AuthPage"; // For logging in and signing up
import CandidateFeedbackPage from "../pages/CandidateFeedbackPage"; // Page with iframe to google forms for candidates after the interview
import CandidateReportPage from "../pages/CandidateReportPage"; // Show the candidates detailed results
import CompanyDashboardPage from "../pages/CompanyDashboardPage"; // For the user to see their dashboard with the roles they have access to
import ReportAccessManagementPage from "../pages/ReportAccessManagementPage";
import PrivacyPolicyPage from "../pages/PrivacyPolicyPage"; // Simple page with privacy policy
import TermsConditionsPage from "../pages/TermsConditionsPage"; // Simple page with terms and conditions
import TestPage from "../pages/TestPage"; // Main page for running interviews
import UserManagementPage from "../pages/UserManagementPage"; // For the admin to manage users
import MyAccountPage from "../pages/MyAccountPage";
import DocumentationPage from "../pages/DocumentationPage";

export default function ProtectedRoutes() {
  return (
    <Routes>
      <Route element={<Navigate replace to="/resultados" />} path="*" />
      <Route element={<AdminPage />} path="/admin" />
      <Route element={<CandidateReportPage />} path="/candidate-report" />
      <Route element={<TestPage />} path="/entrevista" />
      <Route element={<CandidateFeedbackPage />} path="/feedback" />
      <Route element={<ReportAccessManagementPage />} path="/acesso-a-relatorios" />
      <Route element={<PrivacyPolicyPage />} path="/politica-de-privacidade" />
      <Route element={<PrivacyPolicyPage />} path="/privacy-policy" />
      <Route element={<CandidateReportPage />} path="/relatorio" />
      <Route element={<CompanyDashboardPage />} path="/resultados" />
      <Route element={<TermsConditionsPage />} path="/termos-e-condicoes" />
      <Route element={<TermsConditionsPage />} path="/terms-and-conditions" />
      <Route element={<UserManagementPage />} path="/contas" />
      <Route element={<MyAccountPage />} path="/minha-conta" />
      <Route element={<DocumentationPage />} path="/documentacao" />
    </Routes>
  );
}
