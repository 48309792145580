import React, { useState, useEffect } from 'react';
import styles from '../../sharedStyles/AdminFormsStyles.module.css';
import { getPermissionLevel } from '../../utils/getPermissionLevel';
import { format, subWeeks, addWeeks } from 'date-fns'; // Importing date-fns for date manipulation

const AddReportAccessForm = ({ users, roles, userPermissions, setFormError, setAddReportAccessDetails, companyDetails, companies, preselectedUsers = [] }) => {
    const [companyId, setCompanyId] = useState(companyDetails.companyID); // Set the company ID from the company details
    const [selectedUsers, setSelectedUsers] = useState(preselectedUsers); // State for the selected users, default to preselected users
    const [selectedRole, setSelectedRole] = useState(roles.length === 1 ? roles[0].roleCode : ''); // Default to the only role if one role is provided
    const [selectedRoleName, setSelectedRoleName] = useState(roles.length === 1 ? roles[0].roleName : ''); // Default to the only role if one role is provided
    const [selectedCompanyId, setSelectedCompanyId] = useState(companyId); // Default to the only company if one company is provided
    const [selectedCompanyName, setSelectedCompanyName] = useState(companies.find(company => company.companyID === companyId)?.companyName || ''); // Default to the company name
    const [dateFrom, setDateFrom] = useState(format(subWeeks(new Date(), 2), 'yyyy-MM-dd')); // Default to 2 weeks ago
    const [dateTo, setDateTo] = useState(format(addWeeks(new Date(), 2), 'yyyy-MM-dd')); // Default to 2 weeks from now
    const [previousDateFrom, setPreviousDateFrom] = useState(dateFrom); // Store the original start date to enable toggling of no date 
    const [previousDateTo, setPreviousDateTo] = useState(dateTo); // Store the original end date to enable toggling of no date 
    const [noStartDate, setNoStartDate] = useState(false); // Checkbox state for no start date
    const [noEndDate, setNoEndDate] = useState(false); // Checkbox state for no end date
    const permissionLevel = getPermissionLevel(userPermissions, 'ADD_PERMISSION');

    // set companyId from companyDetails
    useEffect(() => {
        setCompanyId(companyDetails.companyID);
    }, [companyDetails]);

    // Handle changes in role selection
    const handleRoleChange = (e) => {
        const selectedRoleCode = e.target.value;
        const selectedRoleObject = roles.find(role => role.roleCode === selectedRoleCode);
        
        setSelectedRole(selectedRoleCode);
        setSelectedRoleName(selectedRoleObject ? selectedRoleObject.roleName : '');
    };

    // Handle changes in company selection
    const handleCompanyChange = (e) => {
        const selectedCompanyID = e.target.value;
        const selectedCompanyObject = companies.find(company => company.companyID === parseInt(selectedCompanyID, 10));
        setSelectedCompanyId(selectedCompanyID);
        setSelectedCompanyName(selectedCompanyObject ? selectedCompanyObject.companyName : '');
    };

    // Handle selection of users
    const handleUserSelection = (e) => {
        const selectedUserId = e.target.value;
        if (selectedUserId) {
            const selectedUser = users.find(user => user.id === parseInt(selectedUserId, 10));
    
            if (selectedUser && !selectedUsers.includes(selectedUser)) {
                setSelectedUsers([...selectedUsers, selectedUser]);
            }
        }
    };

    // Remove selected user
    const removeUser = (userId) => {
        setSelectedUsers(selectedUsers.filter(user => user.id !== userId));
    };

    // Set the default role if only one role is available
    useEffect(() => {
        if (roles.length === 1) {
            setSelectedRole(roles[0].roleCode);  // Set the only role as default
            setSelectedRoleName(roles[0].roleName); // Set the only role name as default
        } else {
            setSelectedRole(''); // No role selected by default
            setSelectedRoleName(''); // Clear the role name
        }
    }, [roles]);

    // Set the default company if only one company is available
    useEffect(() => {
        if (companies.length === 1) {
            setSelectedCompanyId(companies[0].companyID); // Set the only company as default
            setSelectedCompanyName(companies[0].companyName); // Set the only company name as default
        } else {
            setSelectedCompanyId(companyId); // No company selected by default
            setSelectedCompanyName(companyDetails.companyName); // Clear the company name
        }
    }, [companies]);

    // Handle changes to the no start date checkbox
    const handleNoStartDateChange = () => {
        if (!noStartDate) {
            setPreviousDateFrom(dateFrom); // Save the current date before setting it to null
            setDateFrom(null); // Set date to null when the checkbox is checked
        } else {
            setDateFrom(previousDateFrom); // Revert to the previous date when the checkbox is unchecked
        }
        setNoStartDate(!noStartDate);
    };

    // Handle changes to the no end date checkbox
    const handleNoEndDateChange = () => {
        if (!noEndDate) {
            setPreviousDateTo(dateTo); // Save the current date before setting it to null
            setDateTo(null); // Set date to null when the checkbox is checked
        } else {
            setDateTo(previousDateTo); // Revert to the previous date when the checkbox is unchecked
        }
        setNoEndDate(!noEndDate);
    };

    // Call setAddReportAccessDetails whenever any of the fields change
    useEffect(() => {
        const selectedIds = selectedUsers.map(user => user.id);
        setAddReportAccessDetails({
            userIds: selectedIds,
            roleCode: selectedRole,
            roleName: selectedRoleName,
            dateFrom: noStartDate ? null : dateFrom, // Set to null if no start date
            dateTo: noEndDate ? null : dateTo, // Set to null if no end date
            companyId: selectedCompanyId,
            companyName: selectedCompanyName, // Pass companyName
            users: selectedUsers, // Pass the selected user objects
        });
    }, [selectedUsers, selectedRole, selectedRoleName, dateFrom, dateTo, noStartDate, noEndDate, selectedCompanyId, selectedCompanyName]);    

    return (
        <div>
            <div className={styles.inputGroup}>
                <label>Selecionar contas</label>
                {selectedUsers.length > 0 && (
                    <div className={styles.selectedUsersList}>
                        {selectedUsers.map(user => (
                            <span key={user.id} className={styles.selectedUserTag}>
                                {user.name} ({user.email})
                                <button type="button" onClick={() => removeUser(user.id)}>x</button>
                            </span>
                        ))}
                    </div>
                )}
                <select value="" onChange={handleUserSelection}>
                    <option value="" disabled>
                        {selectedUsers.length > 0 ? 'Adicione outra conta' : 'Selecione uma conta'}
                    </option>
                    {users.map((user) => (
                        <option key={user.id} value={user.id}>{user.name} {user.surname} ({user.email})</option>
                    ))}
                </select>
            </div>
            {permissionLevel === 'GLOBAL' && (
                <div className={styles.inputGroup}>
                    <label>Empresa</label>
                    <select value={selectedCompanyId} onChange={handleCompanyChange}>
                        <option value="" disabled>Selecione uma empresa</option> {/* Placeholder */}
                        {companies.map((company) => (
                            <option key={company.companyID} value={company.companyID}>{company.companyName}</option>
                        ))}
                    </select>
                </div>
            )}
            <div className={styles.inputGroup}>
                <label>Cargo</label>
                <select value={selectedRole} onChange={handleRoleChange}>
                    <option value="" disabled>Selecione um cargo</option> {/* Placeholder */}
                    {roles.map((role, index) => (
                        <option key={index} value={role.roleCode}>{role.roleName}</option>
                    ))}
                </select>
            </div>
            <div className={styles.inputGroup}>
                <label>Data de Início</label>
                <input
                    type="date"
                    value={dateFrom || previousDateFrom || ''}
                    onChange={(e) => setDateFrom(e.target.value)}
                    className={noStartDate ? styles.disabledDateInput : ''}
                    disabled={noStartDate} // Disable input if no start date is checked
                />
                <label className={styles.checkboxContainer}>
                    <input
                        type="checkbox"
                        checked={noStartDate}
                        onChange={handleNoStartDateChange}
                        className={styles.checkboxInput}
                    />
                    <span className={styles.checkboxLabel}>Acesso ilimitado a relatórios passados</span>
                </label>
            </div>

            <div className={styles.inputGroup}>
                <label>Data de Fim</label>
                <input
                    type="date"
                    value={dateTo || previousDateTo || ''}
                    onChange={(e) => setDateTo(e.target.value)}
                    className={noEndDate ? styles.disabledDateInput : ''}
                    disabled={noEndDate} // Disable input if no end date is checked
                />
                <label className={styles.checkboxContainer}>
                    <input
                        type="checkbox"
                        checked={noEndDate}
                        onChange={handleNoEndDateChange}
                        className={styles.checkboxInput}
                    />
                    <span className={styles.checkboxLabel}>Acesso ilimitado a relatórios futuros</span>
                </label>
            </div>

        </div>
    );
};

export default AddReportAccessForm;
