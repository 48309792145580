// Process the instruction fetched from useChatInstructions to decide the app actions. The 'type' of the instructions determines the behaviour where:
// - fixedMessage: Simply takes the content of the pre-determined and adds to the chatbox as a system message. This tends to be the first question of a section
// - gptConversation: Enables a looped conversation between GPT and the user until either GPT ends it or they time or # of messages limit is met. It streams GPT responses
// - gptFirstQuestion: This prompts gpt to ask the first question of a section, it is more dynamic than 'fixedMessage', e.g. it can ask about a specific part of the user's code
// - gptCodeEvaluation: Sends the code the GPT and responds with the evaluation which can be used to determine future questions.

import { sendNonStreamedGptMessages } from './sendNonStreamedGptMessages';
import { openGptWebSocket, closeGptWebSocket, sendGptMessage, isGptWebSocketCurrentlyOpen } from './websocketService';
import handleGptConversation from './handleGPTConversation';
import { logEvent, logException, logTrace } from './loggerFront';

const fileName = 'processChatInstruction'
// Check the conversation has passed the time limit
const checkTimeLimits = (sectionStartTime, chatInstruction, accessibilityMode) => {

    // If accessibility mode is on, return infinite time
    if (accessibilityMode) {
        return { elapsedTime: 0, allowedTimePerSection: Infinity }; // Infinite time for accessibility mode
    }

    // if the sectionStartTime is not set, set it to the current time
    const startTime = sectionStartTime || Date.now();
    // Calculate elapsed time
    const currentTime = Date.now();
    const elapsedTime = (currentTime - startTime) / 60000; // Convert to minutes

    let allowedTimePerSection = chatInstruction.maxMinutes;

    return { elapsedTime, allowedTimePerSection };
};

const processChatInstruction = async (chatInstruction, chatInstructions, index, addSystemMessage, addGptSystemMessage, fetchNextChatInstruction, prepareNextAction, testStage, chats, instructionsText, preEvalCriteria, finalCode, boilerplateCode, initialDatabaseSchema, databaseSchema, userInputCount, gptCodeEvaluation, setGptCodeEvaluation, setChats, saveTestChatTranscript, sectionStartTime, codeLanguage, setIsGptResponseDelayed, setIsTestInterrupted, webSocketUrl, isTimeUpCallback, additionalTime, testAttemptId, isAdmin, currentChatId, moveToNextSection, currentInstructionKey, currentSection, onChatInstructionsComplete, accessibilityMode, additionalActions) => {
    if (testStage === "introChat") {
        await addSystemMessage(chatInstruction); // Function in chatbox to add content to the message state as a system message, and 'type' the message to the user
        await checkNextAction(chatInstruction, addSystemMessage, userInputCount, fetchNextChatInstruction, index, chatInstructions, prepareNextAction, moveToNextSection, isTimeUpCallback, currentInstructionKey, onChatInstructionsComplete, chats, setChats ) ;

    } else if (testStage === "test") {

        if (chatInstruction.type === "fixedMessage") {
            await addSystemMessage(chatInstruction);
            await checkNextAction(chatInstruction, addSystemMessage, userInputCount, fetchNextChatInstruction, index, chatInstructions, prepareNextAction, moveToNextSection, isTimeUpCallback, currentInstructionKey, onChatInstructionsComplete, chats, setChats );
        } 
        else if (chatInstruction.type === "gptFirstQuestion") {
            await handleGptConversation(chatInstruction, chats, instructionsText, preEvalCriteria, finalCode, boilerplateCode, initialDatabaseSchema, databaseSchema, addGptSystemMessage, openGptWebSocket, closeGptWebSocket, sendGptMessage, isGptWebSocketCurrentlyOpen, gptCodeEvaluation, codeLanguage, setIsGptResponseDelayed, setIsTestInterrupted, webSocketUrl, isTimeUpCallback, testAttemptId, isAdmin, currentChatId, currentSection, 'gptFirstQuestion' );
            await checkNextAction(chatInstruction, addSystemMessage, userInputCount, fetchNextChatInstruction, index, chatInstructions, prepareNextAction, moveToNextSection, isTimeUpCallback, currentInstructionKey, onChatInstructionsComplete, chats, setChats );
        }         
        else if (chatInstruction.type === "gptConversation") {
            // First, check to see if the time limit is reached or user input limit is met
            const { elapsedTime, allowedTimePerSection } = checkTimeLimits(sectionStartTime, chatInstruction, accessibilityMode);
            
            // If time runs out then stop
            if (isTimeUpCallback()) {
                return; 
            }

            // If the time limit or message count limit has been reached, handle the conversation end directly
            if (userInputCount >= chatInstruction.maxMessages || elapsedTime >= allowedTimePerSection) {
                // Call the limit reached function without checking skipToNextTriggerMet since we're directly acting on time/userInput limits
                await handleConversationEnd(chatInstruction, chatInstructions, currentInstructionKey, chats, instructionsText, preEvalCriteria, finalCode, boilerplateCode, initialDatabaseSchema, databaseSchema, addSystemMessage, addGptSystemMessage, openGptWebSocket, closeGptWebSocket, sendGptMessage, isGptWebSocketCurrentlyOpen, userInputCount, gptCodeEvaluation, codeLanguage, fetchNextChatInstruction, index, prepareNextAction, setIsGptResponseDelayed, setIsTestInterrupted, webSocketUrl, isTimeUpCallback, testAttemptId, currentChatId, moveToNextSection, isAdmin, currentSection, false);
            } else {
                // If the limits aren't reached, then proceed to handle GPT conversation
                const skipToNextTriggerMet = await handleGptConversation(chatInstruction, chats, instructionsText, preEvalCriteria, finalCode, boilerplateCode, initialDatabaseSchema, databaseSchema, addGptSystemMessage, openGptWebSocket, closeGptWebSocket, sendGptMessage, isGptWebSocketCurrentlyOpen, gptCodeEvaluation, codeLanguage, setIsGptResponseDelayed, setIsTestInterrupted, webSocketUrl, isTimeUpCallback, testAttemptId, isAdmin, currentChatId, currentSection, 'gptConversation' );
        
                // Now check if GPT has decided to move on
                if (skipToNextTriggerMet) {
                    // Handle the conversation end due to GPT's decision to move on
                    await handleConversationEnd(chatInstruction, chatInstructions, currentInstructionKey, chats, instructionsText, preEvalCriteria, finalCode, boilerplateCode, initialDatabaseSchema, databaseSchema, addSystemMessage, addGptSystemMessage, openGptWebSocket, closeGptWebSocket, sendGptMessage, isGptWebSocketCurrentlyOpen, userInputCount, gptCodeEvaluation, codeLanguage, fetchNextChatInstruction, index, prepareNextAction, setIsGptResponseDelayed, setIsTestInterrupted, webSocketUrl, isTimeUpCallback, testAttemptId, currentChatId, moveToNextSection, isAdmin, currentSection, true);
                } 
                else await checkNextAction(chatInstruction, addSystemMessage, userInputCount, fetchNextChatInstruction, index, chatInstructions, prepareNextAction, moveToNextSection, isTimeUpCallback, currentInstructionKey, onChatInstructionsComplete, chats, setChats );
            }        
        } 
        else if (chatInstruction.type === "gptCodeEvaluation") {
            //for code evaluation, send the necessary props to the non streaming gpt service and as the response
            sendNonStreamedGptMessages(chatInstruction, finalCode, boilerplateCode, initialDatabaseSchema, databaseSchema, instructionsText, preEvalCriteria, testAttemptId, isAdmin, currentSection)
                .then(({ gptPrompt, gptResponse, runId }) => {
                    // Handle the GPT prompt and response
                    if (gptResponse && gptResponse.choices && gptResponse.choices.length > 0) {
                        const responseContent = gptResponse.choices[0].message.content;

                        // Set the GPT code evaluation response in the Chatbox state
                        setGptCodeEvaluation(responseContent);
                        // Send a non-visible message to the chat
                        addGptSystemMessage('', true, true, chatInstruction, false, gptPrompt, runId, false, false, responseContent );
                    } else {
                        logException('GPT response format unexpected or empty:', {
                            errorType: 'GptError',
                            error: gptResponse,
                            fileName: fileName,
                            code: finalCode,
                        });
                    }
                })
                .catch(error => {
                    logException('Error processing GPT code evaluation', {
                        errorType: 'GptError',
                        errorMessage: error.message,
                        errorStack: error.stack, 
                        statusCode: error.response ? error.response.status : 'No response status',
                        responseBody: error.response ? JSON.stringify(error.response.data) : 'No response body',
                        fileName: fileName,
                        code: finalCode,
                    });
                });
            await checkNextAction(chatInstruction, addSystemMessage, userInputCount, fetchNextChatInstruction, index, chatInstructions, prepareNextAction, moveToNextSection, isTimeUpCallback, currentInstructionKey, chats, setChats );
        }
    }
};

// Process the next action based on 'nextAction' in instructions
async function checkNextAction(chatInstruction, addSystemMessage, userInputCount, fetchNextChatInstruction, index, chatInstructions, prepareNextAction, moveToNextSection, isTimeUpCallback, currentInstructionKey, onChatInstructionsComplete, chats, setChats ) {
    if (isTimeUpCallback()) { // If the time has ran out then stop
        return; 
    }
    if (chatInstruction.nextAction === 'auto') {
        fetchNextChatInstruction(index, chatInstructions, currentInstructionKey, userInputCount, chats, setChats); // Get the next instruction using useChatInstructions
    } else if (chatInstruction.nextAction.includes('Input')) {
        prepareNextAction(chatInstruction.nextAction); // Prepare for user input in Chatbox
    } else if (chatInstruction.nextAction === 'nextSection') {
        moveToNextSection(); // Move to the next section
    } else if (chatInstruction.nextAction === 'finishTest') {
        onChatInstructionsComplete(); // Call the function to handle the completion of chat instructions
    }
};

async function handleConversationEnd(chatInstruction, chatInstructions, currentInstructionKey, chats, instructionsText, preEvalCriteria, finalCode, boilerplateCode, initialDatabaseSchema, databaseSchema, addSystemMessage, addGptSystemMessage, openGptWebSocket, closeGptWebSocket, sendGptMessage, isGptWebSocketCurrentlyOpen, userInputCount, gptCodeEvaluation, codeLanguage, fetchNextChatInstruction, index, prepareNextAction, setIsGptResponseDelayed, setIsTestInterrupted, webSocketUrl, isTimeUpCallback, testAttemptId, currentChatId, moveToNextSection, isAdmin, currentSection, skipToNextTriggerMet) {
    if (isTimeUpCallback()) { // If the time has ran out then stop
        return; 
    }
    // Only generate the message for user if the time or # of messsages limit is met, for the case the GPT adjudges to move on it will already have messaged the user
    if (!skipToNextTriggerMet) {
        switch (chatInstruction.actionAtLimit) {
            case 'fixedMessage':
                const fixedMessageInstruction = {
                    type: 'fixedMessage',
                    content: chatInstruction.limitMessageContent,
                };
                await addSystemMessage(fixedMessageInstruction);
                break;
            case 'gptClosingMessage':
                await handleGptConversation(chatInstruction, chats, instructionsText, preEvalCriteria, finalCode, boilerplateCode, initialDatabaseSchema, databaseSchema, addGptSystemMessage, openGptWebSocket, closeGptWebSocket, sendGptMessage, isGptWebSocketCurrentlyOpen, gptCodeEvaluation, codeLanguage, setIsGptResponseDelayed, setIsTestInterrupted, webSocketUrl, isTimeUpCallback, testAttemptId, isAdmin, currentChatId, currentSection, 'gptClosingMessage', true);
                break;
            // Add other cases here if needed
        }
    }

    // Check if the instruction specifies to move to the next chat instruction
    if (chatInstruction.moveToNext) {
        const isLastInstruction = index === chatInstructions[currentInstructionKey].length - 1;
        if (isLastInstruction) { // If it's the last instruction then move to the next section
            moveToNextSection();
        } else { // Otherwise, fetch the next instruction
            fetchNextChatInstruction(index, chatInstructions, currentInstructionKey, userInputCount, chats, setChats);
        }
    }
}

export default processChatInstruction;
