import TurndownService from 'turndown';

// Utility function to convert old HTML instructions to Markdown
export function convertOldInstructionsToMarkdown(htmlContent) {
    // Initialize the Turndown service with custom rules
    const turndownService = new TurndownService({
        bulletListMarker: '-',  // Ensures bullets are consistent
        emDelimiter: '*',       // Markdown for italic
        strongDelimiter: '**',  // Markdown for bold
        codeBlockStyle: 'fenced',
        fence: '```'
    });

    // Add custom rule to handle <b> and <strong> tags to **bold**
    turndownService.addRule('bold', {
        filter: ['b', 'strong'],
        replacement: function (content) {
            return `**${content}**`;
        }
    });

    // Add custom rule to handle <span> with monospace styling to `code`
    turndownService.addRule('inlineCode', {
        filter: function (node, options) {
            return (
                node.nodeName === 'SPAN' &&
                (node.style.fontFamily.includes('Courier') ||
                 node.style.fontFamily.includes('monospace'))
            );
        },
        replacement: function (content) {
            return `\`${content}\``;
        }
    });

    // Add custom rule to handle <br> tags to avoid extra new lines
    turndownService.addRule('lineBreak', {
        filter: 'br',
        replacement: function() {
            return '\n';
        }
    });

    // Customize the escape function to avoid unnecessary escaping
    turndownService.escape = (string) => {
        // Escape characters, but skip underscores inside code spans
        return string
            .replace(/\\(\*|_|`)/g, '$1') // Remove escape if applied before
            .replace(/[\*`\[\]()#+\-.!]/g, '\\$&'); // Escape other special chars
    };

    // Handle nested lists with correct indentation
    turndownService.addRule('nestedList', {
        filter: 'ul',
        replacement: function (content, node) {
            const parent = node.parentNode;
            const parentTag = parent.nodeName.toLowerCase();
            const indent = parentTag === 'li' ? '    ' : '';
            return `\n${indent}${content.trim()}`;
        }
    });

    // Ensure lists are formatted without additional spaces
    turndownService.addRule('listItem', {
        filter: 'li',
        replacement: function(content, node, options) {
            content = content.trim();
            return `${options.bulletListMarker} ${content}\n`;
        }
    });

    // Convert the provided HTML content to Markdown
    const markdownContent = turndownService.turndown(htmlContent);

    return markdownContent;
}
