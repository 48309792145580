//When user's screen width is too small
import React from 'react';
import Logo from '../common/DegrauLogo';
import styles from './Popup.module.css'; 

const ScreenSizeIssueContent = () => ({ onLoad, logoLoaded, ...otherProps}) => {
    return (
        <>
            <Logo className={styles.degrauLogoPopup} onLoad={onLoad}/>
            <p className={styles.popupText}>
                Sua tela é muito pequena para visualizar este conteúdo. 
                Por favor, redimensione ou use um laptop ou computador.
            </p>
        </>
    );
};

export default ScreenSizeIssueContent;
