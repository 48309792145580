import { jwtDecode } from "jwt-decode"; 
import { logEvent, logException, logTrace } from '../services/loggerFront';

const useAuth = () => {
  const filename = 'useAuth.js'; // Define the filename for the logs

  const accessToken = () => localStorage.getItem("access_token"); // Get token from local storage

  const signIn = (token) => localStorage.setItem("access_token", token); // Save token to local storage

  const signOut = () => localStorage.removeItem("access_token"); // Remove token from local storage

  const isTokenExpired = () => {
    if (!accessToken()) return true;
    try {
      const { exp } = jwtDecode(accessToken());

      if (Date.now() >= exp * 1000) {
        signOut();
        return true;
      }
      return false;
    } catch (e) {
      logException('Error checking token expiration', {
        errorMessage: e.message,
        errorStack: e.stack,
        filename,
      });
      signOut();
      return true;
    }
  };

  // Get permissions from token
  const getPermissions = () => {
    const token = accessToken();
    if (!token) return null;

    try {
      const { permissions } = jwtDecode(token);
      return permissions || [];
    } catch (e) {
      logException('Error getting permissions', {
        errorMessage: e.message,
        errorStack: e.stack,
        filename,
      });
      signOut();
      return null;
    }
  };

  // Get company details
  const getCompanyDetails = () => {
    const token = accessToken();
    if (!token) return null;

    try {
      const { companyDetails } = jwtDecode(token);
      return companyDetails || null;
    } catch (e) {
      logException('Error getting company details', {
        errorMessage: e.message,
        errorStack: e.stack,
        filename
      });
      signOut();
      return null;
    }
  }

  // Get the user roles from token
  const getUserRoles = () => {
    const token = accessToken();
    if (!token) return null;

    try {
      const { roles } = jwtDecode(token);
      return roles || null;
    } catch (e) {
      logException('Error getting roles', {
        errorMessage: e.message,
        errorStack: e.stack,
        filename,
      });
      signOut();
      return null;
    }
  }

  // Get the users name from token
  const getUserName = () => {
    const token = accessToken();
    if (!token) return null;

    try {
      const { name } = jwtDecode(token);
      return name || null;
    } catch (e) {
      logException('Error getting user name', {
        errorMessage: e.message,
        errorStack: e.stack,
        filename,
      });
      signOut();
      return null;
    }
  }

  // Get the user email
  const getUserEmail = () => {
    const token = accessToken();
    if (!token) return null;

    try {
      const { email } = jwtDecode(token);
      return email || null;
    } catch (e) {
      logException('Error getting user email', {
        errorMessage: e.message,
        errorStack: e.stack,
        filename,
      });
      signOut();
      return null;
    }
  }

  // Get the user Id
  const getUserId = () => {
    const token = accessToken();
    if (!token) return null;

    try {
      const { userId } = jwtDecode(token);
      return userId || null;
    } catch (e) {
      logException('Error getting user Id', {
        errorMessage: e.message,
        errorStack: e.stack,
        filename,
      });
      signOut();
      return null;
    }
  }

  return {
    accessToken,
    signIn,
    signOut,
    isTokenExpired,
    getPermissions,
    getUserRoles,
    getUserName,
    getUserEmail,
    getUserId,
    getCompanyDetails,
  };
};

export default useAuth;
