// Show the overall score of the candidate in a pie filled dynamically based on their score
import React from 'react';
import styles from './TotalScorePie.module.css'; // Make sure to update your CSS accordingly

const TotalScorePie = ({ score, pdfMode = false }) => {
    // Adjust radius to accommodate the stroke width within the container
    const radius = 65; // Adjust radius to account for stroke width
    const percentage = score;
    const circumference = Math.PI * radius; // Circumference of the full circle
    const strokeDasharray = `${circumference}`;
    const strokeDashoffset = ((100 - percentage) / 100) * circumference;

    const getFillColor = (score) => {
        if (score >= 80) return '#00B050'; // green
        else if (score >= 60) return '#9acd32'; // Lime green
        else if (score >= 40) return '#ffbf00'; // Amber
        else if (score >= 20) return '#ff7f00'; // Orange
        return '#ff0000'; // Red
    };

    return (
        <div className={styles.totalScoreContainer} >
            <svg
                className={styles.scoreSemiCircle}
                viewBox="0 0 180 85" // This should cover the full area of the container
                preserveAspectRatio="xMidYMid meet"
            >
                {/* Adjust the path to start and end points considering the stroke width */}
                <path
                    d={`M25,85 A40,40 0 0,1 155,85`}
                    fill="none"
                    stroke="#ddd"
                    strokeWidth="25"
                />
                <path
                    d={`M25,85 A40,40 0 0,1 155,85`}
                    fill="none"
                    stroke={getFillColor(score)}
                    strokeWidth="25"
                    strokeDasharray={strokeDasharray}
                    strokeDashoffset={strokeDashoffset} // Adjust based on score
                />
            </svg>
            <div className={`${styles.scoreText} ${pdfMode ? styles.scoreTextPdfMode : ''}`}>
                {score}
            </div>
        </div>
    );
};

export default TotalScorePie;