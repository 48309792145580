import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import styles from './InfoTable.module.css'; // Make sure to define your CSS styles

const InfoTable = ({ headers, rows, popout = false, popoutPosition = 'right', }) => {

    return (
        <div className={`${styles.tableContainer} ${popout ? `${styles.popoutContainer} ${styles[popoutPosition]}` : ''}`}>
            <table className={styles.infoTable}>
                <thead>
                    <tr>
                        <th></th> {/* Empty cell for the row headers */}
                        {headers.map((header, index) => (
                            <th key={index}>{header}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {rows.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            <td className={styles.rowHeader}>{row.label}</td>
                            {row.data.map((cell, cellIndex) => (
                                <td key={cellIndex} className={styles.cell}>
                                    {cell === true && <FontAwesomeIcon icon={faCheck} className={styles.checkIcon} />}
                                    {cell === false && <FontAwesomeIcon icon={faTimes} className={styles.timesIcon} />}
                                    {typeof cell === 'string' && cell !== true && cell !== false && cell}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default InfoTable;
