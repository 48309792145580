//send code to compiler and get results
import axios from 'axios';
import { logEvent, logException, logTrace } from './loggerFront';

const fileName = 'compileCodeService' // for Logging

// Compile the code
const executeCode = async (compiler, language, code, roleCode, version = '', uniqueId = '', opts = {}, isSetup = false ) => {
    logTrace('executeCode', { compiler, language, code, roleCode, version, uniqueId, opts, isSetup }, fileName);
    uniqueId = String(uniqueId);  // Ensure uniqueId is a string

    try {
        const response = await axios.post('/api/execute-code', { compiler, language, code, version, opts, uniqueId, isSetup, roleCode});
        return response.data;
    } catch (error) {
        logException('Error: executing code', {
            errorType: 'CodeExecutionError',
            errorMessage: error.message,
            errorStack: error.stack, 
            code: code,
            language: language,
            codeVersion: version,
            fileName: fileName,
        });
        throw error;
    }
};

// Get the latest schema
const fetchSchema = async (uniqueId) => {
    logTrace('fetchSchema', { uniqueId, fileName });
    uniqueId = String(uniqueId);  // Ensure uniqueId is a string

    try {
        const response = await axios.get('/api/db-schema', {
            params: { uniqueId }
        });
        logTrace('fetchSchema', { response: response, fileName, testAttemptId: uniqueId});
        return response.data;
    } catch (error) {
        logException('Error: executing code', {
            errorType: 'CodeExecutionError',
            errorMessage: error.message,
            errorStack: error.stack, 
            uniqueId,
            fileName: fileName,
        });
        throw error;
    }
};

export { executeCode, fetchSchema };
