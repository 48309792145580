// Get the headings from a markdown file
export const slugify = (text) => {
    return text
        .toString()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .replace(/\s+/g, '-')
        .replace(/[^\w\-]+/g, '')
        .replace(/\-\-+/g, '-')
        .replace(/^-+/, '')
        .replace(/-+$/, '');
};

export const extractHeadingsFromMarkdown = (markdown) => {
    const lines = markdown.split('\n');
    const headings = lines
        .filter(line => line.startsWith('## ') || line.startsWith('### '))
        .map(line => {
            const level = line.startsWith('### ') ? 3 : 2;
            const text = line.replace(/^###?\s/, '');
            const slug = slugify(text);
            return { level, text, slug };
        });
    return headings;
};
