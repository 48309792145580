import React from 'react';
import PropTypes from 'prop-types';
import styles from './TopRibbon.module.css'; // Assuming you use modular CSS

const TopRibbon = ({ title, children }) => {
  return (
    <div className={styles.topRibbon}>
      <div className={styles.pageHeader}>
        {title}
      </div>
      {children && <div className={styles.ribbonContent}>{children}</div>}
    </div>
  );
};

TopRibbon.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default TopRibbon;
