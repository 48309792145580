//For enabling users to resize the interface elements
import React from 'react';
import styles from './Resizer.module.css';

const Resizer = ({ type, onMouseDown }) => {
    return (
        <div 
            className={type === 'vertical' ? styles.verticalResizer : styles.horizontalResizer}
            onMouseDown={onMouseDown}
        >
            <div className={type === 'vertical' ? styles.verticalGrip : styles.horizontalGrip}></div>
        </div>
    );
};

export default Resizer;