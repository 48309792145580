import React from 'react';
import Logo from '../common/DegrauLogo';
import styles from './Popup.module.css'; 

const TestDownContent = ({ onLoad, logoLoaded, ...otherProps }) => {
    return (
        <>
            <Logo className={styles.degrauLogoPopup} onLoad={onLoad} /> 
            <div className={styles.popupTextLeft}>
            <p>Lamentamos, mas estamos enfrentando dificuldades para carregar sua entrevista no momento. Vamos notificá-lo quando voltar a funcionar com o e-mail que você enviou</p>
            <p>Se você tiver alguma dúvida, por favor, entre em contato conosco pelo e-mail <b>suporte@degrau.co</b></p>
        </div>
        </>
    );
};

export default TestDownContent;