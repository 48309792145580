// Full screen component to show when user doesn't have permission to access a page
import React from 'react';
import styles from './AccessDenied.module.css';

const AccessDenied = () => {
  return (
    <div className={styles.accessDeniedContainer}>
      <div className={styles.accessDeniedHeader}>Acesso negado</div>
      <div className={styles.accessDeniedText}>Você não tem permissão para visualizar esta página.</div>
    </div>
  );
};

export default AccessDenied;
