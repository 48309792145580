import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons'; // filled icons
import styles from './LinkContainer.module.css';

const LinkContainer = ({ shareableLink }) => {
    const [isHoveringCopy, setIsHoveringCopy] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const copyToClipboard = () => {
        setIsHoveringCopy(false);
        navigator.clipboard.writeText(shareableLink).then(() => {
            setErrorMessage('');
            setSuccessMessage('Link copiado');
            setTimeout(() => { setSuccessMessage(''); }, 3000);
        }).catch(err => {
            setErrorMessage('Erro ao copiar o link');
        });
    };

    const handleCopyMouseEnter = () => {
        setIsHoveringCopy(true);
    };

    const handleCopyMouseLeave = () => setIsHoveringCopy(false);

    return (
        <>
            <div className={styles.linkContainer}>
                <span className={styles.shareableLink}>{shareableLink}</span>
                <button onClick={copyToClipboard} className={styles.copyButton} aria-label="Copiar link">
                    <FontAwesomeIcon 
                        icon={faCopy} 
                        onMouseEnter={handleCopyMouseEnter}
                        onMouseLeave={handleCopyMouseLeave}
                    />
                </button>
                {isHoveringCopy && (
                    <div className={styles.copyHoverMessage}>
                        Copiar link
                    </div>
                )}
            </div>
            {errorMessage && (
                <div className={styles.errorMessage}>
                    {errorMessage}
                </div>
            )}
            {successMessage && (
                <div className={styles.successMessage}>
                    {successMessage}
                </div>
            )}
        </>
    );
};

export default LinkContainer;
