// Functions needed for authenticating or registering users to view reports
import axios from "axios";
import { logException, logTrace } from "./loggerFront"; // To log to app insights
import useAuth from "../hooks/useAuth";

const fileName = "authenticationService"; // for Logging

const loginUser = async (email, password) => {
  logTrace("loginUser", { email, fileName });

  try {
    const response = await axios.post("/api/users/login", { email, password });
    return response.data.token;
  } catch (error) {
    logException("Error: logging in user", {
      errorMessage: error.message,
      errorStack: error.stack,
      email,
      fileName,
    });
    throw error;
  }
};

// Change the user's password by sending the new password and the recovery token to the API. The token identifies the user
const resetPassword = async (recoveryToken, newPassword) => {

  try {
    const response = await axios.post("/api/users/reset-password", { recoveryToken, newPassword });
    return response.data; // Should have success so we can then show a success message telling to check their email
  } catch (error) {
    logException("Error: resetting password", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName,
    });
    throw error;
  }
};

// Check if token is valid and what permissions it has
const checkTokenPermissions = async (token) => {
  try {
    const response = await fetch(`/api/shareableLinks/check-token/${token}`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const permissions = await response.json(); // Parse the JSON response
    return permissions;
  } catch (error) {
    logException("Error checking token", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName: "yourFileName", // Replace with the actual file name variable if defined
      token,
    });
    throw error;
  }
};

// Function to generate a recovery token, which will lead to them getting an email with a link to reset their password
const generateRecoveryToken = async (email) => {
  logTrace("generateRecoveryToken", { email, fileName });

  try {
    const response = await axios.post("/api/users/generate-recovery-token", { email });
    return response.data; // Should return a success message
  } catch (error) {
    logException("Error: generating recovery token", {
      errorMessage: error.message,
      errorStack: error.stack,
      email,
      fileName,
    });
    throw error;
  }
};

// Change password of logged in user
const changePassword = async (currentPassword, newPassword) => {
  try {
    const { accessToken } = useAuth(); // Get the access token from the auth hook
    const token = accessToken();
    const response = await fetch(`/api/users/change-password`, {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}` // Include the auth token
      },
      body: JSON.stringify({ currentPassword, newPassword }), // Correctly structure the body
    });

    if (!response.ok) {
      const errorData = await response.json(); // Parse the error response
      throw new Error(errorData.message || "Failed to change password");
    }

    const data = await response.json(); // Parse the JSON response
    return data; // Return the success message
  } catch (error) {
    logException("Error: changing password", {
      errorMessage: error.message,
      errorStack: error.stack,
      fileName,
    });
    throw error; // Re-throw the error so it can be caught and handled in the UI
  }
};

export { loginUser, resetPassword, checkTokenPermissions, generateRecoveryToken, changePassword };
