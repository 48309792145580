const getInitials = async (name) => {
  return new Promise((resolve) => {
    const nameParts = name.split(' ').filter(Boolean); // Split the name by spaces and filter out empty parts

    const firstNameInitial = nameParts[0] ? nameParts[0][0] : ''; // First letter of the first name

    let lastNameInitial = '';
    if (nameParts.length > 1) {
      lastNameInitial = nameParts[nameParts.length - 1][0]; // First letter of the last name
    } else if (nameParts.length === 1) {
      lastNameInitial = ''; // No last name, just use the first name initial
    }

    const initials = `${firstNameInitial}${lastNameInitial}`.toUpperCase(); // Return initials in uppercase
    resolve(initials || firstNameInitial.toUpperCase()); // If initials are empty, just use the first name initial
  });
};

export default getInitials;
