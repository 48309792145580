import React, { useState } from 'react';
import Logo from '../common/DegrauLogo';
import Button from '../common/Button';
import styles from './Popup.module.css';

const RejectFailContent = ({ onConfirmReject, onCancelReject, onLoad, logoLoaded, ...otherProps }) => {

    const handleConfirm = () => {
        const reason = 'camera failed';
        onConfirmReject(reason); // Pass the reason back to PhotoCheck.js
    };

    const handleCancel = () => {
        onCancelReject(); // Hide the popup
    };

    return (
        <>
            <Logo className={styles.degrauLogoPopup} onLoad={onLoad} />
            <p className={styles.popupText}>    
                Estamos enfrentando problemas para carregar sua câmera, por favor, tente:
            </p>
            <ul className={styles.leftAlignedList}>
                <li>Verifique as configurações do navegador da câmera para este site</li>
                <li>Pressionar o botão "Tentar novamente" para tentar acessar a câmera novamente</li>
                <li>Recarregar a página</li>
                <li>Verificar se a câmera funciona em outro aplicativo ou site</li>
            </ul>
            <p className={styles.popupText}>
                Se isso não resolver pode continuar sem imagem ou envie um e-mail para <b>suporte@degrau.co</b>
            </p>
            
            <div className={styles.rejectPhotoButtonContainer}>
                <Button onClick={handleCancel} type="proceed" className={styles.cancelRejectPhoto}>
                    Tentar novamente
                </Button>
                <Button onClick={handleConfirm} type="subtle" className={styles.confirmRejectPhoto}>
                    Continuar sem imagem
                </Button>
            </div>
        </>
    );
};

export default RejectFailContent;
