// Table of data which can be sorted by clicking on the column headers
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';

const SortableTable = ({
  columns,
  data,
  onSort,
  sortConfig = {},
  renderRow,
  styles,
}) => {
  const renderHeaderCell = (column) => {
    return (
      <th
        key={column.key}
        onClick={() => column.sortable && onSort(column.key)}
        className={
          `${column.sortable
            ? sortConfig.key === column.key
              ? styles.activeSortHeader
              : styles.sortHeaderClickable
            : styles.sortHeader} ${
            column.align === 'center'
              ? styles.centeredHeader
              : styles.leftAlignedHeader
          }`
        }
      >
        <div className={styles.headerContent}>
          {column.label}
          {column.sortable && sortConfig.key === column.key && (
            <FontAwesomeIcon
              icon={
                sortConfig.direction === 'ascending' ? faSortUp : faSortDown
              }
            />
          )}
          {column.headerComponent && (
            <div className={styles.headerComponent}>
              {column.headerComponent}
            </div>
          )}
        </div>
      </th>
    );
  };

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          {columns.map(renderHeaderCell)}
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          <tr className={styles.sortableTableRow} key={index}>
            {renderRow(row)}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default SortableTable;
