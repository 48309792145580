// Interface bringing together the code editor, output, buttons and dropdown
import React, { useState, useContext, useRef, useEffect, useCallback } from 'react';
import { AppContext } from '../../contexts/AppContext';
import { useTestContext } from '../../contexts/TestContext';
import { useCodeContext } from '../../contexts/CodeContext';
import Button from '../common/Button';
import CodeDropdown from './CodeDropdown';
import CodeFontSize from './CodeFontSize';
import CodeEditor from './CodeEditor';
import CodeOutput from './CodeOutput';
import FileExplorer from './FileExplorer';
import LivePreview from './LivePreview';
import Tooltip from '../tooltip/Tooltip';
import { Tabs, Tab } from '../common/Tabs';
import { languageMappings } from './LanguageMapping';
import { TooltipContext } from '../../contexts/TooltipContext';
import { logEvent, logException, logTrace } from '../../services/loggerFront';
import useCodeActivityTracker from '../../hooks/useCodeActivityTracker';
import styles from './IDE.module.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons'; // non-filled icons
import { faExclamationCircle, faChevronLeft, faChevronRight, faBolt } from '@fortawesome/free-solid-svg-icons'; // filled icons

const IDE = ({ tabsPresent = false }) => {
    // Global states
    const { testAttemptId, currentSection } = useTestContext(); // Test stage to know when to save the code and transcript
    const { currentIdeCode, editorContent, previewContent, activeFile, setActiveFile, defaultFile, isMultiFile, ideCompilerResponse, showIdeCodeOutput, currentIdeLanguage, updateCurrentIdeLanguage, availableCodeLanguages, singleIdeLanguage, theme, themeDropdownOptions, themeDropdownValue, handleThemeChange, 
        showConfirmModal, setShowConfirmModal, handleConfirmIdeSubmission, confirmSubmitCode, handleExecuteCode, ideCurrentlyCompiling, showEmptyEditorAlert, setShowEmptyEditorAlert 
    } = useCodeContext(); // Save the code and transcript to the database

    // Editor settings
    const [autocompleteEnabled, setAutocompleteEnabled] = useState(true);
    const [autocompleteLocal, setAutocompleteLocal] = useState(false); // Flag it just has local autocomplete
    const [enableAutocompleteButton, setEnableAutocompleteButton] = useState(true);

    // Variables to save
    const { trackButtonClick } = useCodeActivityTracker(); //hook to record user activity
    const currentIdeLanguageRef = useRef(currentIdeLanguage);

    // Ui interactions
    const { activeTooltips } = useContext(TooltipContext);
    const [showScrollToBottomIde, setShowScrollToBottomIde] = useState(false);
    const [showScrollToTopIde, setShowScrollToTopIde] = useState(false);
    const ideContainerRef = useRef(null);
    const [isHoveringAutocomplete, setIsHoveringAutocomplete] = useState(false); // For showing the popup message when hovering over the autocomplete checkbox
    const [ideExtraInfo, setIdeExtraInfo] = useState(''); // For showing extra info in the IDE
    const [showIdeQuestionMark, setShowIdeQuestionMark] = useState(false); // For showing the question mark icon in the IDE
    const [showLeftTab, setShowLeftTab] = useState(true);

    // Test retrieval
    const { retrievedSession } = useContext(AppContext); // If code needs to be set when a session is restored from a returning user

    const fileName = 'IDE'; // for logging

    // FUNCTIONS FOR BUTTONS/DROPDOWNS //

    // Determine the theme class
    const themeClass = theme === 'dark' ? styles.dark : styles.light;

    // Function to determine if a language should be included based on availableCodeLanguages
    const shouldIncludeLanguage = (languageKey) => {
        return availableCodeLanguages.includes("all") || availableCodeLanguages.includes(languageKey);
    };

    // Update the current programming language in global context when the language dropdown changes
    const handleLanguageChange = (e) => {
        const selectedKey = e;
        updateCurrentIdeLanguage(selectedKey);
        setIsHoveringAutocomplete(false); // Close the autocomplete hover message if it is open
    
        // Check if the selected language supports autocomplete
        const selectedLanguageAutocomplete = languageMappings[selectedKey].autocomplete;
        if (selectedLanguageAutocomplete === 'N') {
            setAutocompleteEnabled(false);
            setEnableAutocompleteButton(false);
            setAutocompleteLocal(false);
        } else if (selectedLanguageAutocomplete === 'Local') { // Local autocomplete to be flagged in the UI
            setAutocompleteEnabled(true);
            setEnableAutocompleteButton(true);
            setAutocompleteLocal(true);
        } else {
            setAutocompleteEnabled(true);
            setEnableAutocompleteButton(true);
            setAutocompleteLocal(false);
        }
    
        trackButtonClick({ 
            buttonId: 'languageSelector', 
            selectedKey,
            currentCode: currentIdeCode 
        });
    };

     // Show pdf popup when icon is hovered
     const handleAutocompleteMouseEnter = () => {
        setIsHoveringAutocomplete(true);
    };

    // Stopping showing when they stop hovering
    const handleAutocompleteMouseLeave = () => setIsHoveringAutocomplete(false);

    // Update the language ref after any change
    useEffect(() => {
        currentIdeLanguageRef.current = currentIdeLanguage;
    }, [currentIdeLanguage]); 

    // Toggle the left tab
    const toggleLeftTab = () => {
        setShowLeftTab(!showLeftTab);
    };

    const tabIcon = showLeftTab ? faChevronLeft : faChevronRight;

    // SCROLLING FUNCTIONS //

    // When called the IDE goes to the end (showing all the console) smoothly
    const scrollToBottomIde = () => {
        if (ideContainerRef.current) {
            ideContainerRef.current.scrollTo({
                top: ideContainerRef.current.scrollHeight,
                behavior: 'smooth'
            });
        }
        setShowScrollToBottomIde(false);
    };

    // When called, scrolls the IDE to the top smoothly
    const scrollToTopIde = () => {
        if (ideContainerRef.current) {
            ideContainerRef.current.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
        setShowScrollToTopIde(false);
    };

     // Function to check the scroll position and scrollability
     const checkScrollPosition = () => {
        if (ideContainerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = ideContainerRef.current;
            const isScrollable = scrollHeight > clientHeight;
            const isAtTop = scrollTop === 0;
            setShowScrollToBottomIde(isScrollable && (scrollHeight - scrollTop) > clientHeight + 10);
            setShowScrollToTopIde(isScrollable && !isAtTop);
        }
    };

    // Effect for adding/removing scroll event listener
    useEffect(() => {
        const ideContainer = ideContainerRef.current;
        if (ideContainer) {
            ideContainer.addEventListener('scroll', checkScrollPosition);

            // Also check scrollability when window resizes
            window.addEventListener('resize', checkScrollPosition);
    
            return () => {
                ideContainer.removeEventListener('scroll', checkScrollPosition);
                window.removeEventListener('resize', checkScrollPosition);
            };
        }
    }, []);

    // Effect to check scrollability when compilerResponse changes
    useEffect(() => {
        checkScrollPosition();
    }, [ideCompilerResponse]);

    // Check if the tooltips should be visible
    const showIdeLanguageTooltip = activeTooltips['ideLanguage'];
    const showIdeSubmitTooltip = activeTooltips['ideSubmit'];
    const showIdeExecuteTooltip = activeTooltips['ideExecute'];

    // Apply dynamic width based on the resize or default width
    const dynamicWidthStyle = {
        width: '100%' // Adjust accordingly to your dynamic width requirements
    };

    return (
        <div className={`${styles.ide} ${themeClass}`} style={dynamicWidthStyle} ref={ideContainerRef}>
            {showIdeLanguageTooltip && <Tooltip variant="ideLanguage" />}

            {isMultiFile && (
                <div className={`${styles.leftTab} ${!showLeftTab ? styles.hidden : ''}`}>
                    <button className={styles.toggleBtn} onClick={toggleLeftTab}>
                        <FontAwesomeIcon icon={tabIcon} />
                    </button>
                    {showLeftTab && (
                        <Tabs>
                            <Tab label="File Explorer">
                                <FileExplorer 
                                    currentCode={currentIdeCode}
                                    activeFile={activeFile}
                                    setActiveFile={setActiveFile}
                                    defaultFile={defaultFile}
                                />
                            </Tab>
                            <Tab label="Live Preview">
                                <LivePreview 
                                    content={previewContent}
                                />
                            </Tab>
                        </Tabs>
                    )}
                </div>
            )}

            <div className={`${styles.mainTab} ${!showLeftTab ? styles.wide : ''} ${!isMultiFile ? styles.full : ''}`}>

                <div className={`${styles.ideTopRibbon} ${themeClass}`}>

                    <div className={styles.languageButtons}>
                        {singleIdeLanguage ? (
                            <p>Linguagem: {currentIdeLanguageRef.current.key}</p>
                        ) : (
                            <CodeDropdown
                                options={Object.entries(languageMappings)
                                    .filter(([key]) => shouldIncludeLanguage(key))
                                    .map(([key, { monaco }]) => ({
                                        value: key,
                                        label: key,
                                    }))}
                                onChange={(e) => handleLanguageChange(e.target.value)}
                                currentValue={currentIdeLanguageRef.current.key}
                            />
                        )}

                        {/* Autocomplete toggle */}
                        <div className={`${!enableAutocompleteButton ? styles.disabledAutoCheckbox : styles.autoCheckbox} ${themeClass}`}>
                            <input 
                                type="checkbox" 
                                id="autocompleteCheckbox" // Added an id for associating the label with the checkbox
                                checked={autocompleteEnabled} 
                                onChange={(e) => setAutocompleteEnabled(e.target.checked)} 
                                disabled={!enableAutocompleteButton}
                            />
                            <label htmlFor="autocompleteCheckbox">
                                Autocomplete 
                                {autocompleteLocal && (
                                    <>
                                        <FontAwesomeIcon 
                                            icon={faExclamationCircle}
                                            onMouseEnter={handleAutocompleteMouseEnter}
                                            onMouseLeave={handleAutocompleteMouseLeave}
                                        />
                                        {isHoveringAutocomplete && (
                                            <div className={styles.autocompleteButtonHoverMessage}>
                                            Autocomplete limitado, sugere apenas com base no código já presente
                                            </div>
                                        )}
                                    </>
                                )}
                            </label>
                        </div>

                        {showIdeQuestionMark && (
                            <div className={styles.questionMarkIcon}>
                                <FontAwesomeIcon
                                    icon={faQuestionCircle}
                                    className={styles.infoIcon}
                                />
                                <span className={styles.tooltipText}>{ideExtraInfo}</span>
                            </div>
                        )}

                    </div>

                    <div className={styles.uiButtons}>
                        <CodeDropdown
                            options={themeDropdownOptions} 
                            onChange={handleThemeChange}
                            currentValue={themeDropdownValue}
                        />
                        <CodeFontSize/>
                    </div>

                </div>

                <CodeEditor 
                    propMonacoLanguage={currentIdeLanguage.monaco} 
                    aceLanguage={currentIdeLanguage.ace}
                    defaultValue={editorContent} 
                    autocompleteEnabled={autocompleteEnabled}
                    tabsPresent={tabsPresent}
                />

                {showConfirmModal && (
                    <div className={`${styles.confirmSubmitModel} ${themeClass}`}>
                        <div className={styles.confirmSubmitModelTitle}> Aviso </div>
                        <p>Tem certeza de que deseja submeter seu código?</p>
                        <div className={styles.confirmSubmitModelButtons}>
                            <Button 
                                onClick={handleConfirmIdeSubmission} 
                                type="proceed" 
                            >
                                Confirmar
                            </Button>
                            <Button 
                                onClick={() => setShowConfirmModal(false)}
                                type="subtle" 
                            >
                                Cancelar
                            </Button>
                        </div>
                    </div>
                )}

                {showIdeSubmitTooltip && <Tooltip variant="ideSubmit" />}
                {showIdeExecuteTooltip && <Tooltip variant="ideExecute" />}

                <div className={`${styles.ideMiddleRibbon} ${themeClass}`}>

                    <Button type="submitCode" onClick={confirmSubmitCode} />
                    <Button type="executeCode" onClick={() => handleExecuteCode(scrollToBottomIde)} />

                    {showEmptyEditorAlert && (
                        <div className={styles.emptyEditorAlert}>
                            <div className={styles.emptyEditorAlertTitle}> Aviso </div>
                            <p>O editor não pode estar vazio.</p>
                            <Button
                                onClick={() => setShowEmptyEditorAlert(false)}
                                type="tooltip"
                            >
                                Ok
                            </Button>
                        </div>
                    )}
                    
                </div>
                
                {showIdeCodeOutput && (
                    <CodeOutput 
                        compilerResponse={ideCompilerResponse} 
                        currentlyCompiling={ideCurrentlyCompiling}
                    />
                )}

                {showScrollToBottomIde && !showScrollToTopIde && (
                    <div className={`${styles.scrollToBottomButton} ${themeClass}`} onClick={scrollToBottomIde}>
                        <i className="fas fa-arrow-down"></i> {/* Font Awesome icon */}
                    </div>
                )}

                {showScrollToTopIde && (
                    <div className={`${styles.scrollToTopButton} ${themeClass}`} onClick={scrollToTopIde}>
                        <i className="fas fa-arrow-up"></i> {/* Font Awesome icon */}
                    </div>
                )}

            </div>
        </div>
    );
};

export default IDE;
