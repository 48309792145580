// mysql date to dd/mmm/yyyy
const formatDate = (dateString) => {
    if (!dateString) return '';

    // If dateString does not include a time, add 'T12:00:00' to set it to midday
    const dateTimeString = dateString.length <= 10 ? `${dateString}T12:00:00` : dateString;

    const date = new Date(dateTimeString);
    const options = { day: '2-digit', month: 'short', year: 'numeric' };

    return new Intl.DateTimeFormat('pt-BR', options).format(date).replace(/ de /g, ' ');
};

export default formatDate;
