//Record activity in IDE to enable playback
import React, { useState, useContext, useEffect, useCallback } from 'react';
import { AppContext } from '../contexts/AppContext';
    
const useCodeActivityTracker = () => {
    const { formatDateForMySQL, retrievedCodeActivities, retrievedSession } = useContext(AppContext);
    const [activities, setActivities] = useState([]);
    const aggregateTime = 300; // time in milliseconds to aggregate keystrokes

    let lastKeystrokeTime = 0;
    let keystrokeBuffer = '';

    // If it is a retrieved session, start the activities with the previous session log
    useEffect(() => {
        if (retrievedSession && retrievedCodeActivities) {
            console.log('retrievedCodeActivities', retrievedCodeActivities);
            setActivities(retrievedCodeActivities);
        }
    }, [retrievedSession, retrievedCodeActivities]);

    //add activities to the log with the timestamps as per mySQL requirements
    const addActivity = useCallback((type, detail) => {
        const timestamp = new Date();
        const formattedTimestamp = formatDateForMySQL(timestamp);
        setActivities(prevActivities => [...prevActivities, { type, detail, timestamp: formattedTimestamp }]);
    }, []);

    // For keystrokes in the editor
    let timeoutId = null;

    // Process each action from the ace or monaco editor to prapre to be added to the log 
    const trackKeystrokes = useCallback((changeDetails) => {
        const currentTimestamp = Date.now();
        const isMonaco = changeDetails.source === 'monaco';
    
        if (changeDetails.changes && Array.isArray(changeDetails.changes)) {
            changeDetails.changes.forEach(change => {
                // Note: Whether it is remove or insert is decide in the codeEditor handleMonacoChange or handleAceChange
                const isInsert = change.action === 'insert';
                const newKeystrokes = []; // Prepare a list to hold potentially multiple keystroke events
                // For Monaco, if the action is 'remove' and there's text involved, handle it as a 'remove' followed by 'insert', as this is where the user typed over existing code
                if (isMonaco && change.action === 'remove' && change.text) {
                    // First, log the removal
                    newKeystrokes.push({
                        action: 'remove',
                        start: {
                            line: change.startLineNumber,
                            column: change.startColumn,
                        },
                        end: {
                            line: change.endLineNumber,
                            column: change.endColumn,
                        },
                    });
                    // Then, consider the new text as an insert at the start position
                    newKeystrokes.push({
                        action: 'insert',
                        start: {
                            line: change.startLineNumber,
                            column: change.startColumn,
                        },
                        text: change.text, // The new text being inserted
                    });
                } else {
                    // For all other cases process the change normally
                    newKeystrokes.push({
                        action: change.action,
                        start: {
                            line: change.startLineNumber,
                            column: change.startColumn,
                        },
                        ...(isInsert ? { text: change.text } : {
                            end: {
                                line: change.endLineNumber,
                                column: change.endColumn,
                            },
                        }),
                    });
                }

                newKeystrokes.forEach(newKeystroke => {
                    processKeystroke(newKeystroke, currentTimestamp);
                });
            });

            // Clear the existing timeout
            if (timeoutId) {
                clearTimeout(timeoutId);
            }

            // Set a new timeout to ensure the last keystrokes are logged after the aggregation period
            timeoutId = setTimeout(() => {
                if (keystrokeBuffer.length > 0) {
                    addActivity('keystroke', keystrokeBuffer[0]);
                    keystrokeBuffer = [];
                }
            }, aggregateTime);
        }
    }, [addActivity, aggregateTime]);

    // Add the keytroke to the log, if there are several insert or removals in quick succession aggregate them
    function processKeystroke(newKeystroke, currentTimestamp) {
        // If it's within the aggregation time, try to aggregate
        
        if (currentTimestamp - lastKeystrokeTime <= aggregateTime && keystrokeBuffer.length > 0) {
            const lastKeystroke = keystrokeBuffer[keystrokeBuffer.length - 1];
    
            // Only aggregate actions of the same type
            if (lastKeystroke.action === newKeystroke.action) {
                // If the keystroke have more than 1 character already dont aggregate, it is an autocomplete or paste
                if (newKeystroke.action === 'insert' && newKeystroke.text.length > 1) {
                    if (lastKeystroke.text) {
                        addActivity('keystroke', lastKeystroke);
                    }
                    keystrokeBuffer = [newKeystroke]; // Start a new buffer with the current keystroke
                }
                else if (newKeystroke.action === 'insert') {
                    // Otherwise aggregate insert actions with single character text if they are within the buffer time
                    lastKeystroke.text = lastKeystroke.text ? lastKeystroke.text + newKeystroke.text : newKeystroke.text;
                }
                // For remove actions, adjust the start position to aggregate
                else if (newKeystroke.action === 'remove') {
                    lastKeystroke.start = newKeystroke.start;
                }
            } else {
                // For different actions, log the previous action immediately
                addActivity('keystroke', lastKeystroke);
                keystrokeBuffer = [newKeystroke];
            }
        } else {
            // If it's beyond the aggregation time or the buffer is empty, log the previous action and start a new buffer
            if (keystrokeBuffer.length > 0 && keystrokeBuffer[0].text) {
                addActivity('keystroke', keystrokeBuffer[0]);
            }
            keystrokeBuffer = [newKeystroke];
        }
        
        lastKeystrokeTime = currentTimestamp;
    }

    useEffect(() => {
        return () => {
            // Clear the timeout when the component unmounts
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, []);

    // For having a log of current code to enable retrieval
    const currentCodeLog = useCallback((currentCode, currentLanguage) => {
        addActivity('currentCode', { currentCode, currentLanguage });
    }, [addActivity]);

    // For submit/execute code, or theme or language changes
    const trackButtonClick = useCallback((details) => {
        addActivity('buttonClick', details);
    }, [addActivity]);

    // For tracking compiler responses
    const trackCompilerResponse = useCallback((details) => {
        addActivity('compilerResponse', details);
    }, [addActivity]);

    // For tracking when the user leaves or returns to the tab
    const trackFocusChange = useCallback((focusStatus) => {
        addActivity('focusChange', { focusStatus });
    }, [addActivity]);

    // Function to clear the log
    const clearActivities = useCallback(() => {
        setActivities([]); // Clear the activities log
    }, []);

    useEffect(() => {
    }, [activities]);    

    // Effect for cleanup or other side effects if needed
    useEffect(() => {
        // Cleanup or side effects on unmount or changes
        return () => {
            if (keystrokeBuffer !== '') {
                addActivity('keystroke', keystrokeBuffer);
            }
        };
    }, [keystrokeBuffer, addActivity]);

    return { activities, trackKeystrokes, trackButtonClick, trackCompilerResponse, clearActivities, trackFocusChange, currentCodeLog };
};

export default useCodeActivityTracker;
