// If the test is interrupted (database, API issues or loses connection with the server), this component will be displayed to the user
import React, { useContext, useEffect, useState  } from 'react';
import Logo from '../common/DegrauLogo';
import { useAppContext } from '../../contexts/AppContext';
import { useTestContext } from '../../contexts/TestContext';
import styles from './Popup.module.css';
import { PacmanLoader } from 'react-spinners'; // For the loading icon waiting for connection to reconnect

const testInterruptedContent = ({ onLoad, logoLoaded, ...otherProps }) => {
    const { INTERRUPTION_RETRY_MAX, INTERRUPTION_RETRY_INTERVAL, isTestInterrupted, isChatInterrupted } = useAppContext();
    const { testAttemptId, candidateId, candidateEmail } = useTestContext();
    const [retryCount, setRetryCount] = useState(0);
    const [countdown, setCountdown] = useState(INTERRUPTION_RETRY_INTERVAL); // USe same time intervals as the retry mecanisms use

    // Create the urlCode that will be used to retrieve the session
    const testAttemptIdReversed = String(testAttemptId).split('').reverse().join('');
    const urlCode = parseInt(testAttemptId * candidateId + testAttemptIdReversed);

    // Countdown with the same intervals and amount as the retry variables that are used to save data when the first time fails
    useEffect(() => {
        if (retryCount < INTERRUPTION_RETRY_MAX) {
            const timerId = setInterval(() => {
                setCountdown((prevCountdown) => {
                    if (prevCountdown <= 1) {
                        clearInterval(timerId);
                        setRetryCount(retryCount + 1);
                        setCountdown(INTERRUPTION_RETRY_INTERVAL);
                        if (retryCount + 1 === INTERRUPTION_RETRY_MAX) {
                            // Logic to hide the message or change behavior after max retries
                        }
                        return INTERRUPTION_RETRY_INTERVAL; // Reset for next retry
                    }
                    return prevCountdown - 1;
                });
            }, 1000);

            return () => clearInterval(timerId);
        }
    }, [isTestInterrupted, isChatInterrupted, INTERRUPTION_RETRY_INTERVAL, retryCount ]);

    return (
        <>
            <Logo className={styles.degrauLogoPopup} onLoad={onLoad}/>
            <div className={styles.popupText}>
                <p>Desculpe, estamos enfrentando dificuldades técnicas no momento. Não se preocupe, pois seus dados foram salvos.</p>
                <p><br/>Se não for resolvido após as novas tentativas, você poderá reiniciar sua entrevista de onde parou usando este link: <strong><a href={`/entrevista?tentativa=${urlCode}`} target="_blank">app.degrau.co/entrevista?tentativa={urlCode}</a></strong>.</p>
                {retryCount < INTERRUPTION_RETRY_MAX ? (
                    <div className={styles.retryNotice}>
                        <p>Tentaremos conectar novamente em {countdown} segundos. Tentativas restantes: {INTERRUPTION_RETRY_MAX - retryCount}</p>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <PacmanLoader size={15} color={"#246E24"} />
                        </div>
                    </div>
                ) : (
                    <div className={styles.retryNotice}>
                        <p>Estamos trabalhando para resolver o problema. O link para retomar a sua entrevista será enviado para o endereço de email. Por favor verifique sua pasta de spam. <strong>{candidateEmail}</strong> assim que o problema for resolvido.</p>
                    </div>
                )}
                <p>Se precisar de ajuda, entre em contato conosco pelo e-mail <b>suporte@degrau.co</b>.</p>
            </div>
        </>
    );
    
};

export default testInterruptedContent;
