import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import styles from './KanbanCard.module.css';

const KanbanCard = ({ data, index }) => {
    return (
        <Draggable draggableId={data.id.toString()} index={index}>
          {(provided, snapshot) => (
            <div
              className={styles.card}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
                <h4>{data.name}</h4>
                <p>{data.amount}</p>
                <p>{data.date}</p>
            </div>
          )}
        </Draggable>
    );
};

export default KanbanCard;
