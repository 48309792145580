// check if the email is a public domain or, if not, therefore a business domain
const publicDomains = [
    "gmail.com",
    "yahoo.com",
    "outlook.com",
    "hotmail.com",
    "aol.com",
    "icloud.com",
    "mail.com",
    "zoho.com",
    "yandex.com"
];

export const isBusinessEmail = (email) => {
    const domain = email.split('@')[1];
    return !publicDomains.includes(domain);
}